import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, HOST, IArticle, IBrand, IMenu, IMeta, ISet, ITextBook, ITextBookIndex, ITextBookTab, ITextbookBasicData, S3Server, latex_delimiters, latex_macros } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import TextBookIndexList from "./TextBookIndexList";
import PopupTextBookEdit from "./PopupTextBookEdit";
import TextBookIndexEdit, { NodeData } from "./TextBookIndexEdit";
import { log } from "console";
import PopupAddTab from "./PopupAddTab";
import PopupSetList from "./PopupSetList";
import PopupContentsList from "./PopupContentsList";
import PopupIndexNSet from "./PopupIndexNSet";
import PopupAddCurriUnit from "./PopupAddCurriUnit";
import { NodeModel } from "@minoru/react-dnd-treeview";
import PopupConfirm from "../contents/PopupConfirm";
import { theme } from "./DND/theme";
import 'katex/dist/katex.min.css';
import "../latex.css";
import Latex from 'react-latex-next';

export interface ITextBookIndexCurriculum {
    id: number;
    parent: number;
    text: string;
    is_active: boolean;
    is_addContents: boolean;
    is_delete: boolean;
    depth: number;
    child_depth: number;
    textbookIndex_id: number;
    indexName: string;
}

interface ITextBookEdit_1 {
    brand: IBrand;
    menu: IMenu;
    originTextBook: ITextBook;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    onClose: () => void;
}
  
function TextBookEdit_1(props: ITextBookEdit_1) {
    
    const [version, setVersion] = useState("1");
    const [lastUpdateDate, setLastUpdateDate] = useState("");
    // const [subject, setSubject] = useState("");
    const [textbookCategory, setTextbookCategory] = useState<IMeta[]>([]);

    const [viewList, setViewList] = useState(false);

    const [searchData, setSearchData] = useState(null as any);

    const [isEditing, setIsEditing] = useState(false);
    
    const [textbookData, setTextBookData] = useState<ITextBook>(null as any);
    const [curriBook, setCurriBook] = useState(null as any);
    const [currIndex, setCurrIndex] = useState<ITextBookIndex>(null as any);

    const [tabList, setTabList] = useState<ITextBookTab[]>(null as any);
    // const [currTabList, setCurrTabList] = useState<ITextBookTab[]>([]);
    const [selectedTab, setSelectedTab] = useState<ITextBookTab>(null as any);

    const [deleteTabList, setDeleteTabList] = useState<ITextBookTab[]>([]);

    const [curriculumList, setCurriculumList] = useState<ITextBookIndexCurriculum[]>([]);
    const [curriculumDepth1List, setCurriculumDepth1List] = useState<ITextBookIndexCurriculum[]>([]);
    const [curriculumDepth2List, setCurriculumDepth2List] = useState<ITextBookIndexCurriculum[]>([]);
    const [curriculumDepth3List, setCurriculumDepth3List] = useState<ITextBookIndexCurriculum[]>([]);
    const [curriculumDepth4List, setCurriculumDepth4List] = useState<ITextBookIndexCurriculum[]>([]);


    const [canViewSet, setCanViewSet] = useState(false);
    // const [changedTabList, setChangedTabList] = useState<ITextBookTab[]>([]);
    const [textBookIndexId, setTextBookIndexId] = useState(null as any);
    const [previewUrl, setPreviewUrl] = useState("");

    const [selectedCurriculum, setSelectedCurriculum] = useState<ITextBookIndexCurriculum>(null as any);
    const [selectedTabSetInfo, setSelectedTabSetInfo] = useState<ISet>(null as any);
    const [selectedCurriculumToAdd, setSelectedCurriculumToAdd] = useState<ITextBookIndexCurriculum>(null as any);

    const [viewTextBookEdit, setViewTextBookEdit] = useState(false);

    const [viewPopupTabEdit, setViewPopupTabEdit] = useState(false);
    const [viewPopupSetList, setViewPopupSetList] = useState(false);
    const [viewPopupIndexNSet, setViewPopupIndexNSet] = useState(false);
    const [viewPopupContentsList, setViewPopupContentsList] = useState(false);
    const [viewPopupAddCurriUnit, setViewPopupAddCurriUnit] = useState(false);

    const [viewTextBookIndexEdit, setViewTextBookIndexEdit] = useState(false);

    const [treeData, setTreeData] = useState<NodeModel[]>([]);
    const [selectedNode, setSelectedNode] = useState(null as any);
    const [isAITab, setIsAITab] = useState(false);

    const [selectedEditTab, setSelectedEditTab] = useState<ITextBookTab>(null as any);
    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");

    const [viewConfirmChangeIndex, setViewConfirmChangeIndex] = useState(false);
    const [changeIndexTitle, setChangeIndexTitle] = useState("");
    const [changeIndexDesc, setChangeIndexDesc] = useState("");

    const [isDefaultTab, setIsDefaultTab] = useState(false);

    const [selectedCurriculumId, setSelectedCurriculumId] = useState(-1);

    const curriInfo = util.getCurrentCurriInfo();

    // let selectedNode = "";
    let g_curriculumList: ITextBookIndexCurriculum[] = [];
    let g_treeData: NodeModel[] = [];

    // let valLatex = "What is $(3\\times 4) \\div (5-3)$";

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // const basicData = props.basicData;
            const originTextBook = props.originTextBook;
            setTextBookData(originTextBook);
            
            // const curriYear = curriInfo?.curriYear.find((item) => item.id === basicData.curriYear);
            const curriBook = curriInfo?.curriBook.find((item) => item.id === props.originTextBook.curriBook);
            setCurriBook(curriBook);

            if(originTextBook.textbookIndex_id) {
                setTextBookIndexId(originTextBook.textbookIndex_id);
                getTextBookIndexInfo(originTextBook.textbookIndex_id);
            }

            logger.log("originTextBook", originTextBook);
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            // logger.log("props.brand", props.brand);
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(tabList) {
            logger.log("tabList", tabList, selectedCurriculum);
            if(selectedCurriculum) {
                
                setSelectedCurriculumId(selectedCurriculum.id);                    

                // if(selectedCurriculum.depth < 3) {
                //     const _node = document.getElementById("node_button_"+selectedCurriculum.depth+"_"+selectedCurriculum.id) as HTMLElement;
                //     if(_node) {
                //         _node.className = _node.className + " bg-sky-200";

                //         const _selectedNode = _node.id;
                //         localStorage.setItem("selectedNode", _selectedNode);    
                //     }    
                // } else {
                //     const _node = document.getElementById("node_span_label_"+selectedCurriculum.id) as HTMLElement;
                //     if(_node) {
                //         _node.className = _node.className + " bg-sky-200";

                //         const _selectedNode = _node.id;
                //         localStorage.setItem("selectedNode", _selectedNode);    
                //     }    
                // }

                const _currTabList = tabList.filter((item) => item.textbookCurriculum_key === selectedCurriculum.id);
                if(_currTabList && _currTabList.length > 0) {
                    // setCurrTabList(_currTabList);
                    if(!selectedTab) {
                        onSelectTab(_currTabList[0]);
                    }
                } else {
                    // saveDefaultTab(selectedCurriculum);
                }
                
            } else {

                let _selectedCurriculum : ITextBookIndexCurriculum = null as any;
                curriculumList.map((item) => {
                    if(_selectedCurriculum === null && item.is_addContents) {
                        _selectedCurriculum = item;
                    }
                });

                if(_selectedCurriculum) {

                    setSelectedCurriculum(_selectedCurriculum);
                    setSelectedCurriculumId(_selectedCurriculum.id);                    
    
                    // if(_selectedCurriculum.depth < 3) {
                    //     const _node = document.getElementById("node_button_"+_selectedCurriculum.depth+"_"+_selectedCurriculum.id) as HTMLElement;
                    //     if(_node) {
                    //         _node.className = _node.className + " bg-sky-200";
    
                    //         const _selectedNode = _node.id;
                    //         localStorage.setItem("selectedNode", _selectedNode);    
                    //     }    
                    // } else {
                    //     const _node = document.getElementById("node_span_label_"+_selectedCurriculum.id) as HTMLElement;
                    //     if(_node) {
                    //         _node.className = _node.className + " bg-sky-200";
    
                    //         const _selectedNode = _node.id;
                    //         localStorage.setItem("selectedNode", _selectedNode);    
                    //     }    
                    // }

                    logger.log("useEffect curriculumList _selectedCurri", _selectedCurriculum, localStorage.getItem("selectedNode"));
                }
    
                // if(_selectedCurriculum) {

                //     let _selectedNode = "";
                //     if(_selectedCurriculum.depth === 3) {
                //         const node_span_label = "node_span_label_" + _selectedCurriculum.id;
                //         _selectedNode = node_span_label;            
                //     } else {
                //         const node_button = "node_button_" + _selectedCurriculum.depth + "_" + _selectedCurriculum.id;
                //         _selectedNode = node_button;            
                //     }

                //     logger.log("useEffect curriculumList selectedNode", _selectedNode);

                //     const _node = document.getElementById(_selectedNode) as HTMLElement;  
                //     if(_node) {
                //         setTimeout(() => {
                //             _node.click();
                //         }, 100);
                //     }            

                //     // onSelectCurriculum(_node, _selectedCurriculum.id, _selectedCurriculum.depth, curriculumList);
                    
                //     // _node.className = _node.className + " bg-sky-200";
            
                //     // setTimeout(() => {
                //     //     setSelectedCurriculum(_selectedCurriculum);                        
                //     // }, 10);
                // }                
            }
        }
    }, [tabList])

    // useEffect(() => {
    //     if(currTabList && currTabList.length > 0) {
    //         logger.log("currTabList", currTabList);
    //         const _selectedTab = currTabList.find((tab) => tab.name === selectedTab.name);
    //         if(_selectedTab) {
    //             setSelectedTab(null as any);
    //             setTimeout(() => {
    //                 setSelectedTab(_selectedTab);
    //             }, 10);    
    //         } else {
    //             setSelectedTab(null as any);
    //             setTimeout(() => {
    //                 setSelectedTab(currTabList[0]);
    //             }, 10);    
    //         }
    //     }
    // }, [currTabList])

    useEffect(() => {
        if(currIndex) {

            logger.log("currIndex", currIndex);

            getTextbookTabList(currIndex.id!);

            if(currIndex && currIndex.curriculumList) {
                makeUITextBookIndexList(currIndex);  
            }

        }
    }, [currIndex])

    useEffect(() => {
        if(curriculumList && curriculumList.length > 0) {
            logger.log("useEffect curriculumList", curriculumList);
        }
    }, [curriculumList])

    useEffect(() => {
        if(selectedCurriculum) {
            const _currTabList = tabList.filter((item) => item.textbookCurriculum_key === selectedCurriculum.id);
            if(_currTabList && _currTabList.length > 0) {
                // setCurrTabList(_currTabList);
                onSelectTab(_currTabList[0]);
            } else {
                logger.log("useEffect selectedCurriculum", tabList, selectedCurriculum);
                saveDefaultTab(selectedCurriculum);
            }
        }
    }, [selectedCurriculum])

    useEffect(() => {
        if(selectedTab) {
            logger.log("selectedTab", selectedTab);

            let _canViewSet = false;
            const set_id = selectedTab.set_id;            
            if(set_id) {
                _canViewSet = true;

                getSetInfo(set_id);

                const brand = util.getCurrentBrand();
                // const preview_url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand!.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                // +"&set_id="+set_id+"&token="+util.getUserInfo("token");
                const preview_url = "https://t-cms.vsaidt.com/editor/viewer/index.html?brand_id="+brand!.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&set_id="+set_id+"&token="+util.getUserInfo("token");
                logger.log("preview_url", preview_url);
                setPreviewUrl(preview_url);    
            }
            setCanViewSet(_canViewSet);

            let _isAITab = false;
            if(curriInfo && curriInfo.setCategory) {
                const aiStudy = curriInfo.setCategory.find((meta) => meta.code === "aiStudy");
                if(aiStudy && aiStudy.id === selectedTab.setCategory) {
                    _isAITab = true;
                }
            }
            setIsAITab(_isAITab);
        }
    }, [selectedTab])

    async function getSetInfo(id: number) {
        const setInfo = await util.getSetInfo({"id": id});
        if(setInfo) {
            setSelectedTabSetInfo(setInfo)
            logger.log("setInfo", setInfo);    
        }
    }

    function makeUITextBookIndexList(textbookIndex: ITextBookIndex) {

        const _treeData : NodeModel[] = []
        const arr = JSON.parse(textbookIndex.curriculumList!) as any[];
        arr.map((node) => {
            node.id = node.key;
            _treeData.push(node);
        });
        setTreeData(_treeData);
        g_treeData = _treeData;
        logger.log("makeUITextBookIndexList", _treeData);

        if(arr && arr.length > 0) {

            const _curriList : ITextBookIndexCurriculum[] = [];
            arr.map((item) => {

                const _data : ITextBookIndexCurriculum = {
                    id: item.key,
                    parent: item.parent,
                    text: item.text,
                    is_active: item.data.is_active,
                    is_addContents: item.data.is_addContents,
                    is_delete: item.data.is_delete,
                    depth: item.data.depth,
                    child_depth: item.data.child_depth,
                    textbookIndex_id: textbookIndex.id!,
                    indexName: item.data.indexName ? item.data.indexName : "",
                }

                _curriList.push(_data);
            })

            // const _arrCurriList : ITextBookIndexCurriculum[][] = [];

            // let arrCurri : ITextBookIndexCurriculum[] = [];
            // _curriList.map((item, idx) => {
            //     if(item.depth === 0) {
            //         if(arrCurri.length > 0) {
            //             _arrCurriList.push(arrCurri);
            //             arrCurri = [];
            //         }
            //     }
            //     arrCurri.push(item);
            // });
            // if(arrCurri.length > 0) {
            //     _arrCurriList.push(arrCurri);
            // }
    
            // const _arrCurriNode : HTMLElement[] = [];
            // _arrCurriList.map((_arr, idx) => {
            //     _arr.map((_item) => {
            //         const node = getNode(_item);
            //         _arrCurriNode.push(node);
            //     });            
            // })       
                        
            // const root = document.getElementById("TextBookIndexList") as HTMLDivElement;
            // logger.log("root", root);
            // root.innerHTML = "";
            // // root.insertAdjacentHTML('afterend', '');

            // _arrCurriList.map((_arr, idx) => {
            //     _arr.map((_item, idx1) => {
            //         let node = _arrCurriNode.find((node) => node.id === "node_"+_item.id);
            //         if(node) {
            //             const children = _arr.filter((child) => child.parent === _item.id);
            //             children.map((child) => {
            //                 const node_child = _arrCurriNode.find((childNode) => childNode.id === "node_"+child.id);
            //                 if(node_child) {
            //                     node?.appendChild(node_child);
            //                 }
            //             });                                    
            //         }                            
            //     });  
                
            //     const item_ = _arr.find((item_) => item_.parent === 0);
            //     const root_node = _arrCurriNode.find((node) => node.id === "node_"+item_?.id);
            //     if(root_node) {
            //         const node_AutoLaTeX = document.createElement("AutoLaTeX");
            //         node_AutoLaTeX.appendChild(root_node);
            //         root.appendChild(node_AutoLaTeX);
            //         // root.appendChild(root_node);
            //     }
            // })       

            // setValLatex(root.innerHTML);
            // logger.log("root", valLatex);

            setCurriculumList(_curriList);
            setCurriculumDepth1List(_curriList.filter((item) => item.depth === 0));
            setCurriculumDepth2List(_curriList.filter((item) => item.depth === 1));
            setCurriculumDepth3List(_curriList.filter((item) => item.depth === 2));
            setCurriculumDepth4List(_curriList.filter((item) => item.depth === 3));
            g_curriculumList = _curriList;

            // logger.log("setCurriculumList", _curriList);
        }  
    }

    // function makeUITextBookIndexList(textbookIndex: ITextBookIndex) {

    //     const _treeData : NodeModel[] = []
    //     const arr = JSON.parse(textbookIndex.curriculumList!) as any[];
    //     arr.map((node) => {
    //         node.id = node.key;
    //         _treeData.push(node);
    //     });
    //     setTreeData(_treeData);
    //     g_treeData = _treeData;
    //     logger.log("makeUITextBookIndexList", _treeData);

    //     if(arr && arr.length > 0) {

    //         const _curriList : ITextBookIndexCurriculum[] = [];
    //         arr.map((item) => {

    //             const _data : ITextBookIndexCurriculum = {
    //                 id: item.key,
    //                 parent: item.parent,
    //                 text: item.text,
    //                 is_active: item.data.is_active,
    //                 is_addContents: item.data.is_addContents,
    //                 is_delete: item.data.is_delete,
    //                 depth: item.data.depth,
    //                 child_depth: item.data.child_depth,
    //                 textbookIndex_id: textbookIndex.id!,
    //                 indexName: item.data.indexName ? item.data.indexName : "",
    //             }

    //             _curriList.push(_data);
    //         })

    //         const _arrCurriList : ITextBookIndexCurriculum[][] = [];

    //         let arrCurri : ITextBookIndexCurriculum[] = [];
    //         _curriList.map((item, idx) => {
    //             if(item.depth === 0) {
    //                 if(arrCurri.length > 0) {
    //                     _arrCurriList.push(arrCurri);
    //                     arrCurri = [];
    //                 }
    //             }
    //             arrCurri.push(item);
    //         });
    //         if(arrCurri.length > 0) {
    //             _arrCurriList.push(arrCurri);
    //         }
    
    //         const _arrCurriNode : HTMLElement[] = [];
    //         _arrCurriList.map((_arr, idx) => {
    //             _arr.map((_item) => {
    //                 const node = getNode(_item);
    //                 _arrCurriNode.push(node);
    //             });            
    //         })       
                        
    //         const root = document.getElementById("TextBookIndexList") as HTMLDivElement;
    //         logger.log("root", root);
    //         root.innerHTML = "";
    //         // root.insertAdjacentHTML('afterend', '');

    //         _arrCurriList.map((_arr, idx) => {
    //             _arr.map((_item, idx1) => {
    //                 let node = _arrCurriNode.find((node) => node.id === "node_"+_item.id);
    //                 if(node) {
    //                     const children = _arr.filter((child) => child.parent === _item.id);
    //                     children.map((child) => {
    //                         const node_child = _arrCurriNode.find((childNode) => childNode.id === "node_"+child.id);
    //                         if(node_child) {
    //                             node?.appendChild(node_child);
    //                         }
    //                     });                                    
    //                 }                            
    //             });  
                
    //             const item_ = _arr.find((item_) => item_.parent === 0);
    //             const root_node = _arrCurriNode.find((node) => node.id === "node_"+item_?.id);
    //             if(root_node) {
    //                 const node_AutoLaTeX = document.createElement("AutoLaTeX");
    //                 node_AutoLaTeX.appendChild(root_node);
    //                 root.appendChild(node_AutoLaTeX);
    //                 // root.appendChild(root_node);
    //             }
    //         })       

    //         setValLatex(root.innerHTML);
    //         // logger.log("root", valLatex);

    //         setCurriculumList(_curriList);
    //         g_curriculumList = _curriList;

    //         // logger.log("setCurriculumList", _curriList);
    //     }  
    // }

    function onSelectTab(item: ITextBookTab) {
        setSelectedTab(item);
    }

    function onEditTab(item: ITextBookTab) {

        logger.log("onEditTab", tabList);

        const _currTabList = tabList.filter((_item) => _item.textbookCurriculum_key === selectedCurriculum.id);
        setIsDefaultTab(_currTabList[0] === item);

        setSelectedTab(item);
        setSelectedEditTab(item);
        setViewPopupTabEdit(true);
    }

    async function saveDefaultTab(curriculum: ITextBookIndexCurriculum) {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const list = await util.getTextbookTabList({
            "textbookIndex_id": curriculum.textbookIndex_id,
            "textbookCurriculum_key": curriculum.id, 
        });
        logger.log("saveDefaultTab getTextbookTabList", list, curriculum);

        if(list && list.length > 0) {
            // logger.log("saveDefaultTab getTextbookTabList", list);
            return;
        }

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const data = {
            "textbookIndex_id": curriculum.textbookIndex_id,
            // "textbookCurriculum_id": curriculum.id, 
            "textbookCurriculum_key": curriculum.id, 

            "name": "교과서",        
            // "is_publicOpen": true,             
            // "is_active": true,    
            // "brand_id": brand.id, 
            "setCategory": 31,

            // creator: uid,
            // creator_id: _id,
            // creator_name: uname,

            isDirty: true,
        }

        // const result = await util.textbookTabForSave(data, "insert", true);
        // if(result) {
        //     // logger.log("saveDefaultTab", result);

        //     const _tabList = JSON.parse(JSON.stringify(tabList));
        //     _tabList.push(result);
        //     setTabList(_tabList);                    

        //     // const _currTabList : ITextBookTab[] = [];
        //     // setCurrTabList(null as any);
        //     // setTimeout(() => {
        //     //     _currTabList.push(result);
        //     //     setCurrTabList(_currTabList);                    
        //     // }, 10);

        //     setSelectedTab(null as any);
        //     setTimeout(() => {
        //         setSelectedTab(result);
        //     }, 10);
        // }

        const _tabList = JSON.parse(JSON.stringify(tabList));
        _tabList.push(data);
        setTabList(_tabList);                    

        setSelectedTab(null as any);
        setTimeout(() => {
            setSelectedTab(data as ITextBookTab);
        }, 10);

        // getTextbookTabList(curriculum.textbookIndex_id);
    }

    async function getTextbookTabList(textbookIndex_id: number, textbookCurriculum_id: number | undefined = undefined) {
        
        props.showLoading(true);

        if(textbookIndex_id) {
            const data = {
                "textbookIndex_id": textbookIndex_id,
                "textbookCurriculum_id": textbookCurriculum_id,
                "limit_page":1,
                "limit_pageItemCnt":1000,
                "orderby_col":"id",
                "orderby_dir":"asc",
            }
            const result = await util.getTextbookTabList(data);    
            if(result) {
                const _tabList = JSON.parse(JSON.stringify(result));
                setTabList(_tabList);
        
                // if(selectedCurriculum) {
                //     const currTabList = result.filter((item) => item.textbookCurriculum_key === selectedCurriculum.id);
                //     logger.log("selectedCurri", selectedCurriculum, currTabList);
                //     setCurrTabList(currTabList);    
                // }    
            }
            logger.log("getTextbookTabList", result);
        }

        props.showLoading(false);
    }

    async function getTextBookIndexInfo(id: number) {

        props.showLoading(true);

        const textbookIndex_id = id;
        if(textbookIndex_id) {
            const data = {
                // "detail":true,
                "id": textbookIndex_id,
            }
            const result = await util.getTextbookIndexInfo(data);    
            if(result) {
                const _currIndex = result as ITextBookIndex;
                setCurrIndex(_currIndex);    
            }
        }
        props.showLoading(false);
    }

    function onEditBasicInfo() {
        logger.log(textbookData);
        setViewTextBookEdit(true);
    }

    function onChangeBasicData(data: ITextBook) {
        logger.log(data);
        setTextBookData(data);
        setViewTextBookEdit(false);
    }

    function onViewSet() {
        setViewPopupSetList(true);
    }

    function onEditSet() {
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        let url = "";
        if(selectedTab && selectedTab.set_id) {
            // url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")+"&set_id="+selectedTab.set_id;
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&set_id="+selectedTab.set_id+"&token="+util.getUserInfo("token");
            window.open(url, "_blank", "noopener, noreferrer");
        }
    }

    function onRegisterTextBookIndex() {
        logger.log(textbookData);
        setViewTextBookIndexEdit(true);
    }

    function onCloseTextBookIndexEdit(textbookIndex: ITextBookIndex | null) {
        logger.log("textbookIndex", textbookIndex, textBookIndexId);

        setViewTextBookIndexEdit(false);

        if(textBookIndexId) {
            getTextBookIndexInfo(textBookIndexId);
        } else {
            if(textbookIndex && textbookIndex.id) {
                setTextBookIndexId(textbookIndex.id);
                getTextBookIndexInfo(textbookIndex.id);
            }
        }

        // if(textbookIndex) {
        //     setCurrIndex(textbookIndex);
        //     setTextBookIndexId(textbookIndex.id);
        // }
    }

    function onViewTextBookIndexInfo() {
        // alert("작업중입니다.");
        setViewPopupIndexNSet(true);
    }
    
    function onViewTextBookIndexList() {
        if(props.originTextBook.textbookIndex_id) {
            setChangeIndexTitle("알림");
            setChangeIndexDesc("새로운 목차로 불러오는 경우, 기존에 세팅해놓은 데이터가 모두 사라집니다.<br/>그대로 진행하시겠습니까?");
            setViewConfirmChangeIndex(true);    
        } else {
            setViewPopupContentsList(true);
        }
    }

    async function onChangeIndexOK() {
        onCloseChangeIndex();
        setViewPopupContentsList(true);
    }
    
    function onCloseChangeIndex() {
        setViewConfirmChangeIndex(false);
    }    

    function onSelectCurriculum(id: number) {
        const _selectedCurriculum = curriculumList.find((item) => item.id === id);
        if(_selectedCurriculum) {
            setSelectedCurriculum(_selectedCurriculum);
            setSelectedCurriculumId(_selectedCurriculum.id);
        }
    }

    function onSelectCurriculum_old(node: HTMLElement, id: number, depth: number, list: ITextBookIndexCurriculum[] = []) {

        const _curriculumList = g_curriculumList;
        const selectedNode = localStorage.getItem("selectedNode");
        logger.log("onSelectCurriculum", selectedNode);

        if(selectedNode) {
            const _node = document.getElementById(selectedNode) as HTMLElement;
            _node.className = _node.className.replaceAll(" bg-sky-200", "");
        }
        node.className = node.className + " bg-sky-200";
        const newSelectedNode = node.id;
        localStorage.setItem("selectedNode", newSelectedNode);

        const _selectedCurriculum = _curriculumList.find((item) => item.id === id);
        if(_selectedCurriculum) {
            setSelectedCurriculum(_selectedCurriculum);
        }
        logger.log(curriculumList, id, _selectedCurriculum);
    }

    // function onChangeCheckIndex(node: HTMLElement, id: number, depth: number) {
    //     if(selectedNode) {
    //         const _node = document.getElementById(selectedNode) as HTMLElement;
    //         _node.className = _node.className.replace(" bg-sky-200", "");
    //     }

    //     node.className = node.className + " bg-sky-200";
    //     selectedNode = node.id;

    //     const curri = curriList.find((item) => item.id === id);
    //     if(curri) {
    //         setSelectedCurriculum(curri);
    //     }
    //     logger.log(curri);
    // }

    // function getNode(nodeItem: ITextBookIndexCurriculum) {

    //     const is_addContents = nodeItem.is_addContents;
    //     const depth = nodeItem.depth;
    //     const id = nodeItem.id;
    //     // const txt = is_addContents ? ("<span class=\"_link\" style=\"cursor: pointer;\">" + nodeItem.text + "</span>") : nodeItem.text;
    //     const txt = nodeItem.text;

    //     let node = document.createElement("div");
    //     node.setAttribute("key", String(id));
    //     node.id = "node_" + id;

    //     if(depth === 0) {

    //         let div_node = document.createElement("div");
    //         div_node.className = "flex";

    //         node.className = "mt-5";

    //         let node_button1 = document.createElement("button");
    //         node_button1.id = "node_button_0_"+id;
    //         node_button1.innerHTML = txt;
            
    //         // node_button1.insertAdjacentHTML('afterend', txt);
    //         if(is_addContents) {
    //             node_button1.onclick = () => {
    //                 onSelectCurriculum(node_button1, id, depth);
    //             };        
    //             node_button1.className = "font-bold text-lg mr-2 text-left w-avail";
    //         } else {
    //             node_button1.className = "font-bold text-lg mr-2 text-left w-avail disabled";
    //         }

    //         let node_button2 = document.createElement("button");
    //         node_button2.innerHTML = "\u205D";
    //         // node_button2.insertAdjacentHTML('afterend', "\u205D");

    //         node_button2.className = "text-lg text-right";
    //         node_button2.onclick = () => {
    //             const node = g_treeData.find((item) => (item.data as NodeData).key === id);
    //             // alert(JSON.stringify(node));
    //             if(node) {
    //                 onAddCurriUnitToIndex(node);
    //             }
    //         };        

    //         div_node.appendChild(node_button1);   
    //         div_node.appendChild(node_button2);   
    //         node.appendChild(div_node);   

    //     } else if(depth === 1) {

    //         let div_node = document.createElement("div");
    //         div_node.className = "flex";

    //         node.className = "pl-3 my-2 mt-3";

    //         let node_button1 = document.createElement("button");
    //         node_button1.id = "node_button_1_"+id;
    //         node_button1.innerHTML = txt;
    //         // node_button1.insertAdjacentHTML('afterend', txt);
    //         if(is_addContents) {
    //             node_button1.onclick = () => {
    //                 onSelectCurriculum(node_button1, id, depth);
    //             };        
    //             node_button1.className = "block mr-2 text-left text-sm w-avail";
    //         } else {
    //             node_button1.className = "block mr-2 text-left text-sm w-avail disabled";
    //         }

    //         let node_button2 = document.createElement("button");
    //         node_button2.innerHTML = "\u205D";
    //         // node_button2.insertAdjacentHTML('afterend', "\u205D");
    //         node_button2.className = "text-lg text-right";
    //         node_button2.onclick = () => {
    //             const node = g_treeData.find((item) => (item.data as NodeData).key === id);
    //             // alert(JSON.stringify(node));
    //             if(node) {
    //                 onAddCurriUnitToIndex(node);
    //             }
    //         };        

    //         div_node.appendChild(node_button1);   
    //         div_node.appendChild(node_button2);   
    //         node.appendChild(div_node);   

    //     } else if(depth === 2) {

    //         let div_node = document.createElement("div");
    //         div_node.className = "flex";

    //         node.className = "ml-2 py-2 pl-3 border-l border-slate-200";

    //         let node_button1 = document.createElement("button");
    //         node_button1.id = "node_button_2_"+id;
    //         node_button1.innerHTML = txt;
    //         // node_button1.insertAdjacentHTML('afterend', txt);
    //         if(is_addContents) {
    //             node_button1.onclick = () => {
    //                 onSelectCurriculum(node_button1, id, depth);
    //             };        
    //             node_button1.className = "block ml-2 mr-2 text-left text-sm w-avail";
    //         } else {
    //             node_button1.className = "block ml-2 mr-2 text-left text-sm w-avail disabled";
    //         }

    //         let node_button2 = document.createElement("button");
    //         node_button2.innerHTML = "\u205D";
    //         // node_button2.insertAdjacentHTML('afterend', "\u205D");
    //         node_button2.className = "text-lg text-right";
    //         node_button2.onclick = () => {
    //             const node = g_treeData.find((item) => (item.data as NodeData).key === id);
    //             // alert(JSON.stringify(node));
    //             if(node) {
    //                 onAddCurriUnitToIndex(node);
    //             }
    //         };        

    //         div_node.appendChild(node_button1);   
    //         div_node.appendChild(node_button2);   
    //         node.appendChild(div_node);   

    //     // } else if(depth === 2) {

    //     //     node.className = "ml-1 my-2 pl-3 border-l border-slate-200";
    //     //     let node_span = document.createElement("span");
    //     //     node_span.className = "inp-check";
    //     //     let node_span_input = document.createElement("input");
    //     //     node_span_input.id = "node_span_input_check_"+id;
    //     //     node_span_input.type = "checkbox";

    //     //     let node_button = document.createElement("button");
    //     //     node_button.id = "node_button_2_"+id;
    //     //     node_button.className = "block p-2 text-left";
    //     //     node_button.innerText = txt;
    //     //     node_button.onclick = () => {
    //     //         onClickIndex(node_button, id, depth);
    //     //     };    
    //     //     node_span.appendChild(node_span_input);
    //     //     node_span.appendChild(node_button);
    //     //     node.appendChild(node_span);              

    //     } else if(depth === 3) {
    //         node.className = "ml-2 py-3 relative after:block after:w-[1px] after:h-5 after:bg-slate-300 after:absolute after:-bottom after:left-[7px]";
    //         let node_span = document.createElement("span");
    //         node_span.className = "inp-check";
    //         let node_span_input = document.createElement("input");
    //         node_span_input.id = "node_span_input_check_"+id;
    //         node_span_input.type = "checkbox";
    //         if(is_addContents)
    //         node_span_input.onchange = () => {
    //             onSelectCurriculum(node_span_label, id, depth);
    //         };
    //         node_span_input.checked = is_addContents;
            
    //         let node_span_label = document.createElement("label");
    //         node_span_label.id = "node_span_label_"+id;
    //         node_span_label.htmlFor = "label_"+id;
    //         node_span_label.innerHTML = txt;
    //         // node_span_label.insertAdjacentHTML('afterend', txt);
    //         if(is_addContents) {
    //             node_span_label.onclick = () => {
    //                 onSelectCurriculum(node_span_label, id, depth);
    //             };    
    //             node_span_label.className = "w-avail";
    //             node_span_label.style.cursor = "pointer";
    //         } else {
    //             node_span_label.className = "w-avail disabled";
    //         }

    //         let node_button2 = document.createElement("button");
    //         node_button2.innerHTML = "\u205D";
    //         // node_button2.insertAdjacentHTML('afterend', "\u205D");
    //         node_button2.className = "text-lg text-right";
    //         node_button2.onclick = () => {
    //             const node = g_treeData.find((item) => (item.data as NodeData).key === id);
    //             // alert(JSON.stringify(node));
    //             if(node) {
    //                 onAddCurriUnitToIndex(node);
    //             }
    //         };        

    //         node_span.appendChild(node_span_input);
    //         node_span.appendChild(node_span_label);

    //         node_span.appendChild(node_button2);

    //         node.appendChild(node_span);                        
    //     }

    //     // logger.log(depth, txt, node);

    //     return node;
    // }

    function onAddTab() {

        logger.log(currIndex);
        if(!selectedCurriculum) {
            props.showModal("", "목차 리스트를 선택하셔야 합니다.");
            return;
        }

        setIsDefaultTab(false);
        setSelectedEditTab(null as any);
        setViewPopupTabEdit(true);
    }

    async function onDeleteTab(item: ITextBookTab) {
        setViewConfirmDelete(true);
        setDeleteTitle("삭제");
        setDeleteDesc(item.name + " 탭을 삭제 하시겠습니까?<br/>불러온 세트지 정보가 모두 사라집니다.");
        setSelectedEditTab(item);
    }

    async function onDeleteOK() {

        onCloseDelete();

        if(!selectedEditTab) {
            return;
        }

        // props.showLoading(true);

        // const result = await util.deleteTextbookTab(selectedEditTab.id!);
        // logger.log("onDeleteOK", result);

        // props.showLoading(false);

        if(selectedEditTab.id) {
            const _deleteTabList = JSON.parse(JSON.stringify(deleteTabList)) as ITextBookTab[];
            _deleteTabList.push(selectedEditTab);
            setDeleteTabList(_deleteTabList);    
        }

        // const _tabList = JSON.parse(JSON.stringify(tabList.filter((tab) => tab !== selectedEditTab)));
        const _tabList = tabList.filter((tab) => tab !== selectedEditTab);
        setTabList(_tabList);

        props.showModal("", "삭제 되었습니다.");
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }

    async function onDeleteIndexTab(item: ITextBookIndex) {
        const result = await util.deleteTextbookIndexTab(item.id!);
        logger.log("onDeleteIndexTab", result);

        // setCurrTabList(null as any);
        setSelectedCurriculum(null as any);
        setSelectedCurriculumId(-1);
        setSelectedTab(null as any);

        setTabList([]);

        // getTextbookTabList(currIndex.id!);
    }

    async function onRequstedSet(set: ISet) {
        
        // const _id = util.getUserInfo("id");
        // const uid = util.getUserInfo("uid");
        // const uname = util.getUserInfo("name");

        // const data = {
        //     id: selectedTab.id,            
        //     set_id: set.id,
        //     // setCategory: set.setCategory,

        //     updater: uid,
        //     updater_id: _id,
        //     updater_name: uname,
        // }

        // const result = await util.textbookTabForSave(data, "update");    
        // logger.log("onRequstedSet", data, result);

        // if(result) {

        //     // setSelectedTab(result);
        //     // getTextbookTabList(selectedTab.textbookIndex_id);

        //     const newChangedTabList = changedTabList.filter((item) => item.id !== selectedTab.id);

        //     const changedTab = JSON.parse(JSON.stringify(selectedTab));
        //     changedTab.set_id = data.set_id;
        //     // changedTab.setCategory = data.setCategory;    
        //     setSelectedTab(changedTab);            

        //     newChangedTabList.push(changedTab);
        //     setChangedTabList(newChangedTabList)
        //     // logger.log(newChangedTabList);    
        //     logger.log("onRequstedSet", changedTab, newChangedTabList);
        // }    

        // const changedTab = JSON.parse(JSON.stringify(selectedTab));
        // changedTab.set_id = set.id;
        // setSelectedTab(changedTab);      
        
        // const _tabList = JSON.parse(JSON.stringify(tabList)) as ITextBookTab[];
        // const _tab = _tabList.find((tab) => tab.id === selectedTab.id);
        // if(_tab) {
        //     _tab.set_id = changedTab.set_id;
        //     _tab.isDirty = true;
        // }
        // setTabList(_tabList);                    

        selectedTab.set_id = set.id;
        setSelectedTab(null as any);
        setTimeout(() => {
            setSelectedTab(selectedTab);
        }, 10);

        const _tab = tabList.find((tab) => tab === selectedTab);
        if(_tab) {
            _tab.set_id = set.id;
            _tab.isDirty = true;
        }

        logger.log("onRequstedSet", selectedTab, _tab);
    }

    async function onRequestIndex(item: ITextBookIndex) {
        
        logger.log("textbookIndex", item, tabList);

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        let _currIndex = JSON.parse(JSON.stringify(currIndex));
        if(_currIndex) {

            onDeleteIndexTab(_currIndex);

            _currIndex.curriculumList = item.curriculumList;
    
            const data = {
                id: _currIndex.id,
                updater: uid,
                updater_id: _id,
                updater_name: uname,
                curriculumList: _currIndex.curriculumList,
            }
    
            props.showLoading(true);
            const result = await util.textbookIndexForSave(textbookData.id!, data, "update");
            props.showLoading(false);
    
            if(result) {
                setCurrIndex(_currIndex);                    
                logger.log("textbookIndexForSave update", result, _currIndex);
            }

        } else {

            const data = {
                brand_id: item.brand_id,
                name: item.name + "(불러오기)",
                is_active: true,
                is_publicOpen: true,
                max_depth: item.max_depth,
                creator: uid,
                creator_id: _id,
                creator_name: uname,
                curriculumList: item.curriculumList,
            }
    
            props.showLoading(true);
            const result = await util.textbookIndexForSave(textbookData.id!, data, "insert");            
            logger.log("textbookIndexForSave insert", result);
            props.showLoading(false);
                
            if(result) {
                _currIndex = result as ITextBookIndex;
                setCurrIndex(_currIndex);
                setTextBookIndexId(_currIndex.id);
            }
        }

        setViewPopupContentsList(false);
    }

    function onSavedTab(item: ITextBookTab) {
        
        // logger.log("onSavedTab", item, tabList);

        if(selectedEditTab) {
            const _tabList : ITextBookTab[] = [];
            for (let i = 0; i < tabList.length; i++) {
                const tab = tabList[i];

                // if(selectedCurriculum && tab.textbookCurriculum_key === selectedCurriculum.id) {
                //     if(tab === selectedEditTab) {

                //         selectedEditTab.name = item.name;
                //         selectedEditTab.setCategory = item.setCategory;
                //         selectedEditTab.accessLevel = item.accessLevel;
                //         selectedEditTab.source = item.source;
    
                //         selectedEditTab.ebook_id = item.ebook_id;
                //         selectedEditTab.startPage = item.startPage;
                //         selectedEditTab.endPage = item.endPage;
                //         selectedEditTab.is_ebook_active = item.is_ebook_active;

                //         selectedEditTab.theme_code = item.theme_code;
                //         selectedEditTab.theme_path = item.theme_path;

                //         selectedEditTab.isDirty = true;
    
                //         _tabList.push(selectedEditTab);
    
                //     } else {
                //         _tabList.push(tab);
                //     }
                // }

                if(tab === selectedEditTab) {

                    selectedEditTab.name = item.name;
                    selectedEditTab.setCategory = item.setCategory;
                    selectedEditTab.accessLevel = item.accessLevel;
                    selectedEditTab.source = item.source;

                    selectedEditTab.ebook_id = item.ebook_id;
                    selectedEditTab.startPage = item.startPage;
                    selectedEditTab.endPage = item.endPage;
                    selectedEditTab.is_ebook_active = item.is_ebook_active;

                    selectedEditTab.theme_code = item.theme_code;
                    selectedEditTab.theme_path = item.theme_path;
                    selectedEditTab.theme_desc = item.theme_desc;

                    selectedEditTab.isDirty = true;

                    _tabList.push(selectedEditTab);

                } else {
                    _tabList.push(tab);
                }
            }

            setTabList(_tabList);    

            // logger.log("onSavedTab _tabList", _tabList);

        } else {
            const _tabList = JSON.parse(JSON.stringify(tabList));
            _tabList.push(item);
            setTabList(_tabList);

            setSelectedTab(null as any);
            setTimeout(() => {
                setSelectedTab(item);
            }, 10);
                
            // logger.log("onSavedTab _tabList", _tabList);
        }

        // getTextbookTabList(currIndex.id!);
    }

    async function onSave() {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        if(!textBookIndexId) {
            props.showModal("", "목차를 등록하셔야 합니다.");
            return;
        }

        props.showLoading(true);

        logger.log("onSave", deleteTabList, tabList.filter((tab) => tab.isDirty === true));

        for (let i = 0; i < deleteTabList.length; i++) {
            const tab = deleteTabList[i];
            if(tab && tab.id) {
                // logger.log("onSave deleteTabList", tab);        
                const result = await util.deleteTextbookTab(tab.id);
                logger.log("onSave deleteTabList", result);        
            }            
        }

        const dirtyTabList = tabList.filter((tab) => tab.isDirty === true);
        for (let i = 0; i < dirtyTabList.length; i++) {

            const tab = dirtyTabList[i];
    
            const _id = util.getUserInfo("id");
            const uid = util.getUserInfo("uid");
            const uname = util.getUserInfo("name");
    
            const data = {
                id: tab.id ? tab.id : undefined,
                brand_id: brand.id,
                is_publicOpen: true,             
                is_active: true,    
                
                creator: tab.id ? undefined : uid,
                creator_id: tab.id ? undefined : _id,
                creator_name: tab.id ? undefined : uname,

                textbookCurriculum_key: tab.id ? undefined : tab.textbookCurriculum_key,
                textbookIndex_id: tab.id ? undefined : tab.textbookIndex_id,
    
                updater: tab.id ? uid : undefined,
                updater_id: tab.id ? _id : undefined,
                updater_name: tab.id ? uname : undefined,

                name: tab.name,
                setCategory: tab.setCategory ? tab.setCategory : 31,
                accessLevel: tab.accessLevel,
                source: tab.source,
                set_id: tab.set_id,
        
                ebook_id: tab.is_ebook_active ? tab.ebook_id : undefined,
                startPage: tab.is_ebook_active ? tab.startPage : undefined,
                endPage: tab.is_ebook_active ? tab.endPage : undefined,
                is_ebook_active: tab.is_ebook_active,

                theme_code: tab.theme_code,
                theme_path: tab.theme_path,
                theme_desc: tab.theme_desc,
            }

            // logger.log("onSave dirtyTabList", data);        

            const result = await util.textbookTabForSave(data, tab.id ? "update" : "insert");
            if(result) {
                logger.log("onSave dirtyTabList", data, result);        
            }                        
        }

        const result = await util.textbookSaveVersion(textbookData.id!);    
        logger.log("textbookSaveVersion", result);
        if(result) {
            props.onClose();
        }

        props.showLoading(false);        
    }

    function OnClose() {
        logger.log("OnClose");
        props.onClose();
    }

    function onEditTextbookIndex() {
        setSelectedTab(null as any);
        setSelectedCurriculum(null as any);
        setSelectedCurriculumId(-1);
        setCurriculumList([]);
        setViewTextBookIndexEdit(true);
        logger.log("textBookIndexId", textBookIndexId);
    }
    
    function onAddCurriUnitToIndex(node: NodeModel) {
        setSelectedNode(node);
        setViewPopupAddCurriUnit(true);

        logger.log("onAddCurriUnitToIndex", node);    
    }

    function onClosePopup() {
        setViewPopupAddCurriUnit(false);
    }

    let descendant : NodeModel[] = [];
    function getChildren(id: number) {
        const children = treeData.filter((node) => node.parent === id);
        children.map((child) => {
            descendant.push(child);
            getChildren(Number(child.id));
        });
        // return children;
    }

    async function onSaveAddCurriUnit(units: any, studyMaps: any, studyMapVals: any, EA1: any, EA2: any, EA3: any, chk_all_children: boolean) {    
        
        onClosePopup();

        logger.log("onSaveAddCurriUnit", units);    

        let curriculumListStr = "";
        const _treeData = JSON.parse(JSON.stringify(treeData)) as NodeModel[];

        const _selectedNode = selectedNode as NodeModel;
        if(chk_all_children) {
            
            descendant = [_selectedNode];
            getChildren(Number(_selectedNode.id));
            logger.log("descendant", descendant);

            descendant.map((_node) => {
                const data = _treeData.find((item) => (item.data as NodeData).key === (_node.data as NodeData).key);

                if(data) {
                    const nodeData = (data.data as NodeData);
                    if(nodeData) {
                        nodeData.curriUnitList = JSON.stringify(units);
                        nodeData.studyMapList = JSON.stringify(studyMaps);
                        nodeData.studyMapValList = JSON.stringify(studyMapVals);
                        nodeData.EA1List = JSON.stringify(EA1);
                        nodeData.EA2List = JSON.stringify(EA2);
                        nodeData.EA3List = JSON.stringify(EA3);
                    }
                }        
            });

            curriculumListStr = JSON.stringify(_treeData);
            setTreeData(_treeData);
            g_treeData = _treeData as NodeModel[];
            logger.log("onSaveAddCurriUnit", units, _treeData);    

        } else {
            const data = _treeData.find((item) => (item.data as NodeData).key === (_selectedNode.data as NodeData).key);

            if(data) {
                const nodeData = (data.data as NodeData);
                if(nodeData) {
                    nodeData.curriUnitList = JSON.stringify(units);
                    nodeData.studyMapList = JSON.stringify(studyMaps);
                    nodeData.studyMapValList = JSON.stringify(studyMapVals);
                    nodeData.EA1List = JSON.stringify(EA1);
                    nodeData.EA2List = JSON.stringify(EA2);
                    nodeData.EA3List = JSON.stringify(EA3);
                }

                curriculumListStr = JSON.stringify(_treeData);
                setTreeData(_treeData);
                g_treeData = _treeData as NodeModel[];
                logger.log("onSaveAddCurriUnit", units, _treeData);    
            }    
        }

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const data = {

            id: textBookIndexId ? textBookIndexId : undefined,
            
            curriculumList: curriculumListStr,

            updater: uid,
            updater_id: _id,
            updater_name: uname,
        }    

        const textbook_id = textbookData.id!;

        props.showLoading(true);
        const result = await util.textbookIndexForSave(textbook_id, data, "update");            
        // logger.log("onSave", result, JSON.stringify(data));

        props.showLoading(false);

        if(textBookIndexId) {
            await getTextBookIndexInfo(textBookIndexId);
        }
    }

    function onEditCurriUnit(id: number) {
        const node = treeData.find((item) => (item.data as NodeData).key === id);
        logger.log(node);
        // alert(JSON.stringify(node));
        if(node) {
            onAddCurriUnitToIndex(node);
        }
    }

    const hasAuth = util.getHasAuthMenu(textbookData && textbookData.creator ? textbookData.creator : "");

    return (
        <div>
            <div className="w-full relative border border-slate-300" style={{display: viewTextBookIndexEdit ? "none" : ""}}>
                <button type="button" className="btn-white-s absolute top-2 right-2" onClick={onEditBasicInfo}>설정</button>
                <ul className="p-5">
                    <li className="flex"><strong className="min-w-[10rem]">교과서 제목</strong><p>{textbookData?.name}</p></li>
                    <li className="flex"><strong className="min-w-[10rem]">교육과정 및 학교급</strong><p>{curriBook?.val}</p></li>
                    <li className="flex"><strong className="min-w-[10rem]">교과서 버전</strong><p>Ver.{textbookData?.version}</p></li>
                    {/* {textbookData && textbookData.image_url && <li className="flex mt-5"><img width={100} src={S3Server+textbookData.image_url}></img></li>} */}
                </ul>
            </div>
            <div className="h-[calc(100%-100px)]" style={{display: viewTextBookIndexEdit ? "none" : ""}}>
                <div className="flex h-[calc(100%-68px)] w-full border border-slate-300 mt-5 min-h-[600px]">
                    {/* <!-- 목차 --> */}
                    <div className="flex-shrink-0 min-h-full w-61 border-r border-r-slate-300">

                        <div className="flex relative py-2 px-3 bg-slate-100 border-b border-slate-300">
                            <div className="flex font-bold text-left w-avail mt-1">목차</div>
                            <div className="flex text-right">
                                <button type="button" className="btn-white-s w-16 text-center" onClick={onEditTextbookIndex}>수정</button>
                                <button type="button" aria-label="목록" className="ml-1" onClick={onViewTextBookIndexInfo}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                    </svg>
                                </button>
                                <button type="button" aria-label="불러오기" className="ml-1" onClick={onViewTextBookIndexList}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                    </svg>								  
                                </button>
                            </div>
                        </div>

                        {currIndex &&
                        <div className="h-[calc(100%-41px)] overflow-y-auto index-scroll">
                            <fieldset style={{width:"100%"}}>
                                <legend className="sr-only">목차 목록</legend>
                                <ol className="p-3 mb-5" aria-labelledby="contents-list">

                                    <div id="TextBookIndexList">

                                        {curriculumDepth1List && curriculumDepth1List.length > 0 && curriculumDepth1List.map((item1, idx1) => {

                                            let clsName = "font-bold text-lg mr-2 text-left w-avail";
                                            if(!item1.is_addContents) {
                                                clsName = clsName + " disabled";
                                            }
                                            if(item1.id === selectedCurriculumId) {
                                                clsName = clsName + " bg-sky-200";
                                            }

                                            const _item1_child = curriculumList.filter((_item) => _item.parent === item1.id);                                                            
                                            return(

                                            <div key={item1.id} id={"node_"+idx1} className="mt-5">

                                                <div className="flex">
                                                    <button id={"node_button_0_"+idx1} className={clsName} onClick={(e) => {
                                                        if(item1.is_addContents) {
                                                            onSelectCurriculum(item1.id);
                                                        }
                                                    }}>
                                                        <Latex delimiters={latex_delimiters} macros={latex_macros}>{item1.text}</Latex>
                                                    </button>
                                                    <button className="text-lg text-right" onClick={() => {onEditCurriUnit(item1.id);}}>⁝</button>
                                                </div>

                                                {_item1_child && _item1_child.map((item2, idx2) => {

                                                    let clsName = "block mr-2 text-left text-sm w-avail";
                                                    if(!item2.is_addContents) {
                                                        clsName = clsName + " disabled";
                                                    }
                                                    if(item2.id === selectedCurriculumId) {
                                                        clsName = clsName + " bg-sky-200";
                                                    }

                                                    const _item2_child = curriculumList.filter((_item) => _item.parent === item2.id);
                                                    return(

                                                    <div key={item2.id} id={"node_"+idx1+"_"+idx2} className="pl-3 my-2 mt-3">
                                                        <div className="flex">
                                                            <button id={"node_button_"+idx1+"_"+idx2} className={clsName} onClick={(e) => {
                                                                if(item2.is_addContents) {
                                                                    onSelectCurriculum(item2.id);
                                                                }                                                                
                                                            }}>
                                                                <Latex delimiters={latex_delimiters} macros={latex_macros}>{item2.text}</Latex>
                                                            </button>
                                                            <button className="text-lg text-right" onClick={() => {onEditCurriUnit(item2.id);}}>⁝</button>
                                                        </div>

                                                        {_item2_child && _item2_child.map((item3, idx3) => {
                                                            
                                                            let clsName = "block ml-2 mr-2 text-left text-sm w-avail";
                                                            if(!item3.is_addContents) {
                                                                clsName = clsName + " disabled";
                                                            }
                                                            if(item3.id === selectedCurriculumId) {
                                                                clsName = clsName + " bg-sky-200";
                                                            }
        
                                                            const _item3_child = curriculumList.filter((_item) => _item.parent === item3.id);                                                
                                                            return(
                                                            
                                                            <div key={item3.id} id={"node_"+idx1+"_"+idx2+"_"+idx3} className="ml-2 py-2 pl-3 border-l border-slate-200">
                                                                <div className="flex">
                                                                    <button id={"node_button_"+idx1+"_"+idx2+"_"+idx3} className={clsName} onClick={(e) => {
                                                                        if(item3.is_addContents) {
                                                                            onSelectCurriculum(item3.id);
                                                                        }                                                                                                                                        
                                                                    }}>
                                                                        <Latex delimiters={latex_delimiters} macros={latex_macros}>{item3.text}</Latex>
                                                                    </button>
                                                                    <button className="text-lg text-right" onClick={() => {onEditCurriUnit(item3.id);}}>⁝</button>
                                                                </div>

                                                            {_item3_child && _item3_child.map((item4, idx4) => {

                                                                let clsName = "w-avail";
                                                                if(!item4.is_addContents) {
                                                                    clsName = clsName + " disabled";
                                                                }
                                                                if(item4.id === selectedCurriculumId) {
                                                                    clsName = clsName + " bg-sky-200";
                                                                }

                                                                return(

                                                                <div key={item4.id} id={"node_"+idx1+"_"+idx2+"_"+idx3+"_"+idx4} className="ml-2 py-3 relative after:block after:w-[1px] after:h-5 after:bg-slate-300 after:absolute after:-bottom after:left-[7px]">
                                                                    <span className="inp-check">
                                                                        <input id={"node_span_input_check_"+idx1+"_"+idx2+"_"+idx3+"_"+idx4} type="checkbox" defaultChecked={item4.is_addContents} onChange={(e) => {
                                                                            if(item4.is_addContents) {
                                                                                onSelectCurriculum(item4.id);
                                                                            }                                                                                                                                        
                                                                        }}/>
                                                                        <label htmlFor="label" className={clsName} style={{cursor: "pointer", marginRight: 0}} onClick={(e) => {
                                                                            if(item4.is_addContents) {
                                                                                onSelectCurriculum(item4.id);
                                                                            }                                                                                                                                        
                                                                        }}>
                                                                            <Latex delimiters={latex_delimiters} macros={latex_macros}>{item4.text}</Latex>
                                                                        </label>
                                                                        <button className="text-lg text-right" onClick={() => {onEditCurriUnit(item4.id);}}>⁝</button>
                                                                    </span>
                                                                </div>
                                                                );
                                                            })}
                                                            </div>
                                                            );
                                                        })}

                                                    </div>
                                                    );
                                                })}

                                            </div>

                                            );                                  
                                    })}
                                    
                                    </div>      
                                </ol>
                            </fieldset>
                        </div>}
                        {!currIndex &&
                        <div className="min-h-[600px] overflow-y-auto text-center mt-10">
                            <button type="button" className="btn-sky-m" onClick={onRegisterTextBookIndex}>목차를 등록 해 주세요​.</button>
                        </div>}
                    </div>
                    {/* <!-- .//목차 --> */}

                    <div className="overflow-y-auto min-h-full p-5 grow border-r border-r-slate-300">
                        {currIndex && 
                        <div className="flex justify-between items-center pb-2 text-sm font-medium text-center border-b border-gray-200" style={{height:"45px"}}>                            
                            <div className="overflow-x-auto flex pr-3">

                                {selectedCurriculum && tabList && tabList.map((item, idx) => {
                                    const _currTabList = tabList.filter((_item) => _item.textbookCurriculum_key === selectedCurriculum.id);
                                    if(item.textbookCurriculum_key === selectedCurriculum.id) {
                                        const className = "relative flex-shrink-0 me-1 rounded-t-lg h-8 pl-3 " + ((_currTabList[0] === item /*&& item.name === "교과서"*/) ? "pr-10" : "pr-16") + (item === (selectedTab) ? " bg-sky-200" : " bg-slate-100");
                                        return(
                                            <div key={idx} className={className}>
                                                <button type="button" className="min-h-avail" onClick={() => onSelectTab(item)}>{item.name}</button>
                                                <button type="button" className="absolute mt-1" aria-label="탭 닫기" style={{display: (_currTabList[0] === item /*&& item.name === "교과서"*/) ? "none" : "", marginLeft: "2rem"}} onClick={() => onDeleteTab(item)}>
                                                    <svg fill="#000000" width="24px" height="24px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><title>ionicons-v5-m</title><path d="M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z"></path></g></svg>
                                                </button>
                                                <button type="button" className="absolute mt-1 ml-1" onClick={() => onEditTab(item)} style={{marginLeft: "0.5rem"}}>
                                                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-6 h-6'><path fillRule='evenodd' d='M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 01-.517.608 7.45 7.45 0 00-.478.198.798.798 0 01-.796-.064l-.453-.324a1.875 1.875 0 00-2.416.2l-.243.243a1.875 1.875 0 00-.2 2.416l.324.453a.798.798 0 01.064.796 7.448 7.448 0 00-.198.478.798.798 0 01-.608.517l-.55.092a1.875 1.875 0 00-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 01-.064.796l-.324.453a1.875 1.875 0 00.2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 01.796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 01.517-.608 7.52 7.52 0 00.478-.198.798.798 0 01.796.064l.453.324a1.875 1.875 0 002.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 01-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 001.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 01-.608-.517 7.507 7.507 0 00-.198-.478.798.798 0 01.064-.796l.324-.453a1.875 1.875 0 00-.2-2.416l-.243-.243a1.875 1.875 0 00-2.416-.2l-.453.324a.798.798 0 01-.796.064 7.462 7.462 0 00-.478-.198.798.798 0 01-.517-.608l-.091-.55a1.875 1.875 0 00-1.85-1.566h-.344zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z' clipRule='evenodd' /></svg>                                                
                                                </button>
                                            </div>
                                        );    
                                    }
                                })}

                                {selectedCurriculum &&
                                <div className="rounded-t-lg h-8 px-3 bg-sky-200">
                                    <button type="button" className="font-bold text-slate-500" aria-label="탭 생성" onClick={() => onAddTab()}><span aria-hidden="true" className="block py-1">+</span></button>
                                </div>}

                            </div>
                            {/* <button type="button" className="btn-sky-m flex-shrink-0">콘텐츠 은행</button> */}
                            {canViewSet && 
                            <button type="button" className="btn-sky-m" onClick={onViewSet} style={{display: canViewSet ? "" : "none"}}>세트지 불러오기</button>}
                        </div>}

                        <div className="text-sm font-medium flex mt-2">                            
                            <span className="w-full">{selectedCurriculum ? selectedCurriculum.indexName : ""}</span>          
                            <span className="ml-3 text-right">
                                <button type="button" className="btn-sky-m flex-shrink-0" onClick={onEditSet} disabled={!canViewSet}>편집</button>
                            </span>                                              
                        </div>

                        {!canViewSet && 
                        <div className="mt-10 text-sm font-medium text-center border-gray-200">
                            <button type="button" className="btn-sky-m" onClick={onViewSet} disabled={!selectedTab || isAITab}>세트지 불러오기</button>
                        </div>}

                        {canViewSet && 
                        <div className="mt-2 text-sm font-medium text-center border-gray-200">
                            <div className="text-right py-2 px-5">
                                {selectedTab && selectedTab.set_id ? selectedTabSetInfo?.name + "(" + selectedTab.set_id + ")" : ""}
                            </div>

                            {/* <a href={previewUrl}>{previewUrl}</a>  */}
                            {selectedTab && selectedTab.set_id && 
                            <iframe 
                                width="100%" 
                                height="800px"        
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                src={previewUrl}>
                            </iframe>}
                            
                        </div>}
                        
                    </div>

                    {/* <!-- 콘텐츠 별 메타정보 --> */}
                    {/* {indexData && 
                    <div className="h-full w-60 flex-shrink-0 ">
                        <div className="px-2 py-1 bg-slate-100 border-b border-slate-300 text-center"><strong>콘텐츠 별 메타정보</strong></div>
                        <div className="overflow-y-auto h-[calc(100%-41px)]">
                            <div className="p-3">
                                <strong className="block">공릉값</strong>
                                <ul>
                                    <li><span>대단원</span></li>
                                    <li><span>중단원</span></li>
                                    <li><span>소단원</span></li>
                                </ul>
                                <strong className="block mt-5">개별값</strong>
                                <ul>
                                    <li className="flex">
                                        <span role="label" className="min-w-[90px]">성취기준</span>
                                        <div>
                                            <select className="text-xs">
                                                <option value="">9수-05-08</option>
                                                <option value="">9수-05-08</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <span role="label" className="min-w-[90px]">내용요소</span>
                                    </li>
                                    <li className="flex">
                                        <span role="label" className="min-w-[90px]">인지요소</span>
                                        <div>
                                            <select className="text-xs">
                                                <option value="">확률</option>
                                                <option value="">확률</option>
                                            </select>
                                            <select className="text-xs mt-1">
                                                <option value="">문제해결</option>
                                                <option value="">문제해결</option>
                                            </select>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>} */}
                    {/* <!-- .//콘텐츠 별 메타정보 --> */}
                    
                </div>

                <div className="flex justify-between text-right py-3">
                    <button type="button" className="btn-white" onClick={OnClose}>목록</button>
                    <button type="button" className="btn-sky" disabled={!hasAuth} onClick={onSave}>저장하기</button>
                </div>

            </div>

            {viewPopupContentsList && 
            <PopupContentsList showModal={props.showModal} showLoading={props.showLoading} onRequestIndex={onRequestIndex} onClose={() => {
                setViewPopupContentsList(false);
            }} />}

            {viewTextBookEdit && 
            <PopupTextBookEdit selectedItem={textbookData} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewTextBookEdit(false);
            }} onChangeBasicData={onChangeBasicData} />}

            {viewPopupTabEdit &&
            <PopupAddTab textbookCurriculum={selectedCurriculum} selectedTab={selectedEditTab} textbook={textbookData} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewPopupTabEdit(false);
            }} onSaved={onSavedTab} isDefaultTab={isDefaultTab} />}

            {viewPopupSetList && 
            <PopupSetList showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewPopupSetList(false);
            }} onRequst={onRequstedSet} />}

            {viewPopupIndexNSet && 
            <PopupIndexNSet showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewPopupIndexNSet(false);
            } } textbookIndex={currIndex} curriculumList={curriculumList} tabList={tabList} />}

            {viewPopupAddCurriUnit && 
            <PopupAddCurriUnit onClose={onClosePopup} onSave={onSaveAddCurriUnit} showModal={props.showModal} showLoading={props.showLoading} selectedNode={selectedNode} curriBook={curriBook} treeData={treeData} />}

            {viewTextBookIndexEdit &&
            <TextBookIndexEdit brand={props.brand} menu={props.menu} textbook={textbookData} textbookIndexId={textbookData.textbookIndex_id ? textbookData.textbookIndex_id : textBookIndexId} showModal={props.showModal} showLoading={props.showLoading} onClose={onCloseTextBookIndexEdit} />}

            {viewConfirmDelete &&
            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />}

            {viewConfirmChangeIndex &&
            <PopupConfirm view={viewConfirmChangeIndex} title={changeIndexTitle} description={changeIndexDesc} onClose={onCloseChangeIndex} onOk={onChangeIndexOK} showModal={props.showModal} />}

        </div>

    );  
}
export default TextBookEdit_1;
