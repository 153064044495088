import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, IMenu, leftmenu_2, IMeta, ICurriInfo } from "../common";
import { logger } from '../logger';
import TextBookList from "./TextBookList";
import ContentsList from "./TextBookIndexList";
import EBookList from "./EBookList";
import TextBookDeployList from "./TextBookDeployList";

interface ITextBookManager {
    view: boolean;
    menu: IMenu;
    brand: IBrand;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function TextBookManager(props: ITextBookManager) {

    const [title, setTitle] = useState("");
    const [viewContentsList, setViewContentsList] = useState(false);

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewContentsList(false);
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu");
            changeTitle();
            if(viewContentsList) {
                setViewContentsList(false);
            }
        }
    }, [props.menu])

    const changeTitle = () => {
        const _title = leftmenu_2[currMenu.menu1][currMenu.menu2];
        setTitle(_title);
    }

    function showContentsList(view: boolean) {
        setViewContentsList(view);
    }

    return (
    <div>

        {/* {!viewContentsList && 
        <div className="top-title">
            <h3>{title}</h3>
        </div>} */}

        {!viewContentsList && currMenu.menu2 === 0 && 
        <TextBookList showModal={props.showModal} showLoading={props.showLoading} brand={props.brand} menu={props.menu} showContentsList={showContentsList} />}
        {viewContentsList && currMenu.menu2 === 0 && 
        <ContentsList brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} showContentsList={showContentsList} />}
        {currMenu.menu2 === 1 && 
        <EBookList showModal={props.showModal} showLoading={props.showLoading} brand={props.brand} menu={props.menu} showContentsList={showContentsList} />}
        {currMenu.menu2 === 2 && 
        <TextBookDeployList showModal={props.showModal} showLoading={props.showLoading} brand={props.brand} menu={props.menu} showContentsList={showContentsList} />}

    </div>
    );
}
export default TextBookManager;
