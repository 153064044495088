import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, ITextBook, IUser, ITextBookTab, ISet } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { ITextBookIndexCurriculum } from "./TextBookEdit_1";

interface ISetCategoryCnt {
    id: number;
    cnt: number;
} 

interface IPopupIndexNSet {
    textbookIndex: ITextBookIndex;
    curriculumList: ITextBookIndexCurriculum[];
    tabList: ITextBookTab[];
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

function PopupIndexNSet(props: IPopupIndexNSet) {

    const [list, setList] = useState<ISet[]>([]);
    const [view, setView] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();

    const curriculumList = props.curriculumList;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            // getSetList();
            setView(true);
            logger.log("tabList", props.tabList);

        }
        bDone = true;
    }, [])

    function onClose() {
        props.onClose();
    }

    // async function getSetList() {

    //     props.showLoading(true);

    //     const brand = util.getCurrentBrand();
    //     if(!brand || !brand.id) {
    //         return;
    //     }

    //     const data = {
    //         // detail: true,
    //         brand_id: brand.id,
    //         // orderby_col: "id",
    //         // orderby_dir: "desc",
    //         limit_page: 1,
    //         limit_pageItemCnt: 1000,
    //     };
        
    //     const list = await util.getSetList(data);
    //     setList(list);
    //     setView(true);

    //     logger.log("setList", list);
    //     logger.log("tabList", props.tabList);

    //     props.showLoading(false);
    // }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{maxWidth:"60rem", maxHeight:"60rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <h2 className="pop-title">전체 목차 및 하위자료</h2>

                            <div className="px-5 mt-5 mb-5">
                                <div className="w-full h-[600px] border-y border-slate-300">
                                    <table className="border-none">
                                        <thead>
                                        <tr className="">
                                            <td className="text-center w-32">
                                                <button type="button">
                                                    <svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10" className="inline-block h-4 w-4 text-gray-800">
                                                        <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
                                                    </svg>
                                                </button>
                                                <span className="text-xs ml-1">(전체열기)</span>
                                            </td>
                                            <td className="text-center">목차</td>
                                            <td className="text-center w-[200px]">
                                                <span>등록 자료(수)</span>
                                                <div className="flex text-sm py-1 border-t border-slate-300">
                                                    {curriInfo && curriInfo.setCategory && curriInfo.setCategory.map((item, idx) => {
                                                        return(
                                                            <span key={idx} className="grow w-16">{item.val}</span>    
                                                        );
                                                    })}
                                                </div>
                                            </td>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {view && curriculumList && curriculumList.map((item, idx) => {
                                                    
                                            const setCategoryCnt : ISetCategoryCnt[] = [];
                                            
                                            // logger.log("curriculumList", item);
                                            let currSetCategory = 0;
                                            const currTabs = props.tabList.filter((tab) => tab.textbookCurriculum_key === item.id)                                                 
                                            if(currTabs && currTabs.length > 0) {
                                                // currSetCategory = currTab.setCategory;
                                                currTabs.map((tab) => {
                                                    const setCategory = tab.setCategory;
                                                    if(setCategory) {
                                                        const obj = setCategoryCnt.find((set) => set.id === setCategory);
                                                        if(obj) {
                                                            obj.cnt = obj.cnt + 1;
                                                        } else {
                                                            setCategoryCnt.push({id: setCategory, cnt: 1});
                                                        }    
                                                    }
                                                });
                                            }
                                            // logger.log("setCategoryCnt", setCategoryCnt);

                                            let className = "w-avail px-3";
                                            let html = "";
                                            
                                            if(item.depth === 0) {
                                                html = "<strong>" + "<span class=\"text-sky-500\">"+item.text+"</span>" + "</strong>";
                                            } else if(item.depth === 1) {
                                                html = "<strong>" + "<span class=\"\" style=\"margin-left: 2rem;\">"+item.text+"</span>" + "</strong>";
                                            } else if(item.depth === 2) {
                                                html = "<span class=\"\" style=\"margin-left: 4rem;\">"+item.text+"</span>";
                                            } else if(item.depth === 3) {
                                                html = "<span class=\"text-sm\" style=\"margin-left: 6rem;\">"+item.text+"</span>";
                                            }                                                

                                            return(

                                            <tr key={idx} style={{border:0}}>
                                                <td>
                                                    <div className="text-center">
                                                        <button type="button">
                                                            <svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10" className="inline-block h-4 w-4 text-gray-800">
                                                                <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="text-left">
                                                    <div className={className} dangerouslySetInnerHTML={{ __html: html }}></div>
                                                </td>
                                                <td className="text-center w-[200px]">
                                                    <div className="flex">
                                                        {curriInfo && curriInfo.setCategory && curriInfo.setCategory.map((setCategory, idx_setCategory) => {
                                                            let val = "";
                                                            const obj = setCategoryCnt.find((set) => set.id === setCategory.id);
                                                            if(obj) {
                                                                val = String(obj.cnt);
                                                            }
                                                            return(
                                                                <span key={idx_setCategory} className="grow w-16">{val}</span>    
                                                            );
                                                        })}

                                                        {/* <span key={idx} className="grow w-16">1</span>    
                                                        <span key={idx} className="grow w-16">1</span>    
                                                        <span key={idx} className="grow w-16">1</span>     */}
                                                    </div>
                                                </td>
                                            </tr>                                            
                                            );
                                        })}
                                        </tbody>
                                    </table>

                                    {/* <ul className="inline-flex w-full border-b border-slate-300">
                                        <li className="text-center w-[200px] py-4 border-r border-slate-300">
                                            <button type="button">
                                                <svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10" className="inline-block h-4 w-4 text-gray-800">
                                                    <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
                                                </svg>
                                            </button>
                                            <span className="text-xs ml-1">(전체열기)</span>
                                        </li>
                                        <li className="text-center py-4 w-avail border-r border-slate-300">목차</li>
                                        <li className="text-center w-[200px]">
                                            <span>등록 자료(수)</span>
                                            <div className="flex text-sm py-1 border-t border-slate-300">
                                                {curriInfo && curriInfo.setCategory && curriInfo.setCategory.map((item, idx) => {
                                                    return(
                                                        <span key={idx} className="grow w-16">{item.val}</span>    
                                                    );
                                                })}
                                            </div>
                                        </li>
                                    </ul> */}
                                    {/* <ul className="w-full py-4">
                                        {view && curriculumList && curriculumList.map((item, idx) => {
                                            
                                            // logger.log("curriculumList", item);
                                            let currSetCategory = 0;
                                            const currTab = props.tabList.find((tab) => tab.textbookCurriculum_key === item.id)                                            
                                            if(currTab) {
                                                // logger.log("currTab", currTab);
                                                currSetCategory = currTab.setCategory;
                                            }
                                            logger.log("currSetCategory", item, currSetCategory);

                                            let className = "w-avail px-3";
                                            let html = "";
                                            
                                            if(item.depth === 0) {
                                                html = "<strong>" + "<span class=\"text-sky-500\">"+item.text+"</span>" + "</strong>";
                                            } else if(item.depth === 1) {
                                                html = "<strong>" + "<span class=\"\" style=\"margin-left: 2rem;\">"+item.text+"</span>" + "</strong>";
                                            } else if(item.depth === 2) {
                                                html = "<span class=\"\" style=\"margin-left: 4rem;\">"+item.text+"</span>";
                                            } else if(item.depth === 3) {
                                                html = "<span class=\"text-sm\" style=\"margin-left: 6rem;\">"+item.text+"</span>";
                                            }                                                

                                            return(
                                                <li className="flex" key={idx}>
                                                    <div className="text-center px-3 w-[200px]">
                                                        <button type="button">
                                                            <svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10" className="inline-block h-4 w-4 text-gray-800">
                                                                <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
                                                            </svg>
                                                        </button>
                                                    </div>

                                                    <div className={className} dangerouslySetInnerHTML={{ __html: html }}></div>

                                                    <div className="w-[200px] px-3">
                                                        <span key={idx} className="grow w-16">1</span>    
                                                        <span key={idx} className="grow w-16">1</span>    
                                                        <span key={idx} className="grow w-16">1</span>    
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul> */}
                                </div>                              
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>
        </div>        
    );
}
  
export default PopupIndexNSet;
