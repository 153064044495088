import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from './util';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
  

interface IMyModal {
    view: boolean;
    title: string;
    description: string;
    onClose: () => void;
}

function MyModal(props: IMyModal) {
      
    return (

        <Dialog
            open={props.view}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"xs"}>
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained" autoFocus>
                    확인
                </Button>
            </DialogActions>
        </Dialog>

        // <Modal open={props.view} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        //     <Box sx={style}>
        //         {/* <Typography id="modal-modal-title" variant="h6" component="h2">
        //             <center>{props.title}</center>
        //         </Typography> */}
        //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        //             {props.description}
        //         </Typography>
        //         <div>
        //             <Button variant="contained" onClick={props.onClose} style={{position: "absolute", right: "10px", bottom: "10px"}}>확인</Button>
        //         </div>
        //     </Box>
        // </Modal>
    );
}
  
export default MyModal;
