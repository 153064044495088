import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, IMenu, IMeta, IReport } from "../common";
import { logger } from '../logger';
import ReportSearch from "./ReportSearch";
import ReportRegister from "./ReportRegister";

interface IReportList {
    brand: IBrand;
    menu?: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function ReportList(props: IReportList) {

    const [currSubject, setCurrSubject] = useState("");

    const [list, setList] = useState([] as any);
    const [selectedItem, setSelectedItem] = useState(null as any);
    const [viewList, setViewList] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            setIsEditing(false);
            setSelectedItem(null);

            const _pageNum = localStorage.getItem("bbs_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(_pageNum) {
                pagenum.value = _pageNum;
            } else {
                pagenum.value = "10";                
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(currSubject) {
            logger.log("currSubject", currSubject);
            const _searchData = searchData;
            if(currSubject !== "전체") {
                _searchData.subject = currSubject;
            } else {
                _searchData.subject = "";
            }
            onSearch(_searchData);
        }
    }, [currSubject])

    useEffect(() => {
        if(props.brand) {
            logger.log("props.brand", props);
            refreshList();
        }
    }, [props.brand])

    useEffect(() => {
        if(isEditing) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     setIsBulkEditing(false);
            // }
        } 

        if(!isEditing) {
            
            logger.log("isEditing", isEditing);

            const _searchData = searchData;
            if(_searchData) {
                onSearch(_searchData);            
            }
        }

    }, [isEditing])

    const refreshList = () => {

        setIsEditing(false);
        setSelectedItem(null);

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        const data = {
            brand_id: brand.id ? brand.id : null,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("bbs_numOfPage") ? Number(localStorage.getItem("bbs_numOfPage")) : 10,
        };
        logger.log("refreshList", data);
        onSearch(data);

        setCurrPage(1);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("bbs_numOfPage") ? Number(localStorage.getItem("bbs_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);
        const list = await util.getReportList(data);
        setList(list);
        logger.log("onSearch", data, list);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
            }
            calcPage(totalCnt, currentPage);
        }
        setViewList(true);

        props.showLoading(false);
    }

    function onSearch(data: any): void {
        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                // setCurrPage(_currPage);    
                onChangePage(_currPage);
                // logger.log("onPrevPage", _currPage, _listOfPage);
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                // setCurrPage(_currPage);
                onChangePage(_currPage);
                // logger.log("onNextPage", _currPage, _listOfPage);
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("bbs_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        logger.log("numOfPage", numOfPage);
        onSearch(_searchData);            
    }

    function onAdd(): void {
        setIsEditing(true);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onEditClose(): void {
        setIsEditing(false);
        setSelectedItem(null);

        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onChangePage(page: number) {
        
        const _searchData = searchData;
        _searchData.limit_page = page;
        onSearch(_searchData);
        
        logger.log("onChangePage", page, searchData, _searchData);

        setCurrPage(page);
    }
        
    function onConfirm(item: IReport): void {
        setIsEditing(true);
        setSelectedItem(item);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }
    
    function onChangeSubject(subject: string) {
        setCurrSubject(subject);
    }

    return (
        <div>
            <div className="top-title">
                <h3>{isEditing ? "신고 내용" : "신고 관리"}</h3>
            </div>

            <ul className="tab-list" style={{display: isEditing ? "none" : ""}}>
                <li onClick={() => onChangeSubject("전체")}><a href="#" aria-current={currSubject === "전체" ? "page" : undefined} >전체</a></li>
                <li onClick={() => onChangeSubject("수학")} className={"ml-1"}><a href="#" aria-current={currSubject === "수학" ? "page" : undefined}>수학</a></li>
                <li onClick={() => onChangeSubject("영어")} className={"ml-1"}><a href="#" aria-current={currSubject === "영어" ? "page" : undefined}>영어</a></li>
                <li onClick={() => onChangeSubject("과학")} className={"ml-1"}><a href="#" aria-current={currSubject === "과학" ? "page" : undefined}>과학</a> </li>
            </ul>

            <div style={{display: isEditing ? "none" : ""}}>
            
                {/* <!-- 검색영역 --> */}
                <ReportSearch onSearch={onSearch} brand={props.brand} pageInfo={{ page: currPage, pageItemCnt: localStorage.getItem("bbs_numOfPage") ? Number(localStorage.getItem("bbs_numOfPage")) : 10 }} showLoading={props.showLoading} />
                {/* <!-- .//검색영역 --> */}

                <div className="mt-5 mb-3 text-right">
                    <label htmlFor="page-num">페이지당 표기 수</label>
                    <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </select>
                </div>

                {!isEditing && viewList && <>

                {/* <!-- table --> */}
                <table>
                    <caption className="sr-only">관리 테이블</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="pl-4 pr-3">번호</th>
                            <th scope="col" className="px-3">학교명</th>
                            <th scope="col" className="px-3">교과목</th>
                            <th scope="col" className="px-3">유형</th>
                            <th scope="col" className="px-3">내용</th>
                            <th scope="col" className="px-3">구분</th>
                            <th scope="col" className="px-3">등록자</th>
                            <th scope="col" className="px-3">등록일</th>
                            <th scope="col" className="px-3">처리상태</th>
                            <th scope="col" className="pl-3 pr-4">상세보기</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewList && list &&
                        <tr style={{display: (list as IReport[]).length === 0 ? "" : "none"}}>
                            <td colSpan={10}>등록된 오브젝트가 없습니다.</td>
                        </tr>}

                        {viewList && list && (list as IReport[]).map((item, idx) => {

                            const totalCnt = item.full_count ? item.full_count : 1;
                            const _numOfPage = localStorage.getItem("bbs_numOfPage") ? Number(localStorage.getItem("bbs_numOfPage")) : 10;
                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                            // logger.log(totalCnt, _numOfPage, currPage, _num);

                            let description = item.description;
                            if(description && description.length > 30) {
                                description = item.description.substring(0, 30) + "...";
                            }

                            return (
                                // <div key={idx}><img src={item.url} width={40}/>{JSON.stringify(item)}</div>
                                <tr key={idx}>                        
                                    <td>{_num}</td>
                                    <td>{item.school_name}</td>
                                    <td>{item.subject}</td>
                                    <td>{item.category}</td>
                                    <td>{description}</td>
                                    <td>{item.type}</td>
                                    <td>{item.creator_name}<br/>({item.creator})</td>
                                    <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                    <td>{item.status}</td>
                                    <td>
                                        <button type="button" className="btn-white-s" onClick={() => onConfirm(item)}>확인</button>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                {/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    <div className="absolute top-0 right-0 text-right">
                        <button type="button" className="btn-sky" onClick={onAdd}>등록</button>
                    </div>

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div></>}

            </div>

            {isEditing && <ReportRegister OnClose={onEditClose} brand={props.brand} selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} />}

        </div>
    );  
}
export default ReportList;
