import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from '../util';
import { IArticle, IBrand, ICurriInfo, IMeta, IMetaMap } from "../common";
import { logger } from '../logger';
import PopupContentsList from "../contents/PopupContentsList";

interface IMetaContentsItemEdit {
    brand: IBrand;
	item: IMeta | null;
	parentItem: IMeta | null;
    onCloseEdit: (isEdited: boolean, from: string) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaContentsItemEdit(props: IMetaContentsItemEdit) {

    const [UUID, setUUID] = useState("");

    const [representativeContentsList, setRepresentativeContentsList] = useState<IArticle[]>([] as any);
    const [viewPopupContentsList, setViewPopupContentsList] = useState(false);
    const [selectedContentsId, setSelectedContentsId] = useState(-1);

    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.onCloseEdit(false, "");
        //     return;
        // }

        if(!bDone) {

            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);

            logger.log("props", props);

            setRepresentativeContentsList([{id:-1, content_type:""} as IArticle]);

            const item = props.item;
            if(item) {
                if(item.uuid && item.uuid.length > 10) {
                    setUUID(item.uuid);
                }

                const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
                if(meta_val) {
                    meta_val.value = item?.val;
                }
                const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                if(meta_description) {
                    meta_description.value = item.description ? item.description : "";
                }
                const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                if(meta_code) {
                    meta_code.value = item.code;
                }
                meta_code.readOnly = true;
                meta_code.disabled = true;

                if(item.val1) {                    
                    // logger.log("curriInfo?.curriBook", curriInfo?.curriBook, item.val1);                    
                    const curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
                    curriBook.value = item.val1;
                }
                if(item.val2) {
                    const arr = JSON.parse(item.val2) as IArticle[];
                    if(arr && arr.length > 0) {
                        setRepresentativeContentsList(JSON.parse(item.val2));
                    } else {
                        setRepresentativeContentsList([{id:-1, content_type:""} as IArticle]);
                    }
                }
            }

            const active = (document.getElementById('active') as HTMLInputElement);
            if(props.item) {
                active.checked = props.item.is_active;
            } else {
                active.checked = true;
            }
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(representativeContentsList && representativeContentsList.length > 0) {
            logger.log("representativeContentsList", representativeContentsList);
            onContents2UI();
        }
    }, [representativeContentsList])

    async function insertMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);

        const result = await util.insertMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.updateMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    function onSave(): void {

        const brand = util.getCurrentBrand();
        if(!brand) {
            props.showModal("", "브랜드가 정상적이지 않습니다.");
            return;
        }

        let val = "";
        const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
        if(meta_val)
        {
            val = (document.getElementById('meta_val') as HTMLInputElement).value
            if(val === "") {
                props.showModal("", "메타값을 입력해 주세요.");
                return;
            }
        }
        const description = (document.getElementById('meta_description') as HTMLInputElement).value
        const code = (document.getElementById('meta_code') as HTMLInputElement).value
        const is_active = (document.getElementById('active') as HTMLInputElement).checked

        if(code === "") {
            props.showModal("", "메타코드를 입력해 주세요.");
            return;
        }

        const parentItem = props.parentItem;
        logger.log('parentItem', parentItem);

        let _code = code;
        let _parent_id = 0;
        if(parentItem) {
            let arr = [];    
            _code = props.item ? code : parentItem.code + "-" + code;
            _parent_id = parentItem.id!;
        }

        let meta_mapping_meta : IMeta[] = [];

        const curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
        const curriBook_val = curriInfo?.curriBook[curriBook.selectedIndex] as IMeta;
        meta_mapping_meta.push(curriBook_val)

        const repArticle : {id:number, name: string, content_type: string}[] = [];
        representativeContentsList.map((item, idx) => {
            if(item.id > 0) {

                const content_type1 = (document.getElementById('content_type1_'+idx) as HTMLInputElement);
                let content_type = "";
                if(content_type1.checked) {
                    content_type = "개념";
                } else {
                    content_type = "영상";
                }

                repArticle.push({id: item.id, name: item.name, content_type: content_type});
            }
        });

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const meta = {
            id: props.item ? props.item.id : undefined,
            brand_id: brand.id ? brand.id : null,

            parent_id: _parent_id,
            code: _code,
            name: "ContentsItem1",
            val: meta_val.value,
            is_active: is_active ? true : false,
            description: description,
            depth: 2,

            creator: uid,
            creator_id: _id,
            creator_name: uname,

            updater: props.item ? uid : undefined,
            updater_id: props.item ? _id : undefined,
            updater_name: props.item ? uname : undefined,
            
            uuid: props.item ? props.item.uuid : UUID,

            name1: "curriBook",
            val1: curriBook_val.id,

            name2: "대표 콘텐츠",
            val2: JSON.stringify(repArticle),
        }
        
        const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": meta_mapping_meta, "articles": (representativeContentsList.length === 1 && representativeContentsList[0].id === -1) ? undefined : representativeContentsList}
        logger.log("meta_mapping_meta", metamap);
    
        if(props.item) {
            updateMetaMetaMap(metamap);  
        } else {
            insertMetaMetaMap(metamap);  
        }
        logger.log("onSave", metamap);
    }
    
    function onContents2UI() {
        representativeContentsList.map((item, idx) => {
            const html = (document.getElementById('representativeContents_'+idx) as HTMLInputElement);
            if(html) {
                if(item.id > 0) {
                    html.value = item.name + " - " + item.id;
                } else {
                    html.value = "";
                }
            }
        });
    }

    function onAddContents() {
        const _representativeContentsList = JSON.parse(JSON.stringify(representativeContentsList));
        _representativeContentsList.push({id:-1} as IArticle);
        setRepresentativeContentsList(_representativeContentsList);
    }
    
    function onDelContents(idx: number) {
        if(idx === 0) {
            setRepresentativeContentsList([{id:-1, content_type:""} as IArticle]);
        } else {
            const delContent = representativeContentsList[idx];
            delContent.id = -1000;
            const _representativeContentsList = JSON.parse(JSON.stringify(representativeContentsList)) as IArticle[];
    
            const newList = _representativeContentsList.filter((item) => item.id !== -1000);
            setRepresentativeContentsList(newList);    
        }
    }

    function onSearchArticle(idx: number) {
        setSelectedContentsId(idx);
        setViewPopupContentsList(true);
    }

    async function onSelectContent(item: any) {
        
        logger.log("onSelectContent", item);

        const _representativeContentsList = JSON.parse(JSON.stringify(representativeContentsList)) as IArticle[];

        if(_representativeContentsList.length > selectedContentsId) {
            _representativeContentsList[selectedContentsId] = item;
            setRepresentativeContentsList(_representativeContentsList);
        }

        setViewPopupContentsList(false);
    }

    const hasAuth = util.getHasAuthMenu(props.item ? props.item.creator : "");

    return (
        <div>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">관리테이블</caption>
                <tbody>

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">메타 값 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_val" className="w-avail"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-disc">설명</label></th>
                        <td className="text-left">
                            <input type="text" id="meta_description" className="w-avail"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_code" className="w-1/2" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-grade">교과과정<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_curriBook">
                                {curriInfo && curriInfo?.curriBook && (curriInfo?.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.id}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>

                    <tr style={{display:""}}>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">대표 콘텐츠</label></th>
                        <td className="text-left">
                            <div>
                            {representativeContentsList && representativeContentsList.map((item, idx) => {
                                return(
                                    <div key={idx} className={(idx > 0 ? " mt-2" : "")}>
                                        <input type="text" id={"representativeContents_"+idx} className={"w-1/2"} readOnly />
                                        <button type="button" className="btn-sky-s ml-2" onClick={() => onSearchArticle(idx)}>검색</button>
                                        <span className="ml-3">
                                            <input id={"content_type1_"+idx} name={"content_type_"+idx} type="radio" defaultChecked={item.content_type !== "영상"} />
                                            <label htmlFor="content_type1">개념</label>
                                        </span>
                                        <span className="">
                                            <input id={"content_type2_"+idx} name={"content_type_"+idx} type="radio" defaultChecked={item.content_type === "영상"} />
                                            <label htmlFor="content_type2">영상</label>
                                        </span>
                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddContents}>+</button>}
                                        {(idx > 0 || (representativeContentsList.length === 1 && idx === 0)) &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelContents(idx)}>-</button>}
                                    </div>
                                );
                            })}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" className="w-56">고유코드</th>
                        <td className="text-left">{UUID}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록자</th>
                        {props.item &&
                        <td className="text-left">{props.item.creator_name} ({props.item.creator})</td>}
                        {!props.item &&
                        <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록일</th>
                        {props.item &&
                        <td className="text-left">{props.item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>}
                        {!props.item &&
                        <td className="text-left">{util.getDate()}</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
                    
            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-white" onClick={() => props.onCloseEdit(false, "")}>취소</button>
                    {!props.item &&
                    <button type="button" className="btn-sky" onClick={onSave}>{"등록"}</button>}
                    {props.item &&
                    <button type="button" className="btn-sky" onClick={onSave} disabled={!hasAuth}>{"수정"}</button>}
                </div>
            </div>

            {viewPopupContentsList &&
            <PopupContentsList brand={props.brand} textType={""} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectContent} onClose={function (): void {
                setViewPopupContentsList(false);
            } } />}

        </div>
    );
}

export default MetaContentsItemEdit;
