import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IUser } from "../common";  
import { logger } from "../logger";

interface IRestutMultiLang {
    key: string;
    value: string;
}

export interface IMultiLang {
    val: string;
    countryKey: string;
    countryVal: string;
}

interface IPopupEditMultiLang {
    entry: string;
    onClose: () => void;
    onCloseMultiLang: (multiLang: IMultiLang[]) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function PopupEditMultiLang(props: IPopupEditMultiLang) {
      
    const [msg, setMsg] = useState("");
    const [isOk, setIsOk] = useState(false);
    const [multiLangList, setMultiLangList] = useState<IMultiLang[]>([]);

    const listLangs : string[] = ["영어", "한국어", "일본어", "중국어 간체", "중국어 번체", "베트남어", "스페인어", "포르투갈어", "아랍어"];
    const listLangsKey : string[] = ["eng", "kor", "jpn", "ch1", "ch2", "vtn", "spa", "por", "ara"];


    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            
            const listMultiLang : IMultiLang[] = []; 
            listLangsKey.map((item, idx) => {
                const _multiLang : IMultiLang = {
                    val: "",
                    countryKey: item,
                    countryVal: listLangs[idx],
                } 

                listMultiLang.push(_multiLang);
            });
            // logger.log("listMultiLang", listMultiLang);
            setMultiLangList(listMultiLang);
            
            getMultiLang(listMultiLang);
        }
        bDone = true;

    }, [])

    async function getMultiLang(_multiLangList : IMultiLang[]) {

        // logger.log("getMultiLang", props.entry.trim());

        props.showLoading(true);
        const result = await util.getMultiLangInfo(props.entry.trim());
        logger.log("getMultiLang", props.entry.trim(), result);

        if(result) {
            const _result = result as IRestutMultiLang[];
            _result.map((item, idx) => {
                const _multiLang = _multiLangList.find((ml) => ml.countryKey === item.key);
                if(_multiLang) {
                    _multiLang.val = item.value;

                    const lang = document.getElementById(item.key) as HTMLInputElement;
                    if(lang) {
                        lang.value = item.value;
                    }
                }
            });

            setMultiLangList(_multiLangList);
            // logger.log("getMultiLang", _multiLangList);
        }

        props.showLoading(false);
    }

    async function onSave() {

        const _multiLang : IMultiLang[] = [];

        listLangsKey.map((item, idx) => {
            const lang = document.getElementById(item) as HTMLInputElement;
            if(lang) {
                const _val : string = lang.value;
                if(_val) {
                    const data : IMultiLang = {
                        val: _val,
                        countryKey: item,
                        countryVal: listLangs[idx],                 
                    }

                    _multiLang.push(data);
                }
            }
        });

        props.showLoading(true);

        const data = {
            entry: props.entry, 
            eng: _multiLang.find((item) => item.countryKey === "eng") ? _multiLang.find((item) => item.countryKey === "eng")?.val : "",
            kor: _multiLang.find((item) => item.countryKey === "kor") ? _multiLang.find((item) => item.countryKey === "kor")?.val : "",
            jpn: _multiLang.find((item) => item.countryKey === "jpn") ? _multiLang.find((item) => item.countryKey === "jpn")?.val : "",
            ch1: _multiLang.find((item) => item.countryKey === "ch1") ? _multiLang.find((item) => item.countryKey === "ch1")?.val : "",
            ch2: _multiLang.find((item) => item.countryKey === "ch2") ? _multiLang.find((item) => item.countryKey === "ch2")?.val : "",
            vtn: _multiLang.find((item) => item.countryKey === "vtn") ? _multiLang.find((item) => item.countryKey === "vtn")?.val : "",
            spa: _multiLang.find((item) => item.countryKey === "spa") ? _multiLang.find((item) => item.countryKey === "spa")?.val : "",
            por: _multiLang.find((item) => item.countryKey === "por") ? _multiLang.find((item) => item.countryKey === "por")?.val : "",
            ara: _multiLang.find((item) => item.countryKey === "ara") ? _multiLang.find((item) => item.countryKey === "ara")?.val : "",
        };

        const result = await util.subtitleForSave(data, "auto");

        props.showLoading(false);

        logger.log(_multiLang);

        props.onCloseMultiLang(_multiLang);
    }

    function onClose() {
        props.onClose();
    }

    return (
                
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">다국어 편집</h2>
                            <div className="px-5">
                                <div className="py-8 text-center">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="row" className="w-full" colSpan={2}><label htmlFor="inp-meta">CONTENTS</label></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>    
                                                <th scope="row" className="w-8"><label htmlFor="inp-meta">ENTRY</label></th>
                                                <td className="text-left">{props.entry}</td>
                                            </tr>
                                            {listLangs.map((item, idx) => {
                                                return(
                                                    <tr key={idx}>    
                                                        <th scope="row"><label htmlFor="inp-meta">{item}</label></th>
                                                        <td className="text-left">
                                                            <input placeholder="내용입력" className="w-avail" id={listLangsKey[idx]} type="text" />
                                                        </td>
                                                    </tr>    
                                                );
                                            })}
                                        </tbody>
                                    </table>                                    
                                </div>

                                <div className="my-5 text-right">
                                    <button type="button" className="btn-sky" onClick={onSave}>저장</button>
                                </div>

                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>

    );
}
  
export default PopupEditMultiLang;
