import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IUser } from "../common";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
  

interface IPopupCheckID {
    view: boolean;
    userList: IUser[];
    checkedId: string;
    onClose: () => void;
    onUseId: (uid: string) => void;
}

function PopupCheckID(props: IPopupCheckID) {
      
    const [msg, setMsg] = useState("");
    const [isOk, setIsOk] = useState(false);

    useEffect(() => {
        if(props.view) {
            (document.getElementById('uid') as HTMLInputElement).value = props.checkedId;
            onCheckDup();
            // setMsg("아이디를 입력해 주세요.");
        }
    }, [props.view])

    function onCheckDup(): void {

        setIsOk(false);

        const uid = (document.getElementById('uid') as HTMLInputElement).value;
        if(uid === "") {
            setMsg("아이디를 입력해 주세요.");
            return;
        } else if(uid.length < 8 || uid.length > 16) {
            setMsg("8~16자 문자와 숫자를 결합해 입력해 주세요.");
            return;
        }

        const num = uid.search(/[0-9]/g);
        const eng = uid.search(/[a-z]/ig);
        if(num < 0 || eng < 0) {
            setMsg("8~16자 문자와 숫자를 결합해 입력해 주세요.");
            return;    
        }
        // logger.log(num);
        // logger.log(eng);

        const _userList = props.userList;
        if(_userList && _userList.length > 0) {
            const DupUser = _userList.find((user) => user.uid === uid);
            if(DupUser) {
                setMsg("이미 등록된 아이디 입니다.");
            } else {
                setMsg("사용 가능한 아이디 입니다.");
                setIsOk(true);
            }
        }
    }

    function onUseId(): void {
        const uid = (document.getElementById('uid') as HTMLInputElement).value;
        props.onUseId(uid);
    }

    return (
                
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : props.view ? "" : "none"}}>
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box max-w-xl min-w-[576px]">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={props.onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">아이디 중복 확인</h2>
                            <div className="px-5">
                                <div className="py-8 border-b border-slate-600 text-center">
                                    <label htmlFor="overlap_id">아이디</label>
                                    <input type="text" id="uid" onChange={() => {
                                        onCheckDup();
                                    }}/>
                                    <button type="button" className="btn-sky ml-3" onChange={() => {
                                        onCheckDup();
                                    }}>중복확인</button>
                                </div>
                            </div>
                            <div className="my-5 text-center">
                                <p>{msg}</p>
                                <button type="button" className="btn-white mt-5" onClick={props.onClose} style={{display:isOk ? "none" : ""}}>닫기</button>
                                <button type="button" className="btn-white mt-5" onClick={onUseId} style={{display:!isOk ? "none" : ""}}>사용하기</button>

                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>

    );
}
  
export default PopupCheckID;
