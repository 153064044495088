import React, { RefObject, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import type { NodeModel } from "@minoru/react-dnd-treeview";
import styles from "./CustomNode.module.css";
import '../../common.scss';
import { NodeData } from "../TextBookIndexEdit";

type Props = {
    node: NodeModel;
    depth: number;
    isOpen: boolean;
    hasChild: boolean;
    testIdPrefix?: string;
    handleRef: RefObject<any>;
    onToggle: (id: NodeModel["id"]) => void;
};

export const CustomNode_: React.FC<Props> = ({
    testIdPrefix = "",
    ...props
}) => {

    const { id } = props.node;
    const indent = props.depth * 36;

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    return (
        <div id="rootNode" className={styles.root_} style={{ paddingInlineStart: indent }} data-testid={`${testIdPrefix}custom-node-${id}`}>
            <table className="border-none">
                <tbody>
                    <tr>
                        <td className="w-8" style={{padding:0}}>
                            <div className={`${styles.expand} ${props.isOpen ? styles.isOpen : ""}`}>
                                {props.hasChild && (
                                <div onClick={handleToggle}>
                                    <ArrowDropDownIcon />
                                </div>
                                )}
                            </div>
                        </td>
                        <td className="w-avail" style={{padding:0}}>
                            <div className={styles.label + " text-left"} >
                                <Typography variant="body2">
                                    {props.node.text}
                                </Typography>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
