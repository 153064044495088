import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, ICurriInfo, ILibrary, ILibraryFile, IMeta, IScript, IText } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from 'xlsx';

interface IContentsSetListBulkRegister {
    brand: IBrand;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function ContentsSetListBulkRegister(props: IContentsSetListBulkRegister) {

    const [fileContent, setFileContent] = useState(null as any);
    const [fileUploading, setFileUploading] = useState(false);

    let prevView = false;
    
    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    useEffect(() => {
    }, [])

    async function onFileUploadForSave() {

        if(!fileContent) {
            props.showModal("", "엑셀 파일을 등록해주세요.");
            return;
        }

        logger.log("onFileUploadForSave", fileContent.file);      

        var reader = new FileReader();
        reader.onload = (e) => onExcelProccessing(e, fileContent.file);
        reader.readAsBinaryString(fileContent.file)
    }

    async function onExcelProccessing(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            logger.log("dataParse", dataParse);  

            const names = [
                "curriBook",
                "curriSchool", 
                "curriSubject", 
                "articleType",
                "articleCategory",
                "subjectAbility",
                "setCategory",
                "difficulty",
            ];
            const listAll = await util.getMetaNamesList(names);
            logger.log("listAll", listAll);  

            const curriBook = listAll.filter((meta, _) => meta.name === "curriBook");
            const curriSchool = listAll.filter((meta, _) => meta.name === "curriSchool");
            const curriSubject = listAll.filter((meta, _) => meta.name === "curriSubject");
            const articleType = listAll.filter((meta, _) => meta.name === "articleType");
            const articleCategory = listAll.filter((meta, _) => meta.name === "articleCategory");
            const subjectAbility = listAll.filter((meta, _) => meta.name === "subjectAbility");
            const setCategory = listAll.filter((meta, _) => meta.name === "setCategory");
            const difficulty = listAll.filter((meta, _) => meta.name === "difficulty");

            let articles : {article_id: number}[] = [];

            for (let i = 5; i < dataParse.length; i++) {

                const arrItem = dataParse[i];
                const item = arrItem as string[];

                if(item.length === 0) break;

                logger.log("item" + i, item);

                const listMetaMap : IMeta[] = [];

                const _curriSchool = curriSchool.find((meta) => meta.code === item[3]);
                if(_curriSchool) {
                    listMetaMap.push(_curriSchool);
                }
                const _curriSubject = curriSubject.find((meta) => meta.code === item[4]);
                if(_curriSubject) {
                    listMetaMap.push(_curriSubject);
                }
                const _curriBook = curriBook.find((meta) => meta.code === item[5]);
                if(_curriBook) {
                    listMetaMap.push(_curriBook);
                }

                let _curriUnit1 = undefined;
                let _curriUnit2 = undefined;
                let _curriUnit3 = undefined;
                let _curriUnit4 = undefined;
                let _curriUnit5 = undefined;

                if(_curriBook) {
                    const list = await util.getDescendantsList(_curriBook.code);

                    const arr = item[6].split("-");
                    logger.log("arr", item[6], arr, list);

                    _curriUnit1 = arr.length > 0 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0]) : undefined;
                    _curriUnit2 = arr.length > 1 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1]) : undefined;
                    _curriUnit3 = arr.length > 2 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1] + "-" + arr[2]) : undefined;
                    _curriUnit4 = arr.length > 3 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]) : undefined;
                    _curriUnit5 = arr.length > 4 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3] + "-" + arr[4]) : undefined;

                    if(_curriUnit1) {
                        listMetaMap.push(_curriUnit1);
                    }
                    if(_curriUnit2) {
                        listMetaMap.push(_curriUnit2);
                    }
                    if(_curriUnit3) {
                        listMetaMap.push(_curriUnit3);
                    }
                    if(_curriUnit4) {
                        listMetaMap.push(_curriUnit4);
                    }
                    if(_curriUnit5) {
                        listMetaMap.push(_curriUnit5);
                    }
                                        
                }

                const listFile = [];
                const file = {
                    "url":item[7],
                    // "thumbnail":item[7],
                }
                listFile.push(file);

                const data = {
                    "brand_id": item[0],
                    "type_1": item[1],
                    "name": item[2], 
                    "is_active": item[8] === "Y" ? true : false, 
                    "is_publicOpen": item[9] === "Y" ? true : false, 
                    "creator": util.getUserInfo("uid"),
                    "curriBook": _curriBook?.id,
                    "curriUnit1": _curriUnit1?.id,
                    "curriUnit2": _curriUnit2?.id,
                    "curriUnit3": _curriUnit3?.id,
                    "curriUnit4": _curriUnit4?.id,
                    "curriUnit5": _curriUnit5?.id,
                    "metaMap": listMetaMap, 
                    "files": listFile, 
                };
        
                const result = await util.mediaForSave(data, "insert");
                logger.log("mediaForSave", data, result);

                if(result) {
                    
                    const media = result as ILibrary[];

                    if(media && media.length > 0 && media[0].id >= 0) {

                        const media_id = media[0].id;

                        const listMetaMap : IMeta[] = [];

                        const _articleType = articleType.find((meta) => meta.code === item[10]);
                        if(_articleType) {
                            listMetaMap.push(_articleType);
                        }
                        const _articleCategory = articleCategory.find((meta) => meta.code === item[14]);
                        if(_articleCategory) {
                            listMetaMap.push(_articleCategory);
                        }
                        const arr15 = item[15].split(",");
                        for (let i = 0; i < arr15.length; i++) {
                            const element = arr15[i];
                            const _subjectAbility = subjectAbility.find((meta) => meta.code === element.trim());
                            if(_subjectAbility) {
                                listMetaMap.push(_subjectAbility);
                            }
                        }
                
                        const arr = item[17].split("-");
                        logger.log("arr", item[17], arr);

                        const list = await util.getDescendantsList(arr[0]);

                        const sm0 = arr.length > 0 ? list.find((meta) => meta.code === arr[0]) : undefined;
                        const sm1 = arr.length > 1 ? list.find((meta) => meta.code === arr[0] + "-" + arr[1]) : undefined;
                        const sm2 = arr.length > 2 ? list.find((meta) => meta.code === arr[0] + "-" + arr[1] + "-" + arr[2]) : undefined;
                        const sm3 = arr.length > 3 ? list.find((meta) => meta.code === arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]) : undefined;
                        const sm4 = arr.length > 4 ? list.find((meta) => meta.code === arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3] + "-" + arr[4]) : undefined;
                        const sm5 = arr.length > 5 ? list.find((meta) => meta.code === arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3] + "-" + arr[4] + "-" + arr[5]) : undefined;

                        if(sm0) {
                            listMetaMap.push(sm0);
                        }
                        if(sm1) {
                            listMetaMap.push(sm1);
                        }
                        if(sm2) {
                            listMetaMap.push(sm2);
                        }
                        if(sm3) {
                            listMetaMap.push(sm3);
                        }
                        if(sm4) {
                            listMetaMap.push(sm4);
                        }
                        if(sm5) {
                            listMetaMap.push(sm5);
                        }

                        const hashTag = [];
                        const arr16 = item[16].split(",");
                        for (let i = 0; i < arr16.length; i++) {
                            const element = arr16[i];
                            hashTag.push(element.trim());
                        }

                        const data_media = {
                            type_1: "video", 
                            id: media_id, 
                            name: item[2],                      // 없으면 공백(길이 0 문자열)
                            url: item[7],                       // ex) upload/1/20240723/Cxuc317dc.mp4
                            description: "",                    // 없으면 공백(길이 0 문자열)
                            creator: util.getUserInfo("uid"),
                            uniq: "" ,                          //==> 기본
                            updated: false,                     //==> 기본
                        }
                        let data_media_str = JSON.stringify(data_media);
                        data_media_str = data_media_str.replaceAll("\"", "&quot;");
                        logger.log("data_media", data_media);

                        const html = "<div data-editable=\"true\" data-placeholder=\"내용을 입력해 주세요.\"><div data-editable=\"false\" class=\"kve-block caption-none kve-media-container video ui-view center full-width\" id=\"Hxuolwz0g\" data-media=\"" + data_media_str + "\" style=\"text-align: center; transform-origin: 0px 0px; vertical-align: middle;\"><figure><figcaption data-editable=\"true\" aria-placeholder=\"제목입력\" data-placeholder=\"제목입력\" style=\"text-align: center;\"></figcaption><kve-video-ui style=\"max-width: 100%; height: 38.9375rem;\" aria-label=\"\"></kve-video-ui></figure></div></div>";
                        
                        const data = {   

                            "saveType": "insert",
                            "html": html,
                            "medias": [media_id],
                            "article": 
                            {
                                "loginUserId": util.getUserInfo("uid"),

                                "brand_id": item[0],     
                                "name": item[11],
                                "description": item[12],
                                "hashTag": hashTag,
                                "is_publicOpen": item[13] === "Y" ? true : false,             
                                        
                                "creator": util.getUserInfo("uid"),
                        
                                "metaMap": listMetaMap,

                                "thumbnail": item[18],
                            }
                        }
                                          
                        const result = await util.articleForSave(data);
                        logger.log("articleForSave", data, result);        

                        if(result) {
                            const article_id = Number(result);
                            articles.push({article_id: article_id});
                        }

                        if(item[19] === undefined || item[19].trim().length === 0) {
                            continue;
                        }

                        if(articles.length > 0) {
                
                            const listMetaMap : IMeta[] = [];
    
                            const _setCategory = setCategory.find((meta) => meta.code === item[20]);
                            if(_setCategory) {
                                listMetaMap.push(_setCategory);
                            }

                            const _curriBook = curriBook.find((meta) => meta.code === item[21]);
                            if(_curriBook) {
                                listMetaMap.push(_curriBook);
                            }
            
                            let _curriUnit1 = undefined;
                            let _curriUnit2 = undefined;
                            let _curriUnit3 = undefined;
                            let _curriUnit4 = undefined;
                            let _curriUnit5 = undefined;
            
                            if(_curriBook) {
                                const list = await util.getDescendantsList(_curriBook.code);
            
                                const arr = item[22].split("-");
                                logger.log("arr", item[22], arr, list);
            
                                _curriUnit1 = arr.length > 0 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0]) : undefined;
                                _curriUnit2 = arr.length > 1 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1]) : undefined;
                                _curriUnit3 = arr.length > 2 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1] + "-" + arr[2]) : undefined;
                                _curriUnit4 = arr.length > 3 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]) : undefined;
                                _curriUnit5 = arr.length > 4 ? list.find((meta) => meta.code === _curriBook.code + "-" + arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3] + "-" + arr[4]) : undefined;
            
                                if(_curriUnit1) {
                                    listMetaMap.push(_curriUnit1);
                                }
                                if(_curriUnit2) {
                                    listMetaMap.push(_curriUnit2);
                                }
                                if(_curriUnit3) {
                                    listMetaMap.push(_curriUnit3);
                                }
                                if(_curriUnit4) {
                                    listMetaMap.push(_curriUnit4);
                                }
                                if(_curriUnit5) {
                                    listMetaMap.push(_curriUnit5);
                                }                                                        
                            }

                            const _difficulty = difficulty.find((meta) => meta.code === item[24]);
                            if(_difficulty) {
                                listMetaMap.push(_difficulty);
                            }
                            
                            const data = {   
    
                                "saveType": "insert",
                                "articles": articles,
                                "set": 
                                {
                                    "loginUserId": util.getUserInfo("uid"),

                                    "brand_id": item[0],     
                                    "name": item[19],
                                    "is_publicOpen": item[23] === "Y" ? true : false,             
                                            
                                    "creator": util.getUserInfo("uid"),
                            
                                    "metaMap": listMetaMap,
                                }
                            }
                                                              
                            const result = await util.setForInsert(data);
                            logger.log("setForInsert", data, result);                    
                        }

                        articles = [];
                    }
                }
            }

            setFileUploading(false);

            props.showModal("", "등록이 완료되었습니다.");
        }
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files![0];
        const _content = {
            file: _url,
        }
        logger.log("handleChange", _content);
    
        setFileContent(_content);
    }
    
    return (
        <div>

            <div>

                <h4>수업자료(영상)일괄등록</h4>

                {/* <!-- table --> */}
				<table>
					<caption className="sr-only"></caption>
					<tbody>
						<tr>
							<th scope="row"><label htmlFor="file-input" className="mr-0">파일 찾기</label></th>
							<td className="text-left">
								<label htmlFor="file-input" className="sr-only">파일찾기</label>
								{/* <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file"/> */}
								<input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file" onChange={(e) => handleChange(e)}/>
							</td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
            </div>

            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-sky" onClick={() => {
                        const url = "https://t-cms.vsaidt.com/sampleForm/%EC%98%81%EC%83%81%20%EC%9D%BC%EA%B4%84%20%EC%97%85%EB%A1%9C%EB%93%9C%20%EC%97%91%EC%85%80%20%EC%96%91%EC%8B%9D_%EC%8B%9C%EC%8A%A4%ED%85%9C%EA%B8%B0%ED%9A%8D%20Cell_0719_%EC%88%98%EC%A0%95.xlsx";
                        window.open(url, "_blank", "noopener, noreferrer");                            
                    }}>엑셀다운로드</button>
                    <button type="button" className="btn-sky" onClick={onFileUploadForSave}>저장</button>
                </div>
            </div>

            {fileUploading && <>
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>
            </>}

        </div>
    );
}

export default ContentsSetListBulkRegister;