import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from '../util';
import { IBrand, ICurriInfo, IMeta, IMetaMap } from "../common";
import { logger } from '../logger';

interface IMetaCommunicationEdit {
    brand: IBrand;
	item: IMeta | null;
	parentItem: IMeta | null;
    onCloseEdit: (isEdited: boolean, from: string) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaCommunicationEdit(props: IMetaCommunicationEdit) {

    const [UUID, setUUID] = useState("");

    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.onCloseEdit(false, "");
        //     return;
        // }

        if(!bDone) {

            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);

            logger.log("props", props);

            const item = props.item;
            if(item) {
                if(item.uuid && item.uuid.length > 10) {
                    setUUID(item.uuid);
                }

                const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
                if(meta_val) {
                    meta_val.value = item?.val;
                }
                const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                if(meta_description) {
                    meta_description.value = item.description ? item.description : "";
                }
                const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                if(meta_code) {
                    meta_code.value = item.code;
                }
                meta_code.readOnly = true;
                meta_code.disabled = true;
            } else {
            }

            const active = (document.getElementById('active') as HTMLInputElement);
            if(props.item) {
                active.checked = props.item.is_active;
            } else {
                active.checked = true;
            }
        }
        bDone = true;

    }, [])

    async function insertMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);

        const result = await util.insertMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.updateMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    function onSave(): void {

        const brand = util.getCurrentBrand();
        if(!brand) {
            props.showModal("", "브랜드가 정상적이지 않습니다.");
            return;
        }

        let val = "";
        const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
        if(meta_val)
        {
            val = (document.getElementById('meta_val') as HTMLInputElement).value
            if(val === "") {
                props.showModal("", "메타값을 입력해 주세요.");
                return;
            }
        }
        const description = (document.getElementById('meta_description') as HTMLInputElement).value
        const code = (document.getElementById('meta_code') as HTMLInputElement).value
        const is_active = (document.getElementById('active') as HTMLInputElement).checked

        if(code === "") {
            props.showModal("", "메타코드를 입력해 주세요.");
            return;
        }

        const parentItem = props.parentItem;
        logger.log('parentItem', parentItem);

        let _code = code;
        let _parent_id = 0;
        let _depth = 1;
        if(parentItem) {
            _code = props.item ? code : parentItem.code + "-" + code;
            _parent_id = parentItem.id!;
            _depth = parentItem.depth + 1;
        }

        let meta_mapping_meta : IMeta[] = [];

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const meta = {
            id: props.item ? props.item.id : undefined,
            brand_id: brand.id ? brand.id : null,

            parent_id: _parent_id,
            code: _code,
            name: "communication",
            val: meta_val.value,
            is_active: is_active ? true : false,
            description: description,
            depth: _depth,

            creator: uid,
            creator_id: _id,
            creator_name: uname,

            updater: props.item ? uid : undefined,
            updater_id: props.item ? _id : undefined,
            updater_name: props.item ? uname : undefined,
            
            uuid: props.item ? props.item.uuid : UUID,

        }
        
        const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": meta_mapping_meta}
        logger.log("meta_mapping_meta", metamap);
    
        if(props.item) {
            updateMetaMetaMap(metamap);  
        } else {
            insertMetaMetaMap(metamap);  
        }
    }
    
    const hasAuth = util.getHasAuthMenu(props.item ? props.item.creator : "");

    return (
        <div>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">관리테이블</caption>
                <tbody>

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">메타 값 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_val" className="w-avail"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-disc">설명</label></th>
                        <td className="text-left">
                            <input type="text" id="meta_description" className="w-avail"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_code" className="w-1/2" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">고유코드</th>
                        <td className="text-left">{UUID}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록자</th>
                        {props.item &&
                        <td className="text-left">{props.item.creator_name} ({props.item.creator})</td>}
                        {!props.item &&
                        <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록일</th>
                        {props.item &&
                        <td className="text-left">{props.item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>}
                        {!props.item &&
                        <td className="text-left">{util.getDate()}</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
                    
            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-white" onClick={() => props.onCloseEdit(false, "")}>취소</button>
                    {!props.item &&
                    <button type="button" className="btn-sky" onClick={onSave}>{"등록"}</button>}
                    {props.item &&
                    <button type="button" className="btn-sky" onClick={onSave} disabled={!hasAuth}>{"수정"}</button>}
                </div>
            </div>

        </div>
    );
}

export default MetaCommunicationEdit;
