import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, IMenu, IMeta } from "../common";
import { logger } from '../logger';
import ReportList from "./ReportList";

interface IReportManager {
    view: boolean;
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function ReportManager(props: IReportManager) {
    
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
        }
        bDone = true;
    }, [])

    let prevView = false;
    useEffect(() => {
        if(props.view && !prevView) {
        }
        prevView = props.view;
    }, [props.view])

    useEffect(() => {
        if(props.menu) {
            // logger.log("props.menu", props.menu);
        }
    }, [props.menu])

    useEffect(() => {
        if(props.brand) {
            logger.log("props.brand", props.brand);
        }
    }, [props.brand])

    return (
        <div>
            {currMenu && currMenu.menu2 == 0 && <ReportList brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
        </div>
    );
}
export default ReportManager;
