import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IArticle, IMeta, IUser, latex_delimiters, latex_macros } from "../common";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { NodeData } from "../textbook/TextBookIndexEdit";
import { bool } from "aws-sdk/clients/signer";
import axios from "axios";
import { logger } from "../logger";
import "../latex.css";
import * as common from '../common';

import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

interface IPopupTutorConceptEdit {
    view: boolean;
    selectedItem: IArticle;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function PopupTutorConceptEdit(props: IPopupTutorConceptEdit) {

    const [conceptValue, setConceptValue] = useState("");
    const [isEdit, setIsEdit] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();

    useEffect(() => {
        if(props.view) {    
            getConcept();
        }
    }, [props.view])
    
    async function onUpdate_Math_data_get(id: number, type: string) {

        // const url = "https://tutorus.aidtclass.com/visang/math-check-get/api";
        const url = "https://t-tutorus.aidtclass.com/api/check/get";

        const data = 
        {
            "request_id": type+"_"+id,
            "id": id, 
            "type": type, // solution, chatting 사용 
        }        

        let result : any = null;
        await axios.post(url, data, { headers: {}
        }).then(async (response) => {
            logger.log(response);
            result = response.data;
        }).catch((err) => {
            logger.log(err);
        });          

        return result;    
    }
    
    async function getConcept() {
        props.showLoading(true);
        const concept = await onUpdate_Math_data_get(props.selectedItem.id, "concept");
        logger.log("getConcept", concept);
        props.showLoading(false);

        if(concept && concept.result_code === 0) {
            
            let val = concept.result_value.data as string;
            // val = val.replaceAll("$", "");

            // katex.render(val, document.getElementById("katex"));

            setConceptValue(val);

            const ta_concept = document.getElementById("concept") as HTMLTextAreaElement;
            if(ta_concept) {
                ta_concept.value = concept.result_value.data;
            }    
        } else {
            props.showModal("", "튜터 개념 생성이 필요합니다.");
            onClose();
        }
    }

    async function onUpdate_Math_data_set(id: number, type: string) {

        const ta_concept = document.getElementById("concept") as HTMLTextAreaElement;
        if(!ta_concept) {
            return;
        }    

        // const url = "https://tutorus.aidtclass.com/visang/math-check-set/api";
        const url = "https://t-tutorus.aidtclass.com/api/check/set";

        const data = 
        {
            "request_id": type+"_"+id,
            "id": id, 
            "type": type,
            "value": ta_concept.value,
            "target":"all",
            "db":"check",        
        }        

        let result : any = null;
        await axios.post(url, data, { headers: {}
        }).then(async (response) => {
            logger.log(response);
            result = response.data;
        }).catch((err) => {
            logger.log(err);
        });          

        return result;    
    }

    async function onSave() {

        if(isEdit) {
            setIsEdit(false);
            await onUpdate_Math_data_set(props.selectedItem.id, "concept");    
            onClose();
        } else {
            setIsEdit(true);
        }

        // const name = document.getElementById("name") as HTMLInputElement;
        // if(name.value === "") {
        //     props.showModal("목차명을 입력하세요.", "");
        //     name.focus();
        //     return;
        // }
    }

    function onClose(): void {
        props.onClose();
    }

    return (
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : props.view ? "" : "none"}}>                    
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box-new max-w-xl min-w-[768px]">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner-new w-avail">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">튜터 개념 확인​</h2>
                            <div className="py-5 px-5">
                                <div className="flex items-center w-full" style={{display: isEdit ? "" : "none"}}>
                                    <textarea style={{fontSize: "0.85em"}} id="concept" rows={5} cols={120} onChange={(e) => {
                                        setConceptValue(e.currentTarget.value);                                        
                                    }}></textarea>
                                </div>
                                <div className="mt-3 flex items-center w-full font-bold" style={{fontSize: "0.85em"}}>
                                    <pre><Latex delimiters={latex_delimiters} macros={latex_macros}>{conceptValue}</Latex></pre>
                                    {/* <div><Latex>{"$\\not\\subset$"}</Latex></div> */}
                                </div>
                            </div>
                            <div className="py-5 px-5 text-right">
                                <button type="button" className="btn-sky" onClick={onSave}>{isEdit ? "저장하기" : "편집하기​"}</button>
                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>        
    );
}
  
export default PopupTutorConceptEdit;
