import { ChangeEvent, useEffect, useState } from "react";
import * as util from '../util';
import { IBrand, ILibrarySearchData, IMeta, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { DatePicker } from '@gsebdev/react-simple-datepicker';
import { logger } from '../logger';
import '../common.scss';

interface IInstrumentSearch {
    view: boolean;
    brand: IBrand;
    pageInfo: IPageInfo;
	onSearch: (data: any) => void;
    showLoading: (loading: boolean) => void;    
}

export interface IPageInfo {
    page: number;
    pageItemCnt: number;
}

function InstrumentSearch(props: IInstrumentSearch) {

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {

        if(!bDone) {
        }
        bDone = true;

    }, [])

    function init() {
        setCurriDepth(0);

        const LS_keyword_type = document.getElementById("LS_keyword_type") as HTMLSelectElement;
        LS_keyword_type.selectedIndex = 0;
        const LS_keyword = document.getElementById("LS_keyword") as HTMLInputElement;
        LS_keyword.value = "";

        const LS_curriBook = document.getElementById("LS_curriBook") as HTMLSelectElement;
        LS_curriBook.selectedIndex = 0;
        const LS_curriUnit1 = document.getElementById("LS_curriUnit1") as HTMLSelectElement;
        LS_curriUnit1.selectedIndex = 0;
        const LS_curriUnit2 = document.getElementById("LS_curriUnit2") as HTMLSelectElement;
        LS_curriUnit2.selectedIndex = 0;
        const LS_curriUnit3 = document.getElementById("LS_curriUnit3") as HTMLSelectElement;
        LS_curriUnit3.selectedIndex = 0;
        const LS_curriUnit4 = document.getElementById("LS_curriUnit4") as HTMLSelectElement;
        LS_curriUnit4.selectedIndex = 0;
        const LS_curriUnit5 = document.getElementById("LS_curriUnit5") as HTMLSelectElement;
        LS_curriUnit5.selectedIndex = 0;
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);
        }

        props.showLoading(false);
    }

    async function getChildrenList(id: string, name: string) {

        if(id === "0") {
            return;
        }
        
        const list = await util.getChildrenList(id);    
        logger.log("getChildrenList", list);  

        if(name === "CurriBook") {
            logger.log("setCurriUnit1", list);  
            setCurriUnit1(list);
        } else if(name === "CurriUnit1") {
            logger.log("setCurriUnit2", list);  
            setCurriUnit2(list);
        } else if(name === "CurriUnit2") {
            logger.log("setCurriUnit3", list);  
            setCurriUnit3(list);
        } else if(name === "CurriUnit3") {
            logger.log("setCurriUnit4", list);  
            setCurriUnit4(list);
        } else if(name === "CurriUnit4") {
            logger.log("setCurriUnit5", list);  
            setCurriUnit5(list);
        }
    }

    const LS_html_curriUnit1 = document.getElementById("LS_curriUnit1") as HTMLSelectElement;
    const LS_html_curriUnit2 = document.getElementById("LS_curriUnit2") as HTMLSelectElement;
    const LS_html_curriUnit3 = document.getElementById("LS_curriUnit3") as HTMLSelectElement;
    const LS_html_curriUnit4 = document.getElementById("LS_curriUnit4") as HTMLSelectElement;
    const LS_html_curriUnit5 = document.getElementById("LS_curriUnit5") as HTMLSelectElement;

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        getDescendantsList(e.target.value);

        LS_html_curriUnit1.selectedIndex = 0;
        LS_html_curriUnit2.selectedIndex = 0;
        LS_html_curriUnit3.selectedIndex = 0;
        LS_html_curriUnit4.selectedIndex = 0;
        LS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit2([]);
        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit1", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);

        LS_html_curriUnit2.selectedIndex = 0;
        LS_html_curriUnit3.selectedIndex = 0;
        LS_html_curriUnit4.selectedIndex = 0;
        LS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        LS_html_curriUnit3.selectedIndex = 0;
        LS_html_curriUnit4.selectedIndex = 0;
        LS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        LS_html_curriUnit4.selectedIndex = 0;
        LS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        LS_html_curriUnit5.selectedIndex = 0;
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function onSearch(): void {

        if(!curriInfo) {
            return;
        }

        const LS_curriBook = document.getElementById("LS_curriBook") as HTMLSelectElement;
        const LS_curriBook_val = curriInfo.curriBook[LS_curriBook.selectedIndex-1];

        const LS_html_curriUnit1_val = curriUnit1 ? (curriUnit1[LS_html_curriUnit1.selectedIndex-1] as IMeta) : undefined;
        const LS_html_curriUnit2_val = curriUnit2 ? (curriUnit2[LS_html_curriUnit2.selectedIndex-1] as IMeta) : undefined;
        const LS_html_curriUnit3_val = curriUnit3 ? (curriUnit3[LS_html_curriUnit3.selectedIndex-1] as IMeta) : undefined;
        const LS_html_curriUnit4_val = curriUnit4 ? (curriUnit4[LS_html_curriUnit4.selectedIndex-1] as IMeta) : undefined;
        const LS_html_curriUnit5_val = curriUnit5 ? (curriUnit5[LS_html_curriUnit5.selectedIndex-1] as IMeta) : undefined;
            
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const LS_keyword_type = document.getElementById("LS_keyword_type") as HTMLSelectElement;
        const LS_keyword = document.getElementById("LS_keyword") as HTMLInputElement;

        let search = "";
        if(LS_keyword) {
            search = LS_keyword.value;
        }
        let searchField = "";
        if(LS_keyword_type) {
            searchField = LS_keyword_type.value;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            search: search,
            searchField: searchField,

            curriBook: LS_curriBook_val ? LS_curriBook_val.id : undefined,
            curriUnit1: LS_html_curriUnit1_val ? LS_html_curriUnit1_val.id : undefined,
            curriUnit2: LS_html_curriUnit2_val ? LS_html_curriUnit2_val.id : undefined,
            curriUnit3: LS_html_curriUnit3_val ? LS_html_curriUnit3_val.id : undefined,
            curriUnit4: LS_html_curriUnit4_val ? LS_html_curriUnit4_val.id : undefined,
            curriUnit5: LS_html_curriUnit5_val ? LS_html_curriUnit5_val.id : undefined,
            
            // meta: metaForSearch, 
            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: props.pageInfo.pageItemCnt, 
        };

        logger.log("onSearch", data);

        props.onSearch(data);
    }

    return (
        <div>
            {/* <!-- 검색영역 --> */}
            <div className="px-8 py-8 border border-gray-300 rounded-xl">
                <div className="mb-3">
                    <fieldset>
                        <label htmlFor="curriBook">교과</label>
                        <select id="LS_curriBook" name="LS_curriBook" onChange={(e) => onChangeCurriBook(e)}>
                            <option value="0">교과</option>              
                            {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.code}>{item.val}</option>              
                            )})}
                        </select>
                    </fieldset>
                    <fieldset>
                        <label htmlFor="curriUnit1" className="ml-8">교과과정</label>
                        <select className="mr-2" id="LS_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                            <option value="0">1Depth</option>              
                            {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                            return(
                            <option key={idx} value={item.id}>{item.val}</option>              
                            )})}
                        </select>
                        <select className="mr-2" id="LS_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriDepth > 1 ? "" : "none"}}>
                            <option value="0">2Depth</option>              
                            {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                            return(
                            <option key={idx} value={item.id}>{item.val}</option>              
                            )})}
                        </select>
                        <select className="mr-2" id="LS_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriDepth > 2 ? "" : "none"}}>
                            <option value="0">3Depth</option>              
                            {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                            return(
                            <option key={idx} value={item.id}>{item.val}</option>              
                            )})}
                        </select>
                        <select className="mr-2" id="LS_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriDepth > 3 ? "" : "none"}}>
                        {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                            <option value="0">4Depth</option>              
                            {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                            return(
                            <option key={idx} value={item.id}>{item.val}</option>              
                            )})}
                        </select>
                        <select id="LS_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriDepth > 4 ? "" : "none"}}>
                            <option value="0">5Depth</option>              
                            {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                            return(
                            <option key={idx} value={item.id}>{item.val}</option>              
                            )})}
                        </select>
                    </fieldset>
                </div>
                <div className="text-right">
                    <fieldset>
                        <label htmlFor="keyword_type">검색</label>
                        <select className="mr-2" id="LS_keyword_type" name="keyword_type">
                            <option value={"creator_name"}>이름</option>
                            <option value={"creator"}>아이디</option>
                            <option value={"name"}>타이틀</option>
                            <option value={"id"}>Key</option>
                        </select>
                        <input type="text" name="keyword" id="LS_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                onSearch();
                            }
                        }} />
                        <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                            <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                        </button>
                    </fieldset>
                </div>

            </div>
            {/* <!-- .//검색영역 --> */}

        </div>
    );
}
export default InstrumentSearch;
