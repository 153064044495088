import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, IMenu, IMeta } from "../common";
import LibraryMedia from "./LibraryMedia";
import { logger } from '../logger';
import LibraryText from "./LibraryText";
import LibraryEngTemp from "./LibraryEngTemp";
import LibraryExceptionRule from "./LibraryExceptionRule";
import LibraryDescBulkRegister from "./LibraryDescBulkRegister";

interface ILibraryManager {
    view: boolean;
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function LibraryManager(props: ILibraryManager) {

    const [type, setType] = useState("image");
    
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
        }
        bDone = true;
    }, [])

    let prevView = false;
    useEffect(() => {
        if(props.view && !prevView) {
        }
        prevView = props.view;
    }, [props.view])

    useEffect(() => {
        if(props.menu) {
            // logger.log("props.menu", props.menu);
            onViewLibrary();
        }
    }, [props.menu])

    useEffect(() => {
        if(props.brand) {
            logger.log("props.brand", props.brand);
        }
    }, [props.brand])

    function onViewLibrary() {

        const currMenu = util.getCurrentMenu();

        if(currMenu.menu2 === 0) {
            setType("image");
        } else if(currMenu.menu2 === 1) {
            setType("audio");
        } else if(currMenu.menu2 === 2) {
            setType("video");
        }
    }

    return (
        <div>
            {currMenu && currMenu.menu2 < 3 && <LibraryMedia type={type} brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            {(currMenu && currMenu.menu2 === 4 || currMenu && currMenu.menu2 === 5 || currMenu && currMenu.menu2 === 6) && 
            <LibraryText brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu2 === 7 && 
            <LibraryEngTemp brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}            
            {currMenu && currMenu.menu2 === 8 && 
            <LibraryExceptionRule brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}            
            {currMenu && currMenu.menu2 === 9 && 
            <LibraryDescBulkRegister brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}            
        </div>
    );
}
export default LibraryManager;
