import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, ILibrary, ILibraryFile, IMeta, IScript, IText, S3Server, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import PopupEditMultiLang, { IMultiLang } from "./popupEditMultiLang";
import PopupLibrarySearch from "./PopupLibrarySearch";
import PopupContentsList from "../contents/PopupContentsList";
import PopupStudyMapSearch from "./PopupStudyMapSearch";
import { IStudyMap } from "./LibraryRegister";
import PopupMediaPreview from "./PopupMediaPreview";
import { NavigationType, useNavigationType } from "react-router-dom";
import MyButton from "../MyButton";

interface ILibraryTextRegister {
    textType: string;
    brand: IBrand;
    selectedItem: IText | null;
    onChangeTextType3: (val: string) => void;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function LibraryTextRegister(props: ILibraryTextRegister) {

    const [type2TextList, setType2TextList] = useState([] as any);
    // const [type2Text, setType2Text] = useState(null as any);
    const [type3TextList, setType3TextList] = useState([] as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    
    const [curriDepth, setCurriDepth] = useState(0);
    
    const [viewEditMultiLang, setViewEditMultiLang] = useState(false);
    const [defaultLang, setDefaultLang] = useState("");

    const [examList, setExamList] = useState<IText[]>([]);
    const [articleList, setArticleList] = useState<IArticle[]>([]);
    const [studyMapList, setStudyMapList] = useState<IStudyMap[]>([]);
    const [scriptList, setScriptList] = useState<IScript[]>([]);
    const [scriptUIList, setScriptUIList] = useState<string[]>([]);
    
    const [viewPopupObjectSearch, setViewPopupObjectSearch] = useState(false);
    const [viewPopupContentsList, setViewPopupContentsList] = useState(false);
    const [multiLang, setMultiLang] = useState<IMultiLang[]>([]);
    const [inputId, setInputId] = useState("");
    const [selectedArticleIdx, setSelectedArticleIdx] = useState(-1);
    const [selectedExamSentenceIdx, setSelectedExamSentenceIdx] = useState(-1);
    const [contentsImage, setContentsImage] = useState(null as any);
    const [contentsAudio, setContentsAudio] = useState(null as any);
    const [contentsVideo, setContentsVideo] = useState(null as any);
    const [scriptType, setScriptType] = useState("");
    const [multiLangElement, setMultiLangElement] = useState("");
    const [searchType, setSearchType] = useState("");
    
    const [viewPopupSearchStudyMap, setViewPopupSearchStudyMap] = useState(false);
    const [selectedStudyMapId, setSelectedStudyMapId] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null as any);

    const [viewMediaPreview, setViewMediaPreview] = useState(false);
    const [mediaType, setMediaType] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");

    const [isArticleForScript, setIsArticleForScript] = useState(false);

    // const listUsedIn = ["사용처1", "사용처2", "사용처3", "사용처4"];
    const listParts = ["n.", "pron.", "v.", "a.", "ad.", "prep.", "conj.", "int.", "det."];
    const listParts_old = ["Noun", "Pronoun", "Verb", "Adjective", "Adverb", "Preposition", "Conjunction", "Interjection", "Determiner"];

    const curriInfo = util.getCurrentCurriInfo();
    const currMenu = util.getCurrentMenu();

    useEffect(() => {

        if(props.selectedItem) {
            return;
        }

        const _scriptList : IScript[] = [];
        const _script : IScript = {
            pkey: "",
            skey: "",
            type: scriptType === "혼합형" ? "대화형" : "",
            speaker: "",
            entry: "",
            multiLang: "",
            startTime: "",
            endTime: "",
            akey: "",
            summary: "",
            exceptEmpty: "",
        }
        _scriptList.push(_script as IScript);
        setScriptList(_scriptList);

        // if(scriptType === "혼합형") {
        //     for (let i = 0; i < scriptList.length; i++) {
        //         const rd_type1 = document.getElementById("rd_type1_"+i) as HTMLInputElement;
        //         if(rd_type1) {
        //             rd_type1.checked = true;
        //         }
        //         scriptList[i].type = "대화형";
        //     }
        // } else {
        //     for (let i = 0; i < scriptList.length; i++) {
        //         scriptList[i].type = "";
        //     }
        // }

        logger.log("scriptType", scriptType);

    }, [scriptType])

    useEffect(() => {
        logger.log("scriptList", scriptList);
        onScript2UI();
    }, [scriptList])

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     props.OnClose();
            //     return;
            // }

            if(props.textType === "지문") {                
                setScriptType(_type3TextList[2][0]);

                const _scriptList : IScript[] = [];
                const _script : IScript = {
                    pkey: "",
                    skey: "",
                    type: scriptType === "혼합형" ? "대화형" : "",
                    speaker: "",
                    entry: "",
                    multiLang: "",
                    startTime: "",
                    endTime: "",
                    akey: "",
                    summary: "",
                    exceptEmpty: "",
                }
                _scriptList.push(_script as IScript);
                setScriptList(_scriptList);
            }

            const _examList : IText[] = [];
            const _examWord = {
                id: -1,
            }
            _examList.push(_examWord as IText);
            setExamList(_examList);

            const _articleList : IArticle[] = [];
            const _article = {
                id: -1,
            }
            _articleList.push(_article as IArticle);
            setArticleList(_articleList);

            const _studyMapList : IStudyMap[] = [];
            const _studyMap = {
                id: -1,
            }
            _studyMapList.push(_studyMap as IStudyMap);
            setStudyMapList(_studyMapList);

            setCurriDepth(0);

            setType2TextList(_type2List[3]);

            const selectedItem = props.selectedItem;
            if(selectedItem) {
                init(selectedItem.id);
                logger.log("selectedItem", selectedItem);
            } else {
                (document.getElementById('LR_rd-on') as HTMLInputElement).checked = true;
                (document.getElementById('LR_rd-off') as HTMLInputElement).checked = false;
                (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = true;
                (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = false;    
            }
        }
        bDone = true;
    }, [])

    // useEffect(() => {
    //     logger.log("useEffect contents", fileContents);
    // }, [fileContents])

    useEffect(() => {
        logger.log("props.textType", props.textType);
        const LR_type3 = document.getElementById("LR_type3") as HTMLSelectElement;

        if(props.textType === "어휘") {
            setType3TextList(_type3TextList[0]);
        } else if(props.textType === "문장") {
            setType3TextList(_type3TextList[1]);
        } else if(props.textType === "지문") {
            setType3TextList(_type3TextList[2]);
        }            
        LR_type3.selectedIndex = 0;    
    }, [props.textType])

    async function init(id: number) {

        props.showLoading(true);
        const result = await util.getTextInfo(id);
        logger.log("init", result);
        props.showLoading(false);
        
        if(result) {

            const selectedItem  = result as IText;
            setSelectedItem(selectedItem);

            if(selectedItem.exam_sentences.length > 0) {
                setExamList(selectedItem.exam_sentences);
            }
            if(selectedItem.articles.length > 0) {
                setArticleList(selectedItem.articles);
            }
            if(selectedItem.studyMap && selectedItem.studyMap.length > 0) {
                setStudyMapList(selectedItem.studyMap);
            }

            (document.getElementById('LR_title') as HTMLInputElement).value = selectedItem.name;

            (document.getElementById('LR_rd-on') as HTMLInputElement).checked = selectedItem.is_active;
            (document.getElementById('LR_rd-off') as HTMLInputElement).checked = !selectedItem.is_active;    
            (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = selectedItem.is_publicOpen;
            (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = !selectedItem.is_publicOpen;
    
            const LR_entry = (document.getElementById('LR_entry') as HTMLInputElement);
            if(LR_entry) {
                (document.getElementById('LR_entry') as HTMLInputElement).value = selectedItem.contents_entry;
            }
            const LR_multiLang = (document.getElementById('LR_multiLang') as HTMLInputElement);
            if(LR_multiLang) {
                (document.getElementById('LR_multiLang') as HTMLInputElement).value = selectedItem.contents_multiLang;
            }

            const LR_def = (document.getElementById('LR_def') as HTMLInputElement);
            if(LR_def) {
                LR_def.value = selectedItem.contents_def;
            }
            const LR_pron = (document.getElementById('LR_pron') as HTMLInputElement);
            if(LR_pron) {
                LR_pron.value = selectedItem.contents_pron;
            }

            const LR_speaker = document.getElementById("LR_speaker") as HTMLInputElement;
            if(LR_speaker) {
                LR_speaker.value = selectedItem.speaker;
            }
    
            const LR_info1 = document.getElementById("LR_info1") as HTMLInputElement;
            if(LR_info1) {
                LR_info1.value = selectedItem.info1;
            }
    
            const LR_info2 = document.getElementById("LR_info2") as HTMLInputElement;
            if(LR_info2) {
                LR_info2.value = selectedItem.info2;
            }

            const LR_info3 = document.getElementById("LR_info3") as HTMLInputElement;
            if(LR_info3) {
                LR_info3.value = selectedItem.info3;
            }

            const LR_chunk1 = document.getElementById("LR_chunk1") as HTMLInputElement;
            if(LR_chunk1) {
                LR_chunk1.value = selectedItem.chunk1;
            }

            const LR_chunk2 = document.getElementById("LR_chunk2") as HTMLInputElement;
            if(LR_chunk2) {
                LR_chunk2.value = selectedItem.chunk2;
            }

            const LR_key_info = document.getElementById("LR_key_info") as HTMLInputElement;
            if(LR_key_info) {
                LR_key_info.value = selectedItem.key_info;
            }
            
            if(selectedItem.key_type && selectedItem.key_type === "Grammar") {
                const LR_key1 = document.getElementById('LR_key1') as HTMLInputElement;    
                LR_key1.checked = true;
                (document.getElementById("LR_key1_title") as HTMLInputElement).value = selectedItem.key_title;
                (document.getElementById("LR_key1_desc") as HTMLInputElement).value = selectedItem.key_desc;
                const LR_key2 = document.getElementById('LR_key2') as HTMLInputElement;                    
                LR_key2.checked = false;
                (document.getElementById("LR_key2_title") as HTMLInputElement).value = "";
                (document.getElementById("LR_key2_desc") as HTMLInputElement).value = "";
            } else if(selectedItem.key_type && selectedItem.key_type === "Expression") {
                const LR_key2 = document.getElementById('LR_key2') as HTMLInputElement;                    
                LR_key2.checked = true;
                (document.getElementById("LR_key2_title") as HTMLInputElement).value = selectedItem.key_title;
                (document.getElementById("LR_key2_desc") as HTMLInputElement).value = selectedItem.key_desc;
                const LR_key1 = document.getElementById('LR_key1') as HTMLInputElement;    
                LR_key1.checked = false;
                (document.getElementById("LR_key1_title") as HTMLInputElement).value = "";
                (document.getElementById("LR_key1_desc") as HTMLInputElement).value = "";
            }
            
            const LR_image = document.getElementById("LR_image") as HTMLInputElement;
            if(LR_image) {
                (document.getElementById('LR_image') as HTMLInputElement).value = selectedItem.contents_image;
            }
            const LR_audio = document.getElementById("LR_audio") as HTMLInputElement;
            if(LR_audio) {
                (document.getElementById('LR_audio') as HTMLInputElement).value = selectedItem.contents_audio;
            }
            const LR_video = document.getElementById("LR_video") as HTMLInputElement;
            if(LR_video) {
                (document.getElementById('LR_video') as HTMLInputElement).value = selectedItem.contents_video;
            }
            const LR_audioAnalysis = document.getElementById("LR_audioAnalysis") as HTMLInputElement;
            if(LR_audioAnalysis) {
                (document.getElementById('LR_audioAnalysis') as HTMLInputElement).value = selectedItem.contents_audioAnalysis;
            }

            if(selectedItem.contents_image && selectedItem.contents_image.length > 0) {
                (document.getElementById('LR_image_icon') as HTMLInputElement).className = "icon-img-on block w-6 h-6 mx-3";
                (document.getElementById('LR_image_icon') as HTMLInputElement).style.cursor = "pointer";
                (document.getElementById('LR_image_icon') as HTMLInputElement).onclick = () => {
                    setMediaType("image");
                    setMediaUrl(S3Server+selectedItem.contents_image);
                    setViewMediaPreview(true);
                    // window.open(S3Server+selectedItem.contents_image, "_blank", "noopener, noreferrer");    
                };    
            }
            if(selectedItem.contents_audio && selectedItem.contents_audio.length > 0) {
                (document.getElementById('LR_audio_icon') as HTMLInputElement).className = "icon-audio-on block w-6 h-6 mx-3";
                (document.getElementById('LR_audio_icon') as HTMLInputElement).style.cursor = "pointer";
                (document.getElementById('LR_audio_icon') as HTMLInputElement).onclick = () => {
                    setMediaType("audio");
                    setMediaUrl(S3Server+selectedItem.contents_audio);
                    setViewMediaPreview(true);
                    // window.open(S3Server+selectedItem.contents_audio, "_blank", "noopener, noreferrer");    
                };    
            }
            if(selectedItem.contents_video && selectedItem.contents_video.length > 0) {
                (document.getElementById('LR_video_icon') as HTMLInputElement).className = "icon-video-on block w-6 h-6 mx-3";
                (document.getElementById('LR_video_icon') as HTMLInputElement).style.cursor = "pointer";
                (document.getElementById('LR_video_icon') as HTMLInputElement).onclick = () => {
                    setMediaType("video");
                    setMediaUrl(S3Server+selectedItem.contents_video);
                    setViewMediaPreview(true);
                    // window.open(S3Server+selectedItem.contents_video, "_blank", "noopener, noreferrer");    
                };    
            }

            const LR_parts = document.getElementById("LR_parts") as HTMLSelectElement;
            if(LR_parts) {

                LR_parts.value = selectedItem.parts;
                for (let i = 0; i < listParts_old.length; i++) {
                    const oldPart = listParts_old[i];
                    if(oldPart === selectedItem.parts) {
                        LR_parts.value = listParts[i];
                        break;
                    }                    
                }
            }
        
            setTimeout(() => {
                (document.getElementById('LR_type2') as HTMLSelectElement).value = selectedItem.type_1;                    
            }, 10);
    
            if(selectedItem.type_2) {
                (document.getElementById('LR_type3') as HTMLSelectElement).value = selectedItem.type_2;
            }
    
            if(selectedItem.type_1 === "지문") {
                (document.getElementById('LR_type3') as HTMLSelectElement).disabled = true;
            } else {
                (document.getElementById('LR_type3') as HTMLSelectElement).disabled = false;
            }

            const type_2 = selectedItem.type_2;
            setScriptType(type_2);                    

            const _scriptList = selectedItem.dialog;
            setScriptList(_scriptList);

            const metaMap = selectedItem.metaMap;
            if(metaMap && metaMap.length > 0) {
                const curriSchool = metaMap.find((item) => item.name === "curriSchool");
                if(curriSchool) {
                    const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
                    LR_school.value = curriSchool.val;
                }
                const curriSubject = metaMap.find((item) => item.name === "curriSubject");
                if(curriSubject) {
                    const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
                    LR_subject.value = curriSubject.val;
                }
                const difficulty = metaMap.find((meta, _) => meta.name === "difficulty");
                const LR_level = (document.getElementById('LR_level') as HTMLSelectElement);
                if(LR_level) {
                    LR_level.value = difficulty ? difficulty.val : "";
                }
            }
            
            const studyMapListString = JSON.parse(selectedItem.studyMapListString);
            if(studyMapListString) {
                setStudyMapList(studyMapListString);
            }

            const curriBook = curriInfo?.curriBook.find((meta, _) => meta.id === selectedItem.curriBook);
    
            if(curriInfo && curriBook) {

                const _bookDescendantsList = await getDescendantsList(curriBook.code);
    
                const LR_textbook = (document.getElementById("LR_textbook") as HTMLSelectElement);
                if(LR_textbook) {
                    LR_textbook.value = curriBook.val;
                }
    
                if(_bookDescendantsList && _bookDescendantsList.length > 0) {
                    const curriUnit1s = _bookDescendantsList.filter((item) => item.parent_id === curriBook.id);
                    setCurriUnit1(curriUnit1s);
        
                    setTimeout(() => {
                        const curriUnit1 = curriUnit1s.find((meta, _) => meta.id === selectedItem.curriUnit1);
                        if(curriUnit1) {
                            (document.getElementById("LR_curriUnit1") as HTMLSelectElement).value = String(curriUnit1.id);
            
                            const curriUnit2s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit1.id);
                            setCurriUnit2(curriUnit2s);
                            setTimeout(() => {
                                const curriUnit2 = curriUnit2s.find((meta, _) => meta.id === selectedItem.curriUnit2);
                                if(curriUnit2) {
                                    (document.getElementById("LR_curriUnit2") as HTMLSelectElement).value = String(curriUnit2.id);
            
                                    const curriUnit3s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit2.id);
                                    setCurriUnit3(curriUnit3s);
                                    setTimeout(() => {
                                        const curriUnit3 = curriUnit3s.find((meta, _) => meta.id === selectedItem.curriUnit3);
                                        if(curriUnit3) {
                                            (document.getElementById("LR_curriUnit3") as HTMLSelectElement).value = String(curriUnit3.id);
            
                                            const curriUnit4s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit3.id);
                                            setCurriUnit4(curriUnit4s);
                                            setTimeout(() => {
                                                const curriUnit4 = curriUnit4s.find((meta, _) => meta.id === selectedItem.curriUnit4);
                                                if(curriUnit4) {
                                                    (document.getElementById("LR_curriUnit4") as HTMLSelectElement).value = String(curriUnit4.id);
            
                                                    const curriUnit5s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit4.id);
                                                    setCurriUnit5(curriUnit5s);
                                                    setTimeout(() => {
                                                        const curriUnit5 = curriUnit5s.find((meta, _) => meta.id === selectedItem.curriUnit5);
                                                        if(curriUnit5) {
                                                            (document.getElementById("LR_curriUnit5") as HTMLSelectElement).value = String(curriUnit5.id);
                                                        }                            
                                                    }, 10);
                                                }                            
                                            }, 10);
                                        }                            
                                    }, 10);    
                                }                            
                            }, 10);        
                        }                            
                    }, 10);    
                }    
            }
        }
    }

    async function getLibraryMetaList(id: number) {

        const list = await util.getLibraryMetaList(id);    
        const curriSchool = list.find((meta, _) => meta.name === "curriSchool");
        const curriSubject = list.find((meta, _) => meta.name === "curriSubject");
        const difficulty = list.find((meta, _) => meta.name === "difficulty");
        logger.log("getLibraryMetaList", list);  

        const LR_school = (document.getElementById('LR_school') as HTMLSelectElement);
        if(LR_school) {
            LR_school.value = curriSchool ? curriSchool.val : "";
        }
        const LR_subject = (document.getElementById('LR_subject') as HTMLSelectElement);
        if(LR_subject) {
            LR_subject.value = curriSubject ? curriSubject.val : "";
        }
        const LR_level = (document.getElementById('LR_level') as HTMLSelectElement);
        if(LR_level) {
            LR_level.value = difficulty ? difficulty.val : "";
        }

        let max_depth = 0;
        const curriBook = list.find((meta, _) => meta.name === "curriBook");
        if(curriBook && curriBook.max_depth) {
            max_depth = curriBook.max_depth;
        } else {
            const curriUnit1 = list.find((meta, _) => meta.name === "curriUnit1");
            if(curriUnit1) {
                max_depth = 1;
            }
            const curriUnit2 = list.find((meta, _) => meta.name === "curriUnit2");
            if(curriUnit2) {
                max_depth = 2;
            }
            const curriUnit3 = list.find((meta, _) => meta.name === "curriUnit3");
            if(curriUnit3) {
                max_depth = 3;
            }
            const curriUnit4 = list.find((meta, _) => meta.name === "curriUnit4");
            if(curriUnit4) {
                max_depth = 4;
            }
            const curriUnit5 = list.find((meta, _) => meta.name === "curriUnit5");
            if(curriUnit5) {
                max_depth = 5;
            }
        }
        setCurriDepth(max_depth);
    }

    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {

            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        
        const curriBook = curriInfo?.curriBook.find((meta, _) => meta.val === e.target.value);
        if(curriBook) {
            getDescendantsList(curriBook.code);

            const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
            const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
            const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
            const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
            const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
        
            if(LR_html_curriUnit1) {
                LR_html_curriUnit1.selectedIndex = 0;
            }
            if(LR_html_curriUnit2) {
                LR_html_curriUnit2.selectedIndex = 0;
            }
            if(LR_html_curriUnit3) {
                LR_html_curriUnit3.selectedIndex = 0;
            }
            if(LR_html_curriUnit4) {
                LR_html_curriUnit4.selectedIndex = 0;
            }
            if(LR_html_curriUnit5) {
                LR_html_curriUnit5.selectedIndex = 0;
            }
    
            setCurriUnit2([]);
            setCurriUnit3([]);
            setCurriUnit4([]);
            setCurriUnit5([]);
        } else {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit2) {
            LR_html_curriUnit2.selectedIndex = 0;
        }
        if(LR_html_curriUnit3) {
            LR_html_curriUnit3.selectedIndex = 0;
        }
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit3) {
            LR_html_curriUnit3.selectedIndex = 0;
        }
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;    
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function OnClose(): void {
        props.OnClose();
    }

    async function onSave() {

        if(!curriInfo) {
            return;
        }

        const LR_type2_val = props.textType;

        let LR_type3_val = "";
        const LR_type3 = document.getElementById("LR_type3") as HTMLSelectElement;
        if(LR_type3) {
            LR_type3_val = type3TextList[LR_type3.selectedIndex];
        }

        const LR_title = document.getElementById("LR_title") as HTMLInputElement;
        const LR_title_val = LR_title.value;
        if(LR_title_val === "") {
            LR_title.focus();
            props.showModal("", "타이틀을 입력해 주세요.");
            return;
        }

        const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
        const LR_school_val = curriInfo.curriSchool[LR_school.selectedIndex];
        
        const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
        const LR_subject_val = curriInfo.curriSubject[LR_subject.selectedIndex];
        
        const LR_textbook = document.getElementById("LR_textbook") as HTMLSelectElement;
        if(LR_textbook.value === "") {
            props.showModal("", "교과를 선택해 주세요.");
            return;
        }
        const LR_textbook_val = curriInfo.curriBook[LR_textbook.selectedIndex-1];
        
        // const LR_usedin = document.getElementById("LR_usedin") as HTMLSelectElement;
        // const LR_usedin_val = listUsedIn[LR_usedin.selectedIndex];
        
        const LR_level = document.getElementById("LR_level") as HTMLSelectElement;
        const LR_level_val = curriInfo.difficulty[LR_level.selectedIndex-1];
    
        // const LR_description = document.getElementById("LR_description") as HTMLInputElement;
        // const LR_description_val = LR_description.value;

        const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        const LR_html_curriUnit1_val = LR_html_curriUnit1 ? LR_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[LR_html_curriUnit1.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit2_val = LR_html_curriUnit2 ? LR_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[LR_html_curriUnit2.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit3_val = LR_html_curriUnit3 ? LR_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[LR_html_curriUnit3.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit4_val = LR_html_curriUnit4 ? LR_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[LR_html_curriUnit4.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit5_val = LR_html_curriUnit5 ? LR_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[LR_html_curriUnit5.selectedIndex-1] as IMeta : undefined;

        if(LR_html_curriUnit1.style.display !== "none" && !LR_html_curriUnit1_val) {                
            props.showModal("", "교과과정을 선택해 주세요.");
            LR_html_curriUnit1.focus();
            return;
        }
        if(LR_html_curriUnit2.style.display !== "none" && !LR_html_curriUnit2_val) {                
            props.showModal("", "교과과정을 선택해 주세요.");
            LR_html_curriUnit2.focus();
            return;
        }
        if(LR_html_curriUnit3.style.display !== "none" && !LR_html_curriUnit3_val) {                
            props.showModal("", "교과과정을 선택해 주세요.");
            LR_html_curriUnit3.focus();
            return;
        }
        if(LR_html_curriUnit4.style.display !== "none" && !LR_html_curriUnit4_val) {                
            props.showModal("", "교과과정을 선택해 주세요.");
            LR_html_curriUnit4.focus();
            return;
        }
        if(LR_html_curriUnit5.style.display !== "none" && !LR_html_curriUnit5_val) {                
            props.showModal("", "교과과정을 선택해 주세요.");
            LR_html_curriUnit5.focus();
            return;
        }

        const LR_rd_on = document.getElementById('LR_rd-on') as HTMLInputElement;    
        const LR_rd_on_val = LR_rd_on.checked
    
        const LR_rd_public_on = document.getElementById('LR_rd-public-on') as HTMLInputElement;    
        const LR_rd_public_on_val = LR_rd_public_on.checked

        const brandId = props.brand.id;
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");
        
        const listMetaMap : IMeta[] = [];

        listMetaMap.push(LR_school_val);
        listMetaMap.push(LR_subject_val);

        if(LR_level.selectedIndex > 0) {
            listMetaMap.push(LR_level_val);
        }

        let LR_parts_val = undefined;
        const LR_parts = document.getElementById("LR_parts") as HTMLSelectElement;
        if(LR_parts) {
            LR_parts_val = listParts[LR_parts.selectedIndex];
        }

        let LR_entry_val = undefined;
        const LR_entry = document.getElementById("LR_entry") as HTMLInputElement;
        if(LR_entry) {
            LR_entry_val = LR_entry.value;
        }

        let LR_multiLang_val = undefined;
        const LR_multiLang = document.getElementById("LR_multiLang") as HTMLInputElement;
        if(LR_multiLang) {
            LR_multiLang_val = LR_multiLang.value;
        }

        let LR_def_val = undefined;
        const LR_def = document.getElementById("LR_def") as HTMLInputElement;
        if(LR_def) {
            LR_def_val = LR_def.value;
        }

        let LR_pron_val = undefined;
        const LR_pron = document.getElementById("LR_pron") as HTMLInputElement;
        if(LR_pron) {
            LR_pron_val = LR_pron.value;
        }

        let LR_image_val = undefined;
        const LR_image = document.getElementById("LR_image") as HTMLInputElement;
        if(LR_image) {
            LR_image_val = LR_image.value;
        }

        let LR_audio_val = undefined;
        const LR_audio = document.getElementById("LR_audio") as HTMLInputElement;
        if(LR_audio) {
            LR_audio_val = LR_audio.value;
        }

        let LR_video_val = undefined;
        const LR_video = document.getElementById("LR_video") as HTMLInputElement;
        if(LR_video) {
            LR_video_val = LR_video.value;
        }
    
        let LR_speaker_val = undefined;
        const LR_speaker = document.getElementById("LR_speaker") as HTMLInputElement;
        if(LR_speaker) {
            LR_speaker_val = LR_speaker.value;
        }

        let LR_info1_val = undefined;
        const LR_info1 = document.getElementById("LR_info1") as HTMLInputElement;
        if(LR_info1) {
            LR_info1_val = LR_info1.value;
        }

        let LR_info2_val = undefined;
        const LR_info2 = document.getElementById("LR_info2") as HTMLInputElement;
        if(LR_info2) {
            LR_info2_val = LR_info2.value;
        }

        let LR_info3_val = undefined;
        const LR_info3 = document.getElementById("LR_info3") as HTMLInputElement;
        if(LR_info3) {
            LR_info3_val = LR_info3.value;
        }

        let LR_chunk1_val = undefined;
        const LR_chunk1 = document.getElementById("LR_chunk1") as HTMLInputElement;
        if(LR_chunk1) {
            LR_chunk1_val = LR_chunk1.value;
        }

        let LR_chunk2_val = undefined;
        const LR_chunk2 = document.getElementById("LR_chunk2") as HTMLInputElement;
        if(LR_chunk2) {
            LR_chunk2_val = LR_chunk2.value;
        }

        let LR_key_info_val = undefined;
        const LR_key_info = document.getElementById("LR_key_info") as HTMLInputElement;
        if(LR_key_info) {
            LR_key_info_val = LR_key_info.value;
        }
        
        let LR_key_type = undefined;
        let LR_key_title = undefined;
        let LR_key_desc = undefined;
        const LR_key1 = document.getElementById('LR_key1') as HTMLInputElement;    
        if(LR_key1 && LR_key1.checked) {
            LR_key_type = "Grammar";
            LR_key_title = (document.getElementById("LR_key1_title") as HTMLInputElement).value;
            LR_key_desc = (document.getElementById("LR_key1_desc") as HTMLInputElement).value;
        }
        const LR_key2 = document.getElementById('LR_key2') as HTMLInputElement;    
        if(LR_key2 && LR_key2.checked) {
            LR_key_type = "Expression";
            LR_key_title = (document.getElementById("LR_key2_title") as HTMLInputElement).value;
            LR_key_desc = (document.getElementById("LR_key2_desc") as HTMLInputElement).value;
        }

        const _mediasList = [];
        if(contentsImage) {
            _mediasList.push(contentsImage);
        }         
        if(contentsAudio) { 
            _mediasList.push(contentsAudio);   
        }
        if(contentsVideo) { 
            _mediasList.push(contentsVideo);   
        }

        const _studyMapList = studyMapList.filter((item) => item.id! > 0);
        _studyMapList.map((item) => {
            if(item.depth0) {
                listMetaMap.push(item.depth0);
            }
            if(item.depth1) {
                listMetaMap.push(item.depth1);
            }
            if(item.depth2) {
                listMetaMap.push(item.depth2);
            }
            if(item.depth3) {
                listMetaMap.push(item.depth3);
            }
            if(item.depth4) {
                listMetaMap.push(item.depth4);
            }
            if(item.depth5) {
                listMetaMap.push(item.depth5);
            }
            if(item.depth6) {
                listMetaMap.push(item.depth6);
            }
            
        });

        let LR_audioAnalysis_val = undefined;
        const LR_audioAnalysis = document.getElementById("LR_audioAnalysis") as HTMLInputElement;
        if(LR_audioAnalysis) {
            LR_audioAnalysis_val = LR_audioAnalysis.value;
        }

        let data = null;

        if(props.textType === "지문") {

            const _scriptList = await onSaveBulk();
            // const _scriptList = onUI2Script();
            logger.log("_scriptList", _scriptList);

            // const ssmap = [];

            for (let i = 0; i < _scriptList.length; i++) {
                const script = _scriptList[i];
                if(script.entry === undefined || script.entry === "") {
                    props.showModal("", "Script Entry 값을 입력하세요.");
                    return;
                } else if(script.multiLang === undefined || script.multiLang === "") {
                    props.showModal("", "Script 다국어 값을 입력하세요.");
                    return;
                }           
                
                // const data = {id : Number(script.skey)};
                // ssmap.push(data)
            }

            data = {
                "id": props.selectedItem ? props.selectedItem.id : undefined,
                "brand_id": brandId,
                "type_1": LR_type2_val,
                "type_2": LR_type3_val, 
                "name": LR_title_val, 
                "is_active": LR_rd_on_val, 
                "is_publicOpen": LR_rd_public_on_val, 
                "creator": props.selectedItem ? undefined : uid,
                "creator_name": props.selectedItem ? undefined : uname,
                "creator_id": props.selectedItem ? undefined : _id,
                "updater": props.selectedItem ? uid : undefined,
                "updater_name": props.selectedItem ? uname : undefined,
                "updater_id": props.selectedItem ? _id : undefined,
    
                "curriBook": LR_textbook_val?.id,
                "curriUnit1": LR_html_curriUnit1_val ? LR_html_curriUnit1_val.id : undefined,
                "curriUnit2": LR_html_curriUnit2_val ? LR_html_curriUnit2_val.id : undefined,
                "curriUnit3": LR_html_curriUnit3_val ? LR_html_curriUnit3_val.id : undefined,
                "curriUnit4": LR_html_curriUnit4_val ? LR_html_curriUnit4_val.id : undefined,
                "curriUnit5": LR_html_curriUnit5_val ? LR_html_curriUnit5_val.id : undefined,
                "metaMap": listMetaMap, 
    
                "contents_image": LR_image_val,
                "contents_audio": LR_audio_val,
                "contents_video": LR_video_val,

                "studyMapListString": JSON.stringify(_studyMapList),
                "dialog": _scriptList,

                "articles": articleList.filter((item) => item.id > 0),

                // "exam_sentences": ssmap,
            };
    
        } else {

            if(props.textType === "어휘" || props.textType === "문장") {

                if(LR_entry_val === undefined || LR_entry_val === "") {
                    props.showModal("", "Contents Entry 값을 입력하세요.");
                    return;
                } else if(LR_multiLang_val === undefined || LR_multiLang_val === "") {
                    props.showModal("", "Contents 다국어 값을 입력하세요.");
                    return;
                // } else if(LR_def_val === undefined || LR_def_val === "") {
                //     props.showModal("", "Contents DEF 값을 입력하세요.");
                //     return;
                // } else if(LR_pron_val === undefined || LR_pron_val === "") {
                //     props.showModal("", "Contents 발음기호 값을 입력하세요.");
                //     return;
                // } else if(LR_image_val === undefined || LR_image_val === "") {
                //     props.showModal("", "Contents 이미지 값을 입력하세요.");
                //     return;
                // } else if(LR_audio_val === undefined || LR_audio_val === "") {
                //     props.showModal("", "Contents 오디오 값을 입력하세요.");
                //     return;
                // } else if(LR_video_val === undefined || LR_video_val === "") {
                //     props.showModal("", "Contents 비디오 값을 입력하세요.");
                //     return;
                }

            }

            data = {
                "id": props.selectedItem ? props.selectedItem.id : undefined,
                "brand_id": brandId,
                "type_1": LR_type2_val,
                "type_2": LR_type3_val, 
                "name": LR_title_val, 
                "is_active": LR_rd_on_val, 
                "is_publicOpen": LR_rd_public_on_val, 
                "creator": props.selectedItem ? undefined : uid,
                "creator_name": props.selectedItem ? undefined : uname,
                "creator_id": props.selectedItem ? undefined : _id,
                "updater": props.selectedItem ? uid : undefined,
                "updater_name": props.selectedItem ? uname : undefined,
                "updater_id": props.selectedItem ? _id : undefined,
                // "description": LR_description_val,
                // "used_in": LR_usedin_val,
                "curriBook": LR_textbook_val?.id,
                "curriUnit1": LR_html_curriUnit1_val ? LR_html_curriUnit1_val.id : undefined,
                "curriUnit2": LR_html_curriUnit2_val ? LR_html_curriUnit2_val.id : undefined,
                "curriUnit3": LR_html_curriUnit3_val ? LR_html_curriUnit3_val.id : undefined,
                "curriUnit4": LR_html_curriUnit4_val ? LR_html_curriUnit4_val.id : undefined,
                "curriUnit5": LR_html_curriUnit5_val ? LR_html_curriUnit5_val.id : undefined,
                "metaMap": listMetaMap, 
    
                "parts": LR_parts_val,
    
                "contents_entry": LR_entry_val,
                "contents_multiLang": LR_multiLang_val,
                "contents_def": LR_def_val,
                "contents_pron": LR_pron_val,
                "contents_image": LR_image_val,
                "contents_audio": LR_audio_val,
                "contents_video": LR_video_val,
                "contents_audioAnalysis": LR_audioAnalysis_val,
    
                "exam_sentences": examList.filter((item) => item.id > 0),
                "articles": articleList.filter((item) => item.id > 0),
                "studyMapListString": JSON.stringify(_studyMapList),
    
                "speaker": LR_speaker_val,
                "info1": LR_info1_val,
                "info2": LR_info2_val,
                "info3": LR_info3_val,
                "chunk1": LR_chunk1_val,
                "chunk2": LR_chunk2_val,
                "key_info": LR_key_info_val,
    
                "key_type": LR_key_type,
                "key_title": LR_key_title,
                "key_desc": LR_key_desc,
    
                "medias": _mediasList,
            };
        }

        logger.log("onSave", data/*, JSON.stringify(data)*/);
    
        _onSave(data, props.selectedItem ? "update" : "insert");
    }

    async function onSaveBulk() {

        if(!curriInfo) {
            return;
        }

        let cnt = 0;
        const script_ck = document.getElementsByName("script_ck");
        for (let i = 0; i < script_ck.length; i++) {
            const chk = script_ck[i] as HTMLInputElement;
            if(chk.checked) {
                const script = scriptList[i];

                const LR_entry_val = script.entry;
                if(LR_entry_val.trim() === "") {
                    // props.showModal("", "ENTRY 값이 없는 문장이 있습니다.");
                    // return;
                    continue;
                }

                cnt = cnt + 1;
            }
        }
        if(cnt === 0) {
            // props.showModal("", "문장을 선택해주세요.");
            return;
        }

        const LR_title = document.getElementById("LR_title") as HTMLInputElement;
        const LR_title_val = LR_title.value;
        if(LR_title_val === "") {
            LR_title.focus();
            props.showModal("", "타이틀을 입력해 주세요.");
            return;
        }

        const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
        const LR_school_val = curriInfo.curriSchool[LR_school.selectedIndex];
        
        const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
        const LR_subject_val = curriInfo.curriSubject[LR_subject.selectedIndex];
        
        const LR_textbook = document.getElementById("LR_textbook") as HTMLSelectElement;
        if(LR_textbook.value === "") {
            props.showModal("", "교과를 선택해 주세요.");
            return;
        }
        const LR_textbook_val = curriInfo.curriBook[LR_textbook.selectedIndex-1];
        
        const LR_level = document.getElementById("LR_level") as HTMLSelectElement;
        const LR_level_val = curriInfo.difficulty[LR_level.selectedIndex-1];
    
        const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        const LR_html_curriUnit1_val = LR_html_curriUnit1 ? LR_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[LR_html_curriUnit1.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit2_val = LR_html_curriUnit2 ? LR_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[LR_html_curriUnit2.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit3_val = LR_html_curriUnit3 ? LR_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[LR_html_curriUnit3.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit4_val = LR_html_curriUnit4 ? LR_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[LR_html_curriUnit4.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit5_val = LR_html_curriUnit5 ? LR_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[LR_html_curriUnit5.selectedIndex-1] as IMeta : undefined;
    
        const LR_rd_on = document.getElementById('LR_rd-on') as HTMLInputElement;    
        const LR_rd_on_val = LR_rd_on.checked
    
        const LR_rd_public_on = document.getElementById('LR_rd-public-on') as HTMLInputElement;    
        const LR_rd_public_on_val = LR_rd_public_on.checked

        const brandId = props.brand.id;
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");
        
        const listMetaMap : IMeta[] = [];

        listMetaMap.push(LR_school_val);
        listMetaMap.push(LR_subject_val);

        if(LR_level.selectedIndex > 0) {
            listMetaMap.push(LR_level_val);
        }

        if(props.textType === "지문") {
            onUI2Script();
        }

        // const _examList = JSON.parse(JSON.stringify(examList));

        const datas = [];

        cnt = 0;
        for (let i = 0; i < script_ck.length; i++) {
            const chk = script_ck[i] as HTMLInputElement;
            if(chk.checked) {
                const script = scriptList[i];

                const LR_entry_val = script.entry;
                if(LR_entry_val.trim() === "") {
                    continue;
                }
                
                const LR_multiLang_val = script.multiLang;
                const LR_speaker_val = script.speaker;

                const _articles : any[] = [];
                const arr = script.akey.split(",");
                arr.map((akey, idx) => {
                    const _id = akey.trim();
                    _articles.push({"id" : _id});
                });
                
                const data = {
                    "id": script.skey && Number(script.skey) > 0 ? Number(script.skey) : undefined,
                    "type_1": "문장",
                    "type_2": "", 
                    "name": LR_entry_val, 
                    "is_active": LR_rd_on_val, 
                    "is_publicOpen": LR_rd_public_on_val, 
                    "creator": props.selectedItem ? undefined : uid,
                    "creator_name": props.selectedItem ? undefined : uname,
                    "creator_id": props.selectedItem ? undefined : _id,
                    "updater": props.selectedItem ? uid : undefined,
                    "updater_name": props.selectedItem ? uname : undefined,
                    "updater_id": props.selectedItem ? _id : undefined,
                    "brand_id": brandId,
                    // "description": LR_description_val,
                    // "used_in": LR_usedin_val,
                    "curriBook": LR_textbook_val?.id,
                    "curriUnit1": LR_html_curriUnit1_val ? LR_html_curriUnit1_val.id : undefined,
                    "curriUnit2": LR_html_curriUnit2_val ? LR_html_curriUnit2_val.id : undefined,
                    "curriUnit3": LR_html_curriUnit3_val ? LR_html_curriUnit3_val.id : undefined,
                    "curriUnit4": LR_html_curriUnit4_val ? LR_html_curriUnit4_val.id : undefined,
                    "curriUnit5": LR_html_curriUnit5_val ? LR_html_curriUnit5_val.id : undefined,
                    "metaMap": listMetaMap, 
                
                    "contents_entry": LR_entry_val,
                    "contents_multiLang": LR_multiLang_val,
                    "speaker": LR_speaker_val,
        
                    "exam_sentences": examList.filter((item) => item.id > 0),
                    // "articles": _articles,
                    "studyMap": studyMapList.filter((item) => item.id! > 0),
                };
        
                datas.push(data);
            }
        }

        props.showLoading(true);

        // logger.log(cnt, "onSaveBulk", datas);

        const _scriptList = JSON.parse(JSON.stringify(scriptList));
        for (let i = 0; i < datas.length; i++) {
            const data = datas[i];
            const script = _scriptList[i];

            const result = await util.textForSave(data, data.id ? "update" : "insert");
            if(result) {
                if(!data.id) {
                    script.skey = String((result as IText).id);
                }            
                // logger.log(cnt, "onSaveBulk", data, result, script);
            }

            cnt = cnt + 1;
        }

        setScriptList(_scriptList);
        // logger.log("onSaveBulk scriptList", _scriptList);
        
        props.showLoading(false);

        if(cnt > 0) {
            props.showModal("", "총 " + cnt + "개의 문장데이터를 등록 완료 하였습니다.");
        }

        return _scriptList;
    }

    async function _onSave(data: any, type: string) {

        props.showLoading(true);

        const result = await util.textForSave(data, type);
        logger.log("_onSave", result);

        props.showLoading(false);

        props.showModal("", "저장이 완료 되었습니다.");

        props.OnClose();         
    }
  
    function onEditMultiLang(ele_id: string = "LR_entry", multiLang_ele_id = "LR_multiLang"): void {
        const LR_entry = document.getElementById(ele_id) as HTMLInputElement;
        if(LR_entry && LR_entry.value.length > 0) {
            setMultiLangElement(multiLang_ele_id);
            setDefaultLang(LR_entry.value);
            setViewEditMultiLang(true);
        } else {
            LR_entry.focus();
        }
    }

    function onAddExamSentence() {
        const _examList = JSON.parse(JSON.stringify(examList));
        const _examSentence = {
            id: -1,
        }
        _examList.push(_examSentence);
        logger.log("onAddExamSentence", _examList);
        setExamList(_examList);
    }

    function onDelExamSentence(item: IText) {
        const _examList = examList;
        const newExamList = _examList.filter((e) => e.id !== item.id);
        logger.log("onDelExamSentence", newExamList);
        setExamList(newExamList);
    }

    function onSearchExamSentence(idx: number, searchType: string) {
        setSelectedExamSentenceIdx(idx);
        setSearchType(searchType);
        setViewPopupObjectSearch(true);
    }
    
    function onAddArticle() {
        const _articleList = JSON.parse(JSON.stringify(articleList));
        const _article = {
            id: -1,
        }
        _articleList.push(_article as IArticle);
        logger.log("onAddArticle", _articleList);
        setArticleList(_articleList);
    }

    function onDelArticle(idx: number) {
        const _articleList = JSON.parse(JSON.stringify(articleList)) as IArticle[];
        const newArticleList = _articleList.filter((item) => item !== _articleList[idx]);
        logger.log("onDelSentence", newArticleList);
        setArticleList(newArticleList);
    }

    function onAddStudyMap() {
        const _studyMapList = JSON.parse(JSON.stringify(studyMapList));
        const _studyMap = {
            id: -1,
        }
        _studyMapList.push(_studyMap);
        logger.log("onAddStudyMap", _studyMapList);
        setStudyMapList(_studyMapList);
    }

    function onDelStudyMap(item: IStudyMap) {
        const _studyMapList = studyMapList;
        const newStudyMapList = _studyMapList.filter((e) => e !== item);
        logger.log("onDelStudyMap", newStudyMapList);
        setStudyMapList(newStudyMapList);
    }      
      
    async function onObjectGenData() {

        props.showLoading(true);

        const LR_audio = document.getElementById("LR_audio") as HTMLInputElement;
        if(LR_audio) {
            if(LR_audio.value.trim() === "") {

            } else {
                const url = S3Server+LR_audio.value;
                const LR_entry = (document.getElementById('LR_entry') as HTMLInputElement);
                if(LR_entry.value === "") {
                    props.showModal("", "Entry 값이 없습니다.");
                    props.showLoading(false);
                    return;
                }
                const reference = LR_entry.value;
                const user = "visang";            
                    
                // const base64 = await util.getBase64FromUrl(url);
                // logger.log("base64", base64);

                // const data = {
                //     "argument" : {"audio": (base64 as string), "reference": reference, "user": user, "language_code": "english"},
                // }

                // const result = await util.getEngPron_new(data);

                const result = await util.getEngPron(url, reference, user);

                if(result) {

                    const json = JSON.stringify(result);

                    logger.log("result", json);

                    const r = (json as string).replaceAll("\n", "").replaceAll("\t", "").replaceAll("  ", "");
                    
                    logger.log("onObjectGenData", url, json, r);    

                    const LR_audioAnalysis = document.getElementById("LR_audioAnalysis") as HTMLInputElement;
                    LR_audioAnalysis.value = json as string;    
                }    
            }
        }

        // if(props.selectedItem) {
        //     const url = EDITOR_HOST+"/editor/syntax.html?brand_id="+props.brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
        //     +"&key="+props.selectedItem.id+"&token="+util.getUserInfo("token");
        //     window.open(url, "_blank", "noopener, noreferrer");        
        // }

        props.showLoading(false);
    }
    
    function onObjectSearch(input_id: string, searchType: string) {
        setInputId(input_id);
        setSearchType(searchType);
        setViewPopupObjectSearch(true);
    }

    function onCloseMultiLang(multiLang : IMultiLang[]) {
        logger.log("onCloseMultiLang", multiLang);
        const _multiLang = multiLang.filter((item) => item.val !== '');
        setMultiLang(_multiLang);
        setViewEditMultiLang(false);

        let multi_val = "";
        const multiLang_word = (document.getElementById(multiLangElement) as HTMLInputElement);
        if(multiLang_word) {
            _multiLang.map((lang) => {
                if(multi_val === "" && lang.countryKey !== "eng" && lang.val.trim() !== "") {
                    multi_val = lang.val;
                }
            });
            multiLang_word.value = multi_val;
        }
    }

    function onSelectObject(item: any) {
        logger.log("onSelectObject", item);

        if(item.type_1 === "image" || item.type_1 === "audio" || item.type_1 === "video") {
            if(item.type_1 === "image") {
                setContentsImage(item);
            } else if(item.type_1 === "audio") {
                setContentsAudio(item);
            } else if(item.type_1 === "video") {
                setContentsVideo(item);
            }
            const _input = (document.getElementById(inputId) as HTMLInputElement);
            if(_input) {
                if(item.type_1 === "image" || item.type_1 === "audio" || item.type_1 === "video") {
                    _input.value = (item as ILibrary).url;
                }
            }
            const _span = (document.getElementById(inputId+"_icon") as HTMLInputElement);
            if(_span) {
                if(item.type_1 === "image") {
                    _span.className = "icon-img-on block w-6 h-6 mx-3";
                } else if(item.type_1 === "audio") {
                    _span.className = "icon-audio-on block w-6 h-6 mx-3";
                } else if(item.type_1 === "video") {
                    _span.className = "icon-video-on block w-6 h-6 mx-3";                
                }
    
                _span.style.cursor = "pointer";
                _span.onclick = () => {
                    setMediaType(item.type_1);
                    setMediaUrl(S3Server+(item as ILibrary).url);
                    setViewMediaPreview(true);
                    // window.open(S3Server+(item as ILibrary).url, "_blank", "noopener, noreferrer");    
                };    
            }
        } else {
            const _examList = JSON.parse(JSON.stringify(examList)) as IText[];
            _examList[selectedExamSentenceIdx] = item;
            setExamList(_examList);    
            logger.log("examList", _examList);
        }
        setViewPopupObjectSearch(false);
    }

    async function onSelectContent(item: any) {
        
        logger.log("onSelectContent", item, props.textType);

        if(props.textType === "지문" && isArticleForScript) {
            const _script = scriptList[selectedArticleIdx];
            if(_script.akey === "") {
                _script.akey = item.id;
            } else {
                _script.akey = _script.akey + "," + item.id;
            }
            setScriptList(scriptList);

            const akey = document.getElementById("akey_"+selectedArticleIdx) as HTMLInputElement;
            akey.value = _script.akey;

        } else {
            const _articleList = JSON.parse(JSON.stringify(articleList)) as IArticle[];
            _articleList[selectedArticleIdx] = item;
            setArticleList(_articleList);    
        }
        setViewPopupContentsList(false);
    }

    function onContentSearch(idx: number) {
        setIsArticleForScript(false);
        setSelectedArticleIdx(idx);
        setViewPopupContentsList(true);
    }

    function onContentSearchForScript(idx: number) {
        setIsArticleForScript(true);
        setSelectedArticleIdx(idx);
        setViewPopupContentsList(true);
    }

    async function onPreView(item: IArticle) {
        const sets = await util.getSetsByArticleId(item.id);
        logger.log("onPreView", sets);
        if(sets && sets.length > 0) {
            const url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+props.brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&set_id="+sets[0].id+"&token="+util.getUserInfo("token");
            window.open(url, "_blank", "noopener, noreferrer");    
        }
    }

    function onUI2Script() : IScript[] {

        for (let i = 0; i < scriptList.length; i++) {
            const script = scriptList[i];
            const pkey = document.getElementById("pkey_"+i) as HTMLInputElement;
            script.pkey = pkey.value;            
            const skey = document.getElementById("skey_"+i) as HTMLInputElement;
            script.skey = skey.value;            
            const speaker = document.getElementById("speaker_"+i) as HTMLInputElement;
            if(speaker) {
                script.speaker = speaker.value;            
            }
            const entry = document.getElementById("entry_"+i) as HTMLInputElement;
            script.entry = entry.value;            
            const multiLang = document.getElementById("multiLang_"+i) as HTMLInputElement;
            script.multiLang = multiLang.value;            
            const start = document.getElementById("start_"+i) as HTMLInputElement;
            script.startTime = start.value;            
            const end = document.getElementById("end_"+i) as HTMLInputElement;
            script.endTime = end.value;            
            const akey = document.getElementById("akey_"+i) as HTMLInputElement;
            script.akey = akey.value;
            const summary = document.getElementById("summary_"+i) as HTMLInputElement;
            if(summary) {
                script.summary = summary.value;          
            }
            const rd_type1 = document.getElementById("rd_type1_"+i) as HTMLInputElement;
            if(rd_type1) {
                if(rd_type1.checked) {
                    script.type = "대화형";
                } else {
                    script.type = "일반형";
                }
            } else {
                script.type = "";
            }
            const empty_ck_ = document.getElementById("empty_ck_"+i) as HTMLInputElement;
            script.exceptEmpty = empty_ck_.checked ? "Y" : "N";
        }
     
        const _scriptList = JSON.parse(JSON.stringify(scriptList)) as IScript[];
        setScriptList(_scriptList);

        return _scriptList;
    }

    function onScript2UI() {

        if(props.textType !== "지문") {
            return;
        }

        logger.log("onScript2UI", props.selectedItem);

        for (let i = 0; i < scriptList.length; i++) {
            const script = scriptList[i];
            const pkey = document.getElementById("pkey_"+i) as HTMLInputElement;
            pkey.value = script.pkey;            
            const skey = document.getElementById("skey_"+i) as HTMLInputElement;
            skey.value = script.skey;            
            const speaker = document.getElementById("speaker_"+i) as HTMLInputElement;
            if(speaker) {
                if(props.selectedItem && props.selectedItem.type_2 === "혼합형") {
                    const rd_type1 = document.getElementById("rd_type1_"+i) as HTMLInputElement;
                    const rd_type2 = document.getElementById("rd_type2_"+i) as HTMLInputElement;
                    if(script.speaker && script.speaker.trim() === "") {
                        speaker.disabled = true;
                        if(rd_type2) {
                            rd_type2.checked = true;
                        }
                    } else {
                        speaker.disabled = false;
                        if(rd_type1) {
                            rd_type1.checked = true;
                        }
                    }
                }
                speaker.value = script.speaker;            
            }
            const entry = document.getElementById("entry_"+i) as HTMLInputElement;
            entry.value = script.entry;            
            const multiLang = document.getElementById("multiLang_"+i) as HTMLInputElement;
            multiLang.value = script.multiLang;            
            const start = document.getElementById("start_"+i) as HTMLInputElement;
            start.value = script.startTime;            
            const end = document.getElementById("end_"+i) as HTMLInputElement;
            end.value = script.endTime;            
            const akey = document.getElementById("akey_"+i) as HTMLInputElement;
            akey.value = script.akey;
            const summary = document.getElementById("summary_"+i) as HTMLInputElement;
            if(summary) {
                summary.value = script.summary;            
            }
            const empty_ck_ = document.getElementById("empty_ck_"+i) as HTMLInputElement;
            empty_ck_.checked = script.exceptEmpty === "Y";
        }
    }

    // function onAddScipt() {

    //     onUI2Script();

    //     let selectedId = -1;
    //     const script_ck = document.getElementsByName("script_ck");
    //     for (let i = 0; i < script_ck.length; i++) {
    //         const chk = script_ck[i] as HTMLInputElement;
    //         if(chk.checked) {
    //             selectedId = i;
    //             break;
    //         }
    //     }
    //     logger.log("selectedId", selectedId);

    //     const _scriptList = JSON.parse(JSON.stringify(scriptList)) as IScript[];
    //     const _script : IScript = {
    //         pkey: "",
    //         skey: "",
    //         type: scriptType === "혼합형" ? "대화형" : "",
    //         speaker: "",
    //         entry: "",
    //         multiLang: "",
    //         startTime: "",
    //         endTime: "",
    //         akey: "",
    //         summary: ""
    //     }

    //     if(selectedId === -1) {
    //         _scriptList.push(_script as IScript);
    //         setScriptList(_scriptList);
    //     } else {
    //         const newList = [];
    //         for (let i = 0; i < selectedId+1; i++) {
    //             newList.push(_scriptList[i]);                
    //         }
    //         newList.push(_script as IScript);
    //         for (let i = selectedId+1; i < _scriptList.length; i++) {
    //             newList.push(_scriptList[i]);                
    //         }
    //         setScriptList(newList);
    //         logger.log("onAddScipt", newList);
    //     }        
    // }

    // function onDelScript() {

    //     onUI2Script();

    //     let selectedId = -1;
    //     const script_ck = document.getElementsByName("script_ck");
    //     for (let i = 0; i < script_ck.length; i++) {
    //         const chk = script_ck[i] as HTMLInputElement;
    //         if(chk.checked) {
    //             selectedId = i;
    //             break;
    //         }
    //     }
    //     logger.log("selectedId", selectedId);

    //     if(selectedId > 0) {
    //         const selectedScript = scriptList[selectedId];
    //         logger.log("selectedScript", selectedScript);
    //         const newList = scriptList.filter((item) => item !== selectedScript);
    //         setScriptList(newList);
    //     }
    // }

    function onAddScipt(idx: number) {

        onUI2Script();

        let selectedId = idx;
        logger.log("selectedId", selectedId);

        const _scriptList = JSON.parse(JSON.stringify(scriptList)) as IScript[];
        const _script : IScript = {
            pkey: "",
            skey: "",
            type: scriptType === "혼합형" ? "대화형" : "",
            speaker: "",
            entry: "",
            multiLang: "",
            startTime: "",
            endTime: "",
            akey: "",
            summary: "",
            exceptEmpty: "",
        }

        if(selectedId === -1) {
            _scriptList.push(_script as IScript);
            setScriptList(_scriptList);
        } else {
            const newList = [];
            for (let i = 0; i < selectedId+1; i++) {
                newList.push(_scriptList[i]);                
            }
            newList.push(_script as IScript);
            for (let i = selectedId+1; i < _scriptList.length; i++) {
                newList.push(_scriptList[i]);                
            }
            setScriptList(newList);
            logger.log("onAddScipt", newList);
        }        
    }

    function onDelScript(idx: number) {

        const _scriptList = onUI2Script();

        if(_scriptList.length > 1) {
            let selectedId = idx;
            logger.log("selectedId", selectedId);
            const selectedScript = scriptList[selectedId];
            logger.log("selectedScript", selectedScript);
            const newList = scriptList.filter((item) => item !== selectedScript);
            setScriptList(newList);    
        }
    }

    function onChangeScriptType(e: ChangeEvent<HTMLSelectElement>) {
        logger.log("onChangeScriptType", e.target.value, scriptList);
        setScriptType(e.target.value);
    }

    function onSaveSentences() {
        props.showModal("", "스크립트의 문장을 선택하시고 저장/수정 버튼을 누르시면, 문장 데이타로 저장/수정됩니다.");
        // onSaveBulk();
    }

    function onChangeScriptRDType(idx: number) {
        logger.log("onChangeScriptRDType", idx);
        const rd_type1 = document.getElementById("rd_type1_"+idx) as HTMLInputElement;
        const speaker = document.getElementById("speaker_"+idx) as HTMLInputElement;
        speaker.value = "";
        speaker.disabled = !rd_type1.checked;
    }
    
    function onSelectStudyMap(item: IStudyMap) {
        const _studyMapList = JSON.parse(JSON.stringify(studyMapList));
        _studyMapList[selectedStudyMapId] = item;
        setStudyMapList(_studyMapList);
        logger.log("onSelectStudyMap", item, selectedStudyMapId, _studyMapList);

        setViewPopupSearchStudyMap(false);
    }

    return (
        <div>
            <div>

            <h4>기본 정보 등록</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">브랜드</th>
							<td className="text-left">{props.brand.name}</td>
						</tr>
						<tr>
							<th scope="row" className="w-56"><label htmlFor="sel-divi">구분<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">

                                <select className="mr-2" id="LR_type1" name="type1" disabled>
                                    <option>텍스트</option>              
                                </select>
                                <select className="mr-2" id="LR_type2" name="type2" disabled>
                                    <option>{props.textType}</option>              
                                </select>
                                <select className="mr-2" id="LR_type3" name="type3" style={{display: type3TextList && type3TextList.length > 0 ? "" : "none"}} onChange={(e) => onChangeScriptType(e)}>
                                    {type3TextList && (type3TextList as string[]).map((type3, idx3) => {
                                    return(
                                        <option key={idx3} value={type3}>{type3}</option>              
                                    )})}
                                </select>

                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">KEY</th>
							<td className="text-left">{props.selectedItem ? props.selectedItem.id : ""}</td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">타이틀<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="LR_title" className="w-1/2" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-class">학교 급<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_school" name="sel-school">
                                    {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-subject">교과목<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_subject" name="sel-subject">
                                    {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-textbook">교과<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_textbook" name="sel-textbook" onChange={(e) => onChangeCurriBook(e)}>                                    
                                    <option value="">교과</option>              
                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-unit">교과과정<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">

                                <select className="mr-2" id="LR_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                    <option value="">1Depth</option>              
                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                    <option value="">2Depth</option>              
                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                    <option value="">3Depth</option>              
                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                    {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                    <option value="">4Depth</option>              
                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                    <option value="">5Depth</option>              
                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>

                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">작성자</th>
                            {props.selectedItem &&
                            <td className="text-left">{props.selectedItem.creator_name} ({props.selectedItem.creator})</td>}
							{!props.selectedItem &&
                            <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
						</tr>
                        <tr>
							<th scope="row" className="w-56">등록일</th>
                            {props.selectedItem &&
							<td className="text-left">{props.selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
							{!props.selectedItem &&
							<td className="text-left">{util.getDate()}</td>}
                            </tr>
                        <tr>
							<th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-on" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-on">사용</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-off" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-off">미사용</label>
                                    </span>
                                </div>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">공개 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-on" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-on">공개</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-off" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-off">비공개</label>
                                    </span>
                                </div>
                            </td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}

				<h4>데이터 구성</h4>
				{/* <!-- table --> */}

                {props.textType !== "지문" &&
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>

                        {/* Used-In */}
						{/* <tr style={{display:"none"}}>
							<th scope="row" className="w-56">
                                <label htmlFor="sel-usedin" className="mr-0">Used In</label>
                            </th>
							<td className="text-left">
								<select id="LR_usedin" name="sel-usedin">

                                {listUsedIn && listUsedIn.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item}>{item}</option>              
                                    )})}
								</select>
							</td>
						</tr> */}

                        {/* 텍스트 */}
                        {/* 품사 */}
                        {props.textType === "어휘" && <>
						<tr>
							<th scope="row" className="w-56">
                                <label htmlFor="sel-pos" className="mr-0">품사<em className="text-sky-500 align-top"> *</em></label>
                            </th>
							<td className="text-left">
								<select id="LR_parts" name="sel-pos">
                                {listParts && listParts.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item}>{item}</option>              
                                    )})}
								</select>
							</td>
						</tr>

						<tr>
							<th scope="row">CONTENTS <em className="text-sky-500 align-top">*</em></th>
							<td className="p-0">
								<dl className="flex">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">Entry</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_entry" type="text" className="w-[30rem]" />
									</dd>
								</dl>
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">다국어</span>
										<div>
											<button type="button" className="btn-sky-s" onClick={() => onEditMultiLang()}>편집</button>
										</div>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input type="text" className="w-80" id="LR_multiLang" />
									</dd>
								</dl>
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">DEF</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_def" type="text" className="w-80" />
									</dd>
								</dl>
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">발음기호</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_pron" type="text" className="w-80" />
									</dd>
								</dl>
                                
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">이미지</span>
										{/* <div>
											<button type="button" className="btn-white-s h-8 mr-2">추가</button>
											<button type="button" className="btn-white-s h-8">삭제</button>
										</div> */}
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_image" type="text" className="w-[30rem]" readOnly />
										{/* <!-- 활성화 --> */}
										<span id="LR_image_icon" className="icon-img block w-6 h-6 mx-3"></span>
										{/* <!-- .//활성화 --> */}
										<button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_image", "이미지")}>검색</button>
									</dd>
								</dl>
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">오디오</span>
										{/* <div>
											<button type="button" className="btn-white-s h-8 mr-2">추가</button>
											<button type="button" className="btn-white-s h-8">삭제</button>
										</div> */}
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_audio" type="text" className="w-[30rem]" readOnly />
										{/* <!-- 활성화 --> */}
										<span id="LR_audio_icon" className="icon-audio block w-6 h-6 mx-3"></span>
										{/* <!-- .//활성화 --> */}
										<button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_audio", "오디오")}>검색</button>
									</dd>
								</dl>
								{/* <dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">원어민 음원 분석​</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_audio" type="text" className="w-[30rem]" readOnly />
										<span className="w-6 h-6 mx-3"></span>
										<button type="button" className="btn-sky-s" onClick={() => onObjectPreview()}>미리보기</button>
									</dd>
								</dl> */}
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">비디오</span>
										{/* <div>
											<button type="button" className="btn-white-s h-8 mr-2">추가</button>
											<button type="button" className="btn-white-s h-8">삭제</button>
										</div> */}
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_video" type="text" className="w-[30rem]" readOnly />
										{/* <!-- 활성화 --> */}
										<span id="LR_video_icon" className="icon-video block w-6 h-6 mx-3"></span>
										{/* <!-- .//활성화 --> */}
										<button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_video", "비디오")}>검색</button>
									</dd>
								</dl>
							</td>
						</tr>
                        </>}                                              

                        {props.textType === "문장" && <>
						<tr>
							<th scope="row" className="w-56">CONTENTS <em className="text-sky-500 align-top">*</em></th>
							<td className="p-0">
                                <dl className="flex">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">Speaker</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_speaker" type="text" className="w-[30rem]" readOnly />
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">Entry</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_entry" type="text" className="w-[30rem]" />
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">구문분석 정보​</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_info1" type="text" className="w-[30rem]" />
                                        {props.selectedItem &&
                                        <button type="button" className="btn-sky-s ml-2" onClick={() => {                                                                                  
                                            if(props.selectedItem) {
                                                const url = EDITOR_HOST+"/editor/syntax.html?brand_id="+props.brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                                +"&key="+props.selectedItem.id+"&token="+util.getUserInfo("token");
                                                window.open(url, "_blank", "noopener, noreferrer");        
                                            }
                                        }}>구문분석</button>}
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">분절 DB</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_info2" type="text" className="w-[30rem]" />
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">분절 DB 해석</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_info3" type="text" className="w-[30rem]" />
									</dd>
								</dl>

                                <dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">chunk1 (Unscramble)</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_chunk1" type="text" className="w-[30rem]" />
									</dd>
								</dl>

                                <dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">chunk2 (blank)</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_chunk2" type="text" className="w-[30rem]" />
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">Key Grammar</span>
                                        <input className="mr-3" id="LR_key1" type="radio" name="LR_key" />
									</dt>
									<dd className="flex items-center text-left">

                                        <table className="border-none">
                                            <tbody>
                                            <tr>
                                                <td>                                                    
                                                    <input id="LR_key1_title" type="text" className="w-[30rem]" placeholder="(문법 설명문구 Title)" />        
                                                </td>
                                            </tr>    
                                            <tr>
                                                <td style={{paddingTop:"0.75rem"}}>
                                                    <input id="LR_key1_desc" type="text" className="w-[30rem]" placeholder="(문법 설명문구 내용영역)" />        
                                                </td>
                                            </tr>    
                                            </tbody>
                                        </table>                                        
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">Key Expression</span>
                                        <input className="mr-3" id="LR_key2" type="radio" name="LR_key" />
									</dt>
									<dd className="flex items-center text-left">

                                        <table className="border-none">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    
                                                    <input id="LR_key2_title" type="text" className="w-[30rem]" placeholder="(표현 설명문구 Title)" />        
                                                </td>
                                            </tr>    
                                            <tr>
                                                <td style={{paddingTop:"0.75rem"}}>
                                                    <input id="LR_key2_desc" type="text" className="w-[30rem]" placeholder="(표현 설명문구 내용영역)" />        
                                                </td>
                                            </tr>    
                                            </tbody>
                                        </table>                                        
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">다국어</span>
										<div>
											<button type="button" className="btn-sky-s" onClick={() => onEditMultiLang()}>편집</button>
										</div>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input type="text" className="w-[30rem]" id="LR_multiLang" />
									</dd>
								</dl>
                                
                                <dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">연관 지문 Key정보</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_key_info" type="text" className="w-[30rem]" readOnly />
									</dd>
								</dl>

								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">이미지</span>
										{/* <div>
											<button type="button" className="btn-white-s h-8 mr-2">추가</button>
											<button type="button" className="btn-white-s h-8">삭제</button>
										</div> */}
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_image" type="text" className="w-[30rem]" readOnly />
										{/* <!-- 활성화 --> */}
										<span id="LR_image_icon" className="icon-img block w-6 h-6 mx-3"></span>
										{/* <!-- .//활성화 --> */}
										<button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_image", "이미지")}>검색</button>
									</dd>
								</dl>
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">오디오</span>
										{/* <div>
											<button type="button" className="btn-white-s h-8 mr-2">추가</button>
											<button type="button" className="btn-white-s h-8">삭제</button>
										</div> */}
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_audio" type="text" className="w-[30rem]" readOnly />
										{/* <!-- 활성화 --> */}
										<span id="LR_audio_icon" className="icon-audio block w-6 h-6 mx-3"></span>
										{/* <!-- .//활성화 --> */}
										<button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_audio", "오디오")}>검색</button>
									</dd>
								</dl>
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">원어민 음원 분석​</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_audioAnalysis" type="text" className="w-[30rem]" />
										<span className="w-6 h-6 mx-3"></span>
										<button id="btnAudioAnalysis" type="button" className="btn-sky-s" onClick={onObjectGenData}>데이터 생성​</button>
									</dd>
								</dl>
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">비디오</span>
										{/* <div>
											<button type="button" className="btn-white-s h-8 mr-2">추가</button>
											<button type="button" className="btn-white-s h-8">삭제</button>
										</div> */}
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_video" type="text" className="w-[30rem]" readOnly />
										{/* <!-- 활성화 --> */}
										<span id="LR_video_icon" className="icon-video block w-6 h-6 mx-3"></span>
										{/* <!-- .//활성화 --> */}
										<button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_video", "비디오")}>검색</button>
									</dd>
								</dl>
							</td>
						</tr>
                        </>}                                              

                        {/* 어휘, 예문 */}
                        {examList && examList.map((item, idx) => {

                            let imgOn = false;
                            let imgCls = "icon-img block w-6 h-6 mx-3";
                            if(item.contents_image && item.contents_image.trim() !== "") {
                                imgOn = true;
                                imgCls = "icon-img-on block w-6 h-6 mx-3";
                            }
                            let audioOn = false;
                            let audioCls = "icon-audio block w-6 h-6 mx-3";
                            if(item.contents_audio && item.contents_audio.trim() !== "") {
                                audioOn = true;
                                audioCls = "icon-audio-on block w-6 h-6 mx-3";
                            }
                            let videoOn = false;
                            let videoCls = "icon-video block w-6 h-6 mx-3";
                            if(item.contents_video && item.contents_video.trim() !== "") {
                                videoOn = true;
                                videoCls = "icon-video-on block w-6 h-6 mx-3";
                            }

                            return(
                                <tr key={idx}>
                                    <th scope="row">
                                        {props.textType === "문장" ? "어휘" : "예문"} {idx+1}<br/>
                                        {idx === 0 &&
                                        <button type="button" className="btn-white-s h-8" onClick={onAddExamSentence}>추가</button>}
                                        {idx !== 0 &&
                                        <button type="button" className="btn-white-s h-8" onClick={() => onDelExamSentence(item)}>삭제</button>}
                                    </th>
                                    <td className="p-0">
                                        <dl className="flex">
                                            <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                <span className="mr-0 font-semibold text-gray-900">Key</span>
                                            </dt>
                                            <dd className="flex items-center text-left px-3">
                                                {/* <input type="text" className="w-[30rem]" readOnly /> */}
                                                {item.id > 0 && <span className="w-[30rem] ml-1" style={{overflow:"auto"}}>{item.id}</span>}
                                                <div className="ml-2">
                                                    <button type="button" className="btn-sky-s" onClick={() => onSearchExamSentence(idx, props.textType === "어휘" ? "문장" : "어휘")}>검색</button>
                                                </div>                                    
                                            </dd>
                                        </dl>

                                        <dl className="flex border-gray-300 border-t">
                                            <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                <span className="mr-0 font-semibold text-gray-900">Entry</span>
                                            </dt>
                                            <dd className="flex items-center text-left px-3">
                                                {/* <input type="text" className="w-[30rem]" readOnly /> */}
                                                {item.id > 0 && <span className="w-[50rem] ml-1" style={{overflow:"auto"}}>{item.contents_entry}</span>}
                                            </dd>
                                        </dl>
                                        <dl className="flex border-gray-300 border-t">
                                            <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                <span className="mr-0 font-semibold text-gray-900">다국어</span>
                                            </dt>
                                            <dd className="flex items-center text-left px-3">
                                                {/* <input type="text" className="w-80" readOnly /> */}
                                                {item.id > 0 && <span className="w-[50rem] ml-1" style={{overflow:"auto"}}>{item.contents_multiLang}</span>}
                                            </dd>
                                        </dl>
                                        <dl className="flex border-gray-300 border-t">
                                            <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                <span className="mr-0 font-semibold text-gray-900">이미지</span>
                                            </dt>
                                            <dd className="flex items-center text-left px-3">
                                                {/* <input type="text" className="w-80" readOnly /> */}
                                                {item.id > 0 && <span className="w-[50rem] ml-1" style={{overflow:"auto"}}>{item.contents_image}</span>}
                                                {/* <!-- 비활성화 --> */}
                                                <span className={imgCls} onClick={() => {
                                                    if(imgOn) {                                                        
                                                        setMediaType("image");
                                                        setMediaUrl(S3Server+item.contents_image);
                                                        setViewMediaPreview(true);                                    
                                                        // window.open(S3Server+item.contents_image, "_blank", "noopener, noreferrer");    
                                                    }
                                                }} style={{cursor: imgOn ? "pointer" : ""}}></span>

                                                {/* {item.isUploaded && fileType === "이미지" && <a target="_new" href={S3Server+img_src}><img className="icon-img block w-6 h-6 mx-3" id={'LR_divOutput_'+idx} src={S3Server+img_src} /></a>}
                                                {item.isUploaded && fileType === "오디오" && <a target="_new" href={S3Server+img_src}><span className="icon-audio-on block w-6 h-6 mx-3"></span></a>}
                                                {item.isUploaded && fileType === "비디오" && <a target="_new" href={S3Server+img_src}><span className="icon-video-on block w-6 h-6 mx-3"></span></a>} */}

                                                {/* <!-- .//비활성화 --> */}
                                            </dd>
                                        </dl>
                                        <dl className="flex border-gray-300 border-t">
                                            <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                <span className="mr-0 font-semibold text-gray-900">오디오</span>
                                            </dt>
                                            <dd className="flex items-center text-left px-3">
                                                {/* <input type="text" className="w-80" readOnly /> */}
                                                {item.id > 0 && <span className="w-[50rem] ml-1" style={{overflow:"auto"}}>{item.contents_audio}</span>}
                                                {/* <!-- 비활성화 --> */}
                                                <span className={audioCls} onClick={() => {
                                                    if(audioOn) {
                                                        setMediaType("audio");
                                                        setMediaUrl(S3Server+item.contents_audio);
                                                        setViewMediaPreview(true);                                    
                                                        // window.open(S3Server+item.contents_audio, "_blank", "noopener, noreferrer");    
                                                    }
                                                }} style={{cursor: audioOn ? "pointer" : ""}}></span>
                                                {/* <!-- .//비활성화 --> */}
                                            </dd>
                                        </dl>
                                        <dl className="flex border-gray-300 border-t">
                                            <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                <span className="mr-0 font-semibold text-gray-900">비디오</span>
                                            </dt>
                                            <dd className="flex items-center text-left px-3">
                                                {/* <input type="text" className="w-80" readOnly /> */}
                                                {item.id > 0 && <span className="w-[50rem] ml-1" style={{overflow:"auto"}}>{item.contents_video}</span>}
                                                {/* <!-- 비활성화 --> */}
                                                <span className={videoCls} onClick={() => {
                                                    if(videoOn) {
                                                        setMediaType("video");
                                                        setMediaUrl(S3Server+item.contents_video);
                                                        setViewMediaPreview(true);                                    
                                                        // window.open(S3Server+item.contents_video, "_blank", "noopener, noreferrer");    
                                                    }
                                                }} style={{cursor: videoOn ? "pointer" : ""}}></span>
                                                {/* <!-- .//비활성화 --> */}
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>
                            );
                        })}

					</tbody>
				</table>}
				{/* <!-- .//table -->	 */}

                {/* <!-- 혼합형 --> */}
                {props.textType === "지문" && <>

				<table style={{display:""}}>
					<caption className="sr-only">텍스트 관리 테이블</caption>
                    <thead>
                        <tr>
                            <th scope="row">CONTENTS</th>
                        </tr>
                    </thead>
					<tbody>
                        <tr>
                            <td style={{padding:0}}>
                                <dl className="flex border-gray-300 border-t">
                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                        <span className="mr-0 font-semibold text-gray-900">이미지</span>
                                        {/* <div>
                                            <button type="button" className="btn-white-s h-8 mr-2">추가</button>
                                            <button type="button" className="btn-white-s h-8">삭제</button>
                                        </div> */}
                                    </dt>
                                    <dd className="flex items-center text-left px-3">
                                        <input id="LR_image" type="text" className="w-80" readOnly />
                                        {/* <!-- 활성화 --> */}
                                        <span id="LR_image_icon" className="icon-img block w-6 h-6 mx-3"></span>
                                        {/* <!-- .//활성화 --> */}
                                        <button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_image", "이미지")}>검색</button>
                                    </dd>
                                </dl>
                                <dl className="flex border-gray-300 border-t">
                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                        <span className="mr-0 font-semibold text-gray-900">오디오</span>
                                        {/* <div>
                                            <button type="button" className="btn-white-s h-8 mr-2">추가</button>
                                            <button type="button" className="btn-white-s h-8">삭제</button>
                                        </div> */}
                                    </dt>
                                    <dd className="flex items-center text-left px-3">
                                        <input id="LR_audio" type="text" className="w-80" readOnly />
                                        {/* <!-- 활성화 --> */}
                                        <span id="LR_audio_icon" className="icon-audio block w-6 h-6 mx-3"></span>
                                        {/* <!-- .//활성화 --> */}
                                        <button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_audio", "오디오")}>검색</button>
                                    </dd>
                                </dl>
                                <dl className="flex border-gray-300 border-t">
                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                        <span className="mr-0 font-semibold text-gray-900">비디오</span>
                                        {/* <div>
                                            <button type="button" className="btn-white-s h-8 mr-2">추가</button>
                                            <button type="button" className="btn-white-s h-8">삭제</button>
                                        </div> */}
                                    </dt>
                                    <dd className="flex items-center text-left px-3">
                                        <input id="LR_video" type="text" className="w-80" readOnly />
                                        {/* <!-- 활성화 --> */}
                                        <span id="LR_video_icon" className="icon-video block w-6 h-6 mx-3"></span>
                                        {/* <!-- .//활성화 --> */}
                                        <button type="button" className="btn-sky-s" onClick={() => onObjectSearch("LR_video", "비디오")}>검색</button>
                                    </dd>
                                </dl>
                            </td>
                        </tr>
					</tbody>
				</table>

                {/* <div className="text-right mb-3 text-script-padding-top-1 mt-5" style={{display:props.textType === "지문" ? "":"none"}}>
                    <button type="button" className="btn-white-m mr-2" onClick={onAddScipt}>추가</button>
                    <button type="button" className="btn-white-m" onClick={onDelScript}>삭제</button>
                </div> */}

				{/* <!-- table --> */}
				<table className="table-type1 mt-10" style={{display:props.textType === "지문" ? "":"none"}}>
					<caption className="sr-only">텍스트 관리 테이블</caption>
                    <thead>
                        <tr>
                            <th scope="row" colSpan={scriptType === "혼합형" ? 13 : scriptType === "일반형" ? 11: 11}>SCRIPT</th>
                        </tr>
                        <tr>
                            {/* <th scope="row"><input className="h-4 w-4 rounded" type="radio" disabled /></th> */}
                            <th scope="row">추가/삭제</th>
                            <th scope="row">
                                {/* <div>문장데이터 등록</div> */}
                                <input id="all_check" className="h-4 w-4 rounded" type="checkbox" onClick={(e) => {
                                const script_ck = document.getElementsByName("script_ck");
                                for (let i = 0; i < script_ck.length; i++) {
                                    const element = script_ck[i] as HTMLInputElement;
                                    if(!element.disabled) {
                                        element.checked = (e.target as HTMLInputElement).checked;
                                    }                                    
                                }
                            }} /></th>

                            <th scope="row" style={{display:""}}>빈칸예외</th>
                            <th scope="row">문단<br/>Key</th>
                            <th scope="row">문장<br/>Key</th>
                            {scriptType === "혼합형" &&
                            <th scope="row">양식</th>}                            
                            {scriptType !== "일반형" &&
                            <th scope="row">SPEAKER</th>}
                            <th scope="row">ENTRY</th>
                            <th scope="row">다국어</th>
                            <th scope="row">Start</th>
                            <th scope="row">End</th>
                            <th scope="row">문단별<br/>문항key</th>
                            {scriptType !== "대화형" &&
                            <th scope="row">문단​<br/>Summary</th>}
                        </tr>
                    </thead>
					<tbody>

                        {scriptList && scriptList.map((item, idx) => {

                            let disabled = false;
                            if(item.skey && item.skey !== "") {
                                disabled = true;
                            }

                            setTimeout(() => {
                                const script_ck_ = document.getElementById("script_ck_"+idx) as HTMLInputElement;
                                if(script_ck_) {
                                    script_ck_.checked = disabled;
                                }                                    
                            }, 10);

                            return(
                                <tr key={idx}>
                                    {/* <td>
                                        <input id={"script_rd_"+idx} name="script_rd" type="radio" className="h-4 w-4 rounded"/>
                                    </td> */}
                                    <td>
                                        <button type="button" className="btn-sky-s" onClick={() => onAddScipt(idx)}>추가</button>
                                        <button type="button" className="btn-sky-s ml-2" onClick={() => onDelScript(idx)}>삭제</button>
                                    </td>
                                    <td>
                                        <input id={"script_ck_"+idx} name="script_ck" type="checkbox" className="h-4 w-4 rounded" disabled={disabled} onClick={(e) => {
                                            const script_ck = document.getElementsByName("script_ck");
                                            let cntChecked = 0;
                                            for (let i = 0; i < script_ck.length; i++) {
                                                const element = script_ck[i] as HTMLInputElement;
                                                if(element.checked) {
                                                    cntChecked = cntChecked + 1;
                                                }
                                            }
                                            const all_check = document.getElementById("all_check") as HTMLInputElement;
                                            if(cntChecked === script_ck.length) {                                                
                                                all_check.checked = true;                                                
                                            } else {
                                                all_check.checked = false;                                                
                                            }
                                        }}/>
                                    </td>
                                    <td style={{display:""}}>
                                        <input id={"empty_ck_"+idx} name="empty_ck" type="checkbox" className="h-4 w-4 rounded" />
                                    </td>
                                    <td>
                                        <input id={"pkey_"+idx} type="text" className="w-8" />
                                    </td>
                                    <td>
                                        <span id={"skey_"+idx} className="w-8">{item.skey}</span>
                                    </td>
                                    {scriptType === "혼합형" &&
                                    <td>
                                        <div>
                                            <span className="inp-radio">
                                                <input id={"rd_type1_"+idx} name={"rd_type_"+idx} type="radio" onClick={() => onChangeScriptRDType(idx)} />
                                                <label style={{marginRight:0}} htmlFor="rd-mode4-1">대화형</label>
                                            </span>
                                            <span className="inp-radio">
                                                <input id={"rd_type2_"+idx} name={"rd_type_"+idx} type="radio" onClick={() => onChangeScriptRDType(idx)} />
                                                <label style={{marginRight:0}} htmlFor="rd-mode4-2">일반형</label>
                                            </span>
                                        </div>
                                    </td>}
        
                                    {scriptType !== "일반형" &&
                                    <td>
                                        <input id={"speaker_"+idx} type="text" className="w-16" disabled={item.type === "일반형"} />
                                    </td>}
                                    <td>
                                        <input id={"entry_"+idx} type="text" className="" />
                                    </td>
                                    <td>
                                        <input id={"multiLang_"+idx} type="text" className=""  />
                                        <button type="button" className="btn-sky-s ml-2" onClick={() => onEditMultiLang("entry_"+idx, "multiLang_"+idx)}>편집</button>
                                    </td>
                                    <td>
                                        <input id={"start_"+idx} type="text" className="w-16"  />
                                    </td>
                                    <td>
                                        <input id={"end_"+idx} type="text" className="w-16" />
                                    </td>
                                    <td>
                                        <input id={"akey_"+idx} type="text" className="w-16" />
                                        <button type="button" className="btn-sky-s ml-2" onClick={() => onContentSearchForScript(idx)}>검색</button>
                                    </td>
                                    {scriptType !== "대화형" &&
                                    <td>
                                        <input id={"summary_"+idx} type="text" className="" />
                                    </td>}
                                </tr>
                            );
                        })}
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
                </>}

				<table className={props.textType === "지문" ? "mt-x5" :"mt-2"}>
					<tbody>
                        <tr>
                            <th scope="row" className="w-56">문항</th>
                            <td className="p-0">

                            {/* 어휘의 문항 */}
                            {articleList && articleList.map((item, idx) => {
                                let cls = "flex border-gray-300";
                                cls = idx < articleList.length-1 ? cls + " border-b" : cls;

                                const sId = item.id > 0 ? item.id : "";

                                return(
                                    <dl key={idx} className={cls}>
                                        <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                            <span className="mr-0 font-semibold text-gray-900">문항 {idx+1}</span>
                                            <div>
                                                {idx === 0 &&
                                                <button type="button" className="btn-white-s h-8" onClick={onAddArticle}>추가</button>}
                                                {idx !== 0 &&
                                                <button type="button" className="btn-white-s h-8" onClick={() => onDelArticle(idx)}>삭제</button>}
                                            </div>
                                        </dt>
                                        <dd className="flex items-center text-left px-3 w-1/2">
                                            {/* <input id={input_id} type="text" className="w-[30rem]" defaultValue={item.id} readOnly /> */}
                                            <span className="w-1/2 ml-1">{sId}</span>
                                            <div className="text-right">
                                                <button type="button" className="btn-sky-s mx-2" disabled={!item.url} onClick={() => onPreView(item)}>미리보기</button>
                                                <button type="button" className="btn-sky-s" onClick={() => onContentSearch(idx)}>검색</button>
                                            </div>
                                        </dd>
                                    </dl>
                                );
                            })}
                            </td>
                        </tr>
                    </tbody>
				</table>

                <h4 style={{display:"none"}}>추가 정보</h4>
				{/* <!-- table --> */}
				<table style={{display:"none"}}>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">
                                <label htmlFor="sel-level" className="mr-0">난이도</label>
                            </th>
							<td className="text-left">
								<select id="LR_level" name="sel-level">
                                    <option value="0">난이도</option>              
                                    {curriInfo && curriInfo.difficulty && (curriInfo.difficulty as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
							</td>
						</tr>

                        {studyMapList && studyMapList.map((item, idx) => {

                            let _unitVal = "";
                                                    
                            const arr = [item.depth1, item.depth2, item.depth3, item.depth4, item.depth5, item.depth6];
                            arr.map((meta, idx) => {
                                if(meta) {
                                    if(_unitVal === "") {
                                        if(selectedItem) {
                                            const metaMap = (selectedItem as IText).metaMap;
                                            if(metaMap && metaMap.length > 0) {
                                                const found = metaMap.find((mm) => mm.id === meta.id);
                                                if(found) {
                                                    _unitVal = found.val;            
                                                }    
                                            }
                                        } else {
                                            _unitVal = meta.val;    
                                        }
                                    } else {
                                        if(idx === 2) {
                                            if(selectedItem) {
                                                const metaMap = (selectedItem as IText).metaMap;
                                                if(metaMap && metaMap.length > 0) {
                                                    const found = metaMap.find((mm) => mm.id === meta.id);
                                                    if(found) {
                                                        _unitVal = _unitVal + " > " + "" + found.val;
                                                    }
                                                }
                                            } else {
                                                _unitVal = _unitVal + " > " + "" + meta.val;
                                            }
                                        } else {
                                            if(selectedItem) {
                                                const metaMap = (selectedItem as IText).metaMap;
                                                if(metaMap && metaMap.length > 0) {
                                                    const found = metaMap.find((mm) => mm.id === meta.id);
                                                    if(found) {
                                                        _unitVal = _unitVal + " > " + found.val;
                                                    }
                                                }
                                            } else {
                                                _unitVal = _unitVal + " > " + meta.val;
                                            }
                                        }
                                    }
                                }
                            });                            

                            return(

                            <tr key={idx}>
                                <th scope="row">
                                    학습맵 {idx+1}<br />
                                    {idx === 0 &&
                                    <button type="button" className="btn-white-s h-8" onClick={onAddStudyMap}>추가</button>}
                                    {idx !== 0 &&
                                    <button type="button" className="btn-white-s h-8" onClick={() => onDelStudyMap(item)}>삭제</button>}
                                </th>

                                <td className="text-left">
                                    <button type="button" className="btn-sky" onClick={() => {
                                        setSelectedStudyMapId(idx);
                                        setViewPopupSearchStudyMap(true);
                                    }}>검색</button>
                                    <span className="ml-2" dangerouslySetInnerHTML={{ __html: _unitVal }}></span>
                                </td>

                                {/* <td className="text-left">
                                    <div>
                                        <select className="mr-2" id="sel-depth1" name="sel-depth1">
                                            <option>Depth 1</option>
                                            <option>Depth 1</option>
                                            <option>Depth 1</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth2" name="sel-depth3">
                                            <option>Depth 2</option>
                                            <option>Depth 2</option>
                                            <option>Depth 2</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth3" name="sel-depth3">
                                            <option>Depth 3</option>
                                            <option>Depth 3</option>
                                            <option>Depth 3</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth4" name="sel-depth4">
                                            <option>Depth 4</option>
                                            <option>Depth 4</option>
                                            <option>Depth 4</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth5" name="sel-depth5">
                                            <option>Depth 5</option>
                                            <option>Depth 5</option>
                                            <option>Depth 5</option>
                                        </select>
                                    </div>
                                    <div className="mt-2">
                                        <select className="mr-2" id="sel-depth6" name="sel-depth6">
                                            <option>Depth 6</option>
                                            <option>Depth 6</option>
                                            <option>Depth 6</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth7" name="sel-depth7">
                                            <option>Depth 7</option>
                                            <option>Depth 7</option>
                                            <option>Depth 7</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth8" name="sel-depth8">
                                            <option>Depth 8</option>
                                            <option>Depth 8</option>
                                            <option>Depth 8</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth9" name="sel-depth9">
                                            <option>Depth 9</option>
                                            <option>Depth 9</option>
                                            <option>Depth 9</option>
                                        </select>
                                        <select className="mr-2" id="sel-depth10" name="sel-depth10">
                                            <option>Depth 10</option>
                                            <option>Depth 10</option>
                                            <option>Depth 10</option>
                                        </select>
                                    </div>
                                </td> */}
                            </tr>                                
                            );
                        })}

					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
                        <div>
                            {props.textType === "지문" &&
                            <button type="button" className="btn-sky" onClick={onSaveSentences} >문장데이터 등록</button>}
                            {!props.selectedItem &&
                            <button type="button" className="btn-sky ml-2" onClick={onSave} >저장</button>}
                            {props.selectedItem &&
                            <MyButton type="button" className="btn-sky ml-2" onClick={onSave} children={"수정"} creator={props.selectedItem?.creator} showModal={props.showModal} />}
                        </div>
					</div>
				</div>

            </div>

            {viewEditMultiLang &&
            <PopupEditMultiLang entry={defaultLang} onCloseMultiLang={onCloseMultiLang} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewEditMultiLang(false);
            }} />}

            {viewPopupObjectSearch &&
            <PopupLibrarySearch brand={props.brand} searchType={searchType} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectObject} onClose={function (): void {
                setViewPopupObjectSearch(false);
            } } />}

            {viewPopupContentsList &&
            <PopupContentsList brand={props.brand} textType={props.textType} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectContent} onClose={function (): void {
                setViewPopupContentsList(false);
            } } />}

            {viewPopupSearchStudyMap &&
            <PopupStudyMapSearch showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectStudyMap} onClose={function (): void {
                setViewPopupSearchStudyMap(false);
            } } />}

            {viewMediaPreview &&
            <PopupMediaPreview type={mediaType} url={mediaUrl} onClose={function (): void {
                setViewMediaPreview(false);
            } } />}

        </div>
    );
}
export default LibraryTextRegister;
