import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IAITutor, IBrand, ILibrary, IMeta, IScript, IText, S3Server, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import PopupLibrarySearch from "../library/PopupLibrarySearch";

interface IMission {
    val: string;
    image: string;
    imageUse: boolean;
}

interface IAITutorWritingRegister {
    brand: IBrand;
    selectedItem: IAITutor | null;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function AITutorWritingRegister(props: IAITutorWritingRegister) {

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    
    const [curriDepth, setCurriDepth] = useState(0);

    const [missionList, setMissionList] = useState<IMission[]>([]);

    const [studyMapsList, setStudyMapsList] = useState<IMeta[]>([]);
    const [studyMap0List, setStudyMap0List] = useState<IMeta[]>([]);
    const [studyMap1List, setStudyMap1List] = useState<IMeta[]>([]);
    const [studyMap2List, setStudyMap2List] = useState<IMeta[]>([]);
    const [studyMap3List, setStudyMap3List] = useState<IMeta[]>([]);
    const [studyMap4List, setStudyMap4List] = useState<IMeta[]>([]);
    const [studyMap5List, setStudyMap5List] = useState<IMeta[]>([]);
    const [studyMap6List, setStudyMap6List] = useState<IMeta[]>([]);

    const [studyMapDepth, setStudyMapDepth] = useState(0);

    const [viewSearchObject, setViewSearchObject] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        if(!bDone) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     props.OnClose();
            //     return;
            // }
    
            getStudyMapList();

            const mission : IMission = {
                val: "",
                image: "",
                imageUse: false,
            }
            setMissionList([mission]);

            (document.getElementById('AR_rd-on') as HTMLInputElement).checked = true;
            (document.getElementById('AR_rd-off') as HTMLInputElement).checked = false;
            (document.getElementById('AR_rd-public-on') as HTMLInputElement).checked = true;
            (document.getElementById('AR_rd-public-off') as HTMLInputElement).checked = false;
    
            const selectedItem = props.selectedItem;
            if(selectedItem) {
                init(selectedItem.id);
                logger.log("selectedItem", selectedItem);
            }
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(missionList && missionList.length > 0) {
            onMission2UI();
        }
    }, [missionList])

    useEffect(() => {
        if(studyMap0List && studyMap0List.length > 0) {

            if(props.selectedItem) {
                const studyMap = studyMap0List.find((item) => Number(item.id) === Number(props.selectedItem?.studyMap));
                logger.log("studyMap", studyMap);
                if(studyMap) {
                    const AR_studyMap0 = document.getElementById("AR_studyMap0") as HTMLSelectElement;
                    if(AR_studyMap0) {
                        AR_studyMap0.value = studyMap.val;
                        getStudyMapDescendantsList(studyMap.code);    
                    }        
                }
            }
        }
    }, [studyMap0List])

    useEffect(() => {
        if(studyMapsList && studyMapsList.length > 0) {

            if(props.selectedItem) {
                const studyMap1 = studyMapsList.find((item) => Number(item.id) === Number(props.selectedItem?.studyMap1));
                logger.log("studyMap1", studyMap1);
                if(studyMap1) {
                    const AR_studyMap1 = document.getElementById("AR_studyMap1") as HTMLSelectElement;
                    if(AR_studyMap1) {
                        AR_studyMap1.value = String(studyMap1.id);
                    }        
                }
            }
        }
    }, [studyMapsList])
    
    async function init(id: number) {

        props.showLoading(true);
        const result = await util.getAITutorInfo(id);
        logger.log("init", result);
        props.showLoading(false);

        if(result) {
            
            const selectedItem  = result as IAITutor;

            // (document.getElementById('AR_title') as HTMLInputElement).value = selectedItem.name;
    
            (document.getElementById('AR_rd-on') as HTMLInputElement).checked = selectedItem.is_active;
            (document.getElementById('AR_rd-off') as HTMLInputElement).checked = !selectedItem.is_active;    
            (document.getElementById('AR_rd-public-on') as HTMLInputElement).checked = selectedItem.is_publicOpen;
            (document.getElementById('AR_rd-public-off') as HTMLInputElement).checked = !selectedItem.is_publicOpen;    
            // (document.getElementById('AR_description') as HTMLSelectElement).value = selectedItem.description;
    
            const AR_theme = document.getElementById("AR_theme") as HTMLInputElement;
            if(AR_theme) {
                AR_theme.value = selectedItem.theme;
            }
            const AR_writing = document.getElementById("AR_writing") as HTMLInputElement;
            if(AR_writing) {
                AR_writing.value = selectedItem.writing;
            }
    
            const _missionList = JSON.parse(selectedItem.mission);
            setMissionList(_missionList);

            const metaMap = selectedItem.metaMap;
            if(metaMap && metaMap.length > 0) {
                const curriSchool = metaMap.find((item) => item.name === "curriSchool");
                if(curriSchool) {
                    const AR_school = document.getElementById("AR_school") as HTMLSelectElement;
                    AR_school.value = curriSchool.val;
                }
                const curriSubject = metaMap.find((item) => item.name === "curriSubject");
                if(curriSubject) {
                    const AR_subject = document.getElementById("AR_subject") as HTMLSelectElement;
                    AR_subject.value = curriSubject.val;
                }
            }
            
            const curriBook = curriInfo?.curriBook.find((meta, _) => meta.id === selectedItem.curriBook);
    
            if(curriInfo && curriBook) {
    
                const _bookDescendantsList = await getDescendantsList(curriBook.code);

                if(_bookDescendantsList && _bookDescendantsList.length > 0) {

                    (document.getElementById("AR_textbook") as HTMLSelectElement).value = curriBook.val;
    
                    const curriUnit1s = _bookDescendantsList.filter((item) => item.parent_id === curriBook.id);
                    setCurriUnit1(curriUnit1s);
        
                    setTimeout(() => {
                        const curriUnit1 = curriUnit1s.find((meta, _) => meta.id === selectedItem.curriUnit1);
                        if(curriUnit1) {
                            (document.getElementById("AR_curriUnit1") as HTMLSelectElement).value = String(curriUnit1.id);
            
                            const curriUnit2s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit1.id);
                            setCurriUnit2(curriUnit2s);
                            setTimeout(() => {
                                const curriUnit2 = curriUnit2s.find((meta, _) => meta.id === selectedItem.curriUnit2);
                                if(curriUnit2) {
                                    (document.getElementById("AR_curriUnit2") as HTMLSelectElement).value = String(curriUnit2.id);
            
                                    const curriUnit3s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit2.id);
                                    setCurriUnit3(curriUnit3s);
                                    setTimeout(() => {
                                        const curriUnit3 = curriUnit3s.find((meta, _) => meta.id === selectedItem.curriUnit3);
                                        if(curriUnit3) {
                                            (document.getElementById("AR_curriUnit3") as HTMLSelectElement).value = String(curriUnit3.id);
            
                                            const curriUnit4s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit3.id);
                                            setCurriUnit4(curriUnit4s);
                                            setTimeout(() => {
                                                const curriUnit4 = curriUnit4s.find((meta, _) => meta.id === selectedItem.curriUnit4);
                                                if(curriUnit4) {
                                                    (document.getElementById("AR_curriUnit4") as HTMLSelectElement).value = String(curriUnit4.id);
            
                                                    const curriUnit5s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit4.id);
                                                    setCurriUnit5(curriUnit5s);
                                                    setTimeout(() => {
                                                        const curriUnit5 = curriUnit5s.find((meta, _) => meta.id === selectedItem.curriUnit5);
                                                        if(curriUnit5) {
                                                            (document.getElementById("AR_curriUnit5") as HTMLSelectElement).value = String(curriUnit5.id);
                                                        }                            
                                                    }, 10);
                                                }                            
                                            }, 10);
                                        }                            
                                    }, 10);    
                                }                            
                            }, 10);        
                        }                            
                    }, 10);        
                }    
            }            
        }        
    }

    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {

            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        
        const curriBook = curriInfo?.curriBook.find((meta, _) => meta.val === e.target.value);
        if(curriBook) {
            getDescendantsList(curriBook.code);

            const AR_html_curriUnit1 = document.getElementById("AR_curriUnit1") as HTMLSelectElement;
            const AR_html_curriUnit2 = document.getElementById("AR_curriUnit2") as HTMLSelectElement;
            const AR_html_curriUnit3 = document.getElementById("AR_curriUnit3") as HTMLSelectElement;
            const AR_html_curriUnit4 = document.getElementById("AR_curriUnit4") as HTMLSelectElement;
            const AR_html_curriUnit5 = document.getElementById("AR_curriUnit5") as HTMLSelectElement;
        
            AR_html_curriUnit1.selectedIndex = 0;
            AR_html_curriUnit2.selectedIndex = 0;
            AR_html_curriUnit3.selectedIndex = 0;
            AR_html_curriUnit4.selectedIndex = 0;
            if(AR_html_curriUnit5) {
                AR_html_curriUnit5.selectedIndex = 0;
            }
    
            setCurriUnit2([]);
            setCurriUnit3([]);
            setCurriUnit4([]);
            setCurriUnit5([]);
        } else {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const AR_html_curriUnit2 = document.getElementById("AR_curriUnit2") as HTMLSelectElement;
        const AR_html_curriUnit3 = document.getElementById("AR_curriUnit3") as HTMLSelectElement;
        const AR_html_curriUnit4 = document.getElementById("AR_curriUnit4") as HTMLSelectElement;
        const AR_html_curriUnit5 = document.getElementById("AR_curriUnit5") as HTMLSelectElement;
    
        AR_html_curriUnit2.selectedIndex = 0;
        AR_html_curriUnit3.selectedIndex = 0;
        AR_html_curriUnit4.selectedIndex = 0;
        if(AR_html_curriUnit5) {
            AR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const AR_html_curriUnit3 = document.getElementById("AR_curriUnit3") as HTMLSelectElement;
        const AR_html_curriUnit4 = document.getElementById("AR_curriUnit4") as HTMLSelectElement;
        const AR_html_curriUnit5 = document.getElementById("AR_curriUnit5") as HTMLSelectElement;
    
        AR_html_curriUnit3.selectedIndex = 0;
        AR_html_curriUnit4.selectedIndex = 0;
        if(AR_html_curriUnit5) {
            AR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const AR_html_curriUnit4 = document.getElementById("AR_curriUnit4") as HTMLSelectElement;
        const AR_html_curriUnit5 = document.getElementById("AR_curriUnit5") as HTMLSelectElement;
    
        AR_html_curriUnit4.selectedIndex = 0;
        if(AR_html_curriUnit5) {
            AR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const AR_html_curriUnit5 = document.getElementById("AR_curriUnit5") as HTMLSelectElement;    
        if(AR_html_curriUnit5) {
            AR_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function OnClose(): void {
        props.OnClose();
    }

    function onSave() {

        if(!curriInfo) {
            return;
        }

        // const AR_title = document.getElementById("AR_title") as HTMLInputElement;
        // const AR_title_val = AR_title.value;
        // if(AR_title_val === "") {
        //     AR_title.focus();
        //     props.showModal("", "타이틀을 입력해 주세요.");
        //     return;
        // }

        const AR_school = document.getElementById("AR_school") as HTMLSelectElement;
        const AR_school_val = curriInfo.curriSchool[AR_school.selectedIndex];
        
        const AR_subject = document.getElementById("AR_subject") as HTMLSelectElement;
        const AR_subject_val = curriInfo.curriSubject[AR_subject.selectedIndex];
        
        const AR_textbook = document.getElementById("AR_textbook") as HTMLSelectElement;
        if(AR_textbook.value === "0") {
            props.showModal("", "교과를 입력해 주세요.");
            return;
        }
        const AR_textbook_val = curriInfo.curriBook[AR_textbook.selectedIndex-1];
            
        // const AR_description = document.getElementById("AR_description") as HTMLInputElement;
        // const AR_description_val = AR_description.value;

        const AR_html_curriUnit1 = document.getElementById("AR_curriUnit1") as HTMLSelectElement;
        // if(AR_html_curriUnit1.value === "0") {
        //     props.showModal("", "교과 표준체계 Depth1을 입력해 주세요.");
        //     return;
        // }

        const AR_html_curriUnit2 = document.getElementById("AR_curriUnit2") as HTMLSelectElement;
        // if(AR_html_curriUnit2.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const AR_html_curriUnit3 = document.getElementById("AR_curriUnit3") as HTMLSelectElement;
        // if(AR_html_curriUnit3.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const AR_html_curriUnit4 = document.getElementById("AR_curriUnit4") as HTMLSelectElement;
        // if(AR_html_curriUnit4.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const AR_html_curriUnit5 = document.getElementById("AR_curriUnit5") as HTMLSelectElement;
        // if(AR_html_curriUnit5 && AR_html_curriUnit5.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
    
        const AR_html_curriUnit1_val = AR_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[AR_html_curriUnit1.selectedIndex-1] as IMeta;
        const AR_html_curriUnit2_val = AR_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[AR_html_curriUnit2.selectedIndex-1] as IMeta;
        const AR_html_curriUnit3_val = AR_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[AR_html_curriUnit3.selectedIndex-1] as IMeta;
        const AR_html_curriUnit4_val = AR_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[AR_html_curriUnit4.selectedIndex-1] as IMeta;
        const AR_html_curriUnit5_val = AR_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[AR_html_curriUnit5.selectedIndex-1] as IMeta;
    
        const AR_studyMap0 = document.getElementById("AR_studyMap0") as HTMLSelectElement;
        if(AR_studyMap0.value === "") {
            props.showModal("", "학습맵을 입력해 주세요.");
            return;
        }
        const AR_studyMap0_val = studyMap0List[AR_studyMap0.selectedIndex-1];
        const AR_studyMap1 = document.getElementById("AR_studyMap1") as HTMLSelectElement;
        const AR_studyMap1_val = AR_studyMap1.selectedIndex === 0 ? undefined : studyMap1List[AR_studyMap1.selectedIndex-1] as IMeta;

        const AR_rd_on = document.getElementById('AR_rd-on') as HTMLInputElement;    
        const AR_rd_on_val = AR_rd_on.checked

        const AR_rd_public_on = document.getElementById('AR_rd-public-on') as HTMLInputElement;    
        const AR_rd_public_on_val = AR_rd_public_on.checked

        const AR_theme = document.getElementById("AR_theme") as HTMLInputElement;
        const AR_writing = document.getElementById("AR_writing") as HTMLInputElement;

        const brandId = props.brand.id;
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");

        const listMetaMap : IMeta[] = [];

        listMetaMap.push(AR_school_val);
        listMetaMap.push(AR_subject_val);

        if(AR_studyMap0_val) {
            listMetaMap.push(AR_studyMap0_val);
        }
        if(AR_studyMap1_val) {
            listMetaMap.push(AR_studyMap1_val);
        }

        const _missionList = onUI2Mission();
        
        const data = {
            "id": props.selectedItem ? props.selectedItem.id : undefined,
            "brand_id": brandId,

            // "name": AR_title_val, 
            "type": "작문",
            "is_active": AR_rd_on_val, 
            "is_publicOpen": AR_rd_public_on_val, 
            "creator": props.selectedItem ? undefined : uid,
            "creator_name": props.selectedItem ? undefined : uname,
            "creator_id": props.selectedItem ? undefined : _id,
            "updater": props.selectedItem ? uid : undefined,
            "updater_name": props.selectedItem ? uname : undefined,
            "updater_id": props.selectedItem ? _id : undefined,

            "curriBook": AR_textbook_val?.id,
            "curriUnit1": AR_html_curriUnit1_val ? AR_html_curriUnit1_val.id : undefined,
            "curriUnit2": AR_html_curriUnit2_val ? AR_html_curriUnit2_val.id : undefined,
            "curriUnit3": AR_html_curriUnit3_val ? AR_html_curriUnit3_val.id : undefined,
            "curriUnit4": AR_html_curriUnit4_val ? AR_html_curriUnit4_val.id : undefined,
            "curriUnit5": AR_html_curriUnit5_val ? AR_html_curriUnit5_val.id : undefined,

            "studyMap": AR_studyMap0_val ? AR_studyMap0_val.id : undefined,
            "studyMap1": AR_studyMap1_val ? AR_studyMap1_val.id : undefined,
            "metaMap": listMetaMap, 

            "theme": AR_theme ? AR_theme.value.trim() : undefined,
            "writing": AR_writing ? AR_writing.value.trim() : undefined,
            "mission": _missionList && _missionList.length > 0 ? JSON.stringify(_missionList) : undefined,

        };

        logger.log("onSave", data);
    
        _onSave(data, props.selectedItem ? "update" : "insert");
    }

    async function _onSave(data: any, type: string) {

        props.showLoading(true);

        const result = await util.aiTutorForSave(data, type);
        logger.log("_onSave", result);

        props.showLoading(false);

        props.showModal("", "저장이 완료 되었습니다.");

        props.OnClose();         
    }

    function onUI2Mission() : IMission[] {

        const _missionList : IMission[] = [];
        for (let i = 0; i < missionList.length; i++) {

            const AR_mission_ = document.getElementById("AR_mission_"+i) as HTMLInputElement;
            const AR_mission_image_ = document.getElementById("AR_mission_image_"+i) as HTMLInputElement;
            const AR_mission_image_use_on_ = document.getElementById("AR_mission_image_use_on_"+i) as HTMLInputElement;

            const mission : IMission = {
                val: AR_mission_.value,
                image: AR_mission_image_.value,
                imageUse: AR_mission_image_use_on_.checked,
            }
    
            _missionList.push(mission);            
        }

        // logger.log("onUI2Mission", _missionList);

        setMissionList(_missionList);

        return _missionList;
    }

    function onMission2UI() {

        logger.log("onMission2UI", missionList);

        for (let i = 0; i < missionList.length; i++) {
            const mission = missionList[i];

            const AR_mission_ = document.getElementById("AR_mission_"+i) as HTMLInputElement;
            if(AR_mission_) {
                AR_mission_.value = mission.val !== undefined ? mission.val : String(mission) ? String(mission) : "";
            }
            const AR_mission_image_ = document.getElementById("AR_mission_image_"+i) as HTMLInputElement;
            if(AR_mission_image_) {
                AR_mission_image_.value = mission.image ? mission.image : "";
            }
            const AR_mission_image_use_on_ = document.getElementById("AR_mission_image_use_on_"+i) as HTMLInputElement;
            if(AR_mission_image_use_on_) {
                AR_mission_image_use_on_.checked = mission.imageUse;
            }
            const AR_mission_image_use_off_ = document.getElementById("AR_mission_image_use_off_"+i) as HTMLInputElement;
            if(AR_mission_image_use_off_) {
                AR_mission_image_use_off_.checked = !mission.imageUse;
            }
            const AR_mission_image_btn_ = document.getElementById("AR_mission_image_btn_"+i) as HTMLButtonElement;
            if(AR_mission_image_btn_) {
                AR_mission_image_btn_.disabled = !mission.imageUse;
            }
        }
    }

    function onAddMission(idx: number) {

        if(missionList.length > 4) {
            return;
        }

        onUI2Mission();

        let selectedId = idx;
        // logger.log("selectedId", selectedId);

        const _missionList = onUI2Mission();

        if(_missionList.length === 0) {
            const mission : IMission = {
                val: "",
                image: "",
                imageUse: false,
            }
            setMissionList([mission]);
        } else {
            const newList = [];
            for (let i = 0; i < selectedId+1; i++) {
                newList.push(_missionList[i]);                
            }
            const mission : IMission = {
                val: "",
                image: "",
                imageUse: false,
            }
            newList.push(mission);
            for (let i = selectedId+1; i < _missionList.length; i++) {
                newList.push(_missionList[i]);                
            }
            setMissionList(newList);
            // logger.log("onAddMission", newList);
        }        
    }

    function onDelMission(idx: number) {

        const _missionList = onUI2Mission();
        // logger.log("onDelMission", _missionList);

        const newList : IMission[] = [];
        if(_missionList.length > 1) {

            _missionList.map((item, i) => {
                if(idx !== i) {
                    newList.push(item);
                }
            });

            // logger.log("onDelMission", newList);

            setMissionList(newList);    
        }
    }

    async function getStudyMapList() {

        props.showLoading(true);

        const listAll = await util.getMetaList("1", "studyMap");
        const _studyMap = listAll.sort((a, b) => a.id! < b.id! ? 1 : -1);	

        setStudyMap0List(_studyMap);

        logger.log("getStudyMapList", listAll);

        props.showLoading(false);
    }

    async function getStudyMapDescendantsList(code: string) {

        props.showLoading(true);

        const list = await util.getDescendantsList(code);

        const _studyMap = list.find((item) => item.code === code);
        if(!_studyMap) {
            return;
        }
        setStudyMapsList(list);

        const _filtered_1 = list.filter((item) => item.name === "studyMap1");
        setStudyMap1List(_filtered_1);

        let max_depth = 0;
        if(_studyMap.max_depth) {
            max_depth = _studyMap.max_depth;
        } else {
            list.map((item) => {
                if(max_depth < item.depth) {
                    max_depth = item.depth;
                }
            });
            max_depth = max_depth - 1;
        }
        setStudyMapDepth(max_depth);        

        logger.log("getStudyMapDescendantsList", list, max_depth);

        props.showLoading(false);
    }

    async function onChangeStudyMap(e: ChangeEvent<HTMLSelectElement>) {
        if(e.target.selectedIndex > 0) {
            const _studyMap = studyMap0List[e.target.selectedIndex-1];
            logger.log("onChangeStudyMap", _studyMap);
    
            getStudyMapDescendantsList(_studyMap.code);    
        } else {
            setStudyMap1List([]);
        }

        setStudyMap2List([]);
        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
        setStudyMap6List([]);

        const AR_studyMap1 = document.getElementById("AR_studyMap1") as HTMLSelectElement;
        if(AR_studyMap1) {
            AR_studyMap1.selectedIndex = 0;
        }
    }

    async function onChangeStudyMap1(e: ChangeEvent<HTMLSelectElement>) {
        // const _filtered_2 = (studyMapsList as IMeta[]).filter((item) => item.depth === 3);
        // logger.log("onChangeStudyMap1", _filtered_2);

        // const result = _filtered_2.filter((item) => item.parent_id === Number(e.target.value))
        // setStudyMap2List(result);
        // logger.log("onChangeStudyMap1", e.target.value, result);

        // setStudyMap3List([]);
        // setStudyMap4List([]);
        // setStudyMap5List([]);
        // setStudyMap6List([]);
    }

    function onSearchMissionImage(idx: number) {
        setSelectedItemIndex(idx);
        setViewSearchObject(true);
    }

    function onSelectObject(item: any) {

        const media = item as ILibrary;

        const AR_mission_image_ = document.getElementById("AR_mission_image_"+selectedItemIndex) as HTMLInputElement;
        AR_mission_image_.value = String(media ? media.url : "");

        setViewSearchObject(false);
    }

    return (
        <div>

            <div>

                <h4>기본 정보 등록</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">브랜드</th>
							<td className="text-left">{props.brand.name}</td>
						</tr>
						<tr>
							<th scope="row" className="w-56"><label htmlFor="sel-divi">구분<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">작문</td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">KEY</th>
							<td className="text-left">{props.selectedItem ? props.selectedItem.id : ""}</td>
						</tr>
                        {/* <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">타이틀<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="AR_title" className="w-1/2" onChange={() => {}}/>
                            </td>
						</tr> */}
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-class">학교 급<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="AR_school" name="sel-school">
                                    {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-subject">교과목<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="AR_subject" name="sel-subject">
                                    {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-textbook">교과<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="AR_textbook" name="sel-textbook" onChange={(e) => onChangeCurriBook(e)}>
                                    <option value="0">교과</option>              
                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr style={{display:""}}>
							<th scope="row" className="w-56"><label htmlFor="sel-unit">교과과정<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">

                                <select className="mr-2" id="AR_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="AR_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                    <option value="0">2Depth</option>              
                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="AR_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                    <option value="0">3Depth</option>              
                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="AR_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                    {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                    <option value="0">4Depth</option>              
                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="AR_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                    <option value="0">5Depth</option>              
                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>      

                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-studymap">학습맵<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="AR_studyMap0" name="sel-studyMap0" onChange={(e) => onChangeStudyMap(e)}>
                                    <option value="">학습맵</option>              
                                    {studyMap0List && (studyMap0List as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                                <select className="ml-2 mr-2" id="AR_studyMap1" name="studyMap1" onChange={(e) => onChangeStudyMap1(e)} style={{display:studyMap1List && (studyMap1List as IMeta[]).length > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {studyMap1List && (studyMap1List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">작성자</th>
                            {props.selectedItem &&
                            <td className="text-left">{props.selectedItem.creator_name} ({props.selectedItem.creator})</td>}
							{!props.selectedItem &&
                            <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
						</tr>
                        <tr>
							<th scope="row" className="w-56">등록일</th>
                            {props.selectedItem &&
							<td className="text-left">{props.selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
							{!props.selectedItem &&
							<td className="text-left">{util.getDate()}</td>}
                            </tr>
                        <tr>
							<th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="AR_rd-on" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-on">사용</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="AR_rd-off" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-off">미사용</label>
                                    </span>
                                </div>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">공개 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="AR_rd-public-on" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-on">공개</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="AR_rd-public-off" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-off">비공개</label>
                                    </span>
                                </div>
                            </td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
                <h4>데이터 구성</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>

                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">테마</label></th>
							<td className="text-left">
                                <input type="text" id="AR_theme" className="w-1/2" onChange={() => {}}/>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">교과서 쓰기 활동​​</label></th>
							<td className="text-left">
                                <input type="text" id="AR_writing" className="w-1/2" onChange={() => {}}/>
                            </td>
						</tr>

                        {missionList && missionList.map((item, idx) => {
                        return(
                        <tr>
							<th scope="row" className="w-56">
                                <label htmlFor="inp-title">{"미션"+(idx+1)}</label>
                                <div className="mt-2">
                                    <button type="button" className="btn-sky-s" onClick={() => onAddMission(idx)}>추가</button>
                                    {missionList.length > 1 &&
                                    <button type="button" className="btn-sky-s ml-2" onClick={() => onDelMission(idx)}>삭제</button>}
                                </div>
                            </th>
							<td className="text-left" style={{padding:0}}>
                                <div key={idx} className="">
                                    <table className="border-none">
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} className="w-avail">
                                                    <input type="text" id={"AR_mission_"+idx} className="w-avail" defaultValue={item.val} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-36 text-center">이미지</th>
                                                <td className="text-left w-avail">
                                                    <div className="w-avail" >
                                                        <input type="text" id={"AR_mission_image_"+idx} className="w-[85%]" readOnly />
                                                        <button type="button" id={"AR_mission_image_btn_"+idx} className="btn-sky-s ml-2 w-8" onClick={() => onSearchMissionImage(idx)}>검색</button>
                                                    </div>
                                                </td>
                                                <th className="w-36 text-center">이미지 사용 여부​</th>
                                                <td className="text-left w-36">
                                                    <div className="radio-wrap">
                                                        <span className="inp-radio">
                                                            <input id={"AR_mission_image_use_on_"+idx} name={"AR_mission_image_use_"+idx} type="radio" onChange={(e) => {
                                                                const AR_mission_image_btn_ = document.getElementById("AR_mission_image_btn_"+idx) as HTMLButtonElement;
                                                                if(AR_mission_image_btn_) {
                                                                    AR_mission_image_btn_.disabled = !e.target.checked;
                                                                }
                                                            }}/>
                                                            <label htmlFor="rd-on">사용</label>
                                                        </span>
                                                        <span className="inp-radio">
                                                            <input id={"AR_mission_image_use_off_"+idx} name={"AR_mission_image_use_"+idx} type="radio" onChange={(e) => {
                                                                const AR_mission_image_btn_ = document.getElementById("AR_mission_image_btn_"+idx) as HTMLButtonElement;
                                                                if(AR_mission_image_btn_) {
                                                                    AR_mission_image_btn_.disabled = e.target.checked;
                                                                }
                                                                }}/>
                                                            <label htmlFor="rd-off">미사용</label>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </td>
						</tr>
                        )})}

					</tbody>
				</table>
				{/* <!-- .//table -->	 */}

				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
						<button type="button" className="btn-sky" onClick={onSave} >{props.selectedItem ? "수정" : "저장"}</button>
					</div>
				</div>

            </div>

            {viewSearchObject &&
            <PopupLibrarySearch brand={props.brand} from={"AITutorWritingRegister"} searchType={"image"} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectObject} onClose={function (): void {
                setViewSearchObject(false);
            } } />}

        </div>
    );
}
export default AITutorWritingRegister;
