import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, IUser } from "../common";

interface IPopupConfirm {
    view: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onOk: () => void;
    showModal: (title: string, description: string) => void;    
}

function PopupConfirm(props: IPopupConfirm) {

    const curriInfo = util.getCurrentCurriInfo();

    useEffect(() => {
        if(props.view) {
        }
    }, [props.view])

    function onSave(): void {
        props.onClose();
    }

    function onOk(): void {
        props.onOk();
    }

    function onClose(): void {
        props.onClose();
    }

    return (
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : props.view ? "" : "none"}}>
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box max-w-xl min-w-[576px]">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">{props.title}</h2>
                            <div className="px-5">
                                <div className="py-8 text-center">
                                    <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
                                </div>
                            </div>
                            <div className="my-5 text-center">
                            <button type="button" className="btn-white mr-2" onClick={onClose}>취소</button>
                            <button type="button" className="btn-sky" onClick={onOk}>확인</button>
                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>        
    );
}
  
export default PopupConfirm;
