import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, IMenu, leftmenu_2, IMeta, ICurriInfo } from "../common";
import { logger } from '../logger';
import TeachingList from "./TeachingList";
import TeachingContentsList from "./TeachingContentsList";
import TeachingContentsBookList from "./TeachingContentsBookList";

interface ITeachingManager {
    view: boolean;
    menu: IMenu;
    brand: IBrand;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function TeachingManager(props: ITeachingManager) {

    const [title, setTitle] = useState("");

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu");
            changeTitle();
        }
    }, [props.menu])

    const changeTitle = () => {
        const _title = leftmenu_2[currMenu.menu1][currMenu.menu2];
        setTitle(_title);
    }

    return (
    <div>
        {currMenu.menu2 === 0 && 
        <TeachingList showModal={props.showModal} showLoading={props.showLoading} brand={props.brand} menu={props.menu} />}
        {currMenu.menu2 === 1 && 
        <TeachingContentsBookList showModal={props.showModal} showLoading={props.showLoading} brand={props.brand} menu={props.menu} />}
        {currMenu.menu2 === 2 && 
        <TeachingContentsList showModal={props.showModal} showLoading={props.showLoading} brand={props.brand} menu={props.menu} />}
    </div>
    );
}
export default TeachingManager;
