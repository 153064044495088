import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, ITextBook, ITextbookBasicData, IUser } from "../common";
import { logger } from "../logger";
import { NodeModel } from "@minoru/react-dnd-treeview";

interface IPopupTextBookEdit {
    selectedItem: ITextBook;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
    onChangeBasicData: (data: ITextBook) => void;
}

function PopupTextBookEdit(props: IPopupTextBookEdit) {

    const curriInfo = util.getCurrentCurriInfo();
    const selectedItem = props.selectedItem;
    const lastUpdateDate = selectedItem.updatedate ? selectedItem.updatedate : selectedItem.regdate;
    const [imgFile, setImgFile] = useState(null as any);

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            if(selectedItem) {
                init(selectedItem.id!);
            } else {
                onClose();
            }
        }
        bDone = true;
    }, [])

    async function init(id: number) {

        const result = await util.getTextBookInfo(id);
        logger.log("getTextBookInfo", result);
        
        if(result) {

            const textbook = result as ITextBook;

            (document.getElementById('TE_title') as HTMLInputElement).value = textbook.name;
            (document.getElementById('TE_curriBook') as HTMLSelectElement).value = String(textbook.curriBook);
            (document.getElementById('TE_textbookCategory') as HTMLSelectElement).value = String(textbook.textbookCategory);
            (document.getElementById('TE_lang') as HTMLSelectElement).value = String(textbook.language);
            (document.getElementById('TE_auth_all') as HTMLInputElement).checked = textbook.accessLevel === 12;
            (document.getElementById('TE_auth_T') as HTMLInputElement).checked = textbook.accessLevel === 1;
            (document.getElementById('TE_auth_S') as HTMLInputElement).checked = textbook.accessLevel === 2;
            (document.getElementById('TE_pdf') as HTMLInputElement).checked = textbook.supportPdf;
            (document.getElementById('TE_numofpages') as HTMLInputElement).value = String(textbook.maxPages ? textbook.maxPages : "");
            const imgUrl = document.getElementById('imgUrl') as HTMLElement;
            if(imgUrl) {
                imgUrl.innerHTML = textbook.image_url && textbook.image_url.length > 5 ? textbook.image_url : "파일 없슴";
            }    

            const TE_publish = (document.getElementById("TE_publish") as HTMLInputElement);
            TE_publish.checked = textbook.is_publish;
            const TE_non_publish = (document.getElementById("TE_non_publish") as HTMLInputElement);
            TE_non_publish.checked = !textbook.is_publish;
        }
    }

    function onClose() {
        props.onClose();
    }

    async function onSave() {

        const brand = util.getCurrentBrand();
        if(!brand || ! brand.id) {
            return;
        }

        if(!curriInfo) {
            return;
        }

        const TE_title = document.getElementById("TE_title") as HTMLInputElement;
        if(TE_title.value === "") {
            props.showModal("", "교과서 제목을 입력해 주세요.");
            TE_title.focus();
            return;
        }

        const TE_lang = document.getElementById("TE_lang") as HTMLSelectElement;
        // const TE_childBook = document.getElementById("TE_childBook") as HTMLSelectElement;

        // const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
        // const TE_curriYear_val = curriInfo.curriYear[TE_curriYear.selectedIndex];
        const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
        const TE_curriBook_val = curriInfo.curriBook[TE_curriBook.selectedIndex];
        const TE_textbookCategory = document.getElementById("TE_textbookCategory") as HTMLSelectElement;
        const TE_textbookCategory_val = curriInfo.textbookCategory[TE_textbookCategory.selectedIndex];
        
        // const TE_public = (document.getElementById("TE_public") as HTMLInputElement);
        const TE_pdf = (document.getElementById("TE_pdf") as HTMLInputElement);

        const TE_auth_all = (document.getElementById("TE_auth_all") as HTMLInputElement);
        const TE_auth_T = (document.getElementById("TE_auth_T") as HTMLInputElement);
        // const TE_auth_S = (document.getElementById("TE_auth_S") as HTMLInputElement);

        const TE_numofpages = document.getElementById("TE_numofpages") as HTMLInputElement;
        
        const TE_publish = (document.getElementById("TE_publish") as HTMLInputElement);
        const TE_non_publish = (document.getElementById("TE_non_publish") as HTMLInputElement);

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        props.showLoading(true);

        const filepath = "upload/"+brand.id+"/textbook/";
        let filename = "";

        if(imgFile) {

            logger.log("onFileUploadForSave started!!!");      
    
            filename = filepath+imgFile.name;

            const bUpload = await util.S3Upload(imgFile, filepath);      
            logger.log("onFileUploadForSave S3Upload", imgFile, bUpload);       
            if(!bUpload) {
                logger.log("onFileUploadForSave failed!!!");
                props.showLoading(false);
                return;
            }
            logger.log("onFileUploadForSave finished!!!");      
        }

        const data = {
            id: selectedItem.id,
            textbookIndex_id: selectedItem.textbookIndex_id!,
            type: "web",
            brand_id: brand.id,
            name: TE_title.value,
            // subject: subject,
            language: TE_lang.value,
            // is_publicOpen: TE_public.checked,
            supportPdf: TE_pdf.checked,
            accessLevel: TE_auth_all.checked ? 12 : TE_auth_T.checked ? 1 : 2,
            // child: Number(TE_childBook.value),
            textbookCategory: Number(TE_textbookCategory_val.id),
            // curriYear: Number(TE_curriYear_val.id),
            curriBook: Number(TE_curriBook_val.id),

            maxPages: TE_numofpages ? TE_numofpages.value : undefined,

            updater: uid,
            updater_id: _id,
            updater_name: uname,
        
            image_url: imgFile ? filename : undefined,

            is_publish: TE_publish.checked,
        };

        const result = await util.textbookForSave(data, "update");

        logger.log("onSave", data, result);   

        props.showLoading(false);

        if(result) {

            selectedItem.name = data.name;
            selectedItem.language = data.language;
            selectedItem.supportPdf = data.supportPdf;
            selectedItem.accessLevel = data.accessLevel;
            selectedItem.textbookCategory = data.textbookCategory;
            selectedItem.curriBook = data.curriBook;
            selectedItem.maxPages = data.maxPages ? data.maxPages : "";
            selectedItem.image_url = data.image_url ? data.image_url : "";

            props.onChangeBasicData(selectedItem);
        }    
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        // logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const file = e.target.files![0];

        setImgFile(file);
        logger.log("handleChange", file);
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            {selectedItem &&
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box px-8 py-8">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">교과서 설정</h2>
                            <div className="">
                                <div className="px-5">
                                    <div className="py-8 border-b border-slate-600 text-center">

                                        <table>
                                            <caption className="sr-only">교과서 설정</caption>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="w-56">교과서 버전</th>
                                                    <td className="text-left" colSpan={2}>{selectedItem.version} <span className="text-red ml-5">{lastUpdateDate?.substring(0, 10).replaceAll("-", ".")} 최종 수정</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56"><label htmlFor="inp-title">교과서 제목</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        <input type="text" id="TE_title" className="w-full" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56"><label htmlFor="sel-process">교과과정</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        <select className="" id="TE_curriBook" name="search-3">
                                                            {curriInfo && curriInfo?.curriBook && (curriInfo?.curriBook as IMeta[]).map((item, idx) => {
                                                                return(
                                                                    <option key={idx} value={item.id}>{item.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56"><label htmlFor="sel-type">교과서 유형</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        <select id="TE_textbookCategory" name="sel-type">
                                                            {curriInfo && curriInfo?.textbookCategory && curriInfo && curriInfo?.textbookCategory.map((item, idx) => {
                                                                return(
                                                                    <option key={idx} value={item.id}>{item.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56"><label htmlFor="sel-lang">표준언어</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        <select id="TE_lang" name="sel-lang">
                                                            <option value={"한국어"}>한국어</option>
                                                            <option value={"영어"}>영어</option>
                                                            <option value={"중국어"}>중국어</option>
                                                            <option value={"베트남어"}>베트남어</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                
                                                <tr style={{display:""}}>
                                                    <th scope="row" className="w-56"><label htmlFor="sel-lang">목차 상단 이미지</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        <span className={"w-avail"} id="imgUrl" ></span>
                                                        <input className={"ml-3"} name="file" type="file" onChange={(e) => handleChange(e)} />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row" className="w-56">접근권한</th>
                                                    <td className="text-left" colSpan={2}>
                                                        <div className="radio-wrap">
                                                            <span className="inp-radio">
                                                                <input id="TE_auth_all" name="rd-access" type="radio" />
                                                                <label htmlFor="rd-access1">전체</label>
                                                            </span>
                                                            <span className="inp-radio">
                                                                <input id="TE_auth_T" name="rd-access" type="radio" />
                                                                <label htmlFor="rd-access2">교수자</label>
                                                            </span>
                                                            <span className="inp-radio">
                                                                <input id="TE_auth_S" name="rd-access" type="radio" />
                                                                <label htmlFor="rd-access3">학생</label>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56">PDF교과서 형식 지원</th>
                                                    <td className="text-left w-56" colSpan={2}>
                                                        <div className="radio-wrap">
                                                            <span className="inp-radio">
                                                                <input id="TE_pdf" name="rd-pdf" type="radio" />
                                                                <label htmlFor="rd-pdf1">사용가능</label>
                                                            </span>
                                                            <span className="inp-radio">
                                                                <input id="TE_non_pdf" name="rd-pdf" type="radio" />
                                                                <label htmlFor="rd-pdf2">사용불가</label>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    {/* <td className="text-left w-avail" style={{borderLeftWidth:0}}>
                                                        <span className="mr-2">URL</span>
                                                        <input className="w-[600px]" type="text" id="pdfUrl" placeholder="연계되는 PDF교과서의 링크를 입력 해 주세요​." />
                                                    </td> */}
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56">전체 페이지수​</th>
                                                    <td className="text-left w-avail" colSpan={2}>
                                                        <input type="text" id="TE_numofpages" className="w-96" placeholder="해당 교과서의 마지막 페이지 수를 입력해주세요." />
                                                    </td>
                                                    {/* <td className="text-left w-avail" style={{borderLeftWidth:0}}>
                                                        <span className="mr-2">URL</span>
                                                        <input className="w-[600px]" type="text" id="pdfUrl" placeholder="연계되는 PDF교과서의 링크를 입력 해 주세요​." />
                                                    </td> */}
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56">출원여부​</th>
                                                    <td className="text-left w-56" colSpan={2}>
                                                        <div className="radio-wrap">
                                                            <span className="inp-radio">
                                                                <input id="TE_publish" name="rd-publish" type="radio" />
                                                                <label htmlFor="rd-pdf1">출원 교과서</label>
                                                            </span>
                                                            <span className="inp-radio">
                                                                <input id="TE_non_publish" name="rd-publish" type="radio" />
                                                                <label htmlFor="rd-pdf2">미출원 교과서</label>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        {/* <!-- .//table -->	 */}

                                    </div>
                                </div>
                                <div className="my-5 text-center">
                                    <button type="button" className="btn-sky mr-3" onClick={onSave}>저장</button>
                                    <button type="button" className="btn-white" onClick={onClose}>닫기</button>
                                </div>
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>}
        </div>        
    );
}
  
export default PopupTextBookEdit;
