import { ChangeEvent, useEffect, useState } from "react";
import * as util from '../util';
import { IBrand, ILibrarySearchData, IMeta, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { DatePicker } from '@gsebdev/react-simple-datepicker';
import { logger } from '../logger';
import '../common.scss';
import './LibrarySearch.scss';

interface IType2 {
    type1: string;
    value: string[];
}

interface IObjectSearch {
    searchType: string;
    // textType: string;
	onSearch: (data: any) => void;
    showLoading: (loading: boolean) => void;    
}

export interface IPageInfo {
    page: number;
    pageItemCnt: number;
}

function ObjectSearch(props: IObjectSearch) {

    const [type, setType] = useState("");
    const [newType1List, setNewType1List] = useState([] as any);
    const [newType1ListValue, setNewType1ListValue] = useState([] as any);
    const [type2List, setType2List] = useState([] as any);
    const [type3TextList, setType3TextList] = useState([] as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);

    const [curriBook, setCurriBook] = useState(null as any);

    let bDone = false;
    useEffect(() => {

        if(!bDone) {

            logger.log("props.searchType", props.searchType);

            const curriInfo = util.getCurrentCurriInfo();
            if(curriInfo && curriInfo.curriBook) {
                setCurriBook(curriInfo.curriBook);
            } else {
                getCurriBook();
            }

            if(props.searchType === "audio") {
                setNewType1List(["오디오"]);
                setNewType1ListValue(["audio"]);    
            }else if(props.searchType === "video") {
                setNewType1List(["비디오"]);
                setNewType1ListValue(["video"]);    
            } else if(props.searchType === "어휘" || props.searchType === "문장" || props.searchType === "지문") {
                setNewType1List(["텍스트"]);
                setNewType1ListValue(["text"]);    
            }else if(props.searchType === "image") {
                setNewType1List(["이미지"]);
                setNewType1ListValue(["image"]);    
            }else if(props.searchType === "media") {
                setNewType1List(["이미지", "오디오", "비디오"]);
                setNewType1ListValue(["image", "audio", "video"]);    
            } else {
                setNewType1List(type1List);
                setNewType1ListValue(type1ListValue);
            }

            setType(props.searchType);
    
            const div = document.getElementsByClassName("SG-datepicker__input-container");        
            // logger.log(div, div.length);
            if(div) {
                
                const new_OS_sdate = document.getElementById("new_OS_sdate") as HTMLInputElement;
                if(!new_OS_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_OS_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.style.paddingTop = "0.2rem";
                    node.style.paddingBottom = "0.2rem";
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[div.length-2].appendChild(node)
    
                }

                const OS_sdate = document.getElementById("OS_sdate") as HTMLInputElement;
                if(OS_sdate) {
                    OS_sdate.style.display = "none";
                }

                const new_OS_edate = document.getElementById("new_OS_edate") as HTMLInputElement;
                if(!new_OS_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_OS_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.style.paddingTop = "0.2rem";
                    node_e.style.paddingBottom = "0.2rem";
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[div.length-1].appendChild(node_e)
                }

                const OS_edate = document.getElementById("OS_edate") as HTMLInputElement;
                if(OS_edate) {
                    OS_edate.style.display = "none";
                }
            }            
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(newType1List && newType1List.length > 0) {

            logger.log("newType1List", newType1List);

            const OS_type1 = document.getElementById("OS_type1") as HTMLSelectElement;
            if(props.searchType === "audio") {
                OS_type1.value = "오디오";
            } else if(props.searchType === "video") {
                OS_type1.value = "비디오";
            } else if(props.searchType === "어휘" || props.searchType === "문장" || props.searchType === "지문") {
                OS_type1.value = "텍스트";

                setType2List([props.searchType]);
    
                setTimeout(() => {
                    const OS_type2 = document.getElementById("OS_type2") as HTMLSelectElement;
                    if(OS_type2) {
                        OS_type2.value = props.searchType;
                        OS_type2.disabled = true;
                    }                        
                }, 1000);
    
            } else if(props.searchType === "image") {
                OS_type1.value = "이미지";
            } else if(props.searchType === "media") {
                OS_type1.value = "이미지";
            } else {
                OS_type1.value = props.searchType;
            }
            onNewChangeType1(props.searchType);
        }
    }, [newType1List])

    useEffect(() => {
    }, [type])

    // useEffect(() => {
        
    //     if(textType) {
    //         logger.log("textType", textType);

    //         const OS_type1 = document.getElementById("OS_type1") as HTMLSelectElement;
    //         const OS_type2 = document.getElementById("OS_type2") as HTMLSelectElement;
    //         const OS_type3 = document.getElementById("OS_type3") as HTMLSelectElement;

    //         OS_type1.selectedIndex = 3;
    
    //         setType2List(_type2List[3]);
    //         if(textType === "어휘") {
    //             OS_type2.selectedIndex = 1;
    //             setType3TextList(_type3TextList[0]);
    //         } else if(textType === "문장") {
    //             OS_type2.selectedIndex = 2;
    //             setType3TextList(_type3TextList[1]);
    //         } else if(textType === "지문") {
    //             OS_type2.selectedIndex = 3;
    //             setType3TextList(_type3TextList[2]);
    //         }            
    //         OS_type3.selectedIndex = 0;    
    //     }

    //     init();

    // }, [textType])

    function init() {
        setCurriDepth(0);

        const OS_sdate = document.getElementById("new_OS_sdate") as HTMLInputElement;
        OS_sdate.value = "";
        const OS_edate = document.getElementById("new_OS_edate") as HTMLInputElement;
        OS_edate.value = "";
        const OS_keyword_type = document.getElementById("OS_keyword_type") as HTMLSelectElement;
        OS_keyword_type.selectedIndex = 0;
        const OS_keyword = document.getElementById("OS_keyword") as HTMLInputElement;
        OS_keyword.value = "";

        const OS_curriBook = document.getElementById("OS_curriBook") as HTMLSelectElement;
        OS_curriBook.selectedIndex = 0;
        const OS_curriUnit1 = document.getElementById("OS_curriUnit1") as HTMLSelectElement;
        OS_curriUnit1.selectedIndex = 0;
        const OS_curriUnit2 = document.getElementById("OS_curriUnit2") as HTMLSelectElement;
        OS_curriUnit2.selectedIndex = 0;
        const OS_curriUnit3 = document.getElementById("OS_curriUnit3") as HTMLSelectElement;
        OS_curriUnit3.selectedIndex = 0;
        const OS_curriUnit4 = document.getElementById("OS_curriUnit4") as HTMLSelectElement;
        OS_curriUnit4.selectedIndex = 0;
        const OS_curriUnit5 = document.getElementById("OS_curriUnit5") as HTMLSelectElement;
        OS_curriUnit5.selectedIndex = 0;
    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    async function getCurriBook() {
        const list = await getMetaList("1", "curriBook");
        if(list) {
            setCurriBook(list);
        }
    }

    function onClickCancel() : void {
        logger.log("onClickCancel");
        const OS_sdate = document.getElementById("OS_sdate") as HTMLInputElement;
        OS_sdate.value = "";
        const OS_edate = document.getElementById("OS_edate") as HTMLInputElement;
        OS_edate.value = "";
    }

    function onClickSDateIcon() : void {
        const new_OS_sdate = document.getElementById("new_OS_sdate") as HTMLInputElement;
        if(new_OS_sdate) {
            // new_OS_sdate.value = "";
            const OS_sdate = document.getElementById("OS_sdate") as HTMLInputElement;
            OS_sdate.value = "";
            OS_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const OS_edate = document.getElementById("OS_edate") as HTMLInputElement;
                    OS_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_OS_edate = document.getElementById("new_OS_edate") as HTMLInputElement;
        if(new_OS_edate) {
            // new_OS_edate.value = "";
            const OS_edate = document.getElementById("OS_edate") as HTMLInputElement;
            OS_edate.value = "";
            OS_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const OS_sdate = document.getElementById("OS_sdate") as HTMLInputElement;
                    OS_sdate.click();
                }
            }, 10);    
        }
    }

    // function onClickSDate(e: MouseEvent) : void {
    //     // e.stopPropagation();
    //     // const OS_sdate = document.getElementById("OS_sdate") as HTMLInputElement;
    //     // setTimeout(() => {
    //     //     OS_sdate.value = "";
    //     // }, 1000);
    //     setTimeout(() => {
    //         const div = document.getElementsByClassName("SG-datepicker__calendar-container");
    //         if(div && div.length === 2) {
    //             logger.log("onClickSDate", div, div.length);
    //             const OS_edate = document.getElementById("OS_edate") as HTMLInputElement;
    //             OS_edate.click();
    //         }
    //     }, 10);

        
    // }
    // function onClickEDate(e : MouseEvent) : void {
    //     // e.stopPropagation();

    //     setTimeout(() => {
    //         const div = document.getElementsByClassName("SG-datepicker__calendar-container");
    //         if(div && div.length === 2) {
    //             logger.log("onClickEDate", div, div.length);
    //             const OS_sdate = document.getElementById("OS_sdate") as HTMLInputElement;
    //             OS_sdate.click();
    //         }
    //     }, 10);
    // }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "OS_sdate") {
            const new_OS_sdate = document.getElementById("new_OS_sdate") as HTMLInputElement;
            if(new_OS_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_OS_sdate.value = val;
            }
        } else if(e.target.id === "OS_edate") {
            const new_OS_edate = document.getElementById("new_OS_edate") as HTMLInputElement;
            if(new_OS_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_OS_edate.value = val;
            }
        }
    }

    function onChangeType1(e: React.ChangeEvent<HTMLSelectElement>): void {
        logger.log("type2", e.target.value);
        onNewChangeType1(e.target.value);
    }

    function onNewChangeType1(value: string) {

        setType(value);
        setType3TextList([]);

        if(value === "이미지") {
            setType2List(_type2List[0]);
        } else if(value === "오디오" || value === "engTemp") {
            setType2List(_type2List[1]);
        } else if(value === "비디오") {
            setType2List(_type2List[2]);
        } else if(value === "텍스트") {
            setType2List(_type2List[3]);
        }

        const html = document.getElementById("OS_type2");
        if(html) {
            const selectElement = html as HTMLSelectElement;
            selectElement.selectedIndex = 0;
        }
    }

    function onChangeType2(e: React.ChangeEvent<HTMLSelectElement>): void {
        logger.log("type3", e.target.value);
        
        if(e.target.value === "어휘") {
            setType3TextList(_type3TextList[0]);
        } else if(e.target.value === "문장") {
            setType3TextList(_type3TextList[1]);
        } else if(e.target.value === "지문") {
            setType3TextList(_type3TextList[2]);
        } else {
        }

        const html = document.getElementById("OS_type3");
        if(html) {
            const selectElement = html as HTMLSelectElement;
            selectElement.selectedIndex = 0;
        }
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);
        }

        props.showLoading(false);
    }

    async function getChildrenList(id: string, name: string) {

        if(id === "0") {
            return;
        }
        
        const list = await util.getChildrenList(id);    
        logger.log("getChildrenList", list);  

        if(name === "CurriBook") {
            logger.log("setCurriUnit1", list);  
            setCurriUnit1(list);
        } else if(name === "CurriUnit1") {
            logger.log("setCurriUnit2", list);  
            setCurriUnit2(list);
        } else if(name === "CurriUnit2") {
            logger.log("setCurriUnit3", list);  
            setCurriUnit3(list);
        } else if(name === "CurriUnit3") {
            logger.log("setCurriUnit4", list);  
            setCurriUnit4(list);
        } else if(name === "CurriUnit4") {
            logger.log("setCurriUnit5", list);  
            setCurriUnit5(list);
        }
    }

    const OS_html_curriUnit1 = document.getElementById("OS_curriUnit1") as HTMLSelectElement;
    const OS_html_curriUnit2 = document.getElementById("OS_curriUnit2") as HTMLSelectElement;
    const OS_html_curriUnit3 = document.getElementById("OS_curriUnit3") as HTMLSelectElement;
    const OS_html_curriUnit4 = document.getElementById("OS_curriUnit4") as HTMLSelectElement;
    const OS_html_curriUnit5 = document.getElementById("OS_curriUnit5") as HTMLSelectElement;

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        getDescendantsList(e.target.value);

        OS_html_curriUnit1.selectedIndex = 0;
        OS_html_curriUnit2.selectedIndex = 0;
        OS_html_curriUnit3.selectedIndex = 0;
        OS_html_curriUnit4.selectedIndex = 0;
        OS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit2([]);
        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit1", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);

        OS_html_curriUnit2.selectedIndex = 0;
        OS_html_curriUnit3.selectedIndex = 0;
        OS_html_curriUnit4.selectedIndex = 0;
        OS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        OS_html_curriUnit3.selectedIndex = 0;
        OS_html_curriUnit4.selectedIndex = 0;
        OS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        OS_html_curriUnit4.selectedIndex = 0;
        OS_html_curriUnit5.selectedIndex = 0;

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        OS_html_curriUnit5.selectedIndex = 0;
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function onSearch(): void {

        if(!curriBook) {
            return;
        }

        const OS_type1 = document.getElementById("OS_type1") as HTMLSelectElement;
        const OS_type1_val = newType1ListValue[OS_type1.selectedIndex];

        const OS_type2 = document.getElementById("OS_type2") as HTMLSelectElement;
        let OS_type2_val = undefined;
        let OS_type3_val = undefined;
        if(OS_type2.selectedIndex > 0) {
            OS_type2_val = type2List[OS_type2.selectedIndex-1];
            const OS_type3 = document.getElementById("OS_type3") as HTMLSelectElement;
            OS_type3_val = OS_type3 ? _type3TextList[OS_type2.selectedIndex-1][OS_type3.selectedIndex-1] : undefined;
        }        

        const OS_sdate = document.getElementById("new_OS_sdate") as HTMLInputElement;
        const OS_edate = document.getElementById("new_OS_edate") as HTMLInputElement;

        const OS_keyword_type = document.getElementById("OS_keyword_type") as HTMLSelectElement;
        const OS_keyword_type_val = OS_keyword_type.value;

        const OS_keyword = document.getElementById("OS_keyword") as HTMLInputElement;

        const OS_curriBook = document.getElementById("OS_curriBook") as HTMLSelectElement;
        const OS_curriBook_val = curriBook[OS_curriBook.selectedIndex-1];

        const OS_html_curriUnit1_val = curriUnit1 ? (curriUnit1[OS_html_curriUnit1.selectedIndex-1] as IMeta) : undefined;
        const OS_html_curriUnit2_val = curriUnit2 ? (curriUnit2[OS_html_curriUnit2.selectedIndex-1] as IMeta) : undefined;
        const OS_html_curriUnit3_val = curriUnit3 ? (curriUnit3[OS_html_curriUnit3.selectedIndex-1] as IMeta) : undefined;
        const OS_html_curriUnit4_val = curriUnit4 ? (curriUnit4[OS_html_curriUnit4.selectedIndex-1] as IMeta) : undefined;
        const OS_html_curriUnit5_val = curriUnit5 ? (curriUnit5[OS_html_curriUnit5.selectedIndex-1] as IMeta) : undefined;
            
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        // const saperator = "-";
        let sdate = "";
        let edate = "";
        const arrSD = OS_sdate.value.split("-");
        const arrED = OS_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = OS_sdate.value;
            edate = OS_edate.value 
        }  

        let search = "";
        if(OS_keyword) {
            search = OS_keyword.value;
        }
        let searchField = "";
        if(OS_keyword_type) {
            searchField = OS_keyword_type.value;
        }

        const data : ILibrarySearchData = {
            type: type,
            type_1: type === "텍스트" ? OS_type2_val : OS_type1_val,
            type_2: type === "텍스트" ? OS_type3_val : OS_type2_val , 
            // type_3: OS_type3_val, 
            brand_id: brand.id ? brand.id : null,
            // reg_sdate: arrSD.length === 3 ? arrSD[2]+saperator+arrSD[0]+saperator+arrSD[1] : "", 
            // reg_edate: arrED.length === 3 ? arrED[2]+saperator+arrED[0]+saperator+arrED[1] : "", 
            reg_sdate: sdate,  
            reg_edate: edate, 
            // creator: OS_keyword_type_val === "id" ? OS_keyword.value : "",
            // creator_name: OS_keyword_type_val === "name" ? OS_keyword.value : "",
            // id: OS_keyword_type_val === "key" ? Number(OS_keyword.value) : undefined,
            // search: OS_keyword_type_val === "title" ? OS_keyword.value : "",

            search: search,
            searchField: searchField,

            curriBook: OS_curriBook_val ? OS_curriBook_val.id : undefined,
            curriUnit1: OS_html_curriUnit1_val ? OS_html_curriUnit1_val.id : undefined,
            curriUnit2: OS_html_curriUnit2_val ? OS_html_curriUnit2_val.id : undefined,
            curriUnit3: OS_html_curriUnit3_val ? OS_html_curriUnit3_val.id : undefined,
            curriUnit4: OS_html_curriUnit4_val ? OS_html_curriUnit4_val.id : undefined,
            curriUnit5: OS_html_curriUnit5_val ? OS_html_curriUnit5_val.id : undefined,
            
            // meta: metaForSearch, 
            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10, 
        };

        if(type === "어휘" || type === "문장") {
            data.type_1 = type;
            data.type_2 = undefined;
        }
        
        logger.log("onSearch", data, type);

        props.onSearch(data);
    }

    return (
        <div>
            {/* <!-- 검색영역 --> */}
            <div className="px-5 py-5 border border-gray-300 rounded-xl">
                    <div className="mb-1">
                        <fieldset>
                            <label htmlFor="type1">구분</label>
                            <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} className="mr-2" id="OS_type1" name="type1" onChange={(e) => onChangeType1(e)}>
                                {newType1List && (newType1List as string[]).map((type1, idx1) => {                                    
                                return(
                                    <option key={idx1} value={type1}>{type1}</option>              
                                )})}
                            </select>
                            <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem", display: type2List && type2List.length > 0 ? "" : "none"}} className="mr-2" id="OS_type2" name="type2" onChange={(e) => onChangeType2(e)}>
                                <option value="">전체</option>
                                {type2List && (type2List as string[]).map((type2, idx2) => {
                                return(
                                    <option key={idx2} value={type2}>{type2}</option>              
                                )})}
                            </select>
                            <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem", display: type3TextList && type3TextList.length > 0 ? "" : "none"}} id="OS_type3" name="type3">
                                <option value="">전체</option>
                                {type3TextList && (type3TextList as string[]).map((type3, idx3) => {
                                return(
                                    <option key={idx3} value={type3}>{type3}</option>              
                                )})}
                            </select>

                        </fieldset>

                        <fieldset>
                            <label htmlFor="date" className="ml-8">등록기간</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='OS_sdate' name='OS_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickSDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='OS_edate' name='OS_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        {/* <fieldset>
                            <label htmlFor="date" className="ml-8">등록기간</label>
                            <input type="text" name="date" id="OS_sdate" className="w-36" onChange={() => {}}/>
                            <button type="button" className="inline-block ml-1 align-[-6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                            <span className="mx-3">~</span> 
                            <input type="text" name="date" id="OS_edate" className="w-36" onChange={() => {}}/>
                            <button type="button" className="inline-block ml-1 align-[-6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>		
                            </button>
                        </fieldset> */}

                    </div>
                    <div className="mb-1">
                        <fieldset>
                            <label htmlFor="curriBook">교과</label>
                            <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} id="OS_curriBook" name="OS_curriBook" onChange={(e) => onChangeCurriBook(e)}>
                                <option value="0">교과</option>              
                                {curriBook && (curriBook as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.code}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="curriUnit1" className="ml-8">교과과정</label>
                            <select className="mr-2" id="OS_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{paddingTop:"0.2rem", paddingBottom:"0.2rem", display:curriDepth > 0 ? "" : "none"}}>
                                <option value="0">1Depth</option>              
                                {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="OS_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{paddingTop:"0.2rem", paddingBottom:"0.2rem", display:curriDepth > 1 ? "" : "none"}}>
                                <option value="0">2Depth</option>              
                                {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="OS_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{paddingTop:"0.2rem", paddingBottom:"0.2rem", display:curriDepth > 2 ? "" : "none"}}>
                                <option value="0">3Depth</option>              
                                {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="OS_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{paddingTop:"0.2rem", paddingBottom:"0.2rem", display:curriDepth > 3 ? "" : "none"}}>
                            {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                <option value="0">4Depth</option>              
                                {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select id="OS_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{paddingTop:"0.2rem", paddingBottom:"0.2rem", display:curriDepth > 4 ? "" : "none"}}>
                                <option value="0">5Depth</option>              
                                {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                    </div>
                    <div className="text-right">
                        <fieldset>
                            <label htmlFor="keyword_type">검색</label>
                            <select style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} className="mr-2" id="OS_keyword_type" name="keyword_type">
                                <option value={"creator_name"}>이름</option>
                                <option value={"creator"}>아이디</option>
                                <option value={"name"}>타이틀</option>
                                <option value={"id"}>Key</option>
                            </select>
                            <input style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} type="text" name="keyword" id="OS_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>
            </div>
            {/* <!-- .//검색영역 --> */}

        </div>
    );
}
export default ObjectSearch;
