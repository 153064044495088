import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from '../util';
import { IBrand, ICurriInfo, IMeta, IMetaMap } from "../common";
import { logger } from '../logger';

interface IMetaAchievementStandardEdit {
    brand: IBrand;
	item: IMeta | null;
	parentItem: IMeta | null;
    type: string;
    onCloseEdit: (isEdited: boolean, from: string) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaAchievementStandardEdit(props: IMetaAchievementStandardEdit) {

    const [UUID, setUUID] = useState("");
    const [contentArea, setContentArea] = useState<IMeta[]>([]);

    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.onCloseEdit(false, "");
        //     return;
        // }

        if(!bDone) {

            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);

            logger.log("props", props);

            if(props.type === "unit1") {

                getContentArea();

                const item = props.item;
                if(item) {
                    if(item.uuid && item.uuid.length > 10) {
                        setUUID(item.uuid);
                    }

                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                        // meta_code.readOnly = true;
                        // meta_code.disabled = true;
                    }        
                }
                
            } else if(props.type === "1") {

                const item = props.item;
                logger.log("item", item);

                if(item) {
                    
                    const meta_val = (document.getElementById('meta_val') as HTMLInputElement)
                    if(meta_val) {
                        meta_val.value = item.val;
                    }
                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                        // meta_code.readOnly = true;
                        // meta_code.disabled = true;
                    }

                    const meta_val1 = (document.getElementById('meta_val1') as HTMLInputElement)
                    if(meta_val1 && item.val1) {
                        meta_val1.value = item.val1;
                    }
                }

            } else if(props.type === "2") {

                const item = props.item;

                if(item) {
                    
                    const meta_val = (document.getElementById('meta_val') as HTMLInputElement)
                    if(meta_val) {
                        meta_val.value = item.val;
                    }
                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                    }
                    const meta_val1 = (document.getElementById('meta_val1') as HTMLInputElement)
                    if(meta_val1 && item.val1) {
                        meta_val1.value = item.val1;
                    }
                }
            }

            const active = (document.getElementById('active') as HTMLInputElement);
            if(props.item) {
                active.checked = props.item.is_active;
            } else {
                active.checked = true;
            }
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(contentArea && contentArea.length > 0) {
            if(props.item) {
                getMetaOfMetaExById(String(props.item.id));
            }
        }
    }, [contentArea])

    async function getMetaOfMetaExById(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("getMetaOfMetaExById", list);

        const _contentArea = list.find((meta, _) => meta.name === "contentArea");
        if(_contentArea) {
            const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
            meta_contentArea.value = _contentArea.val;    
        }
    }

    async function getContentArea() {
        const list = await getMetaList("1", "contentArea");
        setContentArea(list);
    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    async function insertMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);

        const result = await util.insertMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.updateMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    function onSave(): void {

        const brand = util.getCurrentBrand();
        if(!brand) {
            props.showModal("", "브랜드가 정상적이지 않습니다.");
            return;
        }

        let val = "";
        const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
        if(meta_val)
        {
            val = (document.getElementById('meta_val') as HTMLInputElement).value
            if(val === "") {
                props.showModal("", "메타값을 입력해 주세요.");
                return;
            }
        }
        const description = (document.getElementById('meta_description') as HTMLInputElement).value
        const code = (document.getElementById('meta_code') as HTMLInputElement).value
        const is_active = (document.getElementById('active') as HTMLInputElement).checked

        if(code === "") {
            props.showModal("", "메타코드를 입력해 주세요.");
            return;
        }

        const parentItem = props.parentItem;
        logger.log('parentItem', parentItem);

        let _depth = 1;
        let _code = code;
        let _parent_id = 0;
        if(parentItem) {
            _depth = parentItem.depth + 1;
            _code = props.item ? code : parentItem.code + "-" + code;
            _parent_id = parentItem.id!;
        }

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        if(props.type === "unit1") {

            let val = "";

            let meta_mapping_meta : IMeta[] = [];
            const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
            if(meta_contentArea) {
                const meta_contentArea_val = contentArea[meta_contentArea.selectedIndex];
                meta_mapping_meta.push(meta_contentArea_val);
                val = meta_contentArea_val.val;
            }

            const meta = {
                brand_id: brand.id ? brand.id : null,
                id: props.item ? props.item.id : undefined,

                code: _code,
                name: "achievementStandard",
                val: val,
                is_active: is_active ? true : false,
                description: description,
                depth: _depth,
                parent_id: _parent_id,

                creator: props.item ? undefined : uid,
                creator_id: props.item ? undefined : _id,
                creator_name: props.item ? undefined : uname,
    
                updater: props.item ? uid : undefined,
                updater_id: props.item ? _id : undefined,
                updater_name: props.item ? uname : undefined,

                uuid: props.item ? undefined : UUID,
            }
            
            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);
    
            if(props.item) {
                updateMetaMetaMap(metamap);  
            } else {
                insertMetaMetaMap(metamap);
            }
    
        } else if(props.type === "1") {

            const meta_val1 = (document.getElementById('meta_val1') as HTMLInputElement).value

            const meta = {

                brand_id: brand.id ? brand.id : null,
                id: props.item ? props.item.id : undefined,
    
                code: _code,
                name: "achievementStandard",
                val: val,
                depth: _depth,
                parent_id: _parent_id,
                is_active: is_active ? true : false,
                creator: props.item ? undefined : uid,
                creator_id: props.item ? undefined : _id,
                creator_name: props.item ? undefined : uname,
                description: description,
    
                updater: props.item ? uid : undefined,
                updater_id: props.item ? _id : undefined,
                updater_name: props.item ? uname : undefined,
                
                uuid: props.item ? undefined : UUID,

                name1: "내용",

                val1: meta_val1,
        
            }

            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": []}
            logger.log("meta_mapping_meta", metamap);
    
            if(props.item) {
                updateMetaMetaMap(metamap);  
            } else {
                insertMetaMetaMap(metamap);  
            }

        } else if(props.type === "2") {

            const meta_val1 = (document.getElementById('meta_val1') as HTMLInputElement).value
            
            const meta = {

                brand_id: brand.id ? brand.id : null,
                id: props.item ? props.item.id : undefined,
    
                code: _code,
                name: "achievementStandard",
                val: val,
                depth: _depth,
                parent_id: _parent_id,
                is_active: is_active ? true : false,
                creator: props.item ? undefined : uid,
                creator_id: props.item ? undefined : _id,
                creator_name: props.item ? undefined : uname,
                description: description,
    
                updater: props.item ? uid : undefined,
                updater_id: props.item ? _id : undefined,
                updater_name: props.item ? uname : undefined,
                
                uuid: props.item ? undefined : UUID,
        
                name1: "표준체계 ID",

                val1: meta_val1,
        
            }

            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": []}
            logger.log("meta_mapping_meta", metamap);
    
            if(props.item) {
                updateMetaMetaMap(metamap);  
            } else {
                insertMetaMetaMap(metamap);  
            }
        }
    }
    
    return (
        <div>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">관리테이블</caption>
                <tbody>

                    {props.type === "unit1" &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-year">메타 값<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_contentArea">
                                {contentArea && contentArea.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {(props.type === "1" || props.type === "2") &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">메타 값 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_val" className="w-avail"/>
                        </td>
                    </tr>}

                    {(props.type === "1") &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">내용 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_val1" className="w-avail"/>
                        </td>
                    </tr>}

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-disc">설명</label></th>
                        <td className="text-left">
                            <input type="text" id="meta_description" className="w-avail"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_code" className="w-1/2" />
                        </td>
                    </tr>

                    {(props.type === "2") &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">표준체계 ID <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_val1" className="w-1/2"/>
                        </td>
                    </tr>}

                    <tr>
                        <th scope="row" className="w-56">고유코드</th>
                        <td className="text-left">{UUID}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록자</th>
                        {props.item &&
                        <td className="text-left">{props.item.creator_name} ({props.item.creator})</td>}
                        {!props.item &&
                        <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록일</th>
                        {props.item &&
                        <td className="text-left">{props.item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>}
                        {!props.item &&
                        <td className="text-left">{util.getDate()}</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
                    
            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-white" onClick={() => props.onCloseEdit(false, "")}>취소</button>
                    <button type="button" className="btn-sky" onClick={onSave}>{props.item ? "수정" : "등록"}</button>
                </div>
            </div>

        </div>
    );
}

export default MetaAchievementStandardEdit;
