import React, { useEffect, useState } from "react";
import * as util from './util';
import { logger } from './logger';

interface IMyButton {
    type?: any;
    className: string;
    onClick: () => void;
    children?: React.ReactNode;
    disabled?: boolean;
    creator?: string;
    needAuth?: boolean;
    showModal: (title: string, description: string) => void;    
}

function MyButton(props: IMyButton) {

    const [hasAuth, setHasAuth] = useState(false);

    const currMenu = util.getCurrentMenu();

    useEffect(() => {
        
        if(props.disabled) {
            setHasAuth(false);
        } else {
            let hasAuth = util.getHasAuthMenu(props.creator ? props.creator : "");
            setHasAuth(hasAuth);    
        }
    })

    function onMyClick() {

        //권한체크
        if(hasAuth) {
            props.onClick();
        } else {
            props.showModal("", "권한이 없습니다.");
        }       
    }
    
    return (
        <button type={props.type} className={props.className} onClick={onMyClick} disabled={!hasAuth}>
            {props.children}
        </button>
    );
}

export default MyButton;