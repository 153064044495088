import React, { RefObject, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import type { NodeModel } from "@minoru/react-dnd-treeview";
import styles from "./CustomNode.module.css";
import '../../common.scss';
import { NodeData } from "../TextBookIndexEdit";
import { logger } from "../../logger";

type Props = {
    node: NodeModel;
    depth: number;
    isOpen: boolean;
    hasChild: boolean;
    testIdPrefix?: string;
    handleRef: RefObject<any>;
    isPublished: boolean;
    onToggle: (id: NodeModel["id"]) => void;
    onAddNode: (node: NodeModel) => void;
    onChangeStartPage: (id: number, val: string) => void;
    onChangeEndPage: (id: number, val: string) => void;
    onChangeText: (id: number, val: string) => void;
    onChangeUsage: (id: number, is_active: boolean) => void;
    onChangeAddContents: (id: number, is_addContents: boolean) => void;
    onEnterPress: (id: number) => void;
    onSetup: (node: NodeModel) => void;
    onDel: (node: NodeModel) => void;
    onEditIndexName: (node: NodeModel) => void;
    showModal: (title: string, description: string) => void;
};

export const CustomNode: React.FC<Props> = ({
    testIdPrefix = "",
    ...props
}) => {

    const { id } = props.node;
    const indent = props.depth * 36;
    const depth = props.depth + 1;
    const child_depth = (props.node.data as NodeData).child_depth;
    const tabIndex = (props.node.data as NodeData) ? (props.node.data as NodeData).tabIndex : 0;
    const curriUnitList = (props.node.data as NodeData).curriUnitList;
    const indexName = (props.node.data as NodeData).indexName;
    
    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            const chk = document.getElementById("chk_"+props.node.id) as HTMLInputElement;
            chk.checked = true;
            if(props.node.data) {
                chk.checked = (props.node.data as NodeData).is_active;
            } 
            const addContents = document.getElementById("addContents_"+props.node.id) as HTMLInputElement;
            addContents.checked = true;
            if(props.node.data) {
                addContents.checked = (props.node.data as NodeData).is_addContents;
            } 

            const startPage_ = document.getElementById("startPage_"+props.node.id) as HTMLInputElement;
            if(props.node.data) {
                startPage_.value = String((props.node.data as NodeData).startPage ? (props.node.data as NodeData).startPage : "");
            } 
            const endPage_ = document.getElementById("endPage_"+props.node.id) as HTMLInputElement;
            if(props.node.data) {
                endPage_.value = String((props.node.data as NodeData).endPage ? (props.node.data as NodeData).endPage : "");
            } 

        }
        bDone = true;
    }, [])

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    function onAddNode() {
        props.onAddNode(props.node);
    }

    function onSetup() {
        props.onSetup(props.node);
    }

    function onEditIndexName() {
        props.onEditIndexName(props.node);
    }

    function onDel() {
        props.onDel(props.node);
    }

    function onChangeStartPage(e: React.ChangeEvent<HTMLInputElement>) {
        const html = e.target as HTMLInputElement;
        if(html) {
            props.onChangeStartPage(Number(props.node.id), html.value);
        }       
    }

    function onChangeEndPage(e: React.ChangeEvent<HTMLInputElement>) {
        const html = e.target as HTMLInputElement;
        if(html) {
            props.onChangeEndPage(Number(props.node.id), html.value);
        }       
    }

    function onChangeText(e: React.ChangeEvent<HTMLInputElement>) {
        const html = e.target as HTMLInputElement;
        if(html) {
            props.onChangeText(Number(props.node.id), html.value);
        }       
    }
    
    function onChangeUsage(e: React.ChangeEvent<HTMLInputElement>) {
        const html = e.target as HTMLInputElement;
        if(html) {
            props.onChangeUsage(Number(props.node.id), html.checked);
        }        
        
        html.focus();
    }

    function onChangeAddContents(e: React.ChangeEvent<HTMLInputElement>) {

        const html = e.target as HTMLInputElement;
        if(html) {
            props.onChangeAddContents(Number(props.node.id), html.checked);
        }        
        
        html.focus();
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {   
            props.onEnterPress(Number(tabIndex));
        }
    }      
      
    return (
        <div id="rootNode" className={styles.root + " border-b"} style={{ paddingInlineStart: indent }} data-testid={`${testIdPrefix}custom-node-${id}`}>
            <table className="border-none">
                <tbody>
                    <tr>
                        <td className="w-8" style={{paddingRight:0, paddingTop:0, paddingBottom:0}}>
                            <div className={`${styles.expand} ${props.isOpen ? styles.isOpen : ""}`}>
                                {props.hasChild && (
                                <div onClick={handleToggle}>
                                    <ArrowDropDownIcon />
                                </div>
                                )}
                            </div>
                        </td>
                        <td className="w-avail" style={{paddingLeft:0, paddingTop:0, paddingBottom:0}}>
                            <div className={styles.label + " text-left"} >
                                <input id={"nodeVal_"+tabIndex} name="nodeVal" className="w-avail" type="text" placeholder="목차 내용을 입력 해 주세요. 우측에  하위목차의 ‘추가’버튼을 클릭하면 해당 목차 하위에 빈칸이 생성됩니다." defaultValue={props.node.text} tabIndex={Number(tabIndex)} onChange={(e) => onChangeText(e)} onKeyDown={onKeyDown}/>
                                {/* <Typography variant="body2">
                                    {props.node.text}
                                </Typography> */}
                            </div>
                        </td>
                        <td className="w-[100px]" style={{display:"none", padding:0}}>
                            <p className="orderVal">{props.node.id}</p>
                        </td>
                        <td className="w-[80px]" style={{padding:0}}>
                            <p>{child_depth === 0 ? "-" : child_depth}</p>
                        </td>
                        <td className="w-36" style={{padding:0}}>
                            <p><input className="w-12" id={"startPage_"+props.node.id} type="text" onChange={(e) => onChangeStartPage(e)} />{" ~ "} 
                            <input className="w-12" id={"endPage_"+props.node.id} type="text" onChange={(e) => onChangeEndPage(e)} /></p>
                        </td>
                        <td className="w-[80px]" style={{padding:0}}>
                            <label className="inp-toggle" style={{marginRight:0}}>
                                <input id={"chk_"+props.node.id} type="checkbox" className="peer" onChange={(e) => onChangeUsage(e)} />
                                <div className="peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-sky-500"></div>
                            </label>
                        </td>
                        <td className="w-[80px]" style={{padding:0}}>
                            <button type="button" className="btn-white-s" onClick={onAddNode} tabIndex={-1} disabled={depth>3}>추가</button>
                        </td>
                        <td className="w-[80px]" style={{padding:0}}>
                            <button type="button" className="btn-white-s" onClick={onSetup} tabIndex={-1}>{curriUnitList && curriUnitList.length > 0 ? "수정" : "추가"}</button>
                        </td>
                        <td className="w-[80px]" style={{padding:0}}>
                            <button type="button" className="btn-white-s" onClick={onEditIndexName} tabIndex={-1}>{indexName && indexName.length > 0 ? "수정" : "추가"}</button>
                        </td>
                        <td className="w-[100px]" style={{padding:0}} onClick={() => {
                            if(props.isPublished) {
                                props.showModal("", '배포 후에는 변경이 불가능합니다');
                            }
                        }}>
                            <label className="inp-toggle" style={{marginRight:0}}>
                                <input id={"addContents_"+props.node.id} type="checkbox" className="peer" onChange={(e) => onChangeAddContents(e)} disabled={props.isPublished} />
                                <div className="peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-sky-500"></div>
                            </label>
                        </td>
                        <td className="w-[80px]" style={{padding:0}}>
                            <button type="button" className="btn-white-s" onClick={onDel} tabIndex={-1}>삭제</button>
                        </td>
                        <td className="w-16">
                            <div className={`${styles.handle} drag-handle`} ref={props.handleRef} data-testid={`drag-handle-${props.node.id}`} style={{paddingLeft:"10px"}}>
                                <DragHandleIcon />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
