import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, ILibrary, ILibrarySearchData, IMenu, IMeta, IScript, IText, S3Server } from "../common";
import LibraryTextRegister from "./LibraryTextRegister";
import LibrarySearch from "./LibrarySearch";
import LibraryBulkRegister from "./LibraryBulkRegister";
import { logger } from '../logger';
import '../common.scss';
import ObjectSearch from "./ObjectSearch";
import PopupMediaPreview from "./PopupMediaPreview";

interface IPopupLibrarySearch {
    brand: IBrand;
    searchType: string;
    from? : string;
    isMultiSelect?: boolean;
    onClose: () => void;    
    onSelect: (item: any, isMultiSelect: boolean) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function PopupLibrarySearch(props: IPopupLibrarySearch) {
    
    const [list, setList] = useState([] as any);
    const [list4, setList4] = useState([] as any);
    const [selectedItem, setSelectedItem] = useState(null as any);
    const [selectedItemTitle, setSelectedItemTitle] = useState("");
    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [textType, setTextType] = useState("");
    const [viewDetail, setViewDetail] = useState(false);
    const [viewMediaPreview, setViewMediaPreview] = useState(false);
    const [mediaType, setMediaType] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            setSelectedItem(null);

            logger.log("props.searchType", props.searchType);

            // let _textType = "문장";
            // if(props.textType === "어휘") {
            // } else if(props.textType === "어휘") {
            //     _textType = "어휘";
            // }
            // setTextType(_textType);

            refreshList();

        }
        bDone = true;
    }, [])

    const [_unitVal, set_unitVal] = useState("");
    const [_curriBookVal, set_curriBookVal] = useState("");
    const [_type_1, set_type_1] = useState("");
    const [_curriSchoolVal, set_curriSchoolVal] = useState("");
    const [_curriSubjectVal, set_curriSubjectVal] = useState("");

    useEffect(() => {
        if(selectedItem) {
            logger.log("selectedItem", selectedItem);
            if(selectedItem.metaMap) {
                const list = selectedItem.metaMap as IMeta[];
                const _curriSchool = list.find((meta, _) => meta.name === "curriSchool");
                if(_curriSchool) {
                    set_curriSchoolVal(_curriSchool.val);
                }    
                const _curriSubject = list.find((meta, _) => meta.name === "curriSubject");
                if(_curriSubject) {
                    set_curriSubjectVal(_curriSubject.val);
                }    
            }
    
            if(curriInfo && curriInfo.curriBook) {
                const curriBook = curriInfo.curriBook as IMeta[];
                const _curriBook = curriBook.find((book, _) => book.id === selectedItem.curriBook);
                set_curriBookVal(_curriBook ? _curriBook.val : "");
            
                let unitVal = "";
                // const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                // const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                // const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                // const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                // const curriUnit5 = curriInfo.curriUnit5 as IMeta[];
            
                // const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === selectedItem.curriUnit1);
                // const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === selectedItem.curriUnit2);
                // const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === selectedItem.curriUnit3);
                // const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === selectedItem.curriUnit4);
                // const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === selectedItem.curriUnit5);
            
                // const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                // arr.map((val, idx) => {
                //     if(val) {
                //         if(unitVal === "") {
                //             unitVal = val;
                //         } else {
                //             // if(idx === 2) {
                //             //     unitVal = unitVal + " > " + "<br/>" + val;
                //             // } else {
                //             //     unitVal = unitVal + " > " + val;
                //             // }
                //             unitVal = unitVal + " > " + val;
                //         }
                //     }
                // });
                set_unitVal(unitVal);
            }
                    
            if(selectedItem.type_1 === "image") {
                set_type_1("이미지");
            } else if(selectedItem.type_1 === "audio") {
                set_type_1("오디오");
            } else if(selectedItem.type_1 === "video") {
                set_type_1("비디오");
            } else {
                set_type_1(selectedItem.type_1);
            }   

            setViewDetail(true);

        }
    }, [selectedItem])

    const refreshList = () => {

        setSelectedItem(null);

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
        setCurrPage(1);

        logger.log("props.searchType", props.searchType);

        let type_1 = "";
        if(props.searchType === "이미지" || props.searchType === "image") {
            type_1 = "image";
        } else if(props.searchType === "오디오" || props.searchType === "audio") {
            type_1 = "audio";
        } else if(props.searchType === "비디오" || props.searchType === "video") {
            type_1 = "video";
        } else if(props.searchType === "텍스트" || props.searchType === "어휘" || props.searchType === "문장" || props.searchType === "지문") {
            type_1 = props.searchType === "텍스트" ? "" : props.searchType;
        } else {
            type_1 = "image";
        }

        const data = {
            type: (props.searchType === "어휘" || props.searchType === "문장" || props.searchType === "지문") ? "텍스트" : props.searchType,
            type_1: type_1,
            brand_id: brand.id,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };

        logger.log("refreshList", data);
        onSearch(data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);

        let list = null;
        if(data.type && (data.type === "텍스트" || data.type === "어휘" || data.type === "문장" || data.type === "지문")) {
            list = await util.getTextSearchList(data);
        } else {
            list = await util.getLibrarySearchList(data);
        }

        if(props.from === "Teaching") {

            const newList = [];
            const a = list.length % 4;
            const b = Math.floor(list.length / 4);

            logger.log("newList", a, b);

            for (let i = 0; i < b; i++) {
                const e0 = list[i*4 + 0];
                const e1 = list[i*4 + 1];
                const e2 = list[i*4 + 2];
                const e3 = list[i*4 + 3];     
                
                newList.push([e0,e1,e2,e3]);
            }

            if(a === 0) {
            } else if(a === 1) {
                newList.push([list[b*4+0]]);
            } else if(a === 2) {
                newList.push([list[b*4+0],list[b*4+1]]);
            } else if(a === 3) {
                newList.push([list[b*4+0],list[b*4+1],list[b*4+2]]);
            }

            setList4(newList);

            logger.log("newList", newList);
        }

        setList(list);
        logger.log("_onSearch", data, list);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
            }
            calcPage(totalCnt, currentPage);
        }

        setViewList(true);

        props.showLoading(false);
    }

    function onSearch(data: any) {

        // data.brand_id = undefined;

        logger.log("onSearch", data);
        
        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                onChangePage(_currPage);
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                onChangePage(_currPage);
            }
        }
    }

    function onChangePage(page: number) {
        
        const _searchData = searchData;
        _searchData.limit_page = page;
        onSearch(_searchData);
        
        logger.log("onChangePage", page, searchData, _searchData);

        setCurrPage(page);
    }
    
    function onChangeTextType3(val: string): void {
        setTextType(val);
    }
    
    async function onConfirm(item: any) {

        if(props.from && props.from === "Teaching") {
            logger.log("onSave", item);
            props.onSelect(item, false);
            return;                
        }

        props.showLoading(true);

        let list = null;
        if(item.type_1 === "image" || item.type_1 === "audio" || item.type_1 === "video") {
            list = await util.getLibraryMetaList(item.id);
            item.metaMap = list; 
        } else {
            item = await util.getTextInfo(item.id);    
        }
        
        props.showLoading(false);

        setSelectedItem(item);

        if(props.from && props.from === "AITutorWritingRegister") {
            logger.log("onSave", item);
            props.onSelect(item, false);
            return;                
        }

        let title = item.name;
        if(title && title.length > 50) {
            title = title.substring(0, 47) + "...";
        }
        setSelectedItemTitle(title);

        logger.log("onConfirm", item);
    }
    
    function onSave() {
        logger.log("onSave", selectedItem);
        props.onSelect(selectedItem, false);
    }

    function onSaveBulk() {

        const words : IText[] = [];
        const list_ck = document.getElementsByName("list_ck");

        let checkedCnt = 0;
        for (let i = 0; i < list_ck.length; i++) {
            const element = list_ck[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                words.push(list[i]);
            }
        }

        logger.log("onSaveBulk", words);
        props.onSelect(words, true);
    }

    return (

        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box w-avail" style={{height:"100%", minHeight:"40rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={props.onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">{props.from && props.from === "AITutorWritingRegister" ? "라이브러리 이미지 검색​" : "오브젝트 검색"}</h2>

                            {viewDetail &&
                            <div className="py-5 px-5">

                                <table>
                                    <caption className="sr-only">관리 테이블</caption>
                                    <tbody>
                                        <tr>
                                            <th scope="row" className="w-56">브랜드</th>
                                            <td className="text-left">{props.brand.name}</td>
                                            <th scope="row" className="w-56">구분</th>
                                            <td className="text-left">{_type_1}{selectedItem?.type_2 ? ' > ' : ""}{selectedItem?.type_2}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="w-56">타이틀</th>
                                            <td className="text-left">{selectedItemTitle}</td>
                                            <th scope="row" className="w-56">KEY</th>
                                            <td className="text-left">{selectedItem?.id}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="w-56">학교 급</th>
                                            <td className="text-left">{_curriSchoolVal}
                                            </td>
                                            <th scope="row" className="w-56">교과목</th>
                                            <td className="text-left">{_curriSubjectVal}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="w-56">교과</th>
                                            <td className="text-left">{_curriBookVal}
                                            </td>
                                            <th scope="row" className="w-56">교과과정</th>
                                            <td className="text-left" dangerouslySetInnerHTML={{ __html: _unitVal }}></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="w-56">작성자</th>
                                            <td className="text-left">{selectedItem?.creator_name} ({selectedItem?.creator})</td>
                                            <th scope="row" className="w-56">등록일</th>
                                            <td className="text-left">{selectedItem?.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                        </tr>

                                        {(selectedItem.type_1 === "image" || selectedItem.type_1 === "audio" || selectedItem.type_1 === "video") &&
                                        <tr>
                                            <th scope="row">CONTENTS <em className="text-sky-500 align-top">*</em></th>
                                            <td className="p-0" colSpan={3}>
                                                
                                                {selectedItem.type_1 === "image" &&
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">이미지</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.url}
                                                        {/* <!-- 활성화 --> */}
                                                        <span className={selectedItem?.url && selectedItem?.url.length > 0 ? 
                                                        "icon-img-on block w-6 h-6 mx-3" : "icon-img block w-6 h-6 mx-3"} onClick={() => {
                                                            if(selectedItem?.url && selectedItem?.url.length > 0) {
                                                                setMediaType("image");
                                                                setMediaUrl(S3Server+selectedItem?.url);
                                                                setViewMediaPreview(true);                                    
                                                            }}} style={{cursor: selectedItem?.url && selectedItem?.url.length > 0 ? "pointer" : ""}}></span>
                                                        {/* <!-- .//활성화 --> */}
                                                    </dd>
                                                </dl>}
                                                {selectedItem.type_1 === "audio" &&
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">오디오</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.url}
                                                        {/* <!-- 활성화 --> */}
                                                        <span className={selectedItem?.url && selectedItem?.url.length > 0 ? 
                                                        "icon-audio-on block w-6 h-6 mx-3" : "icon-audio block w-6 h-6 mx-3"} onClick={() => {
                                                            if(selectedItem?.url && selectedItem?.url.length > 0) {
                                                                setMediaType("audio");
                                                                setMediaUrl(S3Server+selectedItem?.url);
                                                                setViewMediaPreview(true);                                    
                                                            }}} style={{cursor: selectedItem?.url && selectedItem?.url.length > 0 ? "pointer" : ""}}></span>
                                                        {/* <!-- .//활성화 --> */}
                                                    </dd>
                                                </dl>}
                                                {selectedItem.type_1 === "video" &&
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">비디오</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.url}
                                                        {/* <!-- 활성화 --> */}
                                                        <span className={selectedItem?.url && selectedItem?.url.length > 0 ? 
                                                        "icon-video-on block w-6 h-6 mx-3" : "icon-video block w-6 h-6 mx-3"} onClick={() => {
                                                            if(selectedItem?.url && selectedItem?.url.length > 0) {
                                                                setMediaType("video");
                                                                setMediaUrl(S3Server+selectedItem?.url);
                                                                setViewMediaPreview(true);                                    
                                                            }}} style={{cursor: selectedItem?.url && selectedItem?.url.length > 0 ? "pointer" : ""}}></span>
                                                        {/* <!-- .//활성화 --> */}
                                                    </dd>
                                                </dl>}
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">설명</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <span>{selectedItem.description}</span>
                                                        {/* <!-- .//활성화 --> */}
                                                    </dd>
                                                </dl>

                                            </td>
                                        </tr>}

                                        {selectedItem.type_1 !== "image" && selectedItem.type_1 !== "audio" && selectedItem.type_1 !== "video" && selectedItem.type_1 !== "지문" && 
                                        <tr>
                                            <th scope="row">CONTENTS <em className="text-sky-500 align-top">*</em></th>
                                            <td className="p-0" colSpan={3}>
                                                <dl className="flex">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">Entry</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.contents_entry}
                                                    </dd>
                                                </dl>
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">다국어</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.contents_multiLang}
                                                    </dd>
                                                </dl>
                                                
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">이미지</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.contents_image}
                                                        {/* <!-- 활성화 --> */}
                                                        <span className={selectedItem?.contents_image && selectedItem?.contents_image.length > 0 ? 
                                                        "icon-img-on block w-6 h-6 mx-3" : "icon-img block w-6 h-6 mx-3"} onClick={() => {
                                                            if(selectedItem?.contents_image && selectedItem?.contents_image.length > 0) {
                                                                setMediaType("image");
                                                                setMediaUrl(S3Server+selectedItem?.contents_image);
                                                                setViewMediaPreview(true);                                    
                                                            }}} style={{cursor: selectedItem?.contents_image && selectedItem?.contents_image.length > 0 ? "pointer" : ""}}></span>
                                                        {/* <!-- .//활성화 --> */}
                                                    </dd>
                                                </dl>
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">오디오</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.contents_audio}
                                                        {/* <!-- 활성화 --> */}
                                                        <span className={selectedItem?.contents_audio && selectedItem?.contents_audio.length > 0 ? 
                                                        "icon-audio-on block w-6 h-6 mx-3" : "icon-audio block w-6 h-6 mx-3"} onClick={() => {
                                                            if(selectedItem?.contents_audio && selectedItem?.contents_audio.length > 0) {
                                                                setMediaType("audio");
                                                                setMediaUrl(S3Server+selectedItem?.contents_audio);
                                                                setViewMediaPreview(true);                                    
                                                            }}} style={{cursor: selectedItem?.contents_audio && selectedItem?.contents_audio.length > 0 ? "pointer" : ""}}></span>
                                                        {/* <!-- .//활성화 --> */}
                                                    </dd>
                                                </dl>
                                                <dl className="flex border-gray-300 border-t">
                                                    <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                                        <span className="mr-0 font-semibold text-gray-900">비디오</span>
                                                    </dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        {selectedItem?.contents_video}
                                                        {/* <!-- 활성화 --> */}
                                                        <span className={selectedItem?.contents_video && selectedItem?.contents_video.length > 0 ? 
                                                        "icon-video-on block w-6 h-6 mx-3" : "icon-video block w-6 h-6 mx-3"} onClick={() => {
                                                            if(selectedItem?.contents_video && selectedItem?.contents_video.length > 0) {
                                                                setMediaType("video");
                                                                setMediaUrl(S3Server+selectedItem?.contents_video);
                                                                setViewMediaPreview(true);                                    
                                                            }}} style={{cursor: selectedItem?.contents_video && selectedItem?.contents_video.length > 0 ? "pointer" : ""}}></span>
                                                        {/* <!-- .//활성화 --> */}
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>

                                {selectedItem && selectedItem.dialog && (selectedItem.dialog as IScript[]).length > 0 &&
                                <table className="table-type1">
                                    <caption className="sr-only">텍스트 관리 테이블</caption>
                                    <thead>
                                        <tr>
                                            <th className="text-center" scope="row" colSpan={selectedItem.type_2 === "일반형" ? 6 : 7}>SCRIPT</th>
                                        </tr>
                                        <tr>
                                            {/* <th scope="row"><input className="h-4 w-4 rounded" type="checkbox" disabled /></th> */}
                                            <th className="text-center" scope="row">문단<br/>Key</th>
                                            <th className="text-center" scope="row">문장<br/>Key</th>
                                            {/* {scriptType === "혼합형" &&
                                            <th scope="row">양식</th>}                             */}
                                            {selectedItem.type_2 !== "일반형" &&
                                            <th className="text-center" scope="row">SPEAKER</th>}
                                            <th className="text-center" scope="row">ENTRY</th>
                                            <th className="text-center" scope="row">다국어</th>
                                            <th className="text-center" scope="row">Start</th>
                                            <th className="text-center" scope="row">End</th>
                                            {/* <th scope="row">문단별<br/>문항key</th>
                                            <th scope="row">문단​<br/>Summary</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {(selectedItem.dialog as IScript[]).map((item, idx) => {
                                            return(
                                                <tr key={idx}>
                                                    {/* <td>
                                                        <input id={"script_ck_"+idx} name="script_ck" type="checkbox" className="h-4 w-4 rounded"/>
                                                    </td> */}
                                                    <td className="w-16">
                                                        <span>{item.pkey}</span>
                                                        {/* <input id={"pkey_"+idx} type="text" className="w-8" /> */}
                                                    </td>
                                                    <td className="w-16">
                                                        <span>{item.skey}</span>
                                                        {/* <span id={"skey_"+idx} className="w-8">{item.skey}</span> */}
                                                    </td>
                                                    {/* {scriptType === "혼합형" &&
                                                    <td>
                                                        <div className="radio-wrap">
                                                            <span className="inp-radio">
                                                                <input id={"rd_type1_"+idx} name={"rd_type_"+idx} type="radio" onChange={() => onChangeScriptRDType(idx)} />
                                                                <label htmlFor="rd-mode4-1">대화형</label>
                                                            </span>
                                                            <span className="inp-radio">
                                                                <input id={"rd_type2_"+idx} name={"rd_type_"+idx} type="radio" onChange={() => onChangeScriptRDType(idx)} />
                                                                <label htmlFor="rd-mode4-2">일반형</label>
                                                            </span>
                                                        </div>
                                                    </td>} */}
                        
                                                    {selectedItem.type_2 !== "일반형" &&
                                                    <td className="w-36">
                                                        <span>{item.speaker}</span>
                                                        {/* <input id={"speaker_"+idx} type="text" className="" disabled={item.type === "일반형"} /> */}
                                                    </td>}
                                                    <td>
                                                    <span>{item.entry}</span>
                                                        {/* <input id={"entry_"+idx} type="text" className="" /> */}
                                                    </td>
                                                    <td>
                                                        <span>{item.multiLang}</span>
                                                        {/* <input id={"multiLang_"+idx} type="text" className=""  /> */}
                                                    </td>
                                                    <td className="w-24">
                                                        <span>{item.startTime}</span>
                                                        {/* <input id={"start_"+idx} type="text" className="w-16"  /> */}
                                                    </td>
                                                    <td className="w-24">
                                                        <span>{item.endTime}</span>
                                                        {/* <input id={"end_"+idx} type="text" className="w-16" /> */}
                                                    </td>
                                                    {/* <td>
                                                        <input id={"akey_"+idx} type="text" className="" />
                                                    </td>
                                                    <td>
                                                        <input id={"summary_"+idx} type="text" className="" />
                                                    </td> */}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>}

                                <div className="relative mt-5">
                                    <div className="text-right">
                                        <button type="button" className="btn-sky" onClick={onSave}>저장</button>
                                    </div>
                                </div>

                            </div>}

                            {!viewDetail &&

                            <div className="px-5">

                                <div>
                                    <div className="py-5">
                                    
                                        {/* <!-- 검색영역 --> */}
                                        <ObjectSearch onSearch={onSearch} searchType={props.searchType} showLoading={props.showLoading} />
                                        {/* <!-- .//검색영역 --> */}

                                        {viewList && props.from !== "Teaching" && <>

                                        {/* <!-- table --> */}
                                        <table className="mt-5">
                                            <caption className="sr-only">텍스트 관리 테이블</caption>
                                            <thead>
                                                <tr>
                                                    <th style={{display: props.isMultiSelect ? "" : "none"}} scope="col" className="text-center">
                                                        <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                                        const list_ck = document.getElementsByName("list_ck");
                                                        for (let i = 0; i < list_ck.length; i++) {
                                                            const element = list_ck[i] as HTMLInputElement;
                                                            if(!element.disabled) {
                                                                element.checked = (e.target as HTMLInputElement).checked;
                                                            }                                    
                                                        }
                                                    }} /></th>

                                                    <th scope="col" className="pl-4 pr-3 text-center">번호</th>
                                                    <th scope="col" className="px-3 text-center">구분</th>
                                                    <th scope="col" className="px-3 text-center">KEY</th>
                                                    <th scope="col" className="px-3 text-center">타이틀</th>
                                                    <th scope="col" className="px-3 text-center">교과</th>
                                                    {/* <th scope="col" className="px-3 text-center">교과과정</th> */}
                                                    <th scope="col" className="px-3 text-center">등록자</th>
                                                    <th scope="col" className="px-3 text-center">등록일</th>
                                                    <th scope="col" className="pl-3 pr-4 text-center">{props.from && props.from === "AITutorWritingRegister" ? "사용하기​" : "상세보기"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewList && list &&
                                                <tr style={{display: (list as ILibrary[]).length === 0 ? "" : "none"}}>
                                                    <td colSpan={8}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                                                </tr>}

                                                {viewList && list && (list as ILibrary[]).map((item, idx) => {

                                                    let _unitVal = "";
                                                    let _curriBookVal = ""

                                                    if(curriInfo && curriInfo.curriBook) {
                                                        const curriBook = curriInfo.curriBook as IMeta[];
                                                        const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                                        _curriBookVal = _curriBook ? _curriBook.val : "";

                                                    //     const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                                                    //     const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                                                    //     const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                                                    //     const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                                                    //     const curriUnit5 = curriInfo.curriUnit5 as IMeta[];
                            
                                                    //     const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === item.curriUnit1);
                                                    //     const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === item.curriUnit2);
                                                    //     const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === item.curriUnit3);
                                                    //     const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === item.curriUnit4);
                                                    //     const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === item.curriUnit5);
                            
                                                    //     const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                                                    //     arr.map((val, idx) => {
                                                    //         if(val) {
                                                    //             if(_unitVal === "") {
                                                    //                 _unitVal = val;    
                                                    //             } else {
                                                    //                 if(idx === 2) {
                                                    //                     _unitVal = _unitVal + " > " + "<br/>" + val;

                                                    //                 } else {
                                                    //                     _unitVal = _unitVal + " > " + val;
                                                    //                 }
                                                    //             }
                                                    //         }
                                                    //     });
                                                    }

                                                    const totalCnt = item.full_count;
                                                    const _numOfPage = localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10;
                                                    let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                                                    // logger.log(totalCnt, _numOfPage, currPage, _num);

                                                    let type_1 = "";
                                                    if(item.type_1 === "image") {
                                                        type_1 = "이미지";
                                                    } else if(item.type_1 === "audio") {
                                                        type_1 = "오디오";
                                                    } else if(item.type_1 === "video") {
                                                        type_1 = "비디오";
                                                    } else  {
                                                        type_1 = item.type_1;
                                                    }

                                                    let type = type_1;
                                                    if(item.type_2) {
                                                        type = type + ' > ' + item.type_2;
                                                    }
                        
                                                    let title = item.name;
                                                    if(title && title.length > 50) {
                                                        title = title.substring(0, 47) + "...";
                                                    }
                        
                                                    let bFound = false;

                                                    return (
                                                        // <div key={idx}><img src={item.url} width={40}/>{JSON.stringify(item)}</div>
                                                        <tr key={idx}>           
                                                            <td style={{display:props.isMultiSelect ? "" : "none"}}>
                                                                <input id={"list_ck_"+idx} name="list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" defaultChecked={bFound} onClick={(e) => {
                                                                    const list_ck = document.getElementsByName("list_ck");
                                                                    let cntChecked = 0;
                                                                    for (let i = 0; i < list_ck.length; i++) {
                                                                        const element = list_ck[i] as HTMLInputElement;
                                                                        if(element.checked) {
                                                                            cntChecked = cntChecked + 1;
                                                                        }
                                                                    }
                                                                    const all_check = document.getElementById("all_check") as HTMLInputElement;
                                                                    if(cntChecked === list_ck.length) {                                                
                                                                        all_check.checked = true;                                                
                                                                    } else {
                                                                        all_check.checked = false;                                                
                                                                    }
                                                                }}/>
                                                            </td>

                                                            <td style={{paddingTop:0, paddingBottom:0}}>{_num}</td>
                                                            <td style={{paddingTop:0, paddingBottom:0}}>{type}</td>
                                                            <td style={{paddingTop:0, paddingBottom:0}}>{item.id}</td>
                                                            <td style={{paddingTop:0, paddingBottom:0}}>{title}</td>
                                                            <td style={{paddingTop:0, paddingBottom:0}}>{_curriBookVal}</td>
                                                            {/* <td style={{paddingTop:0, paddingBottom:0}} dangerouslySetInnerHTML={{ __html: _unitVal }}></td> */}
                                                            <td style={{paddingTop:0, paddingBottom:0}}>{item.creator_name}<br/>({item.creator})</td>
                                                            <td style={{paddingTop:0, paddingBottom:0}}>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                                            <td style={{paddingTop:0, paddingBottom:0}}>
                                                                <button type="button" className="btn-white-s" onClick={() => onConfirm(item)}>확인</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        {/* <!-- .//table -->	 */}
                                        </>}

                                        {viewList && props.from === "Teaching" &&

                                        <table className="mt-5 border-none">
                                            <caption className="sr-only">관리 테이블</caption>
                                            <tbody>
                                                {viewList && list &&
                                                <tr style={{display: (list as ILibrary[]).length === 0 ? "" : "none"}}>
                                                    <td colSpan={8}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                                                </tr>}

                                                {viewList && list4 && (list4 as ILibrary[][]).map((item, idx) => {

                                                    return (

                                                        // <div key={idx}><img src={item.url} width={40}/>{JSON.stringify(item)}</div>
                                                        <tr key={idx} style={{borderTopWidth:0}}>       

                                                            {item.map((_item, _idx) => {

                                                            let title = _item.name;
                                                            if(title && title.length > 50) {
                                                                title = title.substring(0, 47) + "...";
                                                            }

                                                            let url = S3Server + (_item.url ? _item.url : "upload/3/20240802/Ocfnpn38x.png");
                                                            if(searchData.type_1 !== "image") {
                                                                url = S3Server + (_item.thumbnail ? _item.thumbnail : "upload/3/20240802/Ocfnpn38x.png");
                                                            }
                                                            
                                                            // logger.log("url", url);

                                                            return (
                                                                <td key={_idx} style={{width: "25%", borderLeftWidth:0, border: "1px groove gold"}}>

                                                                    {/* <table className="" style={{padding: 0, height: "100%"}}>
                                                                        <tbody>
                                                                            <tr className="flex">
                                                                                <td className="w-avail h-avail">
                                                                                    <div>
                                                                                        <img className="text-center" src={url}></img>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{borderTopWidth:0}}>
                                                                                <td>
                                                                                    <span style={{fontSize: "0.8em"}}>{title}</span>
                                                                                    <button type="button" className="btn-white-s" onClick={() => onConfirm(_item)}>선택</button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table> */}

                                                                    <dl className="flex text-center w-avail" style={{border: "0px groove gold", height: "10rem"}}>
                                                                        <dt className="flex w-avail">
                                                                            <img className="" src={url}></img>
                                                                        </dt>
                                                                    </dl>
                                                                    
                                                                    <dl className="flex mt-2" style={{backgroundColor: "#ffffff"}}>
                                                                        <dd className="items-center text-center w-avail">
                                                                            <span style={{fontSize: "0.8em"}}>{title}</span>
                                                                        </dd>                                                                        
                                                                        <dd className="text-right">
                                                                            <button type="button" className="btn-white-s" onClick={() => onConfirm(_item)}>선택</button>
                                                                        </dd>
                                                                    </dl>

                                                                </td>
                                                            )

                                                            })}

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>}
                                    </div>
                                </div>

                                {/* <!-- pagenation --> */}
                                <div className="text-center mb-5">
                                    <nav className="pagenation" aria-label="Pagination">

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Previous</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}

                                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                        return (
                                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                        )})}

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Next</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}
                                        
                                    </nav>
                                </div>
                                {/* <!-- .// pagenation --> */}

                                <div className="relative mb-5" style={{display: props.isMultiSelect ? "" : "none"}}>
                                    <div className="text-center">
                                        <button type="button" className="btn-sky" onClick={onSaveBulk}>저장</button>
                                        <button type="button" className="btn-sky ml-3" onClick={() => {
                                            props.onClose();
                                        }}>취소</button>
                                    </div>
                                </div>

                            </div>}

                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>

            {viewMediaPreview &&
            <PopupMediaPreview type={mediaType} url={mediaUrl} onClose={function (): void {
                setViewMediaPreview(false);
            } } />}

        </div>
    );  
}

export default PopupLibrarySearch;
