import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ISet, ITextBookIndex, IUser } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import { log } from "console";

interface IPopupSetList {
    search?: string;
    setIds?: string[];
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
    onRequst: (set: ISet) => void;
}

function PopupSetList(props: IPopupSetList) {

    const [list, setList] = useState<ISet[]>([] as any);
    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [listCount, setListCount] = useState(0);

    const curriInfo = util.getCurrentCurriInfo();

    const numOfViewPage = 10;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            if(props.setIds) {
                logger.log("props.setIds", props.setIds);
            }

            const div = document.getElementsByClassName("SG-datepicker__input-container");
            logger.log("SG-datepicker__input-container", div);

            if(div) {
                
                const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
                if(!new_CL_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_CL_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.style.paddingTop = "0.4rem";
                    node.style.paddingBottom = "0.4rem";
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[div.length-2].appendChild(node);
                }

                const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
                if(CL_sdate) {
                    CL_sdate.style.display = "none";
                }

                const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
                if(!new_CL_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_CL_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.style.paddingTop = "0.4rem";
                    node_e.style.paddingBottom = "0.4rem";

                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[div.length-1].appendChild(node_e)
                }

                const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
                if(CL_edate) {
                    CL_edate.style.display = "none";
                }
            }

            const search_setCategory = document.getElementById("search_setCategory") as HTMLSelectElement;
            search_setCategory.disabled = false;
            if(props.search) {
                if(curriInfo && curriInfo.setCategory && curriInfo.setCategory.length > 0) {
                    const cate = curriInfo.setCategory.find((item) => item.val === props.search);
                    logger.log("props.search", props.search, cate);
                    if(cate && cate.id) {
                        if(search_setCategory) {
                            search_setCategory.value = String(cate.id);    
                            search_setCategory.disabled = true;
                        }
                    }
                }    
                onSearch();
            } else {
                refreshList();
            }
            
        }
        bDone = true;
    }, [])

    function onClose() {
        props.onClose();
    }

    const refreshList = () => {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        const data = {
            brand_id: brand.id ? brand.id : null,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        const list = await util.getSetSearch(data);
        setList(list);
        logger.log("onSearch", data, list);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const searchKeyword = document.getElementById("searchKeyword") as HTMLInputElement;        

        const search_setCategory = document.getElementById("search_setCategory") as HTMLSelectElement;
        const search_setCategory_val = curriInfo.setCategory[search_setCategory.selectedIndex-1];
        
        // const TB_curriBook = document.getElementById("TB_curriBook") as HTMLSelectElement;
        // const TB_curriBook_val = curriInfo.curriBook[TB_curriBook.selectedIndex-1];
        // const TB_curriYear = document.getElementById("TB_curriYear") as HTMLSelectElement;
        // const TB_curriYear_val = curriInfo.curriYear[TB_curriYear.selectedIndex-1];
        // const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
        // const TB_curriSchool_val = curriInfo.curriSchool[TB_curriSchool.selectedIndex-1];
        // const TB_usage = document.getElementById("TB_usage") as HTMLSelectElement;
        // const TB_usage_val = TB_usage.value;

        const CL_keyword = document.getElementById("CL_keyword") as HTMLInputElement;        
        const CL_keyword_type = document.getElementById("CL_keyword_type") as HTMLInputElement;

        let search = "";
        if(CL_keyword) {
            search = CL_keyword.value;
        }
        let searchField = "";
        if(CL_keyword_type) {
            searchField = CL_keyword_type.value;
        }

        const publicOpen = document.getElementById("publicOpen") as HTMLSelectElement;
        const difficulty = document.getElementById("difficulty") as HTMLSelectElement;

        const CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
        const CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;        
    
        let sdate = "";
        let edate = "";
        const arrSD = CL_sdate.value.split("-");
        const arrED = CL_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = CL_sdate.value;
            edate = CL_edate.value 
        }  

        const data = {
            brand_id: brand.id ? brand.id : null,
            keyword: searchKeyword && searchKeyword.value !== "" ? searchKeyword.value : undefined,
            
            is_publicOpen: publicOpen.value === "" ? undefined : publicOpen.value === "1" ? true : false,
            difficulty: difficulty.value === "" ? undefined : difficulty.value,
            setCategory: search_setCategory_val ? search_setCategory_val.id : undefined,

            reg_sdate: sdate,  
            reg_edate: edate,

            // curriYear: TB_curriYear_val ? TB_curriYear_val.id : undefined,
            // curriSchool: TB_curriSchool_val ? TB_curriSchool_val.id : undefined,
            // curriBook: TB_curriBook_val ? TB_curriBook_val.id : undefined,
            // usage: TB_usage ? TB_usage_val : undefined,

            search: search,
            searchField: searchField,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onRequest(item: ISet) {
        // logger.log(item);
        props.onRequst(item);
        props.onClose();
    }

    function onChangeSetCategory(e: ChangeEvent<HTMLSelectElement>) {
        const value = e.target.value;
        if(value === "") {

        } else {
            const setCategory = curriInfo?.setCategory.find((item) => item.id === Number(value))
            logger.log("onChangeSetCategory", setCategory);
        }
    }

    function onClickSDateIcon() : void {
        const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
        if(new_CL_sdate) {
            // new_CL_sdate.value = "";
            const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
            CL_sdate.value = "";
            CL_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
                    CL_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
        if(new_CL_edate) {
            // new_CL_edate.value = "";
            const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
            CL_edate.value = "";
            CL_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
                    CL_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "CL_sdate") {
            const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
            if(new_CL_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CL_sdate.value = val;
            }
        } else if(e.target.id === "CL_edate") {
            const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
            if(new_CL_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CL_edate.value = val;
            }
        }
    }

    return (

        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{paddingLeft:"5rem", paddingRight:"5rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">수업자료(세트지) 불러오기</h2>

                            <div className="overflow-x-auto px-3 pt-3 mb-3">
                                <ul className="tab-list !flex-nowrap !mb-0">
                                    {/* <li><a href="#" aria-current="page" className="active">전체</a></li>
                                    <li><a href="#">개념</a></li>
                                    <li><a href="#">활동</a></li>
                                    <li><a href="#">문항</a></li> */}
                                    <li><a href="#" className="active">세트지</a></li>
                                    {/* <li><a href="#">슈퍼템플릿</a></li> */}
                                </ul>
                            </div>

                            {/* <!-- 필터 검색 영역 --> */}

                            <div className="p-3 mx-3 mb-3 border border-gray-300 rounded-xl leading-normal text-sm">
                                <div className="text-left">

                                    <fieldset>
                                        <label htmlFor="search-4">콘텐츠 공개 여부</label>
                                        <select id="publicOpen" name="search-4">
                                            <option value={""}>전체</option>
                                            <option value={"1"}>공개</option>
                                            <option value={"0"}>비공개</option>
                                        </select>
                                    </fieldset>
                                    <fieldset>
                                        <label htmlFor="search-5" className="ml-5">세트지 유형</label>
                                        <select id="search_setCategory" name="search-5" onChange={(e) => onChangeSetCategory(e)}>
                                            <option value={""}>전체</option>
                                            {curriInfo && curriInfo.setCategory && (curriInfo.setCategory as IMeta[]).map((item, idx) => {
                                            return(
                                                <option key={idx} value={item.id}>{item.val}</option>              
                                            )})}
                                        </select>
                                    </fieldset>

                                    <fieldset>
                                        <label htmlFor="search-5" className="ml-5">수업자료 난이도</label>
                                        <select id="difficulty" name="search-5">
                                            <option value={""}>전체</option>
                                            {curriInfo && (curriInfo.difficulty as IMeta[]).map((item, idx) => {

                                            let visible = false;
                                            if(curriInfo.difficulty.length === 5) {
                                                if(idx === 0 || idx === 2 || idx === 4) {
                                                    visible = true;
                                                }
                                            } else if(curriInfo.difficulty.length === 7) {
                                                if(idx === 0 || idx === 3 || idx === 6) {
                                                    visible = true;
                                                }
                                            } else if(curriInfo.difficulty.length === 9) {
                                                if(idx === 0 || idx === 4 || idx === 8) {
                                                    visible = true;
                                                }
                                            }

                                            return(
                                            <option key={idx} value={item.id} style={{display: visible ? "" : "none"}}>{item.val}</option>              
                                            )})}
                                        </select>
                                    </fieldset>

                                    <fieldset>
                                        <label htmlFor="date" className="ml-8">등록기간</label>
                                    </fieldset>

                                    <fieldset>
                                        <DatePicker id='CL_sdate' name='CL_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                                    </fieldset>

                                    <fieldset>
                                        <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickSDateIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                            </svg>                                            
                                        </button> 
                                    </fieldset>

                                    <fieldset>
                                        <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                                    </fieldset>

                                    <fieldset>
                                        <DatePicker id='CL_edate' name='CL_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                                    </fieldset>

                                    <fieldset>
                                        <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                            </svg>                                            
                                        </button> 
                                    </fieldset>

                                </div>
                                <div className="text-right">

                                    <fieldset className="mt-3 text-right">
                                        <label htmlFor="search-6">검색</label>
                                        <select id="CL_keyword_type" name="search-6">
                                            <option value={"name"}>작업 명(수업자료 명)</option>
                                            <option value={"id"}>KEY</option>
                                            <option value={"creator"}>등록자</option>
                                        </select>
                                        <input type="text" name="search" id="CL_keyword" className="w-[300px] ml-3" onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                onSearch();
                                            }
                                        }} />
                                        <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                            <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                        </button>
                                    </fieldset>

                                </div>
                            </div>
                            {/* <!-- .//필터 검색 영역 --> */}

                            <div className="overflow-y-auto p-5">
                                {/* <!-- table --> */}
                                <table className="text-center">
                                    <caption className="sr-only">텍스트 관리 테이블</caption>
                                    {/* <colgroup>
                                        <col width="5%"/>
                                        <col width="10%"/>
                                        <col width="15%"/>
                                        <col width="*"/>
                                        <col width="*"/>
                                    </colgroup> */}
                                    <thead>
                                        <tr>
                                            {/* <th scope="row"><input type="checkbox" className="h-4 w-4 rounded" /></th> */}
                                            <th scope="row">번호</th>
                                            <th scope="row">Key</th>
                                            <th scope="row">교과</th>
                                            <th scope="row">유형</th>
                                            {/* <th scope="row">난이도</th> */}
                                            <th scope="row">작업 명(수업자료 명)</th>
                                            {/* <th scope="row">정답</th> */}
                                            {/* <th scope="row">배점</th> */}
                                            <th scope="row">출저</th>
                                            <th scope="row">확인</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && list.map((item, idx) => {

                                            const totalCnt = item.full_count ? item.full_count : 0;
                                            const _numOfPage = 10;
                                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                                            let setCategoryVal = "";
                                            const setCategory = curriInfo?.setCategory.find((set) => set.id === item.setCategory);
                                            if(setCategory) {
                                                setCategoryVal = setCategory.val;
                                            }
                                            
                                            const curriBook = curriInfo?.curriBook.find((meta) => meta.id === item.curriBook);
                                            const curriBookVal = curriBook ? curriBook.val : "-";
                                            
                                            let isIncludeIds = false;
                                            if(props.setIds && props.setIds.length > 0) {
                                                isIncludeIds = props.setIds.includes(String(item.id));
                                            }
                                            // logger.log("isIncludeIds", props.setIds, item.id, isIncludeIds);

                                            return(
                                                
                                                <tr key={idx} className="rt-tr action" onClick={() => {
                                                    // onRequest(item);
                                                }}>
                                                    {/* <td><input type="checkbox" className="h-4 w-4 rounded" /></td> */}
                                                    <td>{_num}</td>
                                                    <td>{item.id}</td>
                                                    <td>{curriBookVal}</td>
                                                    <td>{setCategoryVal}</td>
                                                    {/* <td>상</td> */}
                                                    {/* <Tooltip key={idx} title="불러오기" arrow>
                                                        <td className="rt-td">
                                                            {item.name}
                                                        </td>
                                                    </Tooltip> */}
                                                    <td>{item.name}</td>
                                                    {/* <td>정답</td> */}
                                                    {/* <td>3</td> */}
                                                    <td>비상교육</td>
                                                    <td className="w-8">                                
                                                        <button type="button" className="btn-white-s" disabled={isIncludeIds} onClick={() => onRequest(item)}>확인</button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {/* <!-- .//table -->	 */}

                                <div className="relative mt-10 mb-5">
                                    {/* <!-- pagenation --> */}
                                    <div className="text-center">
                                        <nav className="pagenation" aria-label="Pagination">

                                            {listOfPage && (listOfPage as number[]).length > 0 &&
                                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                                <span className="sr-only">Previous</span>
                                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                                </svg>
                                            </div>}

                                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                            return (
                                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                            )})}

                                            {listOfPage && (listOfPage as number[]).length > 0 &&
                                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                                <span className="sr-only">Next</span>
                                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                                </svg>
                                            </div>}
                                            
                                        </nav>
                                    </div>
                                    {/* <!-- .// pagenation --> */}
                                </div>

                            </div>  
                            {/* <div className="my-5 text-center">
                                <button type="button" className="btn-sky" onClick={onRequest}>불러오기</button>
                            </div> */}
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>            
                </div>
            </div>
        </div>
    );
}
  
export default PopupSetList;
