import React, { useEffect, useState } from 'react';
import './App.css';

import Login from './Login';
import * as util from './util';
import { IAuthGroup, IBrand, ICurriInfo, IMenu, IUser, IUserBrandAuth, S3Server, VERSION, authGroupInfo, authGroupInfo_new } from './common';
import Contents from './Contents';
import Header from './Header';
import LeftMenu from './LeftMenu';
import MyModal from './MyModal';

import { logger } from "./logger";
import CircularProgress from '@mui/material/CircularProgress';
import PopupLibrarySearch from './library/PopupLibrarySearch';

interface IApp {
}

function App() {

    const [is_SignIn, setIs_SignIn] = useState(false);

    // const [selectedBrand, setSelectedBrand] = useState(null as any);    
    // const [brandList, setBrandList] = useState(null as any);
    // const [state, setState] = useState(null as any);

    // const [menu, setMenu] = useState({menu1: 0, menu2: 0} as any);

    // const [curriInfo, setCurriInfo] = useState(null as any);
    // const [reloadMenu, setReloadMenu] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);
    const [msgTitle, setMsgTitle] = React.useState("");
    const [msgDescription, setMsgDescription] = React.useState("");

    const [brand, setBrand] = useState(null as any);
    const [menu, setMenu] = useState(null as any);

    const [view, setView] = useState(false);
    const [loading, setLoading] = useState(false);

    const [isTest, setIsTest] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [popup, setPopup] = useState("");

    const [isVersion, setIsVersion] = useState(false);

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // setView(false);
            afterLogin();
                        
            // util.setCurrentMenu({ menu1: 0, menu2: 0 });

            // console.log("location >>> ", window.location);
            // console.log("location.search >>> ", window.location.search);     

            const params = new URLSearchParams(window.location.search);          
            const isTest = params.get("test");
            if(isTest !== null) {
                setIsTest(true);
            }          

            const logout = params.get("logout");
            if(logout !== null) {
                onLogout();
            }          

            const popup = params.get("popup");
            if(popup !== null) {
                setIsPopup(true);
                setPopup(popup);
            }          

            const version = params.get("version");
            if(version !== null) {
                setIsVersion(true);
            }          

            // if(popup === "PopupLibrarySearch") {
            //     logger.log("PopupLibrarySearch");
            // }          
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(is_SignIn) {
            // getBrandList();
            // logger.log("localStorage", localStorage);
            // getCurriInfo();
        } else {
            // util.setCurrentBrand(null);
            // setView(false);
        }       
    }, [is_SignIn])
    
    const afterLogin = async () => {        
        const checkLogin = await util.checkLogin();
        setIs_SignIn(checkLogin);
    }

    function onLogout(): void {
        
        util.setUserInfo(null);
        util.setCurrentBrand(null);
        util.setCurrentMenu(null);
        util.setCurrentAuth(null);
        util.setCurrentCurriInfo(null);

        afterLogin();
    }

    async function onSelectMenu(newMenu: IMenu) {

        // if(newMenu.menu1 === 9 && newMenu.menu2 === 4) {
        //     onLogout();
        //     return;
        // }

        setView(true);            

        // showLoading(true);

        // const currMenu = util.getCurrentMenu();

        // if(currMenu && currMenu.menu1 !== 4 && newMenu.menu1 === 4) {
        //     await getCurriInfo();
        // } else if(newMenu.menu1 === 4 && newMenu.menu2 === 5) {
        //     await getCurriInfo();
        // } else if(newMenu.menu1 === 0 && newMenu.menu2 === 0) {
        //     await getCurriInfo();
        // } else if(newMenu.menu1 === 0 && newMenu.menu2 === 1) {
        //     await getCurriInfo();
        // } else if(newMenu.menu1 === 0 && newMenu.menu2 === 2) {
        //     await getCurriInfo();
        // } else if(newMenu.menu1 === 1 && newMenu.menu2 === 0) {
        //     await getCurriInfo();
        // } else if(newMenu.menu1 === 1 && newMenu.menu2 === 1) {
        //     await getCurriInfo();
        // } else if(newMenu.menu1 === 1 && newMenu.menu2 === 2) {
        //     await getCurriInfo();
        // }    

        util.setCurrentMenu(newMenu);
        setMenu(newMenu);

        // const authMenu = util.getAuthMenu(newMenu);
        
        logger.log("onSelectMenu", newMenu);

        // setTimeout(() => {
        //     showLoading(false);                        
        // }, 1000);
    }
    
    async function _setCurrentAuth(bid : number) {
        
        const authGroupList = await util.getAuthGroupList();
        if(!authGroupList) {
            alert("권한 가져오기 실패!!!");
            return;
        }

        const me = await util.getUserById(util.getUserInfo("uid")); 
        if(!me) {
            alert("사용자 가져오기 실패!!!");
            return;
        }
        const authgroup = (me as IUser).authgroup;

        let myAuthGroup : IAuthGroup[] = [];
        const arrAuthGroupId = authgroup.split(",");
        arrAuthGroupId.map((aid) => {
            const authGroup = authGroupList.find((item) => item.id === Number(aid));
            if(authGroup) {
                myAuthGroup.push(authGroup);
            }
        });
        // logger.log("setMyBrandList", arrAuthGroupId, myAuthGroup);

        const auth = myAuthGroup.find((item) => item.brand_id === bid);
        if(auth) {
            const strAuth = auth.auth;
            let newAuth = "";
            if(strAuth.indexOf("[") < 0) {
                for (let i = 0; i < authGroupInfo.length; i++) {
                    if(strAuth.indexOf(authGroupInfo[i]+"1") > -1) {
                        if(newAuth === "") {
                            newAuth = "[" + authGroupInfo[i] + "]1";
                        } else {
                            newAuth = newAuth + ",[" + authGroupInfo[i] + "]1";
                        }

                        for (let j = 0; j < authGroupInfo_new[i].length; j++) {
                            const ag = authGroupInfo_new[i][j];
                            newAuth = newAuth + ",[" + authGroupInfo_new[i][j] + "]1";
                        }                        
                    } else {
                        if(strAuth.indexOf(authGroupInfo[i]) > -1) {
                            if(newAuth === "") {
                                newAuth = "[" + authGroupInfo[i] + "]";
                            } else {
                                newAuth = newAuth + ",[" + authGroupInfo[i] + "]";
                            }
    
                            for (let j = 0; j < authGroupInfo_new[i].length; j++) {
                                const ag = authGroupInfo_new[i][j];
                                newAuth = newAuth + ",[" + authGroupInfo_new[i][j] + "]";
                            }                        
                        }                                
                    }                            
                }
            } else {
                newAuth = strAuth;
            }
            util.setCurrentAuth(newAuth);
        }
    }

    async function onChangeBrand(_brand : IBrand) {

        logger.log("Brand changed!!!", brand, _brand);

        if(_brand && _brand.id) {

            showLoading(true);

            await _setCurrentAuth(_brand.id);
    
            util.setCurrentBrand(_brand);

            // if(brand) {
            //     await getCurriInfo();
            // }

            await getCurriInfo();

            setBrand(_brand);                

            showLoading(false);
        }
    }

    function showModal(title: string, description: string): void {
        setMsgTitle(title);
        setMsgDescription(description);
        setModalShow(true);
    }

    function showLoading(loading: boolean, afterSec: number = 0): void {
        if(loading) {
            setLoading(loading);
        } else {
            setTimeout(() => {
                setLoading(loading);                
            }, 500);
        }
    }

    function onCloseModal(): void {
        setModalShow(false);
    }

    async function getCurriInfo() {

        const currBrand = util.getCurrentBrand();
        if(!currBrand) {
            return;
        }
        // logger.log("currBrand", currBrand);  

        const names = [
                        "curriBook", /*"curriUnit1", "curriUnit2", "curriUnit3", "curriUnit4", "curriUnit5", "curriUnit6", */
                        "difficulty", 
                        "curriYear", 
                        "curriSchool", 
                        "curriSubject", 
                        "curriGrade", 
                        "curriSemester",
                        "articleCategory",
                        "articleType",
                        "textbookCategory",
                        "setCategory",
        ];
        const listAll = await util.getMetaNamesList(names);
        const _listAll = listAll.filter((meta, _) => meta.is_active);

        logger.log("getCurriInfo listAll", _listAll);  

        // const _listAll = listAll.slice(0).reverse().map(element => {return element});
        const filtered_listAll = _listAll.filter((meta) => meta.brand_id === currBrand.id);

        const _curriInfo : ICurriInfo = {
            all: _listAll,
            curriBook: filtered_listAll.filter((meta, _) => meta.name === "curriBook"),
            // curriUnit1: filtered_listAll.filter((meta, _) => meta.name === "curriUnit1"),
            // curriUnit2: filtered_listAll.filter((meta, _) => meta.name === "curriUnit2"),
            // curriUnit3: filtered_listAll.filter((meta, _) => meta.name === "curriUnit3"),
            // curriUnit4: filtered_listAll.filter((meta, _) => meta.name === "curriUnit4"),
            // curriUnit5: filtered_listAll.filter((meta, _) => meta.name === "curriUnit5"),
            // curriUnit6: filtered_listAll.filter((meta, _) => meta.name === "curriUnit6"),
            difficulty: filtered_listAll.filter((meta, _) => meta.name === "difficulty"),

            curriYear: _listAll.filter((meta, _) => meta.name === "curriYear"),
            curriSchool: _listAll.filter((meta, _) => meta.name === "curriSchool"),
            curriSubject: _listAll.filter((meta, _) => meta.name === "curriSubject"),
            curriGrade: _listAll.filter((meta, _) => meta.name === "curriGrade"),
            curriSemester: _listAll.filter((meta, _) => meta.name === "curriSemester"),
            articleCategory: _listAll.filter((meta, _) => meta.name === "articleCategory"),
            articleType: _listAll.filter((meta, _) => meta.name === "articleType"),
            textbookCategory: _listAll.filter((meta, _) => meta.name === "textbookCategory"),
            setCategory: _listAll.filter((meta, _) => meta.name === "setCategory"),
        }

        util.setCurrentCurriInfo(_curriInfo);
    }

    async function getUserBrandAuth() {

        const listUser = await util.getUserList();
        const listBrand = await util.getBrandList();
        const listAuth = await util.getAuthGroupList();

        const _UserBrandAuth : IUserBrandAuth = {
            user: listUser,
            brand: listBrand,
            authgroup: listAuth,
        }

        // util.setCurrentCurriInfo(_curriInfo);
    }    

    return (
        <div>

        {isVersion &&
            <>{VERSION}</>
        }

        {!isVersion && 
            <>            
                {is_SignIn &&
                <div className="wrap" style={{display: is_SignIn && !popup ? "" : "none"}}>
                    <Header brand={brand} menu={menu} onChangeBrand={onChangeBrand} onLogout={onLogout} />
                    <div className="container">
                        <LeftMenu onSelectMenu={onSelectMenu} brand={brand} showModal={showModal} onLogout={onLogout} />
                        <Contents view={view} brand={brand} menu={menu} showModal={showModal} showLoading={showLoading} />
                    </div>
                </div>}

                {is_SignIn && popup === "librarySearch" &&
                <div className="wrap" style={{display: is_SignIn ? "" : "none", padding: "5rem"}}>
                    <PopupLibrarySearch brand={brand} showModal={showModal} showLoading={showLoading} searchType={'media'} from={"Teaching"} onClose={function (): void {
                        if(window.opener) {
                            window.close();
                        } else {
                            // window.location.href = "/";
                        }
                        } } onSelect={function (item: any): void {
                            alert(S3Server + item.url);
                        } } />
                </div>}

                {!is_SignIn && <Login view={!is_SignIn} afterLogin={afterLogin} showModal={showModal} showLoading={showLoading} isTest={isTest} />}
            
                <MyModal view={modalShow} onClose={onCloseModal} title={msgTitle} description={msgDescription} />

                {loading &&
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim_light">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>}

            </>
        }

        </div>   
    );      
}

export default App;
