import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, IMenu, IMeta, ITextbookBasicData, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import ContentsList from "./TextBookIndexList";
import TextBookEdit_1 from "./TextBookEdit_1";

interface ITextBookEdit {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    onClose: () => void;
}
  
function TextBookEdit(props: ITextBookEdit) {
    
    const [version, setVersion] = useState(1);
    const [lastUpdateDate, setLastUpdateDate] = useState("");
    // const [subject, setSubject] = useState("");
    const [textbookCategory, setTextbookCategory] = useState<IMeta[]>([]);
    const [basicData, setBasicData] = useState(null as any);
    
    const [viewList, setViewList] = useState(false);

    const [searchData, setSearchData] = useState(null as any);

    const [isNext, setIsNext] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const [imgFile, setImgFile] = useState(null as any);

    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setLastUpdateDate(util.getDate());

            setVersion(1);

            // const brand = util.getCurrentBrand();
            // if(brand) {
            //     setSubject("수학");
            // }    
            
            // (document.getElementById("TE_public") as HTMLInputElement).checked = true;
            (document.getElementById("TE_auth_all") as HTMLInputElement).checked = true;
            (document.getElementById("TE_pdf") as HTMLInputElement).checked = true;

            const TE_publish = (document.getElementById("TE_publish") as HTMLInputElement);
            TE_publish.checked = true;
            const TE_non_publish = (document.getElementById("TE_non_publish") as HTMLInputElement);
            TE_non_publish.checked = false;
    
            const TE_title = document.getElementById("TE_title") as HTMLInputElement;
            TE_title.focus();

            getTextBookCategory();
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
        }
        preBrand = props.brand;
    }, [props.brand])

    async function getTextBookCategory() {

        const list = curriInfo?.textbookCategory;
        // const list = await util.getMetaList("1", "textbookCategory");
        if(list) {
            const _list = list.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTextbookCategory(_list);    
        }
    }

    async function onNext() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const TE_title = document.getElementById("TE_title") as HTMLInputElement;
        if(TE_title.value === "") {
            props.showModal("", "교과서 제목을 입력해 주세요.");
            TE_title.focus();
            return;
        }

        const TE_lang = document.getElementById("TE_lang") as HTMLSelectElement;
        // const TE_childBook = document.getElementById("TE_childBook") as HTMLSelectElement;

        // const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
        // const TE_curriYear_val = curriInfo.curriYear[TE_curriYear.selectedIndex];
        const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
        const TE_curriBook_val = curriInfo.curriBook[TE_curriBook.selectedIndex];
        const TE_textbookCategory = document.getElementById("TE_textbookCategory") as HTMLSelectElement;
        const TE_textbookCategory_val = textbookCategory[TE_textbookCategory.selectedIndex];
        
        // const TE_public = (document.getElementById("TE_public") as HTMLInputElement);
        const TE_pdf = (document.getElementById("TE_pdf") as HTMLInputElement);

        const TE_auth_all = (document.getElementById("TE_auth_all") as HTMLInputElement);
        const TE_auth_T = (document.getElementById("TE_auth_T") as HTMLInputElement);
        // const TE_auth_S = (document.getElementById("TE_auth_S") as HTMLInputElement);

        const TE_publish = (document.getElementById("TE_publish") as HTMLInputElement);
        const TE_non_publish = (document.getElementById("TE_non_publish") as HTMLInputElement);

        const TE_numofpages = document.getElementById("TE_numofpages") as HTMLInputElement;

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        props.showLoading(true);

        let filename = "";

        if(imgFile) {

            const filepath = "upload/"+brand.id+"/textbook/";
            filename = filepath+imgFile.name;
    
            logger.log("onFileUploadForSave started!!!");      

            const bUpload = await util.S3Upload(imgFile, filepath);      
            logger.log("onFileUploadForSave S3Upload", imgFile, bUpload);       
            if(!bUpload) {
                logger.log("onFileUploadForSave failed!!!");  
                props.showLoading(false);
                return;
            }
            logger.log("onFileUploadForSave finished!!!");      
        }

        const data = {
            type: "web",
            brand_id: brand.id,
            name: TE_title.value,

            creator: uid,
            creator_id: _id,
            creator_name: uname,

            // subject: subject,
            language: TE_lang.value,
            // is_publicOpen: TE_public.checked,
            supportPdf: TE_pdf.checked,
            accessLevel: TE_auth_all.checked ? 12 : TE_auth_T.checked ? 1 : 2,
            // child: Number(TE_childBook.value),
            textbookCategory: Number(TE_textbookCategory_val.id),
            // curriYear: Number(TE_curriYear_val.id),
            curriBook: Number(TE_curriBook_val.id),

            maxPages: TE_numofpages ? TE_numofpages.value : undefined,

            image_url: imgFile ? filename : undefined,

            is_publish: TE_publish.checked,
        };

        const result = await util.textbookForSave(data, "insert");

        logger.log("onNext", data, result);   

        props.showLoading(false);

        if(result) {
            setBasicData(result);     
            setIsNext(true);    
        }

    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        // logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const file = e.target.files![0];

        setImgFile(file);
        logger.log("handleChange", file);
    }

    return (
        <div>

            {!isNext && <>
            <div className="top-title">
                <h3>웹교과서 등록</h3>
            </div>

            <h4>교과서 설정</h4>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">교과서 등록</caption>
                <tbody>
                    <tr>
                        <th scope="row" className="w-56">교과서 버전</th>
                        <td className="text-left" colSpan={2}>{version} <span className="text-red ml-5">{lastUpdateDate} 최종 수정</span></td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-title">교과서 제목</label></th>
                        <td className="text-left" colSpan={2}>
                            <input type="text" id="TE_title" className="w-full" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-process">교과과정</label></th>
                        <td className="text-left" colSpan={2}>
                            {/* <select id="TE_curriYear" name="search-3">
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select> */}
                            <select className="" id="TE_curriBook" name="search-3">
                                {curriInfo && curriInfo?.curriBook && (curriInfo?.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    {/* <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학교급</label></th>
                        <td className="text-left">
                            <select id="TE_curriSchool" name="search-4">
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <th scope="row" className="w-56">과목</th>
                        <td className="text-left">{subject}</td>
                    </tr> */}
                    {/* <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">하위교과서</label></th>
                        <td className="text-left flex items-center text-xs">
                            <select id="TE_childBook" name="search-5">
                                <option value={0}>없슴</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                            <p className="text-center ml-5">* 해당 교육과정/학교급에 따른 교과서를 다중으로 등록할 경우 선택 해 주세요</p>
                        </td>
                    </tr> */}
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">교과서 유형</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_textbookCategory" name="sel-type">
                                {textbookCategory && textbookCategory.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    {/* <tr>
                        <th scope="row" className="w-56">공개여부</th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="TE_public" name="rd-public" type="radio" />
                                    <label htmlFor="rd-public">공개</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_non_public" name="rd-public" type="radio" />
                                    <label htmlFor="rd-private">비공개</label>
                                </span>
                            </div>
                        </td>
                    </tr> */}
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-lang">표준언어</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_lang" name="sel-lang">
                                <option value={"한국어"}>한국어</option>
                                <option value={"영어"}>영어</option>
                                <option value={"중국어"}>중국어</option>
                                <option value={"베트남어"}>베트남어</option>
                            </select>
                        </td>
                    </tr>

                    <tr style={{display:""}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-lang">목차 상단 이미지</label></th>
                        <td className="text-left" colSpan={2}>
                            <input className={""} name="file" type="file" onChange={(e) => handleChange(e)} />
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" className="w-56">접근권한</th>
                        <td className="text-left" colSpan={2}>
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="TE_auth_all" name="rd-access" type="radio" />
                                    <label htmlFor="rd-access1">전체</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_auth_T" name="rd-access" type="radio" />
                                    <label htmlFor="rd-access2">교수자</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_auth_S" name="rd-access" type="radio" />
                                    <label htmlFor="rd-access3">학생</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">PDF교과서 형식 지원</th>
                        <td className="text-left w-56" colSpan={2}>
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="TE_pdf" name="rd-pdf" type="radio" />
                                    <label htmlFor="rd-pdf1">사용가능</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_non_pdf" name="rd-pdf" type="radio" />
                                    <label htmlFor="rd-pdf2">사용불가</label>
                                </span>
                            </div>
                        </td>
                        {/* <td className="text-left w-avail" style={{borderLeftWidth:0}}>
                            <span className="mr-2">URL</span>
                            <input className="w-[600px]" type="text" id="pdfUrl" placeholder="연계되는 PDF교과서의 링크를 입력 해 주세요​." />
                        </td> */}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">전체 페이지수​</th>
                        <td className="text-left w-avail" colSpan={2}>
                            <input type="text" id="TE_numofpages" className="w-96" placeholder="해당 교과서의 마지막 페이지 수를 입력해주세요." />
                        </td>
                        {/* <td className="text-left w-avail" style={{borderLeftWidth:0}}>
                            <span className="mr-2">URL</span>
                            <input className="w-[600px]" type="text" id="pdfUrl" placeholder="연계되는 PDF교과서의 링크를 입력 해 주세요​." />
                        </td> */}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">출원여부​</th>
                        <td className="text-left w-56" colSpan={2}>
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="TE_publish" name="rd-publish" type="radio" />
                                    <label htmlFor="rd-pdf1">출원 교과서</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_non_publish" name="rd-publish" type="radio" />
                                    <label htmlFor="rd-pdf2">미출원 교과서</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
            
            {/* <div className="mt-5 text-right">
                <button type="button" className="btn-sky">다음</button>
            </div> */}

            <div className="flex justify-between mt-5">
                <button type="button" className="btn-white" onClick={props.onClose}>취소</button>
                <button type="button" className="btn-sky" onClick={onNext}>다음</button>
            </div></>}

            {isNext && <TextBookEdit_1 brand={props.brand} menu={props.menu} originTextBook={basicData} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                props.onClose();
            }} />}

        </div>

    );  
}
export default TextBookEdit;
