import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, IInstrument, ILibrary, ILibraryFile, IMeta, IScript, S3Server, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import { NavigationType, useNavigationType } from "react-router-dom";

interface IInstrumentRegister {
    brand: IBrand;
    selectedItem: IInstrument | null;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function InstrumentRegister(props: IInstrumentRegister) {

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    
    const [curriDepth, setCurriDepth] = useState(0);
        
    const curriInfo = util.getCurrentCurriInfo();
    const currMenu = util.getCurrentMenu();
    const currBrand = util.getCurrentBrand();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     props.OnClose();
            //     return;
            // }

            setCurriDepth(0);

            const selectedItem = props.selectedItem;
            if(selectedItem) {
                init(selectedItem.id);
                logger.log("selectedItem", selectedItem);
            } else {
                (document.getElementById('IR_rd-on') as HTMLInputElement).checked = true;
                (document.getElementById('IR_rd-off') as HTMLInputElement).checked = false;
                (document.getElementById('IR_rd-public-on') as HTMLInputElement).checked = true;
                (document.getElementById('IR_rd-public-off') as HTMLInputElement).checked = false;    
                const IR_module_off = document.getElementById("IR_module_off") as HTMLInputElement;
                IR_module_off.checked = true;
                const IR_scale_off = document.getElementById("IR_scale_off") as HTMLInputElement;
                IR_scale_off.checked = true;
                const IR_eng_off = document.getElementById("IR_eng_off") as HTMLInputElement;
                IR_eng_off.checked = true;
            }
        }
        bDone = true;
    }, [])

    async function init(id: number) {

        props.showLoading(true);
        const result = await util.getCurriToolInfo(id);
        logger.log("init", result);
        props.showLoading(false);
        
        if(result) {

            const selectedItem  = result as IInstrument;

            (document.getElementById('IR_name') as HTMLInputElement).value = selectedItem.name;

            (document.getElementById('IR_rd-on') as HTMLInputElement).checked = selectedItem.is_active;
            (document.getElementById('IR_rd-off') as HTMLInputElement).checked = !selectedItem.is_active;    
            (document.getElementById('IR_rd-public-on') as HTMLInputElement).checked = selectedItem.is_publicOpen;
            (document.getElementById('IR_rd-public-off') as HTMLInputElement).checked = !selectedItem.is_publicOpen;
    
            const IR_module_off = document.getElementById("IR_module_off") as HTMLInputElement;
            IR_module_off.checked = !selectedItem.module;
            const IR_module_on = document.getElementById("IR_module_on") as HTMLInputElement;
            IR_module_on.checked = selectedItem.module;
            const IR_scale_off = document.getElementById("IR_scale_off") as HTMLInputElement;
            IR_scale_off.checked = !selectedItem.scale;
            const IR_scale_on = document.getElementById("IR_scale_on") as HTMLInputElement;
            IR_scale_on.checked = selectedItem.scale;
            const IR_includeType = document.getElementById("IR_includeType") as HTMLSelectElement;
            IR_includeType.value = selectedItem.includeType;
            const IR_eng_off = document.getElementById("IR_eng_off") as HTMLInputElement;
            IR_eng_off.checked = !selectedItem.subjectTemplate;
            const IR_eng_on = document.getElementById("IR_eng_on") as HTMLInputElement;
            IR_eng_on.checked = selectedItem.subjectTemplate;

            const IR_description = (document.getElementById('IR_description') as HTMLInputElement);
            if(IR_description) {
                IR_description.value = selectedItem.description;
            }
            const IR_code = (document.getElementById('IR_code') as HTMLInputElement);
            if(IR_code) {
                IR_code.value = selectedItem.code;
            }
            const IR_type = (document.getElementById('IR_type') as HTMLSelectElement);
            if(IR_type) {
                IR_type.value = selectedItem.type;
            }
            const IR_IntroFileUrl = (document.getElementById('IR_introFileUrl') as HTMLSelectElement);
            if(IR_IntroFileUrl) {
                IR_IntroFileUrl.value = selectedItem.introFileUrl;
            }
            const IR_FontCssUrl = (document.getElementById('IR_fontCssUrl') as HTMLSelectElement);
            if(IR_FontCssUrl) {
                IR_FontCssUrl.value = selectedItem.fontCssUrl;
            }
            const IR_ImageUrl = (document.getElementById('IR_imageUrl') as HTMLSelectElement);
            if(IR_ImageUrl) {
                IR_ImageUrl.value = selectedItem.imageUrl;
            }
            const IR_Width = (document.getElementById('IR_width') as HTMLSelectElement);
            if(IR_Width) {
                IR_Width.value = selectedItem.width;
            }
            const IR_Height = (document.getElementById('IR_height') as HTMLSelectElement);
            if(IR_Height) {
                IR_Height.value = selectedItem.height;
            }
            const IR_Tag = (document.getElementById('IR_tag') as HTMLSelectElement);
            if(IR_Tag) {
                IR_Tag.value = selectedItem.tag;
            }
            const IR_Params = (document.getElementById('IR_params') as HTMLSelectElement);
            if(IR_Params) {
                IR_Params.value = selectedItem.params;
            }
            const IR_Meta = (document.getElementById('IR_meta') as HTMLSelectElement);
            if(IR_Meta) {
                IR_Meta.value = selectedItem.meta;
            }

            const curriBook = curriInfo?.curriBook.find((meta, _) => meta.id === selectedItem.curriBook);
    
            if(curriInfo && curriBook) {

                const _bookDescendantsList = await getDescendantsList(curriBook.code);
    
                const IR_textbook = (document.getElementById("IR_textbook") as HTMLSelectElement);
                if(IR_textbook) {
                    IR_textbook.value = curriBook.val;
                }
    
                if(_bookDescendantsList && _bookDescendantsList.length > 0) {
                    const curriUnit1s = _bookDescendantsList.filter((item) => item.parent_id === curriBook.id);
                    setCurriUnit1(curriUnit1s);
        
                    setTimeout(() => {
                        const curriUnit1 = curriUnit1s.find((meta, _) => meta.id === selectedItem.curriUnit1);
                        if(curriUnit1) {
                            (document.getElementById("IR_curriUnit1") as HTMLSelectElement).value = String(curriUnit1.id);
            
                            const curriUnit2s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit1.id);
                            setCurriUnit2(curriUnit2s);
                            setTimeout(() => {
                                const curriUnit2 = curriUnit2s.find((meta, _) => meta.id === selectedItem.curriUnit2);
                                if(curriUnit2) {
                                    (document.getElementById("IR_curriUnit2") as HTMLSelectElement).value = String(curriUnit2.id);
            
                                    const curriUnit3s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit2.id);
                                    setCurriUnit3(curriUnit3s);
                                    setTimeout(() => {
                                        const curriUnit3 = curriUnit3s.find((meta, _) => meta.id === selectedItem.curriUnit3);
                                        if(curriUnit3) {
                                            (document.getElementById("IR_curriUnit3") as HTMLSelectElement).value = String(curriUnit3.id);
            
                                            const curriUnit4s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit3.id);
                                            setCurriUnit4(curriUnit4s);
                                            setTimeout(() => {
                                                const curriUnit4 = curriUnit4s.find((meta, _) => meta.id === selectedItem.curriUnit4);
                                                if(curriUnit4) {
                                                    (document.getElementById("IR_curriUnit4") as HTMLSelectElement).value = String(curriUnit4.id);
            
                                                    const curriUnit5s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit4.id);
                                                    setCurriUnit5(curriUnit5s);
                                                    setTimeout(() => {
                                                        const curriUnit5 = curriUnit5s.find((meta, _) => meta.id === selectedItem.curriUnit5);
                                                        if(curriUnit5) {
                                                            (document.getElementById("IR_curriUnit5") as HTMLSelectElement).value = String(curriUnit5.id);
                                                        }                            
                                                    }, 10);
                                                }                            
                                            }, 10);
                                        }                            
                                    }, 10);    
                                }                            
                            }, 10);        
                        }                            
                    }, 10);    
                }    
            }
        }
    }

    async function getLibraryMetaList(id: number) {

        const list = await util.getLibraryMetaList(id);    
        const curriSchool = list.find((meta, _) => meta.name === "curriSchool");
        const curriSubject = list.find((meta, _) => meta.name === "curriSubject");
        const difficulty = list.find((meta, _) => meta.name === "difficulty");
        logger.log("getLibraryMetaList", list);  

        const IR_school = (document.getElementById('IR_school') as HTMLSelectElement);
        if(IR_school) {
            IR_school.value = curriSchool ? curriSchool.val : "";
        }
        const IR_subject = (document.getElementById('IR_subject') as HTMLSelectElement);
        if(IR_subject) {
            IR_subject.value = curriSubject ? curriSubject.val : "";
        }
        const IR_level = (document.getElementById('IR_level') as HTMLSelectElement);
        if(IR_level) {
            IR_level.value = difficulty ? difficulty.val : "";
        }

        let max_depth = 0;
        const curriBook = list.find((meta, _) => meta.name === "curriBook");
        if(curriBook && curriBook.max_depth) {
            max_depth = curriBook.max_depth;
        } else {
            const curriUnit1 = list.find((meta, _) => meta.name === "curriUnit1");
            if(curriUnit1) {
                max_depth = 1;
            }
            const curriUnit2 = list.find((meta, _) => meta.name === "curriUnit2");
            if(curriUnit2) {
                max_depth = 2;
            }
            const curriUnit3 = list.find((meta, _) => meta.name === "curriUnit3");
            if(curriUnit3) {
                max_depth = 3;
            }
            const curriUnit4 = list.find((meta, _) => meta.name === "curriUnit4");
            if(curriUnit4) {
                max_depth = 4;
            }
            const curriUnit5 = list.find((meta, _) => meta.name === "curriUnit5");
            if(curriUnit5) {
                max_depth = 5;
            }
        }
        setCurriDepth(max_depth);
    }

    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {

            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        
        const curriBook = curriInfo?.curriBook.find((meta, _) => meta.val === e.target.value);
        if(curriBook) {
            getDescendantsList(curriBook.code);

            const IR_html_curriUnit1 = document.getElementById("IR_curriUnit1") as HTMLSelectElement;
            const IR_html_curriUnit2 = document.getElementById("IR_curriUnit2") as HTMLSelectElement;
            const IR_html_curriUnit3 = document.getElementById("IR_curriUnit3") as HTMLSelectElement;
            const IR_html_curriUnit4 = document.getElementById("IR_curriUnit4") as HTMLSelectElement;
            const IR_html_curriUnit5 = document.getElementById("IR_curriUnit5") as HTMLSelectElement;
        
            if(IR_html_curriUnit1) {
                IR_html_curriUnit1.selectedIndex = 0;
            }
            if(IR_html_curriUnit2) {
                IR_html_curriUnit2.selectedIndex = 0;
            }
            if(IR_html_curriUnit3) {
                IR_html_curriUnit3.selectedIndex = 0;
            }
            if(IR_html_curriUnit4) {
                IR_html_curriUnit4.selectedIndex = 0;
            }
            if(IR_html_curriUnit5) {
                IR_html_curriUnit5.selectedIndex = 0;
            }
    
            setCurriUnit2([]);
            setCurriUnit3([]);
            setCurriUnit4([]);
            setCurriUnit5([]);
        } else {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const IR_html_curriUnit2 = document.getElementById("IR_curriUnit2") as HTMLSelectElement;
        const IR_html_curriUnit3 = document.getElementById("IR_curriUnit3") as HTMLSelectElement;
        const IR_html_curriUnit4 = document.getElementById("IR_curriUnit4") as HTMLSelectElement;
        const IR_html_curriUnit5 = document.getElementById("IR_curriUnit5") as HTMLSelectElement;
    
        if(IR_html_curriUnit2) {
            IR_html_curriUnit2.selectedIndex = 0;
        }
        if(IR_html_curriUnit3) {
            IR_html_curriUnit3.selectedIndex = 0;
        }
        if(IR_html_curriUnit4) {
            IR_html_curriUnit4.selectedIndex = 0;
        }
        if(IR_html_curriUnit5) {
            IR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const IR_html_curriUnit3 = document.getElementById("IR_curriUnit3") as HTMLSelectElement;
        const IR_html_curriUnit4 = document.getElementById("IR_curriUnit4") as HTMLSelectElement;
        const IR_html_curriUnit5 = document.getElementById("IR_curriUnit5") as HTMLSelectElement;
    
        if(IR_html_curriUnit3) {
            IR_html_curriUnit3.selectedIndex = 0;
        }
        if(IR_html_curriUnit4) {
            IR_html_curriUnit4.selectedIndex = 0;
        }
        if(IR_html_curriUnit5) {
            IR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const IR_html_curriUnit4 = document.getElementById("IR_curriUnit4") as HTMLSelectElement;
        const IR_html_curriUnit5 = document.getElementById("IR_curriUnit5") as HTMLSelectElement;
    
        if(IR_html_curriUnit4) {
            IR_html_curriUnit4.selectedIndex = 0;
        }
        if(IR_html_curriUnit5) {
            IR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const IR_html_curriUnit5 = document.getElementById("IR_curriUnit5") as HTMLSelectElement;    
        if(IR_html_curriUnit5) {
            IR_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function OnClose(): void {
        props.OnClose();
    }

    async function onSave() {

        if(!curriInfo) {
            return;
        }

        const IR_name = document.getElementById("IR_name") as HTMLInputElement;
        const IR_name_val = IR_name.value;
        if(IR_name_val === "") {
            IR_name.focus();
            props.showModal("", "도구 이름을 입력해 주세요.");
            return;
        }

        const IR_description = document.getElementById("IR_description") as HTMLInputElement;
        const IR_description_val = IR_description.value;
        if(IR_description_val === "") {
        }

        const IR_code = document.getElementById("IR_code") as HTMLInputElement;
        const IR_code_val = IR_code.value;
        if(IR_code_val === "") {
            IR_code.focus();
            props.showModal("", "코드를 입력해 주세요.");
            return;
        }

        const IR_type = document.getElementById("IR_type") as HTMLSelectElement;
        const IR_type_val = IR_type.value;
        
        const IR_textbook = document.getElementById("IR_textbook") as HTMLSelectElement;
        // if(IR_textbook.value === "") {
        //     props.showModal("", "교과를 선택해 주세요.");
        //     return;
        // }
        const IR_textbook_val = IR_textbook.selectedIndex > 0 ? curriInfo.curriBook[IR_textbook.selectedIndex-1] : undefined;
        
        const IR_html_curriUnit1 = document.getElementById("IR_curriUnit1") as HTMLSelectElement;
        const IR_html_curriUnit2 = document.getElementById("IR_curriUnit2") as HTMLSelectElement;
        const IR_html_curriUnit3 = document.getElementById("IR_curriUnit3") as HTMLSelectElement;
        const IR_html_curriUnit4 = document.getElementById("IR_curriUnit4") as HTMLSelectElement;
        const IR_html_curriUnit5 = document.getElementById("IR_curriUnit5") as HTMLSelectElement;
    
        const IR_html_curriUnit1_val = IR_html_curriUnit1 ? IR_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[IR_html_curriUnit1.selectedIndex-1] as IMeta : undefined;
        const IR_html_curriUnit2_val = IR_html_curriUnit2 ? IR_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[IR_html_curriUnit2.selectedIndex-1] as IMeta : undefined;
        const IR_html_curriUnit3_val = IR_html_curriUnit3 ? IR_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[IR_html_curriUnit3.selectedIndex-1] as IMeta : undefined;
        const IR_html_curriUnit4_val = IR_html_curriUnit4 ? IR_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[IR_html_curriUnit4.selectedIndex-1] as IMeta : undefined;
        const IR_html_curriUnit5_val = IR_html_curriUnit5 ? IR_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[IR_html_curriUnit5.selectedIndex-1] as IMeta : undefined;

        // if(IR_html_curriUnit1.style.display !== "none" && !IR_html_curriUnit1_val) {                
        //     props.showModal("", "교과과정을 선택해 주세요.");
        //     IR_html_curriUnit1.focus();
        //     return;
        // }
        // if(IR_html_curriUnit2.style.display !== "none" && !IR_html_curriUnit2_val) {                
        //     props.showModal("", "교과과정을 선택해 주세요.");
        //     IR_html_curriUnit2.focus();
        //     return;
        // }
        // if(IR_html_curriUnit3.style.display !== "none" && !IR_html_curriUnit3_val) {                
        //     props.showModal("", "교과과정을 선택해 주세요.");
        //     IR_html_curriUnit3.focus();
        //     return;
        // }
        // if(IR_html_curriUnit4.style.display !== "none" && !IR_html_curriUnit4_val) {                
        //     props.showModal("", "교과과정을 선택해 주세요.");
        //     IR_html_curriUnit4.focus();
        //     return;
        // }
        // if(IR_html_curriUnit5.style.display !== "none" && !IR_html_curriUnit5_val) {                
        //     props.showModal("", "교과과정을 선택해 주세요.");
        //     IR_html_curriUnit5.focus();
        //     return;
        // }

        const IR_introFileUrl = document.getElementById("IR_introFileUrl") as HTMLInputElement;
        const IR_introFileUrl_val = IR_introFileUrl.value;
        if(IR_introFileUrl_val === "") {
            IR_introFileUrl.focus();
            props.showModal("", "Intro File Url을 입력해 주세요.");
            return;
        }
        const IR_fontCssUrl = document.getElementById("IR_fontCssUrl") as HTMLInputElement;
        const IR_fontCssUrl_val = IR_fontCssUrl.value;
        // if(IR_fontCssUrl_val === "") {
        //     IR_fontCssUrl.focus();
        //     props.showModal("", "Font css Url을 입력해 주세요.");
        //     return;
        // }
        const IR_imageUrl = document.getElementById("IR_imageUrl") as HTMLInputElement;
        const IR_imageUrl_val = IR_imageUrl.value;
        if(IR_imageUrl_val === "") {
            IR_imageUrl.focus();
            props.showModal("", "Image Url을 입력해 주세요.");
            return;
        }
        const IR_width = document.getElementById("IR_width") as HTMLInputElement;
        const IR_width_val = IR_width.value;
        if(IR_width_val === "") {
            IR_width.focus();
            props.showModal("", "Width를 입력해 주세요.");
            return;
        } else {
            const regMatch = /^[0-9\b]+$/.test(IR_width_val);
            if(!regMatch) {
                props.showModal("", "숫자만 입력 가능합니다.");
                IR_width.focus();
                return;    
            }    
        }

        const IR_height = document.getElementById("IR_height") as HTMLInputElement;
        const IR_height_val = IR_height.value;
        if(IR_height_val === "") {
            IR_height.focus();
            props.showModal("", "Height를 입력해 주세요.");
            return;
        } else {
            const regMatch = /^[0-9\b]+$/.test(IR_width_val);
            if(!regMatch) {
                props.showModal("", "숫자만 입력 가능합니다.");
                IR_height.focus();
                return;    
            }    
        }

        const IR_tag = document.getElementById("IR_tag") as HTMLInputElement;
        const IR_tag_val = IR_tag.value;
        const IR_params = document.getElementById("IR_params") as HTMLInputElement;
        const IR_params_val = IR_params.value;
        const IR_meta = document.getElementById("IR_meta") as HTMLInputElement;
        const IR_meta_val = IR_meta.value;

        const IR_module_off = document.getElementById("IR_module_off") as HTMLInputElement;
        const IR_module_val = IR_module_off.checked ? false : true;
        const IR_scale_off = document.getElementById("IR_scale_off") as HTMLInputElement;
        const IR_scale_val = IR_scale_off.checked ? false : true;
        const IR_eng_off = document.getElementById("IR_eng_off") as HTMLInputElement;
        const IR_eng_val = IR_eng_off.checked ? false : true;
        const IR_includeType = document.getElementById("IR_includeType") as HTMLSelectElement;
        const IR_includeType_val = IR_includeType.value;

        const IR_rd_on = document.getElementById('IR_rd-on') as HTMLInputElement;    
        const IR_rd_on_val = IR_rd_on.checked
    
        const IR_rd_public_on = document.getElementById('IR_rd-public-on') as HTMLInputElement;    
        const IR_rd_public_on_val = IR_rd_public_on.checked

        const brandId = props.brand.id;
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");
        
        const listMetaMap : IMeta[] = [];

        const data = {
            "id": props.selectedItem ? props.selectedItem.id : undefined,
            "brand_id": brandId,
            "name": IR_name_val, 
            "code": IR_code_val,
            "type": IR_type_val,
            "introFileUrl": IR_introFileUrl_val,
            "fontCssUrl": IR_fontCssUrl_val,
            "imageUrl": IR_imageUrl_val,
            "width": IR_width_val,
            "height": IR_height_val,
            "tag": IR_tag_val,
            "params": IR_params_val,
            "meta": IR_meta_val,
            "module": IR_module_val,
            "scale": IR_scale_val,
            "subjectTemplate": IR_eng_val,
            "includeType": IR_includeType_val,

            "is_active": IR_rd_on_val, 
            "is_publicOpen": IR_rd_public_on_val, 
            "creator": props.selectedItem ? undefined : uid,
            "creator_name": props.selectedItem ? undefined : uname,
            "creator_id": props.selectedItem ? undefined : _id,
            "updater": props.selectedItem ? uid : undefined,
            "updater_name": props.selectedItem ? uname : undefined,
            "updater_id": props.selectedItem ? _id : undefined,
            "description": IR_description_val,
            // "used_in": IR_usedin_val,
            "curriBook": IR_textbook_val?.id,
            "curriUnit1": IR_html_curriUnit1_val ? IR_html_curriUnit1_val.id : undefined,
            "curriUnit2": IR_html_curriUnit2_val ? IR_html_curriUnit2_val.id : undefined,
            "curriUnit3": IR_html_curriUnit3_val ? IR_html_curriUnit3_val.id : undefined,
            "curriUnit4": IR_html_curriUnit4_val ? IR_html_curriUnit4_val.id : undefined,
            "curriUnit5": IR_html_curriUnit5_val ? IR_html_curriUnit5_val.id : undefined,

            "metaMap": listMetaMap, 
        };
    
        logger.log("onSave", data);
        _onSave(data, props.selectedItem ? "update" : "insert");
    }

    async function _onSave(data: any, type: string) {

        props.showLoading(true);

        const result = await util.curriToolForSave(data, type);
        logger.log("_onSave", result);

        props.showLoading(false);

        props.showModal("", "저장이 완료 되었습니다.");

        props.OnClose();         
    }

    const hasAuth = util.getHasAuthMenu(props.selectedItem?.creator ? props.selectedItem?.creator : "");

    return (
        <div>
            <div>

            <h4>기본 정보 등록</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">도구 이름<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="IR_name" className="w-avail" />
                            </td>
						</tr>
						<tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">설명</label></th>
							<td className="text-left">
                                <input type="text" id="IR_description" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">KEY</th>
							<td className="text-left">{props.selectedItem ? props.selectedItem.id : ""}</td>
						</tr>                      
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">코드<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="IR_code" className="w-1/2" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-class">타입</label></th>
							<td className="text-left">
                                <select id="IR_type" name="sel-type">
                                    <option value="self">self</option>              
                                    <option value="custom">custom</option>              
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-textbook">교과</label></th>
							<td className="text-left">
                                <select id="IR_textbook" name="sel-textbook" onChange={(e) => onChangeCurriBook(e)}>                                    
                                    <option value="">교과</option>              
                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-unit">교과과정</label></th>
							<td className="text-left">
                                <select className="mr-2" id="IR_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                    <option value="">1Depth</option>              
                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="IR_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                    <option value="">2Depth</option>              
                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="IR_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                    <option value="">3Depth</option>              
                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="IR_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                    {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                    <option value="">4Depth</option>              
                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="IR_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                    <option value="">5Depth</option>              
                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>

                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Intro file url<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="IR_introFileUrl" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Font css url</label></th>
							<td className="text-left">
                                <input type="text" id="IR_fontCssUrl" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Image url<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="IR_imageUrl" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Width<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="IR_width" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Height <em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="IR_height" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Tag</label></th>
							<td className="text-left">
                                <input type="text" id="IR_tag" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Params</label></th>
							<td className="text-left">
                                <input type="text" id="IR_params" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">Meta</label></th>
							<td className="text-left">
                                <input type="text" id="IR_meta" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">모듈</label></th>
							<td className="text-left">
                                <input type="radio" id="IR_module_off" name="IR_module" /> False
                                <input type="radio" id="IR_module_on" name="IR_module" className="ml-2" /> True
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">스케일</label></th>
							<td className="text-left">
                                <input type="radio" id="IR_scale_off" name="IR_scale" /> 미적용
                                <input type="radio" id="IR_scale_on" name="IR_scale" className="ml-2" /> 적용
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">포함 방법</label></th>
							<td className="text-left">
                                <select id="IR_includeType">
                                    <option value="아티클">아티클</option>              
                                    <option value="객체">객체</option>              
                                    <option value="전체">전체</option>              
                                </select>
                            </td>
						</tr>
                        <tr style={{display: currBrand?.code === "VAET01" ? "" : "none"}}>
							<th scope="row" className="w-56"><label htmlFor="inp-title">교과템 여부​</label></th>
							<td className="text-left">
                                <input type="radio" id="IR_eng_off" name="IR_eng" /> False
                                <input type="radio" id="IR_eng_on" name="IR_eng" className="ml-2" /> True
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">등록자</th>
                            {props.selectedItem &&
                            <td className="text-left">{props.selectedItem.creator_name} ({props.selectedItem.creator})</td>}
							{!props.selectedItem &&
                            <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
						</tr>
                        <tr>
							<th scope="row" className="w-56">등록일</th>
                            {props.selectedItem &&
							<td className="text-left">{props.selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
							{!props.selectedItem &&
							<td className="text-left">{util.getDate()}</td>}
                            </tr>
                        <tr>
							<th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="IR_rd-on" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-on">사용</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="IR_rd-off" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-off">미사용</label>
                                    </span>
                                </div>
                            </td>
						</tr>
                        <tr style={{display:"none"}}>
							<th scope="row" className="w-56">공개 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="IR_rd-public-on" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-on">공개</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="IR_rd-public-off" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-off">비공개</label>
                                    </span>
                                </div>
                            </td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
                
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
                        <div>
                            {!props.selectedItem &&
                            <button type="button" className="btn-sky ml-2" onClick={onSave} >{"저장"}</button>}
                            {props.selectedItem &&
                            <button type="button" className="btn-sky ml-2" onClick={onSave} disabled={!hasAuth} >{"수정"}</button>}
                        </div>
					</div>
				</div>
            </div>
        </div>
    );
}
export default InstrumentRegister;
