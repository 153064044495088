import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, IMenu, IMeta, ITextBook, ITextbookBasicData, S3Server } from "../common";
import { logger } from '../logger';

interface ITeachingContentsBookEdit {
    brand: IBrand;
    menu: IMenu;
    item: ITextBook | null;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    onClose: () => void;
}
  
function TeachingContentsBookEdit(props: ITeachingContentsBookEdit) {
    
    const [version, setVersion] = useState(1);
    const [lastUpdateDate, setLastUpdateDate] = useState("");

    const [isNext, setIsNext] = useState(false);

    const [teachingCategoryList, setTeachingCategoryList] = useState(null as any);
    const [teachingUsageList, setTeachingUsageList] = useState(null as any);
    const [teachingSubjectList, setTeachingSubjectList] = useState(null as any);

    const [teachingCurriBookList, setTeachingCurriBookList] = useState(null as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);

    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getTeachingInfo();

            setLastUpdateDate(util.getDate());

            setVersion(1);

            const TE_is_active = (document.getElementById("TE_is_active") as HTMLInputElement);
            if(TE_is_active) {
                TE_is_active.checked = true;
            }
            const TE_non_active = (document.getElementById("TE_non_active") as HTMLInputElement);
            if(TE_non_active) {
                TE_non_active.checked = true;
            }
            
            const TE_title = document.getElementById("TE_title") as HTMLInputElement;
            if(TE_title) {
                TE_title.focus();
            }

            const item = props.item;
            if(item) {
                logger.log("props.item -> ", item);
                TE_title.value = item.name;

                if(item.version && item.version > 1) {
                    setVersion(item.version);
                }

                TE_is_active.checked = item.is_active;

                const TE_non_active = (document.getElementById("TE_non_active") as HTMLInputElement);
                TE_non_active.checked = !item.is_active;

                const curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
                if(curriYear) {
                    const _curriYear = curriInfo?.curriYear.find((meta, _) => meta.id === item.curriYear);
                    if(_curriYear) {
                        curriYear.value = _curriYear.val;
                    }    
                }
                const curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
                if(curriSchool) {
                    const _curriSchool = curriInfo?.curriSchool.find((meta, _) => meta.id === item.curriSchool);
                    if(_curriSchool) {
                        curriSchool.value = _curriSchool.val;
                    }    
                }
                const curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
                if(curriSubject) {
                    const _curriSubject = curriInfo?.curriSubject.find((meta, _) => meta.id === item.curriSubject);
                    if(_curriSubject) {
                        curriSubject.value = _curriSubject.val;
                    }
                }
                const TE_hasgtag = (document.getElementById("TE_hasgtag") as HTMLInputElement);
                let val = "";
                if(TE_hasgtag && item.hashTag && item.hashTag.length > 0) {
                    for (let i = 0; i < item.hashTag.length; i++) {
                        const tag = item.hashTag[i];     
                        if(tag && tag.trim().length > 0) {
                            if(val === "") {
                                val = tag;
                            } else {
                                val = val + "," + tag;
                            }      
                        }
                    }
                }
                TE_hasgtag.value = val;

                onChangeForCurriBook();
            } else {
                setTeachingCurriBookList(curriInfo?.curriBook);
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(teachingCategoryList && teachingUsageList && teachingSubjectList) {
            if(props.item) {
                getTextBookInfo(props.item.id!);
            }
        }
    }, [teachingCategoryList, teachingUsageList, teachingSubjectList])

    useEffect(() => {
        if(teachingCurriBookList) {
            if(props.item) {
                const curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
                if(curriBook) {
                    const _curriBook = (teachingCurriBookList as IMeta[]).find((meta, _) => meta.id === props.item?.curriBook);
                    logger.log("_curriBook", _curriBook);
                    if(_curriBook) {
                        curriBook.value = String(_curriBook.id);
                    }
                }                                    
            }
        }
    }, [teachingCurriBookList])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            // logger.log("props.brand", props.brand);
        }
        preBrand = props.brand;
    }, [props.brand])

    async function getTeachingInfo() {
        const teachingCategory = await util.getMetaList("1", "teachingCategory");
        if(teachingCategory) {
            const _teachingCategory = teachingCategory.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingCategoryList(_teachingCategory);    
        }
        const teachingUsage = await util.getMetaList("1", "teachingUsage");
        if(teachingUsage) {
            const _teachingUsage = teachingUsage.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingUsageList(_teachingUsage);    
        }
        const teachingSubject = await util.getMetaList("1", "teachingSubject");
        if(teachingSubject) {
            const _teachingSubject = teachingSubject.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingSubjectList(_teachingSubject);    
        }

        logger.log("getTeachingInfo", teachingCategory, teachingUsage, teachingSubject);
    }

    async function getTextBookInfo(id: number) {
        
        const list = await util.getTextBookInfo(id);
        logger.log("getTextBookInfo", list);

        if(list) {
            const metaMap = (list as ITextBook).metaMap;

            const TE_category = document.getElementById("TE_Category") as HTMLSelectElement;
            if(TE_category) {
                const _teachingCategory = metaMap.find((meta, _) => meta.name === "teachingCategory");
                if(_teachingCategory) {
                    TE_category.value = _teachingCategory.val;
                }
            }                            

            const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
            const _curriBook = (teachingCurriBookList as IMeta[])[TE_curriBook.selectedIndex-1];

            if(_curriBook) {
                const _bookDescendantsList = await getDescendantsList(_curriBook.code);
                    
                if(_bookDescendantsList && _bookDescendantsList.length > 0) {
    
                    const curriUnit1s = _bookDescendantsList.filter((item) => item.parent_id === _curriBook.id);
                    setCurriUnit1(curriUnit1s);
        
                    setTimeout(() => {
                        const _curriUnit1 = metaMap.find((meta, _) => meta.name === "curriUnit1");
                        const curriUnit1 = curriUnit1s.find((meta, _) => meta.id === _curriUnit1?.id);
    
                        logger.log("_curriUnit1", _curriUnit1, curriUnit1s, curriUnit1);
    
                        if(curriUnit1) {
                            (document.getElementById("TE_curriUnit1") as HTMLSelectElement).value = String(curriUnit1.id);
            
                            const curriUnit2s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit1.id);
                            setCurriUnit2(curriUnit2s);
                            setTimeout(() => {
                                const _curriUnit2 = metaMap.find((meta, _) => meta.name === "curriUnit2");
                                const curriUnit2 = curriUnit2s.find((meta, _) => meta.id === _curriUnit2?.id);
                                if(curriUnit2) {
                                    (document.getElementById("TE_curriUnit2") as HTMLSelectElement).value = String(curriUnit2.id);
            
                                    const curriUnit3s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit2.id);
                                    setCurriUnit3(curriUnit3s);
                                    setTimeout(() => {
                                        const _curriUnit3 = metaMap.find((meta, _) => meta.name === "curriUnit3");
                                        const curriUnit3 = curriUnit3s.find((meta, _) => meta.id === _curriUnit3?.id);
                                        if(curriUnit3) {
                                            (document.getElementById("TE_curriUnit3") as HTMLSelectElement).value = String(curriUnit3.id);
            
                                            const curriUnit4s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit3.id);
                                            setCurriUnit4(curriUnit4s);
                                            setTimeout(() => {
                                                const _curriUnit4 = metaMap.find((meta, _) => meta.name === "curriUnit4");
                                                const curriUnit4 = curriUnit4s.find((meta, _) => meta.id === _curriUnit4?.id);
                                                if(curriUnit4) {
                                                    (document.getElementById("TE_curriUnit4") as HTMLSelectElement).value = String(curriUnit4.id);
            
                                                    const curriUnit5s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit4.id);
                                                    setCurriUnit5(curriUnit5s);
                                                    setTimeout(() => {
                                                        const _curriUnit5 = metaMap.find((meta, _) => meta.name === "curriUnit5");
                                                        const curriUnit5 = curriUnit5s.find((meta, _) => meta.id === _curriUnit5?.id);
                                                        if(curriUnit5) {
                                                            (document.getElementById("TE_curriUnit5") as HTMLSelectElement).value = String(curriUnit5.id);
                                                        }                            
                                                    }, 10);
                                                }                            
                                            }, 10);
                                        }                            
                                    }, 10);    
                                }                            
                            }, 10);        
                        }                            
                    }, 10);    
                } 
            }  
        }
    }
    
    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {

            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        
        const curriBook = curriInfo?.curriBook.find((meta, _) => meta.id === Number(e.target.value));
        if(curriBook) {
            getDescendantsList(curriBook.code);

            const TE_html_curriUnit1 = document.getElementById("TE_curriUnit1") as HTMLSelectElement;
            const TE_html_curriUnit2 = document.getElementById("TE_curriUnit2") as HTMLSelectElement;
            const TE_html_curriUnit3 = document.getElementById("TE_curriUnit3") as HTMLSelectElement;
            const TE_html_curriUnit4 = document.getElementById("TE_curriUnit4") as HTMLSelectElement;
            const TE_html_curriUnit5 = document.getElementById("TE_curriUnit5") as HTMLSelectElement;
        
            TE_html_curriUnit1.selectedIndex = 0;
            TE_html_curriUnit2.selectedIndex = 0;
            TE_html_curriUnit3.selectedIndex = 0;
            TE_html_curriUnit4.selectedIndex = 0;
            if(TE_html_curriUnit5) {
                TE_html_curriUnit5.selectedIndex = 0;
            }
    
            setCurriUnit2([]);
            setCurriUnit3([]);
            setCurriUnit4([]);
            setCurriUnit5([]);
        } else {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const TE_html_curriUnit2 = document.getElementById("TE_curriUnit2") as HTMLSelectElement;
        const TE_html_curriUnit3 = document.getElementById("TE_curriUnit3") as HTMLSelectElement;
        const TE_html_curriUnit4 = document.getElementById("TE_curriUnit4") as HTMLSelectElement;
        const TE_html_curriUnit5 = document.getElementById("TE_curriUnit5") as HTMLSelectElement;
    
        TE_html_curriUnit2.selectedIndex = 0;
        TE_html_curriUnit3.selectedIndex = 0;
        TE_html_curriUnit4.selectedIndex = 0;
        if(TE_html_curriUnit5) {
            TE_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const TE_html_curriUnit3 = document.getElementById("TE_curriUnit3") as HTMLSelectElement;
        const TE_html_curriUnit4 = document.getElementById("TE_curriUnit4") as HTMLSelectElement;
        const TE_html_curriUnit5 = document.getElementById("TE_curriUnit5") as HTMLSelectElement;
    
        TE_html_curriUnit3.selectedIndex = 0;
        TE_html_curriUnit4.selectedIndex = 0;
        if(TE_html_curriUnit5) {
            TE_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const TE_html_curriUnit4 = document.getElementById("TE_curriUnit4") as HTMLSelectElement;
        const TE_html_curriUnit5 = document.getElementById("TE_curriUnit5") as HTMLSelectElement;
    
        TE_html_curriUnit4.selectedIndex = 0;
        if(TE_html_curriUnit5) {
            TE_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const TE_html_curriUnit5 = document.getElementById("TE_curriUnit5") as HTMLSelectElement;    
        if(TE_html_curriUnit5) {
            TE_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    async function onNext() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const TE_title = document.getElementById("TE_title") as HTMLInputElement;
        if(TE_title.value === "") {
            props.showModal("", "제목을 입력해 주세요.");
            TE_title.focus();
            return;
        }

        const metaMap : IMeta[] = [];

        let TE_curriYear_val = undefined;
        const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
        if(TE_curriYear) {
            if(TE_curriYear.selectedIndex > 0) {
                TE_curriYear_val = curriInfo.curriYear[TE_curriYear.selectedIndex-1];
                // metaMap.push(TE_curriYear_val);    
            }
        }
        let TE_curriSchool_val = undefined;
        const TE_curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
        if(TE_curriSchool) {
            if(TE_curriSchool.selectedIndex > 0) {
                TE_curriSchool_val = curriInfo.curriSchool[TE_curriSchool.selectedIndex-1];
                // metaMap.push(TE_curriSchool_val);
            }
        }
        let TE_curriSubject_val = undefined;
        const TE_curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
        if(TE_curriSubject) {
            if(TE_curriSubject.selectedIndex > 0) {
                TE_curriSubject_val = curriInfo.curriSubject[TE_curriSubject.selectedIndex-1];
                // metaMap.push(TE_curriSubject_val);
            }
        }
        let TE_category_val = undefined;
        const TE_category = document.getElementById("TE_Category") as HTMLSelectElement;
        if(TE_category) {
            if(TE_category.selectedIndex > 0) {
                TE_category_val = teachingCategoryList[TE_category.selectedIndex-1];
                metaMap.push(TE_category_val);
            }
        }
        let TE_usage_val = undefined;
        const TE_usage = document.getElementById("TE_Usage") as HTMLSelectElement;
        if(TE_usage) {
            if(TE_usage.selectedIndex > 0) {
                TE_usage_val = teachingUsageList[TE_usage.selectedIndex-1];
                metaMap.push(TE_usage_val);
            }
        }
        let TE_curriBook_val = undefined;
        const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
        if(TE_curriBook) {
            if(TE_curriBook.selectedIndex > 0) {
                TE_curriBook_val = teachingCurriBookList[TE_curriBook.selectedIndex-1];
                // metaMap.push(TE_curriBook_val);
            }
        }
        let TE_Subject_val = undefined;
        const TE_Subject = document.getElementById("TE_Subject") as HTMLSelectElement;
        if(TE_Subject) {
            if(TE_Subject.selectedIndex > 0) {
                TE_Subject_val = teachingSubjectList[TE_Subject.selectedIndex-1];
                metaMap.push(TE_Subject_val);
            }
        }
        let TE_curriGrade_val = undefined;
        const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
        if(TE_curriGrade) {
            if(TE_curriGrade.selectedIndex > 0) {
                TE_curriGrade_val = curriInfo.curriGrade[TE_curriGrade.selectedIndex-1];
                metaMap.push(TE_curriGrade_val);
            }
        }

        const TE_html_curriUnit1 = document.getElementById("TE_curriUnit1") as HTMLSelectElement;
        // if(TE_html_curriUnit1.value === "0") {
        //     props.showModal("", "교과 표준체계 Depth1을 입력해 주세요.");
        //     return;
        // }

        const TE_html_curriUnit2 = document.getElementById("TE_curriUnit2") as HTMLSelectElement;
        // if(TE_html_curriUnit2.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const TE_html_curriUnit3 = document.getElementById("TE_curriUnit3") as HTMLSelectElement;
        // if(TE_html_curriUnit3.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const TE_html_curriUnit4 = document.getElementById("TE_curriUnit4") as HTMLSelectElement;
        // if(TE_html_curriUnit4.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const TE_html_curriUnit5 = document.getElementById("TE_curriUnit5") as HTMLSelectElement;
        // if(TE_html_curriUnit5 && TE_html_curriUnit5.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
    
        const TE_html_curriUnit1_val = TE_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[TE_html_curriUnit1.selectedIndex-1] as IMeta;
        const TE_html_curriUnit2_val = TE_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[TE_html_curriUnit2.selectedIndex-1] as IMeta;
        const TE_html_curriUnit3_val = TE_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[TE_html_curriUnit3.selectedIndex-1] as IMeta;
        const TE_html_curriUnit4_val = TE_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[TE_html_curriUnit4.selectedIndex-1] as IMeta;
        const TE_html_curriUnit5_val = TE_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[TE_html_curriUnit5.selectedIndex-1] as IMeta;

        if(TE_html_curriUnit1_val) {
            metaMap.push(TE_html_curriUnit1_val);
        }
        if(TE_html_curriUnit2_val) {
            metaMap.push(TE_html_curriUnit2_val);
        }
        if(TE_html_curriUnit3_val) {
            metaMap.push(TE_html_curriUnit3_val);
        }
        if(TE_html_curriUnit4_val) {
            metaMap.push(TE_html_curriUnit4_val);
        }
        if(TE_html_curriUnit5_val) {
            metaMap.push(TE_html_curriUnit5_val);
        }

        const TE_is_active = (document.getElementById("TE_is_active") as HTMLInputElement);

        const TE_hasgtag = (document.getElementById("TE_hasgtag") as HTMLInputElement);
        const TE_hasgtag_val = TE_hasgtag.value ? TE_hasgtag.value.split(",") : [];
        
        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const data = {
            type: "teachingContentsBook",

            brand_id: brand.id,
            id: props.item ? props.item.id : undefined,
            name: TE_title.value,

            creator: props.item ? undefined : uid,
            creator_id: props.item ? undefined : _id,
            creator_name: props.item ? undefined : uname,

            data_type: TE_category_val ? TE_category_val.id : undefined,

            curriBook: TE_curriBook_val ? TE_curriBook_val.id : undefined,
            curriSchool: TE_curriSchool_val ? TE_curriSchool_val.id : undefined,
            curriSubject: TE_curriSubject_val ? TE_curriSubject_val.id : undefined,
            curriYear: TE_curriYear_val ? TE_curriYear_val.id : undefined,

            is_active: TE_is_active.checked,

            hashTag: TE_hasgtag_val,
            
            metaMap: metaMap,

            updater: props.item ? uid : undefined,
            updater_id: props.item ? _id : undefined,
            updater_name: props.item ? uname : undefined,
        };

        // logger.log("onNext", data);   

        props.showLoading(true);

        const result = await util.textbookForSave(data, props.item ? "update" : "insert");
        logger.log("onNext", data, result);   

        if(result) {
            if(!props.item) {
                const item = result as ITextBook;
            
                // const textbook_id = props.item ? props.item.id : item.id;
                // const web_textbook_id = props.item ? props.item.web_textbook_id : item.web_textbook_id;
                const textbook_id = item.id;
                const web_textbook_id = item.web_textbook_id;
    
                let url = "https://ibook.vivasam.com" + "/ebookeditor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&textbook_id="+textbook_id
                +"&web_textbook_id="+(web_textbook_id ? web_textbook_id : "")
                +"&type=teaching"
                +"&token="+util.getUserInfo("token");
    
                // const url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                // +"&create=article"+"&token="+util.getUserInfo("token");
    
                window.open(url, "_blank", "noopener, noreferrer");            
            }
        }

        props.showLoading(false);

        props.onClose();
    }

    async function onChangeForCurriBook() {
        
        const brand = util.getCurrentBrand();

        const metaMap: {"name": string, "id": number}[] = [];

        const data = {
            "brand_id": brand?.id,
            "name":"curriBook",
            "metaMap": metaMap,
        }

        if(curriInfo) {

            const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
            if(TE_curriYear) {
                const _curriYear = curriInfo.curriYear.find((meta) => meta.val === TE_curriYear.value)
                if(_curriYear) {
                    metaMap.push({"name":"curriYear", "id":Number(_curriYear.id)});
                }
            }
            const TE_curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
            if(TE_curriSchool) {
                const _curriSchool = curriInfo.curriSchool.find((meta) => meta.val === TE_curriSchool.value)
                if(_curriSchool) {
                    metaMap.push({"name":"curriSchool", "id":Number(_curriSchool.id)});
                }
            }
            const TE_curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
            if(TE_curriSubject) {
                const _curriSubject = curriInfo.curriSubject.find((meta) => meta.val === TE_curriSubject.value)
                if(_curriSubject) {
                    metaMap.push({"name":"curriSubject", "id":Number(_curriSubject.id)});
                }
            }
            const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
            if(TE_curriGrade) {
                const _curriGrade = curriInfo.curriGrade.find((meta) => meta.val === TE_curriGrade.value)
                if(_curriGrade) {
                    metaMap.push({"name":"curriGrade", "id":Number(_curriGrade.id)});
                }
            }
        }

        data.metaMap = metaMap;
        
        props.showLoading(true);

        const list = await util.searchMeta(data);
        if(list) {
            setTeachingCurriBookList(list);
        }

        logger.log("onChangeForCurriBook", data, curriInfo?.curriBook, list);

        props.showLoading(false);
    }

    return (
        <div>

            {!isNext && <>
            <div className="top-title">
                <h3>콘텐츠 book 설정</h3>
            </div>

            <h4>콘텐츠 book 설정</h4>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">콘텐츠 book 설정</caption>
                <tbody>
                    <tr>
                        <th scope="row" className="w-56">버전</th>
                        <td className="text-left" colSpan={2}>{version} <span className="text-red ml-5">{lastUpdateDate} 최종 수정</span></td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-title">제목<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left" colSpan={2}>
                            <input type="text" id="TE_title" className="w-full" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-process">교육과정</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_curriYear" name="search-3" onChange={onChangeForCurriBook}>
                                <option value="">교육과정</option>
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학교급</label></th>
                        <td className="text-left">
                            <select id="TE_curriSchool" name="search-4" onChange={onChangeForCurriBook}>
                                <option value="">학교급</option>
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr style={{display: "none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학년</label></th>
                        <td className="text-left">
                            <select id="TE_curriGrade" name="search-4" onChange={onChangeForCurriBook}>
                                <option value="">학년</option>
                                {curriInfo && curriInfo?.curriGrade && (curriInfo?.curriGrade as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">교과</label></th>
                        <td className="text-left">
                            <select id="TE_curriSubject" name="search-4" onChange={onChangeForCurriBook}>
                                <option value="">교과</option>
                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr style={{display: "none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">과목</label></th>
                        <td className="text-left">
                            <select id="TE_Subject" name="search-4">
                                <option value="">교수자료 과목</option>
                                {teachingSubjectList && (teachingSubjectList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">교과서</label></th>
                        <td className="text-left">
                            <select id="TE_curriBook" name="search-4" onChange={onChangeCurriBook}>
                                <option value="">교과서</option>
                                {teachingCurriBookList && (teachingCurriBookList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.id}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-unit">교과과정</label></th>
                        <td className="text-left">

                            <select className="mr-2" id="TE_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                <option value="0">1Depth</option>              
                                {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="TE_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                <option value="0">2Depth</option>              
                                {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="TE_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                <option value="0">3Depth</option>              
                                {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="TE_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                <option value="0">4Depth</option>              
                                {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="TE_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                <option value="0">5Depth</option>              
                                {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>      

                        </td>
                    </tr>                    
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">교수자료 유형</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_Category" name="sel-type">
                                <option value="">교수자료 유형</option>
                                {teachingCategoryList && (teachingCategoryList as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr style={{display: "none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">교수자료 용도</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_Usage" name="sel-type">
                                <option value="">교수자료 용도</option>
                                {teachingUsageList && (teachingUsageList as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-title">해시태그​</label></th>
                        <td className="text-left" colSpan={2}>
                            <input type="text" id="TE_hasgtag" className="w-full" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" className="w-56">사용 여부</th>
                        <td className="text-left text-slate-950 text-sm">
                            <div className="radio-wrap">
                                <span className="inp-radio text-sky-500">
                                    <input id="TE_is_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio text-red-600">
                                    <input id="TE_non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
            
            <div className="flex justify-between mt-5">
                <button type="button" className="btn-white" onClick={props.onClose}>취소</button>
                <button type="button" className="btn-sky" onClick={onNext}>{props.item ? "저장" : "다음"}</button>
            </div></>}

        </div>

    );  
}
export default TeachingContentsBookEdit;
