import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IBrand, ICurriInfo, ILibrarySearchData, IMenu, IMeta, IText } from "../common";
import LibraryTextRegister from "./LibraryTextRegister";
import LibrarySearch from "./LibrarySearch";
import LibraryBulkRegister from "./LibraryBulkRegister";
import { logger } from '../logger';
import LibraryTextSearch from "./LibraryTextSearch";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IStudyMap } from "./LibraryRegister";

interface ILibraryText {
    brand: IBrand;
    menu?: IMenu;
    isPopup?: boolean;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function LibraryText(props: ILibraryText) {
    
    const [list, setList] = useState([] as any);
    const [selectedItem, setSelectedItem] = useState(null as any);
    const [viewList, setViewList] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isBulkEditing, setIsBulkEditing] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [textType, setTextType] = useState("어휘");

    const [isPopup, setIsPopup] = useState(false);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // window.history.pushState(null, "", document.URL);
            // window.addEventListener('popstate', function(event) {
            //     logger.log("popstate");
            // });

            setViewList(true);
            setIsEditing(false);
            setIsBulkEditing(false);
            setSelectedItem(null);

            const _pageNum = localStorage.getItem("Library_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(_pageNum) {
                pagenum.value = _pageNum;
            } else {
                pagenum.value = "10";                
            }
            if(props.brand) {
                refreshList();
            }
        }
        bDone = true;
    }, [])

    // useEffect(() => {
    //     if(props.brand) {
    //         logger.log("Library props.brand", props);
    //         refreshList();
    //     }
    // }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            if(props.menu.menu2 === 4) {
                setTextType("어휘");
            } else if(props.menu.menu2 === 5) {
                setTextType("문장");
            } else if(props.menu.menu2 === 6) {
                setTextType("지문");
            }

            setListOfPage([]);

            if(isEditing || isBulkEditing) {
                onEditClose();
            }
            refreshList();

        }
    }, [props.menu])

    useEffect(() => {
        if(textType) {
            logger.log("textType", textType);
            if(props.brand) {
                refreshList();
            }
        }
    }, [textType])

    useEffect(() => {
        if(isEditing || isBulkEditing) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     setIsBulkEditing(false);
            // }
        } 

        if(!isEditing && !isBulkEditing) {
            
            logger.log("isEditing", isEditing);

            const _searchData = searchData;
            if(_searchData) {
                onSearch(_searchData);            
            }
        }

    }, [isEditing, isBulkEditing])

    useEffect(() => {
        setIsPopup(props.isPopup ? props.isPopup : false);
    }, [props.isPopup])

    useEffect(() => {
        if(props.brand) {
            logger.log("props.brand", props);
            refreshList();
        }
    }, [props.brand])

    const refreshList = () => {

        setIsEditing(false);
        setIsBulkEditing(false);
        setSelectedItem(null);

        setSearchData(null);
        setList([]);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);
        const list = await util.getTextSearchList(data);
        setList(list);
        logger.log("onSearch", data, list);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
            }
            calcPage(totalCnt, currentPage);
        }
        setViewList(true);

        props.showLoading(false);
    }

    function onSearch(data: any): void {
        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                onChangePage(_currPage);
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                onChangePage(_currPage);
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Library_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        logger.log("numOfPage", numOfPage);
        onSearch(_searchData);            
    }

    function onBulkAdd(): void {
        setIsBulkEditing(true);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onAdd(): void {
        setIsEditing(true);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onEditClose(): void {

        setIsEditing(false);
        setIsBulkEditing(false);
        setSelectedItem(null);

        // refreshList();
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onChangePage(page: number) {
        
        const _searchData = searchData;
        _searchData.limit_page = page;
        onSearch(_searchData);
        
        logger.log("onChangePage", page, searchData, _searchData);

        setCurrPage(page);
    }
    
    function onChangeTextType3(val: string): void {
        setTextType(val);
    }
    
    function onConfirm(item: IText): void {
        setIsEditing(true);
        setSelectedItem(item);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }
    
    async function getTextInfo(id: number) {
        const result = await util.getTextInfo(id);
        logger.log("getTextInfo", result);
        return result;
    }

    async function onDownload() {

        const _list = (list as IText[]);

        props.showLoading(true);

        if(textType === "지문") {
            
            const text_ck = document.getElementsByName("text_ck");
            for (let i = 0; i < text_ck.length; i++) {
                const element = text_ck[i] as HTMLInputElement;
                if(element.checked) {
    
                    const _libText = await getTextInfo(_list[i].id);
                    if(_libText) {

                        const checkedList = [["텍스트_지문"] as any, [] as any];

                        const column = [
                            "브랜드",
                            "구분1",
                            "구분2",
                            "구분3",
                            "KEY",
                            "타이틀",
                            "학교급",
                            "교과목",
                            "교과",
                            "교과과정1",
                            "교과과정2",
                            "교과과정3",
                            "교과과정4",
                            "작성자",
                            "등록일",
                            "수정일",                            
                            "사용설정",
                            "공개여부",

                            "문단Key",
                            "문단 Summary(영어)",
                            "문장관리로 복사여부(Y/N)",
                            "문장Key",
                            "양식 (대화형/ 일반형)",
                            "Speaker",
                            "Entry(문장)",
                            "다국어_영어",
                            "다국어_한국어",
                            "다국어_일본어",
                            "다국어_중국어간체",
                            "다국어_중국어번체",
                            "다국어_베트남어",
                            "다국어_스페인어",
                            "다국어_포르투갈어",
                            "다국어_아랍어",

                            "구문분석",
                            "분절 DB",
                            "분절 DB 해석",
                            "chunk1 (Unscramble)",
                            "chunk2 (blank)",
                            "Key Grammar_타이틀",
                            "Key Grammar_내용",
                            "Key Expression_타이틀",
                            "Key Expression_내용",

                            "이미지_경로",
                            "오디오_경로",
                            "오디오_시작점(MM:SS)",
                            "오디오_끝점(MM:SS)",
                            "비디오_경로",
                            "비디오_시작점(MM:SS)",
                            "비디오_끝점(MM:SS)",
                            "문항Key_문장",
                            "문항Key_문단",
                            "문항KEY_지문",
                            "빈칸 예외",
                        ];
            
                        checkedList.push(column);

                        const libText = _libText as IText;
    
                        const metaMap = libText.metaMap;
                        const curriSchool = metaMap.find((item) => item.name === "curriSchool");
                        const curriSubject = metaMap.find((item) => item.name === "curriSubject");
                        // const difficulty = metaMap.find((meta, _) => meta.name === "difficulty");
        
                        const scriptList = libText.dialog;
                        for (let j = 0; j < scriptList.length; j++) {

                            const excelData = [];
                            for (let i = 0; i < column.length; i++) {
                                excelData[i] = "";
                            }
    
                            excelData[0] = util.getCurrentBrand()?.id;
                            excelData[1] = "텍스트";
                            excelData[2] = libText.type_1;
                            excelData[3] = libText.type_2;
                            excelData[4] = libText.id;
                            excelData[5] = libText.name;
        
                            excelData[6] = curriSchool?.id;
                            excelData[7] = curriSubject?.id;
                            excelData[8] = libText.curriBook;
                            excelData[9] = libText.curriUnit1;
                            excelData[10] = libText.curriUnit2;
                            excelData[11] = libText.curriUnit3;
                            excelData[12] = libText.curriUnit4;
        
                            excelData[13] = libText.creator;
                            excelData[14] = libText.regdate;
                            excelData[15] = libText.updatedate;
        
                            excelData[16] = libText.is_active ? "1" : "0";
                            excelData[17] = libText.is_publicOpen ? "1" : "0";
    
                            const script = scriptList[j];
                
                            excelData[18] = script.pkey;
                            excelData[19] = script.summary;
                            if(script.skey && Number(script.skey) > 0) {
                                excelData[20] = "Y";
                                const script_libText = await getTextInfo(Number(script.skey));
                                logger.log("script_libText", script_libText);

                                if(script_libText) {

                                    const _script_libText = script_libText as IText;

                                    excelData[34] = _script_libText.info1;
                                    excelData[35] = _script_libText.info2;
                                    excelData[36] = _script_libText.info3;
                                    excelData[37] = _script_libText.chunk1;
                                    excelData[38] = _script_libText.chunk2;
                
                                    if(_script_libText.key_type === "Grammar") {
                                        excelData[39] = _script_libText.key_title;
                                        excelData[40] = _script_libText.key_desc;
                                    } else {
                                        excelData[41] = _script_libText.key_title;
                                        excelData[42] = _script_libText.key_desc;
                                    }
            
                                    if(_script_libText.articles && _script_libText.articles.length > 0) {
                                        let _unitVal = "";
                                        _script_libText.articles.map((item) => {
                                            if(_unitVal === "") {
                                                _unitVal = "" + item.id;    
                                            } else {
                                                _unitVal = _unitVal + "," + item.id;
                                            }
                                        });
                                        excelData[50] = _unitVal;
                                    } 
                                }

                            } else {
                                excelData[20] = "N";    
                            }
                            excelData[21] = script.skey;
                            excelData[22] = script.type;
                            excelData[23] = script.speaker;
                            excelData[24] = script.entry;
                            excelData[26] = script.multiLang;

                            excelData[43] = libText.contents_image;

                            excelData[44] = libText.contents_audio;
                            excelData[47] = libText.contents_video;
                            if(excelData[44] && excelData[44].length > 0) {
                                excelData[45] = script.startTime;
                                excelData[46] = script.endTime;        
                            } else if(excelData[47] && excelData[47].length > 0) {
                                excelData[48] = script.startTime;
                                excelData[49] = script.endTime;    
                            } else {
                                excelData[45] = script.startTime;
                                excelData[46] = script.endTime;            
                                excelData[48] = script.startTime;
                                excelData[49] = script.endTime;        
                            }

                            excelData[51] = script.akey;
                            
                            if(libText.articles && libText.articles.length > 0) {
                                let _unitVal = "";
                                libText.articles.map((item) => {
                                    if(_unitVal === "") {
                                        _unitVal = "" + item.id;    
                                    } else {
                                        _unitVal = _unitVal + "," + item.id;
                                    }
                                });
                                excelData[52] = _unitVal;
                            } 
    
                            excelData[53] = script.exceptEmpty;
                            
                            // excelData[53] = difficulty?.id;

                            // const studyMapList = JSON.parse(libText.studyMapListString) as IStudyMap[];
                            // studyMapList.map((item, idx) => {
        
                            //     let _unitVal = "";
        
                            //     const arr = [item.depth1, item.depth2, item.depth3, item.depth4, item.depth5, item.depth6];
                            //     arr.map((val, idx) => {
                            //         if(val && Number(val.id) > 0) {
                            //             if(_unitVal === "") {
                            //                 _unitVal = "" + val.id;    
                            //             } else {
                            //                 _unitVal = _unitVal + "," + val.id;
                            //             }
                            //         }
                            //     });
        
                            //     excelData[54+idx] = _unitVal;
                            // });
        
                            checkedList.push(excelData);    
                        }
                        logger.log("checkedList", checkedList);                            
                        exportToExcel(checkedList);                
                    }
                }
            }

        } else {

            const checkedList = [[] as any, [] as any];

            const text_ck = document.getElementsByName("text_ck");
            for (let i = 0; i < text_ck.length; i++) {
                const element = text_ck[i] as HTMLInputElement;
                if(element.checked) {
    
                    const _libText = await getTextInfo(_list[i].id);
                    if(_libText) {
                        const libText = _libText as IText;
    
                        const metaMap = libText.metaMap;
                        const curriSchool = metaMap.find((item) => item.name === "curriSchool");
                        const curriSubject = metaMap.find((item) => item.name === "curriSubject");
                        const difficulty = metaMap.find((meta, _) => meta.name === "difficulty");
            
                        if(libText.type_1 === "어휘") {

                            const column = [
                                "브랜드",
                                "구분1",
                                "구분2",
                                "구분3",
                                "KEY",
                                "타이틀",
                                "학교급",
                                "교과목",
                                "교과",
                                "교과과정1",
                                "교과과정2",
                                "교과과정3",
                                "교과과정4",
                                "작성자",
                                "등록일",
                                "수정일",
                                "사용설정",
                                "공개여부",
                                "POS(품사)",
                                "Entry(어휘)",
                                "다국어_영어",
                                "다국어_한국어",
                                "다국어_일본어",
                                "다국어_중국어간체",
                                "다국어_중국어번체",
                                "다국어_베트남어",
                                "다국어_스페인어",
                                "다국어_포르투갈어",
                                "다국어_아랍어",
                                "DEF",
                                "발음기호",
                                "이미지_경로",
                                "오디오_경로",
                                "비디오_경로",
                                "예문 문장KEY",
                                "문항KEY_어휘"
                            ];
                
                            if(checkedList.length === 2) {
                                checkedList.push(column);
                            }
                            
                            const excelData = [];
                            for (let i = 0; i < column.length; i++) {
                                excelData[i] = "";
                            }
            
                            excelData[0] = util.getCurrentBrand()?.id;
                            excelData[1] = "텍스트";
                            excelData[2] = libText.type_1;
                            excelData[4] = libText.id;
                            excelData[5] = libText.name;
        
                            excelData[6] = curriSchool?.id;
                            excelData[7] = curriSubject?.id;
                            excelData[8] = libText.curriBook;
                            excelData[9] = libText.curriUnit1;
                            excelData[10] = libText.curriUnit2;
                            excelData[11] = libText.curriUnit3;
                            excelData[12] = libText.curriUnit4;
        
                            excelData[13] = libText.creator;
                            excelData[14] = libText.regdate;
                            excelData[15] = libText.updatedate;

                            excelData[16] = libText.is_active ? "1" : "0";
                            excelData[17] = libText.is_publicOpen ? "1" : "0";
        
                            excelData[18] = libText.parts;
                            excelData[19] = libText.contents_entry;
                            excelData[21] = libText.contents_multiLang;
        
                            excelData[29] = libText.contents_def;
                            excelData[30] = libText.contents_pron;
                            excelData[31] = libText.contents_image;
                            excelData[32] = libText.contents_audio;
                            excelData[33] = libText.contents_video;
        
                            if(libText.exam_sentences && libText.exam_sentences.length > 0) {
                                let _unitVal = "";
                                libText.exam_sentences.map((item) => {
                                    if(_unitVal === "") {
                                        _unitVal = "" + item.id;    
                                    } else {
                                        _unitVal = _unitVal + "," + item.id;
                                    }
                                });
                                excelData[34] = _unitVal;
                            } 
                            if(libText.articles && libText.articles.length > 0) {
                                let _unitVal = "";
                                libText.articles.map((item) => {
                                    if(_unitVal === "") {
                                        _unitVal = "" + item.id;    
                                    } else {
                                        _unitVal = _unitVal + "," + item.id;
                                    }
                                });
                                excelData[35] = _unitVal;
                            } 
    
                            // excelData[36] = difficulty?.id;
        
                            // const studyMapList = JSON.parse(libText.studyMapListString) as IStudyMap[];
                            // studyMapList.map((item, idx) => {
        
                            //     let _unitVal = "";
        
                            //     const arr = [item.depth1, item.depth2, item.depth3, item.depth4, item.depth5, item.depth6];
                            //     arr.map((val, idx) => {
                            //         if(val && Number(val.id) > 0) {
                            //             if(_unitVal === "") {
                            //                 _unitVal = "" + val.id;    
                            //             } else {
                            //                 _unitVal = _unitVal + "," + val.id;
                            //             }
                            //         }
                            //     });
        
                            //     excelData[37+idx] = _unitVal;
                            // });
        
                            checkedList.push(excelData);   
    
                        } else if(libText.type_1 === "문장") {
        
                            const column = [
                                "브랜드",
                                "구분1",
                                "구분2",
                                "구분3",
                                "KEY",
                                "타이틀",
                                "학교급",
                                "교과목",
                                "교과",
                                "교과과정1",
                                "교과과정2",
                                "교과과정3",
                                "교과과정4",
                                "작성자",
                                "등록일",
                                "수정일",
                                "사용설정",
                                "공개여부",
                                "Speaker",
                                "Entry(문장)",
                                "다국어_영어",
                                "다국어_한국어",
                                "다국어_일본어",
                                "다국어_중국어간체",
                                "다국어_중국어번체",
                                "다국어_베트남어",
                                "다국어_스페인어",
                                "다국어_포르투갈어",
                                "다국어_아랍어",
                                "구문분석",
                                "분절 DB",
                                "분절 DB 해석",
                                "chunk1 (Unscramble)",
                                "chunk2 (blank)",
                                "Key Grammar_타이틀",
                                "Key Grammar_내용",
                                "Key Expression_타이틀",
                                "Key Expression_내용",
                                "연관 지문정보(Key)",
                                "이미지_경로",
                                "오디오_경로",
                                "비디오_경로",
                                "원천DB(어휘) Key",
                                "문항KEY_문장",
                                "원어민 음원 분석"
                            ];
                
                            if(checkedList.length === 2) {
                                checkedList.push(column);
                            }

                            const excelData = [];
                            for (let i = 0; i < column.length; i++) {
                                excelData[i] = "";
                            }
            
                            excelData[0] = util.getCurrentBrand()?.id;
                            excelData[1] = "텍스트";
                            excelData[2] = libText.type_1;
                            excelData[4] = libText.id;
                            excelData[5] = libText.name;
        
                            excelData[6] = curriSchool?.id;
                            excelData[7] = curriSubject?.id;
                            excelData[8] = libText.curriBook;
                            excelData[9] = libText.curriUnit1;
                            excelData[10] = libText.curriUnit2;
                            excelData[11] = libText.curriUnit3;
                            excelData[12] = libText.curriUnit4;
        
                            excelData[13] = libText.creator;
                            excelData[14] = libText.regdate;
                            excelData[15] = libText.updatedate;
        
                            excelData[16] = libText.is_active ? "1" : "0";
                            excelData[17] = libText.is_publicOpen ? "1" : "0";
        
                            excelData[18] = libText.speaker;
                            excelData[19] = libText.contents_entry;
                            excelData[21] = libText.contents_multiLang;
        
                            excelData[29] = libText.info1;
                            excelData[30] = libText.info2;
                            excelData[31] = libText.info3;
                            excelData[32] = libText.chunk1;
                            excelData[33] = libText.chunk2;
        
                            if(libText.key_type === "Grammar") {
                                excelData[34] = libText.key_title;
                                excelData[35] = libText.key_desc;
                            } else {
                                excelData[36] = libText.key_title;
                                excelData[37] = libText.key_desc;
                            }
    
                            excelData[38] = libText.key_info;
    
                            excelData[39] = libText.contents_image;
                            excelData[40] = libText.contents_audio;
                            excelData[41] = libText.contents_video;
                            excelData[44] = libText.contents_audioAnalysis;
    
                            if(libText.exam_sentences && libText.exam_sentences.length > 0) {
                                let _unitVal = "";
                                libText.exam_sentences.map((item) => {
                                    if(_unitVal === "") {
                                        _unitVal = "" + item.id;    
                                    } else {
                                        _unitVal = _unitVal + "," + item.id;
                                    }
                                });
                                excelData[42] = _unitVal;
                            } 
                            if(libText.articles && libText.articles.length > 0) {
                                let _unitVal = "";
                                libText.articles.map((item) => {
                                    if(_unitVal === "") {
                                        _unitVal = "" + item.id;    
                                    } else {
                                        _unitVal = _unitVal + "," + item.id;
                                    }
                                });
                                excelData[43] = _unitVal;
                            } 
    
                            // excelData[44] = difficulty?.id;
        
                            // const studyMapList = JSON.parse(libText.studyMapListString) as IStudyMap[];
                            // studyMapList.map((item, idx) => {
        
                            //     let _unitVal = "";
        
                            //     const arr = [item.depth1, item.depth2, item.depth3, item.depth4, item.depth5, item.depth6];
                            //     arr.map((val, idx) => {
                            //         if(val && Number(val.id) > 0) {
                            //             if(_unitVal === "") {
                            //                 _unitVal = "" + val.id;    
                            //             } else {
                            //                 _unitVal = _unitVal + "," + val.id;
                            //             }
                            //         }
                            //     });
        
                            //     excelData[45+idx] = _unitVal;
                            // });
        
                            checkedList.push(excelData);   
                        }
                    }
                }
            }

            logger.log("checkedList", checkedList);
            
            exportToExcel(checkedList);            
        }

        props.showLoading(false);
        
    }
    
    const exportToExcel = (data: any[]) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        const today = new Date();
        saveAs(blob, "downloaded_data_" + today.toISOString() + ".xlsx");
    };
    
    return (
        <div style={{padding: isPopup ? "40px" : "0"}}>
            <div className="top-title">
                <h3>{common.leftmenu_2[0][3]} <strong className="text-sky-500">({textType})</strong></h3>
            </div>

            <div style={{display: isEditing || isBulkEditing ? "none" : ""}}>
            
                {/* <!-- 검색영역 --> */}
                <LibraryTextSearch view={true} onSearch={onSearch} textType={textType} brand={props.brand} pageInfo={{ page: currPage, pageItemCnt: localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10 }} showLoading={props.showLoading} />
                {/* <!-- .//검색영역 --> */}

                <div className="mt-5 mb-3 text-right">
                    <label htmlFor="page-num">페이지당 표기 수</label>
                    <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </select>
                </div>

                {!isEditing && !isBulkEditing && viewList && <>

                {/* <!-- table --> */}
                <table>
                    <caption className="sr-only">텍스트 관리 테이블</caption>
                    <thead>
                        <tr>
                            <th style={{display:""}} scope="col" className="pl-4 pr-3">
                                <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                const text_ck = document.getElementsByName("text_ck");
                                for (let i = 0; i < text_ck.length; i++) {
                                    const element = text_ck[i] as HTMLInputElement;
                                    if(!element.disabled) {
                                        element.checked = (e.target as HTMLInputElement).checked;
                                    }                                    
                                }
                            }} /></th>
                            <th scope="col" className="px-3">번호</th>
                            <th scope="col" className="px-3">구분</th>
                            <th scope="col" className="px-3">KEY</th>
                            <th scope="col" className="px-3">타이틀</th>
                            <th scope="col" className="px-3">교과</th>
                            {/* <th scope="col" className="px-3">교과과정</th> */}
                            <th scope="col" className="px-3">등록자</th>
                            <th scope="col" className="px-3">등록일</th>
                            <th scope="col" className="pl-3 pr-4">상세보기</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewList && list &&
                        <tr style={{display: (list as IText[]).length === 0 ? "" : "none"}}>
                            <td colSpan={9}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as IText[]).map((item, idx) => {

                            let _unitVal = "";
                            let _curriBookVal = ""

                            if(curriInfo && curriInfo.curriBook) {
                                const curriBook = curriInfo.curriBook as IMeta[];
                                const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                _curriBookVal = _curriBook ? _curriBook.val : "";

                            //     const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                            //     const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                            //     const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                            //     const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                            //     const curriUnit5 = curriInfo.curriUnit5 as IMeta[];
    
                            //     const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === item.curriUnit1);
                            //     const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === item.curriUnit2);
                            //     const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === item.curriUnit3);
                            //     const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === item.curriUnit4);
                            //     const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === item.curriUnit5);
    
                            //     const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                            //     arr.map((val, idx) => {
                            //         if(val) {
                            //             if(_unitVal === "") {
                            //                 _unitVal = val;    
                            //             } else {
                            //                 if(idx === 2) {
                            //                     _unitVal = _unitVal + " > " + "<br/>" + val;

                            //                 } else {
                            //                     _unitVal = _unitVal + " > " + val;
                            //                 }
                            //             }
                            //         }
                            //     });
                            }

                            const totalCnt = item.full_count;
                            const _numOfPage = localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10;
                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                            let type = item.type_1;
                            if(item.type_2) {
                                type = type + ' > ' + item.type_2;
                            }

                            // logger.log(totalCnt, _numOfPage, currPage, _num);

                            let title = item.name;
                            if(title && title.length > 50) {
                                title = title.substring(0, 47) + "...";
                            }

                            return (
                                // <div key={idx}><img src={item.url} width={40}/>{JSON.stringify(item)}</div>
                                <tr key={idx}>    
                                    <td style={{display:""}}>
                                        <input id={"text_ck_"+idx} name="text_ck" type="checkbox" className="h-4 w-4 rounded mt-2" onClick={(e) => {
                                            const text_ck = document.getElementsByName("text_ck");
                                            let cntChecked = 0;
                                            for (let i = 0; i < text_ck.length; i++) {
                                                const element = text_ck[i] as HTMLInputElement;
                                                if(element.checked) {
                                                    cntChecked = cntChecked + 1;
                                                }
                                            }
                                            const all_check = document.getElementById("all_check") as HTMLInputElement;
                                            if(cntChecked === text_ck.length) {                                                
                                                all_check.checked = true;                                                
                                            } else {
                                                all_check.checked = false;                                                
                                            }
                                        }}/>
                                    </td>
                                    <td>{_num}</td>
                                    <td>{type}</td>
                                    <td>{item.id}</td>
                                    <td>{title}</td>
                                    <td>{_curriBookVal}</td>
                                    {/* <td dangerouslySetInnerHTML={{ __html: _unitVal }}></td> */}
                                    <td>{item.creator_name} ({item.creator})</td>
                                    <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                    <td>
                                        <button type="button" className="btn-white-s" onClick={() => onConfirm(item)}>확인</button>
                                        {/* {item.info1 && 
                                        <button type="button" className="btn-sky-s ml-2" onClick={() => {                                                                                  
                                            const url = EDITOR_HOST+"/editor/syntax.html?brand_id="+props.brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                            +"&key="+item.id+"&token="+util.getUserInfo("token");
                                            window.open(url, "_blank", "noopener, noreferrer");        
                                    }}>구문분석</button>} */}
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                {/* <!-- .//table -->	 */}

                {!isPopup &&
                <div className="relative mt-5">

                    <div className="absolute top-0 left-0 text-left">
                        <button type="button" className="btn-sky mr-2" onClick={onDownload}>다운로드</button>
                    </div>

                    <div className="absolute top-0 right-0 text-right">
                        <button type="button" className="btn-sky mr-2" onClick={onBulkAdd}>일괄등록</button>
                        <button type="button" className="btn-sky" onClick={onAdd}>등록</button>
                    </div>

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}

                </div>}</>}

            </div>

            {isEditing && <LibraryTextRegister OnClose={onEditClose} textType={textType} brand={props.brand} onChangeTextType3={onChangeTextType3} selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} />}
            {isBulkEditing && <LibraryBulkRegister OnClose={onEditClose} type={"text"} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}

        </div>
    );  
}
export default LibraryText;
