import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { count } from "console";
import MetaAddEdit from "./MetaAddEdit";
import { CurriInfo, IBrand, ICurriInfo, IMenu, IMeta, headers } from "../common";
import '../common.scss';
import { logger } from '../logger';
import MetaStudyMapEdit from "./MetaStudyMapEdit";
import MetaContentsItemEdit from "./MetaContentsItemEdit";

interface IMetaContentsItem {
    brand: IBrand;
    menu: IMenu;
    item: IMeta;
    onClose: () => void;    
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaContentsItem(props: IMetaContentsItem) {

    const [viewList, setViewList] = useState(false);
    const [topMeta, setTopMeta] = useState(null as any);
    const [metaHList, setMetaHList] = useState([] as IMeta[]);
    const [oriMetaHList, setOriMetaHList] = useState([] as IMeta[]);

    const [currItem, setCurrItem] = useState(null as any);
    const [parentItem, setParentItem] = useState(null as any);
    const [isEditing, setIsEditing] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            logger.log("selected item", props.item);
            if(props.item) {
                setParentItem(props.item);
                getDescendantsList(props.item);
            } else {
                props.onClose();
            }
        }
        bDone = true;
    }, [])

    async function getDescendantsList(meta: IMeta) {

        props.showLoading(true);

        const list = await util.getDescendantsList(meta.code);
        const _list = list.filter((item) => item.code !== meta.code);
        const top = list.find((item) => item.code === meta.code);
        setTopMeta(top);
        logger.log("getDescendantsList", meta, list, _list);  

        if(oriMetaHList.length === 0) {
            setOriMetaHList(_list);
        }
        setMetaHList(_list);

        props.showLoading(false);
    }

    function onAddTopMeta(): void {
        setCurrItem(null);
        setIsEditing(true);
    }
    
    function onEditMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta): void {
        e.stopPropagation();

        let _pcode = "";
        const arr = item.code.split("-");
        for (let i = 0; i < arr.length-1; i++) {
            if(_pcode === "")
                _pcode = arr[i];
            else 
                _pcode = _pcode + "-" + arr[i];
        }
        // logger.log("onEditMeta setCurrItem", item, _pcode);
        // const _pcode = item.code.substring(0, item.code.length-5);

        let _parentItem = metaHList.find((item) => item.code === _pcode);
        if(!_parentItem) {
            _parentItem = topMeta;
            if(!_parentItem) {
                logger.log("onEditMeta setCurrItem", _parentItem, _pcode);
                alert("parentItem 가져오기 실패!!!");
                return;
            }    
        }
        setParentItem(_parentItem);
        logger.log("onEditMeta setCurrItem", item, _parentItem);

        setCurrItem(item);
        setIsEditing(true);
    }

    function onCloseEdit(isEdited: boolean, from: string): void {

        if(isEdited) {            
            getDescendantsList(props.item);
        }

        setIsEditing(false);
    }

    function onSearch(): void {
        const MM_curriBook = document.getElementById("MM_curriBook") as HTMLSelectElement;
        const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement).value

        let newList : IMeta[] = [];
        let list : IMeta[] = JSON.parse(JSON.stringify(oriMetaHList));
        if(MM_curriBook && MM_curriBook.value !== "") {
            const curriBook_val = curriInfo?.curriBook[MM_curriBook.selectedIndex-1] as IMeta;
            list = list.filter((_item) => Number(_item.val1) === curriBook_val.id);
        }
        if(searchKeyword !== "") {
            list.map((meta) => {
                if(meta.val.indexOf(searchKeyword) > -1) {
                    newList.push(meta);
                }
            })
        } else {
            newList = list;
        }
        setMetaHList(newList);

        logger.log("onSearch", list, newList);
    }

    return (
    <div>

        {!isEditing && viewList && <>

        <div className="px-8 py-8 mb-8 border border-gray-300 rounded-xl">
            <div className="text-right">
                <fieldset>
                {/* <label htmlFor="sel-title">{MetaMenuInfo[props.menu.menu2].name === "curriBook" ? "학교급" : "교과목"}</label> */}
                <label htmlFor="sel-title">{"교과과정"}</label>
                    <select id="MM_curriBook">
                        <option value="">전체</option>
                        {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.val}>{item.val}</option>              
                        )})}
                    </select>

                    <label htmlFor="sel-meta" className="ml-3">메타 값</label>
                    <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" />
                    <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                        <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                    </button>
                </fieldset>
            </div>
        </div>

        <div>
            <table>
                <caption className="sr-only"></caption>
                <thead>
                    <tr>
                        <th scope="col" className="px-3">메타 값</th>
                        <th scope="col" className="px-3">Key</th>
                        <th scope="col" className="px-3">교과과정</th>
                        <th scope="col" className="px-3">코드</th>
                        <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                        <th scope="col" className="px-3" style={{width:"100px"}}>등록자​</th>
                        <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                    </tr>
                </thead>
                
                <tbody>
                    {metaHList && (metaHList as IMeta[]).length === 0 &&
                    <tr>
                        <td colSpan={7}>등록된 메타 정보가 없습니다.</td>
                    </tr>}

                    {metaHList && (metaHList as IMeta[]).map((item, idx) => {
                        let _code = item.code;
                        const arrCode = item.code.split("-");
                        // _code = arrCode[arrCode.length-1];
                        _code = _code.replace(arrCode[0]+"-", "");

                        let clsName = "";
                        if(item.depth === 1) {
                            clsName = "";
                        } else if(item.depth === 2) {
                            clsName = "";
                        } else if(item.depth === 3) {
                            clsName = "pl-5";
                        } else if(item.depth === 4) {
                            clsName = "pl-10";
                        } else if(item.depth === 5) {
                            clsName = "pl-15";
                        } else if(item.depth === 6) {
                            clsName = "pl-20";
                        } else if(item.depth === 7) {
                            clsName = "pl-25";
                        }
                        
                        let name = item.title ? item.title : item.val;

                        let bookName = "";
                        if(curriInfo) {
                            const book = curriInfo.curriBook.find((_item) => _item.id === Number(item.val1));
                            if(book) {
                                bookName = book.val;
                            }
                        }

                    return (
						<tr key={idx}>
							<td className="text-center"><p className={clsName}>{name}</p></td>
                            <td className="text-center">{item.id}</td>
							<td className="text-center">{bookName}</td>
							<td className="text-left">{_code}</td>
                            <td>{item.is_active ? "사용" : "미사용"}</td>
                            <td className="leading-4">{item.creator}</td>
							<td className="w-24">
                                <button type="button" className="btn-white-s" onClick={(e) => onEditMeta(e, item)}>보기</button>
							</td>
						</tr>
                    )
                    })}
                </tbody>

            </table>

            <div className="relative mt-5">
                <div className="flex">
                    <button type="button" className="btn-white mr-2" onClick={() => {
                        props.onClose();
                    }}>목록</button>
                    {/* <button type="button" className="btn-white" onClick={onAddMetaToCurrent}>추가<br/>(1Depth)</button> */}
                    <div className="text-right" style={{width: "100%"}}>
                        <button type="button" className="btn-sky" onClick={onAddTopMeta}>등록</button>
                    </div>
                </div>
            </div>
            
        </div></>}
        
        {isEditing && 
            <MetaContentsItemEdit brand={props.brand} item={currItem} parentItem={parentItem} onCloseEdit={onCloseEdit} showModal={props.showModal} showLoading={props.showLoading} />
        }

    </div>
    );
}

export default MetaContentsItem;
