import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, HOST, IBrand, IMenu, IMeta, ITextBook, isDev } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import Tooltip from "@mui/material/Tooltip";
import TeachingEdit from "./TeachingEdit";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import TeachingContentsBookEdit from "./TeachingContentsBookEdit";
import PopupConfirm from "../contents/PopupConfirm";

interface ITeachingContentsBookList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
}
  
function TeachingContentsBookList(props: ITeachingContentsBookList) {
    
    const [list, setList] = useState([] as any);
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [isEditing, setIsEditing] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null as any);
    const [selectedEditItem, setSelectedEditItem] = useState(null as any);

    const [teachingCategoryList, setTeachingCategoryList] = useState(null as any);
    const [teachingUsageList, setTeachingUsageList] = useState(null as any);
    const [teachingSubjectList, setTeachingSubjectList] = useState(null as any);
    
    const [teachingCurriBookList, setTeachingCurriBookList] = useState(null as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    const TB_html_curriUnit1 = document.getElementById("TB_curriUnit1") as HTMLSelectElement;
    const TB_html_curriUnit2 = document.getElementById("TB_curriUnit2") as HTMLSelectElement;
    const TB_html_curriUnit3 = document.getElementById("TB_curriUnit3") as HTMLSelectElement;
    const TB_html_curriUnit4 = document.getElementById("TB_curriUnit4") as HTMLSelectElement;
    const TB_html_curriUnit5 = document.getElementById("TB_curriUnit5") as HTMLSelectElement;

    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");

    const preview_skin = ["basic02", "basic03"];

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getTeachingInfo();

            setTeachingCurriBookList(curriInfo?.curriBook);

            const _pageNum = localStorage.getItem("Teaching_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                
                const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
                if(!new_TB_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_TB_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[0].appendChild(node)
    
                }

                const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
                if(TB_sdate) {
                    TB_sdate.style.display = "none";
                }

                const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
                if(!new_TB_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_TB_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[1].appendChild(node_e)
                }

                const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
                if(TB_edate) {
                    TB_edate.style.display = "none";
                }
            }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            if(isEditing) {
                setIsEditing(false);
            }
        }
    }, [props.menu])

    async function getTeachingInfo() {
        const teachingCategory = await util.getMetaList("1", "teachingCategory");
        if(teachingCategory) {
            const _teachingCategory = teachingCategory.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingCategoryList(_teachingCategory);    
        }
        const teachingUsage = await util.getMetaList("1", "teachingUsage");
        if(teachingUsage) {
            const _teachingUsage = teachingUsage.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingUsageList(_teachingUsage);    
        }
        const teachingSubject = await util.getMetaList("1", "teachingSubject");
        if(teachingSubject) {
            const _teachingSubject = teachingSubject.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingSubjectList(_teachingSubject);    
        }
    }

    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);
        setCurrPage(1);

        // const brand = util.getCurrentBrand();
        // if(!brand) {
        //     return;
        // }
        // setCurrPage(1);

        // const data = {
        //     brand_id: brand.id ? brand.id : null,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function existsUrl(idx: number, _list: ITextBook[]) {

        const item = _list[idx];

        if(item) {
            if(false && isDev) {
                item.isValid = true;    
            } else {
                const url = "https://ibook.vivasam.com"+"/CBS_iBook/"+item.id+"/contents/index.html?skin=basic03";
                const result = await util.existsUrl(url);
    
                logger.log("url", url, result);
    
                item.isValid = result;    

                const __list = JSON.parse(JSON.stringify(_list));
                setList(__list);
            }    
        }
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);

        let list = await util.getTextbookSearch(data);
        if(!list) {
            list = [];
        }

        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            item.isValid = item.pdf_url && item.pdf_url.length > 10 ? true : false;    
        }        

        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const TB_keyword = document.getElementById("TB_keyword") as HTMLInputElement;
        const TB_keyword_type = document.getElementById("TB_keyword_type") as HTMLInputElement;
        
        const TB_curriYear = document.getElementById("TB_curriYear") as HTMLSelectElement;
        const TB_curriYear_val = curriInfo.curriYear[TB_curriYear.selectedIndex-1];

        const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
        const TB_curriSchool_val = curriInfo.curriSchool[TB_curriSchool.selectedIndex-1];

        const TB_curriSubject = document.getElementById("TB_curriSubject") as HTMLSelectElement;
        const TB_curriSubject_val = curriInfo.curriSubject[TB_curriSubject.selectedIndex-1];

        // const TB_curriGrade = document.getElementById("TB_curriGrade") as HTMLSelectElement;
        // const TB_curriGrade_val = curriInfo.curriGrade[TB_curriGrade.selectedIndex-1];

        const TB_curriBook = document.getElementById("TB_curriBook") as HTMLSelectElement;
        const TB_curriBook_val = teachingCurriBookList[TB_curriBook.selectedIndex-1];
        // const TB_curriBook_val = curriInfo.curriBook[TB_curriBook.selectedIndex-1];

        // const TB_Subject = document.getElementById("TB_Subject") as HTMLSelectElement;
        // const TB_Subject_val = teachingSubjectList && (teachingSubjectList as IMeta[]).length > 1 ? teachingSubjectList[TB_Subject.selectedIndex-1] : undefined;
        
        const TB_category = document.getElementById("TB_category") as HTMLSelectElement;
        const TB_category_val = teachingCategoryList && (teachingCategoryList as IMeta[]).length > 1 ? teachingCategoryList[TB_category.selectedIndex-1] : undefined;

        const TB_usage = document.getElementById("TB_usage") as HTMLSelectElement;
        const TB_usage_val = teachingUsageList && (teachingUsageList as IMeta[]).length > 1 ? teachingUsageList[TB_usage.selectedIndex-1] : undefined;

        const metaMap : IMeta[] = [];
        if(TB_curriYear_val) {
            metaMap.push(TB_curriYear_val);
        }
        if(TB_curriSchool_val) {
            metaMap.push(TB_curriSchool_val);
        }        
        if(TB_curriSubject_val) {
            metaMap.push(TB_curriSubject_val);
        }        
        // if(TB_curriGrade_val) {
        //     metaMap.push(TB_curriGrade_val);
        // }        
        if(TB_curriBook_val) {
            metaMap.push(TB_curriBook_val);
        }        
        // if(TB_Subject_val) {
        //     metaMap.push(TB_Subject_val);
        // }
        if(TB_category_val) {
            metaMap.push(TB_category_val);
        }
        if(TB_usage_val) {
            metaMap.push(TB_usage_val);
        }        

        const TB_curriUnit1 = document.getElementById("TB_curriUnit1") as HTMLSelectElement;
        if(TB_curriUnit1.selectedIndex > 0) {
            const TB_curriUnit1_val = curriUnit1[TB_curriUnit1.selectedIndex-1];
            if(TB_curriUnit1_val) {
                metaMap.push(TB_curriUnit1_val);
            }            
        }
        const TB_curriUnit2 = document.getElementById("TB_curriUnit2") as HTMLSelectElement;
        if(TB_curriUnit2.selectedIndex > 0) {
            const TB_curriUnit2_val = curriUnit2[TB_curriUnit2.selectedIndex-1];
            if(TB_curriUnit2_val) {
                metaMap.push(TB_curriUnit2_val);
            }        
        }
        const TB_curriUnit3 = document.getElementById("TB_curriUnit3") as HTMLSelectElement;
        if(TB_curriUnit3.selectedIndex > 0) {
            const TB_curriUnit3_val = curriUnit3[TB_curriUnit3.selectedIndex-1];
            if(TB_curriUnit3_val) {
                metaMap.push(TB_curriUnit3_val);
            }        
        }
        const TB_curriUnit4 = document.getElementById("TB_curriUnit4") as HTMLSelectElement;
        if(TB_curriUnit4.selectedIndex > 0) {
            const TB_curriUnit4_val = curriUnit4[TB_curriUnit4.selectedIndex-1];
            if(TB_curriUnit4_val) {
                metaMap.push(TB_curriUnit4_val);
            }        
        }
        const TB_curriUnit5 = document.getElementById("TB_curriUnit5") as HTMLSelectElement;
        if(TB_curriUnit5.selectedIndex > 0) {
            const TB_curriUnit5_val = curriUnit5[TB_curriUnit5.selectedIndex-1];
            if(TB_curriUnit5_val) {
                metaMap.push(TB_curriUnit5_val);
            }        
        }

        const TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
        const TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
        
        let sdate = undefined;
        let edate = undefined;

        if(TB_sdate && TB_edate) {
            const arrSD = TB_sdate.value.split("-");
            const arrED = TB_edate.value.split("-");
            if(arrSD.length === 3 && arrED.length === 3) {
                sdate = TB_sdate.value;
                edate = TB_edate.value 
            }      
        }

        let search = "";
        if(TB_keyword) {
            search = TB_keyword.value;
        }
        let searchField = "";
        if(TB_keyword_type) {
            searchField = TB_keyword_type.value;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            // keyword: TB_keyword_type && TB_keyword_type.value === "keyword" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,
            // creator: TB_keyword_type && TB_keyword_type.value === "id" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,
            search: search,
            searchField: searchField,

            type: "teachingContentsBook",

            reg_sdate: sdate,  
            reg_edate: edate,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10,

            metaMap: metaMap,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Teaching_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onEditTextBook(textbook: ITextBook | null = null) {
        setSelectedEditItem(textbook);
        setIsEditing(true);
    }

    async function onCopy() {

        props.showLoading(true);

        const TB_chk = document.getElementsByName("TB_chk");

        let checkedCnt = 0;
        for (let i = 0; i < TB_chk.length; i++) {
            const element = TB_chk[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                if(checkedCnt > 1) {
                    props.showModal("", "복사할 i-book 을 하나만 선택해 주세요!");
                    props.showLoading(false);
                    return;        
                }
            }
        }

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const TB_chk_ = document.getElementById("TB_chk_"+i) as HTMLInputElement;
            if(item && TB_chk_ && TB_chk_.checked) {
                logger.log("onCopy", item);

                const data = {
                    id: item.id,
                }
                const result = await util.copyTextbook(item.id!);
                if(!result) {
                    await util.onCheckLoginApi();
                    props.showModal("", "오류가 있습니다. 관리자에게 문의해 주시기 바랍니다.");
                } else {
                    logger.log("onCopy", result);

                    for(;;) {
                        const info = await util.getTextBookInfo(result.id!, false);
                        if(info) {

                            const version = (info as ITextBook).version;
                            logger.log("onCopy check version", version);

                            if(version && version > 0) {
                                break;
                            }
                            await util.timeout(1000); //for 1 sec delay
                        }
                    }

                    props.showModal("", "복사가 완료 되었습니다.");
                }
                
                _onSearch(searchData);
    
                break;
            }            
        }

        props.showLoading(false);
    }

    async function onDelete() {

        const TB_chk = document.getElementsByName("TB_chk");

        let checkedCnt = 0;
        for (let i = 0; i < TB_chk.length; i++) {
            const element = TB_chk[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                if(checkedCnt > 1) {
                    props.showModal("", "삭제할 i-book 을 하나만 선택해 주세요!");
                    return;        
                }
            }
        }

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const radio = document.getElementById("TB_chk_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {

                logger.log("onDelete", item);

                const isAdmin = util.getHasAuthMenu("");
                if(isAdmin) {
                } else {
                    if(util.getUserInfo("uid") !== item.creator) {
                        props.showModal("", "본인이 등록한 교수자료만 삭제할 수 있습니다.");
                        return;        
                    }
                }

                setViewConfirmDelete(true);
                setDeleteTitle("삭제");
                setDeleteDesc("\"" + item.name + "\" 콘텐츠 book 을 삭제 하시겠습니까?");
                setSelectedItem(item);
        
                break;    
            }            
        }
    }

    async function onDeleteOK() {

        onCloseDelete();

        const result = await util.deleteTextbook(selectedItem.id!);
        logger.log("onDelete", result);
        
        _onSearch(searchData);

        props.showLoading(false);

        props.showModal("", "삭제 되었습니다.");
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }

    async function onUse(use: boolean) {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        props.showLoading(true);

        let cnt = 0;
        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const radio = document.getElementById("TB_chk_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {

                logger.log("onUse", item);

                const isAdmin = util.getHasAuthMenu("");
                if(isAdmin) {
                } else {
                    if(util.getUserInfo("uid") !== item.creator) {
                        // props.showModal("", "본인이 등록한 교수자료만 수정할 수 있습니다.");
                        continue;        
                    }
                }

                const _id = util.getUserInfo("id");
                const uid = util.getUserInfo("uid");
                const uname = util.getUserInfo("name");
        
                const data = {

                    brand_id: brand.id,

                    id: item.id,
        
                    updater: uid,
                    updater_id: _id,
                    updater_name: uname,
        
                    is_active: use,
                };
                const result = await util.textbookForSave(data, "update");
                cnt++;
            }            
        }

        props.showLoading(false);

        if(cnt > 0) {
            const _searchData = searchData;
            _onSearch(_searchData);                
        }
    }

    async function onDownload() {

        const TB_chk = document.getElementsByName("TB_chk");

        const checkedList: string[][] = [];

        const column = [
            "제목",
            "링크",
            "뷰어타입",
        ];
    
        checkedList.push(column);

        let cnt = 0;
        for (let i = 0; i < TB_chk.length; i++) {
            const element = TB_chk[i] as HTMLInputElement;
            if(element.checked) {
                const item = list[i] as ITextBook;
                logger.log("onDownload", item);
                
                for (let k = 0; k < preview_skin.length; k++) {
                    const excelData: string[] = [];
                    for (let j = 0; j < column.length; j++) {
                        excelData[j] = "";
                    }
    
                    const url = "https://ibook.vivasam.com"+"/CBS_iBook/"+item.id+"/contents/index.html?skin="+preview_skin[k];
    
                    excelData[0] = item.name;
                    excelData[1] = url;
                    excelData[2] = preview_skin[k];
                    checkedList.push(excelData);      
        
                    cnt++;
                }
            }
        }

        if(cnt > 0) {
            logger.log("checkedList", checkedList);        
            exportToExcel(checkedList);                
        }
    }

    const exportToExcel = (data: string[][]) => {
        const worksheet = XLSX.utils.aoa_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        const today = new Date();
        saveAs(blob, "downloaded_data_" + today.toISOString() + ".xlsx");
    };

    function onClickSDateIcon() : void {
        const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
        if(new_TB_sdate) {
            // new_TB_sdate.value = "";
            const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
            TB_sdate.value = "";
            TB_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
                    TB_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
        if(new_TB_edate) {
            // new_TB_edate.value = "";
            const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
            TB_edate.value = "";
            TB_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
                    TB_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "TB_sdate") {
            const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
            if(new_TB_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_TB_sdate.value = val;
            }
        } else if(e.target.id === "TB_edate") {
            const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
            if(new_TB_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_TB_edate.value = val;
            }
        }
    }

    async function onChangeForCurriBook() {
        
        const brand = util.getCurrentBrand();

        const metaMap: {"name":string, "id":number}[] = [];

        const data = {
            "brand_id": brand?.id,
            "name":"curriBook",
            "metaMap": metaMap,
        }

        if(curriInfo) {

            const TB_curriYear = document.getElementById("TB_curriYear") as HTMLSelectElement;
            if(TB_curriYear) {
                const _curriYear = curriInfo.curriYear.find((meta) => meta.val === TB_curriYear.value)
                if(_curriYear) {
                    metaMap.push({"name":"curriYear", "id":Number(_curriYear.id)});
                }
            }
            const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
            if(TB_curriSchool) {
                const _curriSchool = curriInfo.curriSchool.find((meta) => meta.val === TB_curriSchool.value)
                if(_curriSchool) {
                    metaMap.push({"name":"curriSchool", "id":Number(_curriSchool.id)});
                }
            }
            const TB_curriSubject = document.getElementById("TB_curriSubject") as HTMLSelectElement;
            if(TB_curriSubject) {
                const _curriSubject = curriInfo.curriSubject.find((meta) => meta.val === TB_curriSubject.value)
                if(_curriSubject) {
                    metaMap.push({"name":"curriSubject", "id":Number(_curriSubject.id)});
                }
            }
            // const TB_curriGrade = document.getElementById("TB_curriGrade") as HTMLSelectElement;
            // if(TB_curriGrade) {
            //     const _curriGrade = curriInfo.curriGrade.find((meta) => meta.val === TB_curriGrade.value)
            //     if(_curriGrade) {
            //         metaMap.push({"name":"curriGrade", "id":Number(_curriGrade.id)});
            //     }
            // }
        }

        data.metaMap = metaMap;
        
        props.showLoading(true);

        setBookDescendantsList(null);
        setCurriUnit1(null);
        setCurriUnit2(null);
        setCurriUnit3(null);
        setCurriUnit4(null);
        setCurriUnit5(null);
        setCurriDepth(0);

        const list = await util.searchMeta(data);

        if(list) {
            const _list = (list as IMeta[]).sort((a, b) => a.val! < b.val! ? -1 : 1);	
            setTeachingCurriBookList(_list);
        }

        logger.log("onChangeForCurriBook", data, curriInfo?.curriBook, list);

        props.showLoading(false);
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);

        if(code === "") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);
        }

        props.showLoading(false);
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        getDescendantsList(e.target.value);

        TB_html_curriUnit1.selectedIndex = 0;
        TB_html_curriUnit2.selectedIndex = 0;
        TB_html_curriUnit3.selectedIndex = 0;
        TB_html_curriUnit4.selectedIndex = 0;
        TB_html_curriUnit5.selectedIndex = 0;

        setCurriUnit2([]);
        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit1", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);

        TB_html_curriUnit2.selectedIndex = 0;
        TB_html_curriUnit3.selectedIndex = 0;
        TB_html_curriUnit4.selectedIndex = 0;
        TB_html_curriUnit5.selectedIndex = 0;

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        TB_html_curriUnit3.selectedIndex = 0;
        TB_html_curriUnit4.selectedIndex = 0;
        TB_html_curriUnit5.selectedIndex = 0;

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        TB_html_curriUnit4.selectedIndex = 0;
        TB_html_curriUnit5.selectedIndex = 0;

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        TB_html_curriUnit5.selectedIndex = 0;
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function onChangePreview(e: ChangeEvent<HTMLSelectElement>, item: ITextBook) {

        const select = e.target;

        if(select) {
            const url = "https://ibook.vivasam.com"+"/CBS_iBook/"+item.id+"/contents/index.html?skin=" + select.value;                
            window.open(url, "_blank", "width=1024,height=768");        

            select.selectedIndex = 0;
        }
    }

    return (
        <div>

            {!isEditing && <>
            <div className="top-title">
                <h3>콘텐츠 book 관리</h3>
            </div>

            <div className="" id="subject" role="tabpanel" aria-labelledby="subject-tab"> 
                
                <div className="mb-3 text-right">
                    <button type="button" className="btn-sky" onClick={() => onEditTextBook()}>콘텐츠 book 생성</button>
                </div>

                {/* <!-- 검색영역 --> */}
                <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    <div>
                        <fieldset>
                            <label htmlFor="search-3">교육과정</label>
                            <select id="TB_curriYear" name="search-3" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">학교급</label>
                            <select id="TB_curriSchool" name="search-4" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>

                        {/* <fieldset style={{display: "none"}}>
                            <label htmlFor="search-4" className="ml-4">학년</label>
                            <select id="TB_curriGrade" name="search-4">
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriGrade && (curriInfo?.curriGrade as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>
 */}
                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">교과</label>
                            <select id="TB_curriSubject" name="search-4" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>

                        {/* <fieldset style={{display: "none"}}>
                            <label htmlFor="search-4" className="ml-4">과목</label>
                            <select id="TB_Subject" name="search-4">
                                <option value={""}>전체</option>
                                {teachingSubjectList && (teachingSubjectList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset> */}

                    </div>
                    <div className="mt-5">

                        <fieldset>
                            <label htmlFor="search-4" className="">교과서</label>
                            <select id="TB_curriBook" name="search-4" onChange={(e) => onChangeCurriBook(e)}>
                                <option value={""}>전체</option>
                                {teachingCurriBookList && (teachingCurriBookList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.code}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>

                        <fieldset>
                            {/* <label htmlFor="curriUnit1" className="ml-8">교과과정</label> */}
                            <select className="ml-2 mr-2" id="TB_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                <option value="0">1Depth</option>              
                                {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="TB_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriDepth > 1 ? "" : "none"}}>
                                <option value="0">2Depth</option>              
                                {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="TB_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriDepth > 2 ? "" : "none"}}>
                                <option value="0">3Depth</option>              
                                {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="TB_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriDepth > 3 ? "" : "none"}}>
                            {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                <option value="0">4Depth</option>              
                                {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select id="TB_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriDepth > 4 ? "" : "none"}}>
                                <option value="0">5Depth</option>              
                                {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="search-5" className="ml-4">교수자료 유형</label>
                            <select id="TB_category" name="search-5">
                                <option value={""}>전체</option>
                                {teachingCategoryList && (teachingCategoryList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        <fieldset style={{display: "none"}}>
                            <label htmlFor="search-5" className="ml-4">교수자료 용도</label>
                            <select id="TB_usage" name="search-5">
                                <option value={""}>전체</option>
                                {teachingUsageList && (teachingUsageList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                    </div>

                    <div className="mt-3">
                        <fieldset>
                            <label htmlFor="date" className="">등록일​</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='TB_sdate' name='TB_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px] ml-5" onClick={onClickSDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='TB_edate' name='TB_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                    </div>

                    <div className="mt-3 text-right">

                        <fieldset>
                            <label htmlFor="keyword_type">검색</label>
                            <select className="mr-2" id="TB_keyword_type" name="keyword_type">
                                {/* <option value={"keyword"}>검색어</option> */}
                                <option value={"name"}>제목</option>
                                <option value={"creator"}>등록자</option>
                                <option value={"hashTags"}>해시태그</option>
                                <option value={"id"}>Key</option>
                            </select>
                            <input type="text" name="keyword" id="TB_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>                        
                </div>
                {/* <!-- .//검색영역 --> */}

                <div className="mt-6 mb-3">
                    <form action="" className="flex justify-between">
                        <fieldset>
                            <span>총 <strong className="text-red">{listCount}</strong>건</span>
                            <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                            <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={100}>100</option>
                            </select>
                        </fieldset>
                    </form>                    
                </div>

                <div className="mt-3 mb-5 text-right">
                    <div className="w-avail" style={{display:"ruby"}}>
                        <button type="button" className="btn-white mr-2" onClick={onDownload}>링크 다운로드</button>
                        <button type="button" className="btn-white mr-2" onClick={onCopy}>복사</button>
                        <button type="button" className="btn-white mr-2" onClick={onDelete}>삭제</button>
                        <button type="button" className="text-sky-500 btn-white mr-2" onClick={() => onUse(true)}><span className="text-sky-500">사용</span></button>
                        <button type="button" className="text-red-600 btn-white" onClick={() => onUse(false)}> <span className="text-red-600">미사용</span></button>

                    </div>
                    {/* <button type="button" className="btn-white mr-2" onClick={() => {}}>링크 생성</button> */}
                </div>


				{/* <!-- table --> */}
                {viewList &&
				<table>
					<caption className="sr-only">목록</caption>
					<thead>
						<tr>
                            <th scope="col" className="pl-4 pr-3">
                                <input id="all_check" type="checkbox" className="h-4 w-4 rounded" onClick={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    if(input) {
                                        (list as ITextBook[]).map((_, idx) => {
                                            const TB_check = document.getElementById("TB_chk_"+idx) as HTMLInputElement;
                                            TB_check.checked = input.checked;
                                        })
                                    }
                                }}                                
                                />
                            </th>

							<th scope="col" className="px-3">번호</th>
							<th scope="col" className="px-3">Key</th>
							<th scope="col" className="px-3">학교급</th>
							<th scope="col" className="px-3">교과</th>
							<th scope="col" className="px-3">교수자료 유형</th>
							<th scope="col" className="px-3">제목</th>
							<th scope="col" className="px-3">등록자</th>
							<th scope="col" className="px-3">등록일</th>
							<th scope="col" className="px-3">설정</th>
							<th scope="col" className="px-3">편집</th>
							<th scope="col" className="px-3">미리보기</th>
							<th scope="col" className="px-3">사용</th>
						</tr>
					</thead>
					<tbody>
                        {teachingSubjectList && viewList && list && (list as ITextBook[]).length === 0 &&
                        <tr>    
                            <td colSpan={13}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as ITextBook[]).map((item, idx) => {

                        // const totalCnt = item.full_count ? item.full_count : 0;
                        const totalCnt = Math.max(item.full_count ? item.full_count : 0, listCount);
                        const _numOfPage = localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        // logger.log("totalCnt", totalCnt, "_numOfPage", _numOfPage, currPage, _num);

                        // let _teachingSubject = undefined;
                        // const _teachingSubjectList = (teachingSubjectList as IMeta[]);
                        // if(_teachingSubjectList) {
                        //     _teachingSubject = _teachingSubjectList.find((subject) => subject.id === item.curriSubject);
                        // }

                        const curriSchool = curriInfo?.curriSchool.find((school) => school.id === item.curriSchool);

                        const curriSubject = curriInfo?.curriSubject.find((school) => school.id === item.curriSubject);

                        const _teachingCategory = (teachingCategoryList as IMeta[]).find((cate) => cate.id === Number(item.data_type));

                        const hasAuth = util.getHasAuthMenu(item.creator);

                        return (
                            <tr className="rt-tr action" key={idx}>
                                <td>
                                    <input id={"TB_chk_"+idx} type="checkbox" name="TB_chk" className="h-4 w-4 rounded" onClick={(e) => {
                                        const text_ck = document.getElementsByName("TB_chk");
                                        let cntChecked = 0;
                                        for (let i = 0; i < text_ck.length; i++) {
                                            const element = text_ck[i] as HTMLInputElement;
                                            if(element.checked) {
                                                cntChecked = cntChecked + 1;
                                            }
                                        }
                                        const all_check = document.getElementById("all_check") as HTMLInputElement;
                                        if(cntChecked === text_ck.length) {                                                
                                            all_check.checked = true;                                                
                                        } else {
                                            all_check.checked = false;                                                
                                        }
                                    }}/>
                                </td>

                                <td>{_num}</td>
                                <td>{item.id}</td>
                                <td>{curriSchool?.val}</td>
                                <td>{curriSubject?.val}</td>
                                <td>{_teachingCategory?.val}</td>
                                <td>{item.name}</td>
                                <td>{item.creator}</td>
                                <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                <td>
                                    <button type="button" className="btn-white-s" onClick={() => {
                                        onEditTextBook(item);
                                    }} disabled={!hasAuth}>수정</button>
                                </td>
                                <td>
                                    <button type="button" className="btn-white-s" onClick={() => {

                                        // alert("새창으로 띄울 경로가 필요합니다.");

                                        const brand = util.getCurrentBrand();
                                        if(!brand || !brand.id) {
                                            return;
                                        }
                                
                                        let url = "https://ibook.vivasam.com" + "/ebookeditor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                        +"&textbook_id="+item.id
                                        +"&web_textbook_id="+(item.web_textbook_id ? item.web_textbook_id : "")
                                        +"&type=teaching"
                                        +"&token="+util.getUserInfo("token");
                                                
                                        // const url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                        // +"&set_id="+item.id+"&token="+util.getUserInfo("token");

                                        window.open(url, "_blank", "noopener, noreferrer");    
                            
                                    }} disabled={!hasAuth}>수정</button>
                                </td>
                                <td>
                                    <select id="TB_preview" name="preview" onChange={(e) => onChangePreview(e, item)} disabled={!item.isValid} style={{backgroundColor:item.isValid ? "#ffffff" : "#dddddd"}} >
                                        <option value={""}>미리보기</option>
                                        {preview_skin && preview_skin.map((skin, idx) => {
                                        return(
                                            <option key={idx} value={skin}>{skin}</option>              
                                        )})}
                                    </select>
                                        
                                    {/* <button type="button" className="btn-white-s" disabled={!item.isValid} onClick={() => {
                                        const url = "https://ibook.vivasam.com"+"/CBS_iBook/"+item.id+"/contents/index.html?skin=basic03";
                                        window.open(url, "_blank", "width=1024,height=768");        
                                    }}>미리보기</button> */}

                                </td>
                                
                                <td>
                                    {item.is_active ? "사용" : "미사용"}
                                    {/* <button type="button" className="btn-white-s" onClick={() => {
                                    }}>링크생성</button> */}
                                </td>
                            </tr>
                        )})}
					</tbody>
				</table>}
				{/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div>
            </div></>}

            {isEditing && 
            <TeachingContentsBookEdit brand={props.brand} menu={props.menu} item={selectedEditItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                const _searchData = searchData;
                if(_searchData) {
                    _onSearch(_searchData);                    
                }
                setIsEditing(false);
            }} />}

            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />

        </div>

    );  
}
export default TeachingContentsBookList;
