import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, IMenu, IMeta } from "../common";
import { logger } from '../logger';
import AITutorFreeSubject from "./AITutorFreeSubject";
import AITutorWriting from "./AITutorWriting";
import AITutorContentsList from "./AITutorContentsList";

interface IAITutorManager {
    view: boolean;
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function AITutorManager(props: IAITutorManager) {
    
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
        }
        bDone = true;
    }, [])

    let prevView = false;
    useEffect(() => {
        if(props.view && !prevView) {
        }
        prevView = props.view;
    }, [props.view])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
        }
    }, [props.menu])

    useEffect(() => {
        if(props.brand) {
            logger.log("props.brand", props.brand);
        }
    }, [props.brand])

    return (
        <div>
            {currMenu && currMenu.menu2 === 0 && <AITutorContentsList brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu2 === 2 && <AITutorFreeSubject brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu2 === 3 && <AITutorWriting brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
        </div>
    );
}
export default AITutorManager;
