import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, IEngTemp, IGrammarTemplate, ILibrary, ILibrarySearchData, IMenu, IMeta } from "../common";
import { logger } from '../logger';
import LibraryEngTemplateSearch from "./LibraryEngTempSearch";
import LibraryEngTempBulkRegister from "./LibraryEngTempBulkRegister";
import LibraryEngTempRegister, { ITempDiv2EV1, ITempDiv2EV3 } from "./LibraryEngTempRegister";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface ILibraryEngTemplate {
    brand: IBrand;
    menu?: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function LibraryEngTemplate(props: ILibraryEngTemplate) {
    
    const [list, setList] = useState([] as any);
    const [selectedItem, setSelectedItem] = useState(null as any);
    const [viewList, setViewList] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isBulkEditing, setIsBulkEditing] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [textType3, setTextType3] = useState("");

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            setIsEditing(false);
            setIsBulkEditing(false);
            setSelectedItem(null);

            const _pageNum = localStorage.getItem("Library_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(_pageNum) {
                pagenum.value = _pageNum;
            } else {
                pagenum.value = "10";                
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.brand) {
            logger.log("Library props.brand", props);
            refreshList();
        }
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            if(isEditing || isBulkEditing) {
                onEditClose();
            }
        }
    }, [props.menu])

    useEffect(() => {
        if(isEditing || isBulkEditing) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     setIsBulkEditing(false);
            // }
        } 

        if(!isEditing && !isBulkEditing) {
            
            logger.log("isEditing", isEditing);

            const _searchData = searchData;
            if(_searchData) {
                onSearch(_searchData);            
            }
        }

    }, [isEditing, isBulkEditing])

    useEffect(() => {    
    }, [currPage])

    const refreshList = () => {

        setIsEditing(false);
        setIsBulkEditing(false);
        setSelectedItem(null);

        setSearchData(null);
        setList([]);
        
        // const brand = util.getCurrentBrand();
        // if(!brand) {
        //     return;
        // }
        // const data = {
        //     type: "영어교과템",
        //     type_1: "",
        //     brand_id: brand.id ? brand.id : null,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10,
        // };
        // logger.log("refreshList", data);
        // onSearch(data);

        // setCurrPage(1);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);
        const list = await util.getEngTempList(data);
        setList(list);
        logger.log("onSearch", data, list);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
            }
            calcPage(totalCnt, currentPage);
        }
        setViewList(true);

        props.showLoading(false);
    }

    function onSearch(data: any): void {
        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                onChangePage(_currPage);
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                onChangePage(_currPage);
            }
        }
    }


    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Library_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        logger.log("numOfPage", numOfPage);
        onSearch(_searchData);            
    }

    function onBulkAdd(): void {
        setIsBulkEditing(true);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onAdd(): void {
        setIsEditing(true);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onEditClose(): void {

        setIsEditing(false);
        setIsBulkEditing(false);
        setSelectedItem(null);

        // refreshList();
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onChangePage(page: number) {
        
        const _searchData = searchData;
        _searchData.limit_page = page;
        onSearch(_searchData);
        
        logger.log("onChangePage", page, searchData, _searchData);

        setCurrPage(page);
    }
    
    function onChangeTextType3(val: string): void {
        setTextType3(val);
    }
    
    function onConfirm(item: IEngTemp): void {
        setIsEditing(true);
        setSelectedItem(item);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }
    
    async function onDownload(item: IEngTemp) {

        props.showLoading(true);
        const result = await util.getEngTempInfo(item.id);
        logger.log("onDownload", result);

        if(result) {
    
            const engTemp = result as IEngTemp;

            const metaMap = engTemp.metaMap;
            const curriSchool = metaMap.find((meta) => meta.name === "curriSchool");
            const curriSubject = metaMap.find((meta) => meta.name === "curriSubject");
 
            if(engTemp && engTemp.type_1 === "Listening Comprehension" || engTemp.type_1 === "Reading Comprehension") {

                const templateDiv = await util.getMetaList("1", "templateDiv");

                const column = [
                "브랜드",
                "KEY",
                "타이틀",
                "설명",
                "학교급",
                "교과목",
                "교과",
                "교과과정1",
                "교과과정2",
                "교과과정3",
                "교과과정4",
                "템플릿 구분값(1depth)",
                "템플릿 구분값(2depth)",
                "평가영역1",
                "지문id(1view)",
                "오디오Key",
                "비디오Key",
                "작성자",
                "등록일",
                "사용설정",
                "공개여부"];

                const checkedList = [[] as any, [] as any];
                checkedList.push(column);

                const excelData = [];
                for (let i = 0; i < column.length; i++) {
                    excelData[i] = "";
                }

                excelData[0] = util.getCurrentBrand()?.id;
                excelData[1] = engTemp.id;
                excelData[2] = engTemp.name;
                excelData[3] = engTemp.description;
                excelData[4] = curriSchool?.id;
                excelData[5] = curriSubject?.id;
                excelData[6] = engTemp.curriBook;
                excelData[7] = engTemp.curriUnit1;
                excelData[8] = engTemp.curriUnit2;
                excelData[9] = engTemp.curriUnit3;
                excelData[10] = engTemp.curriUnit4;

                excelData[15] = engTemp.audioKey ? engTemp.audioKey.id : "";
                excelData[16] = engTemp.videoKey ? engTemp.videoKey.id : "";
                excelData[17] = engTemp.creator;
                excelData[18] = engTemp.regdate;
                excelData[19] = engTemp.is_active ? "1" : "0";
                excelData[20] = engTemp.is_publicOpen ? "1" : "0";

                const _templateDiv = templateDiv.find((meta) => meta.val === engTemp.type_1);
                if(_templateDiv) {
                    excelData[11] = engTemp.type_1;
                    const arrTDiv2 = JSON.parse(engTemp.evaluationArea1String) as ITempDiv2EV1[];
                    logger.log("arrTDiv2", arrTDiv2);

                    for (let j = 0; j < arrTDiv2.length; j++) {
                        excelData[12] = "";
                        excelData[13] = "";
                        excelData[14] = "";
                        const _TDiv2 = arrTDiv2[j];
                        excelData[12] = "" + _TDiv2.tempDiv2.val;
                        let evVal = "";
                        if(_TDiv2.EV1_1) {
                            evVal = "" + _TDiv2.EV1_1.val;
                        } 
                        if(_TDiv2.EV1_2) {
                            evVal = evVal + "," + _TDiv2.EV1_2.val;
                        }
                        if(_TDiv2.EV1_3) {
                            evVal = evVal + "," + _TDiv2.EV1_3.val;
                        }
                        if(_TDiv2.EV1_4) {
                            evVal = evVal + "," + _TDiv2.EV1_4.val;
                        }
                        if(_TDiv2.EV1_5) {
                            evVal = evVal + "," + _TDiv2.EV1_5.val;
                        }
                        if(_TDiv2.EV1_6) {
                            evVal = evVal + "," + _TDiv2.EV1_6.val;
                        }
                        excelData[13] = evVal;

                        if(_TDiv2.scripts) {
                            let scriptIds = "";
                            for (let k = 0; k < _TDiv2.scripts.length; k++) {
                                const element = _TDiv2.scripts[k];
                                if(scriptIds === "") {
                                    scriptIds = element.id + "";
                                } else {
                                    scriptIds = scriptIds + "," + element.id;
                                }
                            }
                            excelData[14] = scriptIds;
                        }

                        checkedList.push(JSON.parse(JSON.stringify(excelData)));
                    }
                }  
                
                logger.log("checkedList", checkedList);
                exportToExcel(checkedList);            

            } else if(engTemp && engTemp.type_1 === "Voca") {

                const templateDiv = await util.getMetaList("1", "templateDiv");

                const column = [
                "브랜드",
                "KEY",
                "타이틀",
                "설명",
                "학교급",
                "교과목",
                "교과",
                "교과과정1",
                "교과과정2",
                "교과과정3",
                "교과과정4",
                "템플릿 구분값(1depth)",
                "템플릿 구분값(2depth)",
                "평가영역3",
                "어휘Key",
                "작성자",
                "등록일",
                "사용설정",
                "공개여부"];

                const checkedList = [[] as any, [] as any];
                checkedList.push(column);

                const excelData = [];
                for (let i = 0; i < column.length; i++) {
                    excelData[i] = "";
                }

                excelData[0] = util.getCurrentBrand()?.id;
                excelData[1] = engTemp.id;
                excelData[2] = engTemp.name;
                excelData[3] = engTemp.description;
                excelData[4] = curriSchool?.id;
                excelData[5] = curriSubject?.id;
                excelData[6] = engTemp.curriBook;
                excelData[7] = engTemp.curriUnit1;
                excelData[8] = engTemp.curriUnit2;
                excelData[9] = engTemp.curriUnit3;
                excelData[10] = engTemp.curriUnit4;

                let textKey = "";
                for (let j = 0; j < engTemp.textKey.length; j++) {
                    if(textKey === "") {
                        textKey = "" + engTemp.textKey[j].id;
                    } else {
                        textKey = textKey + "," + engTemp.textKey[j].id;
                    }                    
                }
                
                excelData[14] = textKey;
                excelData[15] = engTemp.creator;
                excelData[16] = engTemp.regdate;
                excelData[17] = engTemp.is_active ? "1" : "0";
                excelData[18] = engTemp.is_publicOpen ? "1" : "0";

                const _templateDiv = templateDiv.find((meta) => meta.val === engTemp.type_1);
                if(_templateDiv) {
                    excelData[11] = engTemp.type_1;
                    const arrTDiv2 = JSON.parse(engTemp.evaluationArea3String) as ITempDiv2EV3[];
                    for (let j = 0; j < arrTDiv2.length; j++) {
                        excelData[12] = "";
                        excelData[13] = "";
                        const _TDiv2 = arrTDiv2[j];
                        excelData[12] = "" + _TDiv2.tempDiv2.val;
                        let evVal = "";
                        if(_TDiv2.EV3) {
                            evVal = "" + _TDiv2.EV3.val;
                        } 
                        excelData[13] = evVal;

                        checkedList.push(JSON.parse(JSON.stringify(excelData)));
                    }
                }  
                
                logger.log("checkedList", checkedList);
                exportToExcel(checkedList);   

            } else if(engTemp && engTemp.type_1 === "Grammar") {

                const column = [
                "브랜드",
                "KEY",
                "타이틀",
                "설명",
                "학교급",
                "교과목",
                "교과",
                "교과과정1",
                "교과과정2",
                "교과과정3",
                "교과과정4",
                "템플릿 구분값(1depth)",
                "평가영역2",
                "문법1-문법타이틀",
                "문법1-개념학습​",
                "문법1-연습문제1",
                "문법1-연습문제2",
                "문법1-실전테스트1",
                "문법1-실전테스트2",
                "문법1-실전테스트3",
                "문법2-문법타이틀",
                "문법2-개념학습​",
                "문법2-연습문제1",
                "문법2-연습문제2",
                "문법2-실전테스트1",
                "문법2-실전테스트2",
                "문법2-실전테스트3",
                "단원테스트1",
                "단원테스트2",
                "단원테스트3",

                "작성자",
                "등록일",
                "사용설정",
                "공개여부"];

                const checkedList = [[] as any, [] as any];
                checkedList.push(column);

                const excelData = [];
                for (let i = 0; i < column.length; i++) {
                    excelData[i] = "";
                }

                excelData[0] = util.getCurrentBrand()?.id;
                excelData[1] = engTemp.id;
                excelData[2] = engTemp.name;
                excelData[3] = engTemp.description;
                excelData[4] = curriSchool?.id;
                excelData[5] = curriSubject?.id;
                excelData[6] = engTemp.curriBook;
                excelData[7] = engTemp.curriUnit1;
                excelData[8] = engTemp.curriUnit2;
                excelData[9] = engTemp.curriUnit3;
                excelData[10] = engTemp.curriUnit4;

                excelData[11] = engTemp.type_1;
                excelData[12] = engTemp.evaluationArea2String;
                
                excelData[30] = engTemp.creator;
                excelData[31] = engTemp.regdate;
                excelData[32] = engTemp.is_active ? "1" : "0";
                excelData[33] = engTemp.is_publicOpen ? "1" : "0";

                const arr = JSON.parse(engTemp.tempListString) as IGrammarTemplate[];
                for (let j = 0; j < arr.length; j++) {
                    const item = arr[j];
                    excelData[13+j] = "" + item.val;

                }

                checkedList.push(JSON.parse(JSON.stringify(excelData)));

                logger.log("checkedList", checkedList);
                exportToExcel(checkedList);            

            } else {

                const column = [
                "브랜드",
                "KEY",
                "타이틀",
                "설명",
                "학교급",
                "교과목",
                "교과",
                "교과과정1",
                "교과과정2",
                "교과과정3",
                "교과과정4",
                "템플릿 구분값(1depth)",
                "작성자",
                "등록일",
                "사용설정",
                "공개여부"];

                const checkedList = [[] as any, [] as any];
                checkedList.push(column);

                const excelData = [];
                for (let i = 0; i < column.length; i++) {
                    excelData[i] = "";
                }

                excelData[0] = util.getCurrentBrand()?.id;
                excelData[1] = engTemp.id;
                excelData[2] = engTemp.name;
                excelData[3] = engTemp.description;
                excelData[4] = curriSchool?.id;
                excelData[5] = curriSubject?.id;
                excelData[6] = engTemp.curriBook;
                excelData[7] = engTemp.curriUnit1;
                excelData[8] = engTemp.curriUnit2;
                excelData[9] = engTemp.curriUnit3;
                excelData[10] = engTemp.curriUnit4;

                excelData[11] = engTemp.type_1;
                excelData[12] = engTemp.creator;
                excelData[13] = engTemp.regdate;
                excelData[14] = engTemp.is_active ? "1" : "0";
                excelData[15] = engTemp.is_publicOpen ? "1" : "0";

                checkedList.push(JSON.parse(JSON.stringify(excelData)));
            
                logger.log("checkedList", checkedList);
                exportToExcel(checkedList);            
            }
        }

        props.showLoading(false);
    }

    const exportToExcel = (data: any[]) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        const today = new Date();
        saveAs(blob, "downloaded_data_" + today.toISOString() + ".xlsx");
    };

    return (
        <div>
            <div className="top-title">
                <h3>영어 교과 전용 템플릿 관리</h3>
            </div>

            <div style={{display: isEditing || isBulkEditing ? "none" : ""}}>            
                {/* <!-- 검색영역 --> */}
                <LibraryEngTemplateSearch view={true} onSearch={onSearch} brand={props.brand} pageInfo={{ page: currPage, pageItemCnt: localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10 }} showLoading={props.showLoading} />
                {/* <!-- .//검색영역 --> */}

                <div className="mt-5 mb-3 text-right">
                    <label htmlFor="page-num">페이지당 표기 수</label>
                    <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </select>
                </div>

                {!isEditing && !isBulkEditing && viewList && <>

                {/* <!-- table --> */}
                <table>
                    <caption className="sr-only">관리 테이블</caption>
                    <thead>
                        <tr>
                            {/* <th scope="col" className="pl-3 pr-3">콘텐츠<br/>미리보기</th> */}
                            <th scope="col" className="px-3">구분</th>
                            <th scope="col" className="px-3">KEY</th>
                            <th scope="col" className="px-3">타이틀</th>
                            <th scope="col" className="px-3">교과</th>
                            {/* <th scope="col" className="px-3">교과과정</th> */}
                            <th scope="col" className="px-3">등록자</th>
                            <th scope="col" className="px-3">등록일</th>
                            <th scope="col" className="pl-3">상세보기</th>
                            <th scope="col" className="pl-3 pr-3">다운로드</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewList && list && (list as IEngTemp[]).length === 0 &&
                        <tr>
                            <td colSpan={8}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as IEngTemp[]).map((item, idx) => {

                            let _unitVal = "";
                            let _curriBookVal = ""

                            if(curriInfo && curriInfo.curriBook) {
                                const curriBook = curriInfo.curriBook as IMeta[];
                                const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                _curriBookVal = _curriBook ? _curriBook.val : "";
                            }

                            const totalCnt = item.full_count;
                            const _numOfPage = localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10;
                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                            let _type = item.type_1;
                            const children = item.children;
                            if(children) {
                                const arr = children.split(",");
                                if(arr.length > 1) {
                                    _type = _type + " > " + arr[0] + "외 " + (arr.length-1) + "건";
                                } else if(arr.length === 1) {
                                    _type = _type + " > " + arr[0]
                                }
                            }
            
                            let title = item.name;
                            if(title && title.length > 50) {
                                title = title.substring(0, 47) + "...";
                            }

                            return (
                                <tr key={idx}>                        
                                    {/* <td>
                                        <center>
                                            <img src={thumb} style={{width:"150px", height:"150px", cursor:"pointer"}} alt="썸네일" onClick={() => {}} />
                                        </center>
                                    </td> */}
                                    <td>{_type}</td>
                                    <td>{item.id}</td>
                                    <td>{title}</td>
                                    <td>{_curriBookVal}</td>
                                    {/* <td dangerouslySetInnerHTML={{ __html: _unitVal }}></td> */}
                                    <td>{item.creator_name} ({item.creator})</td>
                                    <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                    <td>
                                        <button type="button" className="btn-white-s" onClick={() => onConfirm(item)}>확인</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn-white-s" onClick={() => {onDownload(item)}}>다운로드</button>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                {/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    <div className="absolute top-0 right-0 text-right">
                        <button type="button" className="btn-sky mr-2" onClick={onBulkAdd}>일괄등록</button>
                        <button type="button" className="btn-sky" onClick={onAdd}>등록</button>
                    </div>

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}

                </div></>}

            </div>

            {isEditing && <LibraryEngTempRegister OnClose={onEditClose} brand={props.brand} onChangeTextType3={onChangeTextType3} selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} />}
            {isBulkEditing && <LibraryEngTempBulkRegister OnClose={onEditClose} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} type={""} />}

        </div>
    );  
}
export default LibraryEngTemplate;
