import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IBrand, ICurriInfo, IMenu, IMeta } from "../common";
import { logger } from '../logger';
import ContentsList from "./ContentsList";
import ContentsMyEdit from "./ContentsMyEdit";
import ContentsSetList from "./ContentsSetList";
import ContentsSetListBulkRegister from "./ContentsSetListBulkRegister";
import AnalogousContentsList from "./AnalogousContentsList";

interface IContentsManager {
    view: boolean;
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function ContentsManager(props: IContentsManager) {

    const [type, setType] = useState("article");
    const [urlIframe, setUrlIframe] = useState("");
    
    let prevView = false;
    
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.view && !prevView) {
        }
        // logger.log("props.view", props.view);
        prevView = props.view;
    }, [props.view])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            onViewContents();
        }
    }, [props.menu])

    useEffect(() => {
        if(props.brand) {
            // logger.log("props.brand", props.brand);
        }
    }, [props.brand])

    function onViewContents() {

        const currMenu = util.getCurrentMenu();

        setUrlIframe("");

        if(currMenu.menu2 === 0) {
        } else if(currMenu.menu2 === 1) {
            setType("article");
        } else if(currMenu.menu2 === 2) {
            setType("set");
        } else if(currMenu.menu2 === 3) {

            const brand = util.getCurrentBrand();
            if(!brand || !brand.id) {
                return;
            }
    
            const url = EDITOR_HOST+"/editor/manage-template.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&token="+util.getUserInfo("token");

            // setUrlIframe(url);
            window.open(url, "manage-template", "noopener, noreferrer");        

        } else if(currMenu.menu2 === 4) {

            const brand = util.getCurrentBrand();
            if(!brand || !brand.id) {
                return;
            }
    
            const url = EDITOR_HOST+"/editor/batch.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&token="+util.getUserInfo("token");

            // setUrlIframe(url);
            window.open(url, "batch", "noopener, noreferrer");        

        }
    }

    return (
        <div>
            {currMenu && (currMenu.menu2 === 0) && <ContentsMyEdit brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && (currMenu.menu2 === 1) && <ContentsList brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && (currMenu.menu2 === 2) && <ContentsSetList brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && (currMenu.menu2 === 5) && <ContentsSetListBulkRegister brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && (currMenu.menu2 === 6) && <AnalogousContentsList brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}

            {urlIframe !== "" &&
            <iframe 
                width="100%" 
                height="1000px"        
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                src={urlIframe}>
            </iframe>}
        </div>
    );
}
export default ContentsManager;
