import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IUser } from "../common";

interface IPopupChangePassword {
    view: boolean;
    onChangePwd: (pwd: string) => void;
    showModal: (title: string, description: string) => void;    
}

function PopupChangePassword(props: IPopupChangePassword) {
      
    const [isOk, setIsOk] = useState(false);

    useEffect(() => {
        if(props.view) {
            (document.getElementById('pwd') as HTMLInputElement).value = "";
            (document.getElementById('pwdc') as HTMLInputElement).value = "";
        }
    }, [props.view])

    function onChangePwd(): void {

        const pwd = (document.getElementById('pwd') as HTMLInputElement).value;
        const pwdc = (document.getElementById('pwdc') as HTMLInputElement).value;

        if(pwd === "") {
            props.showModal("", "신규 비밀번호를 입력해 주세요.");
            (document.getElementById('pwd') as HTMLInputElement).focus();
            return;    
        } else if(pwdc === "") {
            props.showModal("", "비밀번호 확인을 입력해 주세요.");
            (document.getElementById('pwdc') as HTMLInputElement).focus();
            return;
        } else if(pwd !== pwdc) {
            props.showModal("", "신규 비밀번호와 비밀번호 확인을 동일하게 입력해 주세요.​");
            return;
        }

        const uid = util.getUserInfo("uid");
        // if(uid !== "gozmon7") {            
        // }
        if(pwd.length < 8 || pwd.length > 16) {
            props.showModal("", "비밀번호는 8~16자 문자, 숫자를 결합해 입력해 주세요.");
            return;    
        }
        const num = pwd.search(/[0-9]/g);
        const eng = pwd.search(/[a-z]/ig);
        if(num < 0 || eng < 0) {
            props.showModal("", "비밀번호는 8~16자 문자, 숫자를 결합해 입력해 주세요.");
            return;    
        }
        // logger.log(num);
        // logger.log(eng);

        props.onChangePwd(pwd);

    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : props.view ? "" : "none"}}>
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box max-w-xl min-w-[576px]">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onChangePwd("");
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">비밀번호 변경</h2>
                            <div>
                                <div className="px-5">
                                    <div className="py-8 border-b border-slate-600 text-center">
                                        <label htmlFor="new_pw">신규 비밀번호</label>
                                        <input type="password" id="pwd" />
                                        <p className="mt-2 mb-3 text-sm text-slate-600">※ 8~16자 문자, 순자를 결합하여 입력해 주세요.</p>
                                        <label htmlFor="new_pw2">비밀번호 확인</label>
                                        <input type="password" id="pwdc" />
                                    </div>
                                </div>
                                <div className="my-5 text-center">
                                    <button type="button" className="btn-sky" onClick={onChangePwd}>비밀번호 변경</button>
                                </div>
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>
        </div>        
    );
}
  
export default PopupChangePassword;
