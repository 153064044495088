import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, IMenu, IMeta, S3Server, isDev } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import PopupConfirm from "./PopupConfirm";
import { IArticlePartGroup } from "../aitutor/AITutorContentsList";
import MyButton from "../MyButton";
import PopupContentsUpdateHistory from "./PopupContentsUpdateHistory";
import AnalogousArticles from "./AnalogousContentsEdit";

interface IContentsList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function ContentsList(props: IContentsList) {
    
    const [list, setList] = useState([] as any);
    const [viewType, setViewType] = useState("");  //s:small, b:big
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [studyMapsList, setStudyMapsList] = useState<IMeta[]>([]);
    const [studyMap0List, setStudyMap0List] = useState<IMeta[]>([]);    
    const [studyMap1List, setStudyMap1List] = useState<IMeta[]>([]);
    const [studyMap2List, setStudyMap2List] = useState<IMeta[]>([]);
    const [studyMap3List, setStudyMap3List] = useState<IMeta[]>([]);
    const [studyMap4List, setStudyMap4List] = useState<IMeta[]>([]);
    const [studyMap5List, setStudyMap5List] = useState<IMeta[]>([]);

    const [curriDepth, setCurriDepth] = useState(0);
    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    const [currArticleCategory, setCurrArticleCategory] = useState("");

    const [modalShow, setModalShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");

    const [viewContentsUpdateHistory, setViewContentsUpdateHistory] = useState(false);

    const [viewAnalogousArticles, setViewAnalogousArticles] = useState(false);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    const CL_html_curriUnit1 = document.getElementById("CL_curriUnit1") as HTMLSelectElement;
    const CL_html_curriUnit2 = document.getElementById("CL_curriUnit2") as HTMLSelectElement;
    const CL_html_curriUnit3 = document.getElementById("CL_curriUnit3") as HTMLSelectElement;
    const CL_html_curriUnit4 = document.getElementById("CL_curriUnit4") as HTMLSelectElement;
    const CL_html_curriUnit5 = document.getElementById("CL_curriUnit5") as HTMLSelectElement;

    const CL_studyMap1 = document.getElementById("CL_studyMap1") as HTMLSelectElement;
    const CL_studyMap2 = document.getElementById("CL_studyMap2") as HTMLSelectElement;
    const CL_studyMap3 = document.getElementById("CL_studyMap3") as HTMLSelectElement;
    const CL_studyMap4 = document.getElementById("CL_studyMap4") as HTMLSelectElement;
    const CL_studyMap5 = document.getElementById("CL_studyMap5") as HTMLSelectElement;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getStudyMapList();

            // setViewList(true);
            setViewType("s");
            setCurrArticleCategory("60");

            const _pageNum = localStorage.getItem("Contents_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
            viewlist1.checked = true;

            const selectTypeCB = document.getElementById("rd-select-cb") as HTMLInputElement;
            selectTypeCB.checked = true;
            
            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                
                const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
                if(!new_CL_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_CL_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[0].appendChild(node)
    
                }

                const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
                if(CL_sdate) {
                    CL_sdate.style.display = "none";
                }

                const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
                if(!new_CL_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_CL_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[1].appendChild(node_e)
                }

                const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
                if(CL_edate) {
                    CL_edate.style.display = "none";
                }
            }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        let articleCategoryId = undefined;
        if(curriInfo && curriInfo.articleCategory) {
            const articleCategory = curriInfo.articleCategory as IMeta[];
            const _articleCategory = articleCategory.find((article, _) => article.id === 60);
            if(_articleCategory) {
                articleCategoryId = _articleCategory.id;
            }
        }

        // const data = {
        //     brand_id: brand.id ? brand.id : null,
        //     articleCategory: articleCategoryId,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        if(!data) {
            return;
        }

        props.showLoading(true);

        if(data) {
            data.detail = true;
            data.is_active = true;
        }

        setViewList(false);

        const list = await util.getArticleSearch(data);
        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }

        const CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
        const CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;        
    
        let sdate = "";
        let edate = "";
        const arrSD = CL_sdate.value.split("-");
        const arrED = CL_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = CL_sdate.value;
            edate = CL_edate.value 
        }  

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const CL_contentsType = document.getElementById("CL_contentsType") as HTMLSelectElement;
        const CL_public = document.getElementById("CL_public") as HTMLSelectElement;
        const CL_review = document.getElementById("CL_review") as HTMLSelectElement;

        let articleTypeId = undefined;
        if(CL_contentsType.value !== "") {
            if(curriInfo && curriInfo.articleType) {
                const articleType = curriInfo.articleType as IMeta[];
                const _articleType = articleType.find((article, _) => article.val === CL_contentsType.value);
                if(_articleType) {
                    articleTypeId = _articleType.id;
                }
            }    
        }

        let articleCategoryId = undefined;
        if(curriInfo && curriInfo.articleCategory) {
            const articleCategory = curriInfo.articleCategory as IMeta[];
            const _articleCategory = articleCategory.find((article, _) => article.id === Number(currArticleCategory));
            if(_articleCategory) {
                articleCategoryId = _articleCategory.id;
            }
        }

        const CL_keyword = document.getElementById("CL_keyword") as HTMLInputElement;        
        const CL_keyword_type = document.getElementById("CL_keyword_type") as HTMLInputElement;

        let search = "";
        if(CL_keyword) {
            search = CL_keyword.value;
        }
        let searchField = "";
        if(CL_keyword_type) {
            searchField = CL_keyword_type.value;
        }

        const CL_curriBook = document.getElementById("CL_curriBook") as HTMLSelectElement;
        const CL_curriBook_val = curriInfo.curriBook[CL_curriBook.selectedIndex-1];

        const CL_html_curriUnit1_val = curriUnit1 ? (curriUnit1[CL_html_curriUnit1.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit2_val = curriUnit2 ? (curriUnit2[CL_html_curriUnit2.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit3_val = curriUnit3 ? (curriUnit3[CL_html_curriUnit3.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit4_val = curriUnit4 ? (curriUnit4[CL_html_curriUnit4.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit5_val = curriUnit5 ? (curriUnit5[CL_html_curriUnit5.selectedIndex-1] as IMeta) : undefined;

        const CL_studyMap = document.getElementById("CL_studyMap") as HTMLSelectElement;
        const CL_studyMap_val = studyMap0List[CL_studyMap.selectedIndex-1];

        const CL_studyMap1_val = studyMap1List.length > 0 ? (studyMap1List[CL_studyMap1.selectedIndex-1] as IMeta) : undefined;
        const CL_studyMap2_val = studyMap2List.length > 0 ? (studyMap2List[CL_studyMap2.selectedIndex-1] as IMeta) : undefined;
        const CL_studyMap3_val = studyMap3List.length > 0 ? (studyMap3List[CL_studyMap3.selectedIndex-1] as IMeta) : undefined;
        const CL_studyMap4_val = studyMap4List.length > 0 ? (studyMap4List[CL_studyMap4.selectedIndex-1] as IMeta) : undefined;
        const CL_studyMap5_val = studyMap5List.length > 0 ? (studyMap5List[CL_studyMap5.selectedIndex-1] as IMeta) : undefined;

        const metaMap: IMeta[] = [];

        const selectTypeSM = document.getElementById("rd-select-sm") as HTMLInputElement;
        const selectTypeCB = document.getElementById("rd-select-cb") as HTMLInputElement;

        if(selectTypeSM.checked) {

            // if(CL_studyMap_val) {
            //     metaMap.push(CL_studyMap_val);
            // }
            // if(CL_studyMap1_val) {
            //     metaMap.push(CL_studyMap1_val);
            // }
            // if(CL_studyMap2_val) {
            //     metaMap.push(CL_studyMap2_val);
            // }
            // if(CL_studyMap3_val) {
            //     metaMap.push(CL_studyMap3_val);
            // }
            // if(CL_studyMap4_val) {
            //     metaMap.push(CL_studyMap4_val);
            // }
            // if(CL_studyMap5_val) {
            //     metaMap.push(CL_studyMap5_val);
            // }    

            if(CL_studyMap_val) {
                if(CL_studyMap1_val) {
                    if(CL_studyMap2_val) {
                        if(CL_studyMap3_val) {
                            if(CL_studyMap4_val) {
                                if(CL_studyMap5_val) {
                                    metaMap.push(CL_studyMap5_val);
                                } else {
                                    metaMap.push(CL_studyMap4_val);
                                }
                            } else {
                                metaMap.push(CL_studyMap3_val);
                            }
                        } else {
                            metaMap.push(CL_studyMap2_val);
                        }
                    } else {
                        metaMap.push(CL_studyMap1_val);
                    }
                } else {
                    metaMap.push(CL_studyMap_val);
                }
            }

        } else if(selectTypeCB.checked) {

            // if(CL_curriBook_val) {
            //     metaMap.push(CL_curriBook_val);
            // }
            // if(CL_html_curriUnit1_val) {
            //     metaMap.push(CL_html_curriUnit1_val);
            // }
            // if(CL_html_curriUnit2_val) {
            //     metaMap.push(CL_html_curriUnit2_val);
            // }
            // if(CL_html_curriUnit3_val) {
            //     metaMap.push(CL_html_curriUnit3_val);
            // }
            // if(CL_html_curriUnit4_val) {
            //     metaMap.push(CL_html_curriUnit4_val);
            // }
            // if(CL_html_curriUnit5_val) {
            //     metaMap.push(CL_html_curriUnit5_val);
            // }

            if(CL_curriBook_val) {
                if(CL_html_curriUnit1_val) {
                    if(CL_html_curriUnit2_val) {
                        if(CL_html_curriUnit3_val) {
                            if(CL_html_curriUnit4_val) {
                                if(CL_html_curriUnit5_val) {
                                    metaMap.push(CL_html_curriUnit5_val);
                                } else {
                                    metaMap.push(CL_html_curriUnit4_val);
                                }
                            } else {
                                metaMap.push(CL_html_curriUnit3_val);
                            }
                        } else {
                            metaMap.push(CL_html_curriUnit2_val);
                        }
                    } else {
                        metaMap.push(CL_html_curriUnit1_val);
                    }
                } else {
                    metaMap.push(CL_curriBook_val);
                }
            }            
        }

        const CL_difficulty = document.getElementById("CL_difficulty") as HTMLSelectElement;
        if(CL_difficulty.selectedIndex > 0) {
            const CL_difficulty_val = curriInfo.difficulty[CL_difficulty.selectedIndex-1] as IMeta;
            metaMap.push(CL_difficulty_val);    
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            articleCategory: articleCategoryId,
            articleType: articleTypeId,
            // name: CL_keyword && CL_keyword.value !== "" ? CL_name.value : undefined,
            // keyword: CL_keyword && CL_keyword.value !== "" ? CL_keyword.value : undefined,
            reg_sdate: sdate,  
            reg_edate: edate,
            is_publicOpen: CL_public.value === "" ? undefined : CL_public.value === "1" ? true : false,
            review: CL_review.value === "" ? undefined : Number(CL_review.value),

            search: search,
            searchField: searchField,
            
            // difficulty: CL_difficulty.value === "" ? undefined : CL_difficulty.value,

            // curriBook: CL_curriBook_val ? CL_curriBook_val.id : undefined,
            // curriUnit1: CL_html_curriUnit1_val ? CL_html_curriUnit1_val.id : undefined,
            // curriUnit2: CL_html_curriUnit2_val ? CL_html_curriUnit2_val.id : undefined,
            // curriUnit3: CL_html_curriUnit3_val ? CL_html_curriUnit3_val.id : undefined,
            // curriUnit4: CL_html_curriUnit4_val ? CL_html_curriUnit4_val.id : undefined,
            // curriUnit5: CL_html_curriUnit5_val ? CL_html_curriUnit5_val.id : undefined,

            metaMap: metaMap,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10,
        };

        // const selectTypeCB = document.getElementById("rd-select-cb") as HTMLInputElement;
        // if(!selectTypeCB.checked) {
        //     data.curriBook = undefined;
        //     data.curriUnit1 = undefined;
        //     data.curriUnit2 = undefined;
        //     data.curriUnit3 = undefined;
        //     data.curriUnit4 = undefined;
        //     data.curriUnit5 = undefined;
        // }

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Contents_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);
        }

        props.showLoading(false);
    }

    async function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>) {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        await getDescendantsList(e.target.value);

        CL_html_curriUnit1.selectedIndex = 0;
        CL_html_curriUnit2.selectedIndex = 0;
        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit2([]);
        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);

        // const curriBook = curriInfo?.curriBook.find((meta) => meta.code === e.target.value);
        // if(curriBook) {
        //     await getStudyMapList(curriBook.id!);
        // }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit1", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);

        CL_html_curriUnit2.selectedIndex = 0;
        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        CL_html_curriUnit5.selectedIndex = 0;
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }
    
    async function getStudyMapList(cb_id: number | null = null) {

        props.showLoading(true);

        const metaMap: {"name": string, "id": number}[] = [];

        const data = {
            "brand_id": util.getCurrentBrand()?.id,
            "name": "studyMap",
            "depth": "1",
            "metaMap": metaMap,
            "is_active": true,
        }

        if(cb_id !== null) {
            metaMap.push({"name":"curriBook", "id":Number(cb_id)});
            data.metaMap = metaMap;
        }
        
        props.showLoading(true);

        const list = await util.searchMeta(data);

        // const list = await util.getMetaList("1", "studyMap");
        // const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	

        if(list) {
            setStudyMap0List(list);
        }
        props.showLoading(false);

        logger.log("getStudyMapList", data, list);

    }

    async function getStudyMapDescendantsList(code: string) {

        props.showLoading(true);

        const list = await util.getDescendantsList(code);

        const _studyMap = list.find((item) => item.code === code);
        if(!_studyMap) {
            return;
        }

        const _list = list.filter((item) => item.is_active === true);

        setStudyMapsList(_list);

        const _filtered_1 = _list.filter((item) => item.name === "studyMap1");
        setStudyMap1List(_filtered_1);

        logger.log("getStudyMapDescendantsList", list, _list);

        props.showLoading(false);
    }

    async function onChangeStudyMap(e: ChangeEvent<HTMLSelectElement>) {
        if(e.target.selectedIndex > 0) {
            const _studyMap = studyMap0List[e.target.selectedIndex-1];
            logger.log("onChangeStudyMap", _studyMap);
    
            getStudyMapDescendantsList(_studyMap.code);    
        } else {
            setStudyMap1List([]);
        }

        CL_studyMap1.selectedIndex = 0;

        setStudyMap2List([]);
        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
    }

    async function onChangeStudyMap1(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_2 = (studyMapsList as IMeta[]).filter((item) => item.depth === 3);
        logger.log("onChangeStudyMap1", _filtered_2);

        const result = _filtered_2.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap2List(result);
        logger.log("onChangeStudyMap1", e.target.value, result);

        CL_studyMap2.selectedIndex = 0;

        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
    }

    async function onChangeStudyMap2(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_3 = (studyMapsList as IMeta[]).filter((item) => item.depth === 4);
        logger.log("onChangeStudyMap2", _filtered_3);

        const result = _filtered_3.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap3List(result);
        logger.log("onChangeStudyMap2", e.target.value, result);

        CL_studyMap3.selectedIndex = 0;

        setStudyMap4List([]);
        setStudyMap5List([]);
    }
    async function onChangeStudyMap3(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_4 = (studyMapsList as IMeta[]).filter((item) => item.depth === 5);
        logger.log("onChangeStudyMap3", _filtered_4);

        const result = _filtered_4.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap4List(result);
        logger.log("onChangeStudyMap3", e.target.value, result);

        CL_studyMap4.selectedIndex = 0;

        setStudyMap5List([]);
    }
    async function onChangeStudyMap4(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_5 = (studyMapsList as IMeta[]).filter((item) => item.depth === 6);
        logger.log("onChangeStudyMap4", _filtered_5);

        const result = _filtered_5.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap5List(result);
        logger.log("onChangeStudyMap4", e.target.value, result);

        CL_studyMap5.selectedIndex = 0;
    }
    
    function onClickSDateIcon() : void {
        const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
        if(new_CL_sdate) {
            // new_CL_sdate.value = "";
            const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
            CL_sdate.value = "";
            CL_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
                    CL_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
        if(new_CL_edate) {
            // new_CL_edate.value = "";
            const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
            CL_edate.value = "";
            CL_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
                    CL_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "CL_sdate") {
            const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
            if(new_CL_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CL_sdate.value = val;
            }
        } else if(e.target.id === "CL_edate") {
            const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
            if(new_CL_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CL_edate.value = val;
            }
        }
    }

    function onChangeViewType() {
        const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
        if(viewlist1.checked) {
            setViewType("s");    
        } else {
            setViewType("b");
        }        
    }

    function onChangeCurriContents(newArticleCategory: string) {

        setCurrArticleCategory(newArticleCategory);

        let articleCategoryId = undefined;
        if(curriInfo && curriInfo.articleCategory) {
            const articleCategory = curriInfo.articleCategory as IMeta[];
            const _articleCategory = articleCategory.find((article, _) => article.id === Number(newArticleCategory));
            if(_articleCategory) {
                articleCategoryId = _articleCategory.id;
            }
        }

        const _searchData = searchData;
        if(_searchData) {
            _searchData.articleCategory = articleCategoryId;
        }

        logger.log("onChangeCurriContents", newArticleCategory, articleCategoryId, _searchData);

        setSearchData(_searchData);
        _onSearch(_searchData);
    }

    async function onChangePublicOpen(item: IArticle) {
        const CL_is_publicOpen = document.getElementById("CL_is_publicOpen") as HTMLInputElement;
        if(CL_is_publicOpen) {
            logger.log("CL_is_publicOpen.checked", item, CL_is_publicOpen.checked);

            const data = {
                saveType: "update",
                article: {
                    id: item.id,
                    is_publicOpen: !item.is_publicOpen,
                    loginUserId : util.getUserInfo("uid"),
                },
            };

            logger.log(data);

            await util.updateArticle(data);
            _onSearch(searchData);
        }
    }

    function onReview(item: IArticle) {
        if(item && item.review === 0) {
            setModalShow(true);
            setSelectedItem(item);
        }
    }

    async function onReviewOk() {
        if(selectedItem && selectedItem.review === 0) {    
            const data = {
                saveType: "update",
                article: {
                    id: selectedItem.id,
                    review: 1,
                    loginUserId : util.getUserInfo("uid"),
                },
            };

            logger.log(data);

            await util.updateArticle(data);
            _onSearch(searchData);
        }

        onReviewClose();
    }

    function onReviewClose() {
        setModalShow(false);
        setSelectedItem(null);
    }

    async function onPreView(item: IArticle) {
        // alert("미리보기 작업중...");
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        // const sets = await util.getSetsByArticleId(item.id);
        // logger.log("onPreView", sets);
        // if(sets && sets.length > 0) {
        //     const url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
        //     +"&article_id="+item.id+"&token="+util.getUserInfo("token");
        //     window.open(url, "_blank", "noopener, noreferrer");    
        // }

        if(item) {
            let url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&article_id="+item.id+"&token="+util.getUserInfo("token");

            if(searchData.searchField === "creator" && searchData.search === util.getUserInfo("uid")) {
                let ids = "";
                const _list = list as IArticle[];
                for (let i = 0; i < _list.length; i++) {
                    const a = _list[i];
                    if(ids === "") {
                        ids = String(a.id);
                    } else {
                        ids = ids + "," + String(a.id);
                    }
                }    
                url = url + "&article_ids=" + ids;
            }

            window.open(url, "_blank", "noopener, noreferrer");        
        }

    }

    async function onEditPage(item: IArticle | null) {
        // alert("편집 작업중...");
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        let url = "";
        if(item) {
            // const sets = await util.getSetsByArticleId(item.id);
            // if(sets && sets.length > 0) {
            //     url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            //     +"&article_id="+item.id+"&token="+util.getUserInfo("token");
            // }

            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&article_id="+item.id+"&token="+util.getUserInfo("token");

            if(searchData.searchField === "creator" && searchData.search === util.getUserInfo("uid")) {
                let ids = "";
                const _list = list as IArticle[];
                for (let i = 0; i < _list.length; i++) {
                    const a = _list[i];
                    if(ids === "") {
                        ids = String(a.id);
                    } else {
                        ids = ids + "," + String(a.id);
                    }
                }    
                url = url + "&article_ids=" + ids;
            }
    
        } else {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&create=y"+"&token="+util.getUserInfo("token");
        }
        window.open(url, "_blank", "noopener, noreferrer");
    }

    async function onDelete() {
        setViewConfirmDelete(true);
        setDeleteTitle("삭제");
        setDeleteDesc("삭제시 복구가 불가능합니다. 진행 하시겠습니까? (본인이 저작한 문항만 삭제됩니다.)");
    }

    async function onDeleteOK() {

        onCloseDelete();

        logger.log("onDeleteOK");

        const articles : {"id": number}[] = [];
        const list_ck = document.getElementsByName("list_ck");
        const _articles = list as IArticle[];

        let checkedCnt = 0;
        const me = util.getUserInfo("uid");
        for (let i = 0; i < list_ck.length; i++) {
            const element = list_ck[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                if(me === _articles[i].creator) {
                    const _data = {"id": _articles[i].id};
                    articles.push(_data);
                }
            }
        }

        const data = {"articles": articles, "is_active": false};
        logger.log(data);

        if(checkedCnt > 0 && articles.length === 0) {
            props.showModal("", "타 저작자가 만든 콘텐츠는 삭제할 수 없습니다.");
            return;
        } else if(checkedCnt === 0) {
            return;
        }

        props.showLoading(true);

        const result = await util.changeContentsActiveStatus(data);
        logger.log(data, result);

        props.showLoading(false);
        
        _onSearch(searchData);

        logger.log(checkedCnt, articles.length);
        if(checkedCnt === articles.length) {
            props.showModal("", "콘텐츠가 삭제되었습니다.");
        } else {
            props.showModal("", "본인이 저작한 콘텐츠가 삭제되었습니다. 타 저작자가 만든 콘텐츠는 삭제할 수 없습니다.");
        }
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }
    
    async function onBulkUpdateHintAll() {
        for (let i = 0; i < 200; i++) {
            logger.log("onBulkUpdateHintAll", i);
            const result = await onBulkUpdateHint(true);            
            if(!result) {
                break;
            }
        }

        props.showModal("", "모든 콘텐츠 힌트 생성완료!!!");

        props.showLoading(false);
    }

    async function onBulkUpdateHint(isForAll: boolean = false) {

        logger.log("onBulkUpdateHint");

        props.showLoading(true);

        const articles : {"id": number}[] = [];

        if(isForAll) {

            const _searchData = JSON.parse(JSON.stringify(searchData));
            // _searchData.articleCategory = undefined;
            // _searchData.detail = undefined;
            _searchData.limit_pageItemCnt = 300;
            _searchData.orderby_dir = "asc";
            _searchData.hintYN = "N";

            // props.showLoading(true);
            const _list = await util.getArticleSearch(_searchData);
            // props.showLoading(false);

            logger.log("isForAll", _list);

            for (let i = 0; i < _list.length; i++) {
                const _data = {"id": _list[i].id};
                if(_list[i].hintYN === "N") {
                    articles.push(_data);
                }
            }    

        } else {
            const list_ck = document.getElementsByName("list_ck");
            const _articles = list as IArticle[];
            for (let i = 0; i < list_ck.length; i++) {
                const element = list_ck[i] as HTMLInputElement;
                if(element.checked) {
                    const _data = {"id": _articles[i].id};
                    articles.push(_data);
                }
            }    
        }

        if(articles.length === 0) {
            props.showModal("", "선택된 콘텐츠가 없습니다.");
            return false;
        }

        if(isForAll) {
            // props.showModal("", "모든 콘텐츠 힌트 생성중(" + articles.length + ")");
            // return false;
        }

        const totalCnt = articles.length;
        let successCnt = 0;

        const data = {"articles": articles};
        const result = await util.articlePartGroup(data);
        logger.log(data, result);

        if(result) {

            const _result = result as any[];
            if(_result.length === 0) {
                return false;
            }

            const articlePartGroup = result as IArticlePartGroup[];
            for (let i = 0; i < articlePartGroup.length; i++) {

                logger.log("current", i+1 + "/" + articlePartGroup.length, "totalCnt", totalCnt);

                const element = articlePartGroup[i];

                let input_area = element.part.input_area ? element.part.input_area + '\n' : '';
                if(input_area.length > 1024 * 100) {
                    input_area = "";
                }

                const problem = (element.part.directive ? element.part.directive + '\n' : '') +
                (element.part.passage ? element.part.passage + '\n' : '') +
                (element.part.example ? element.part.example + '\n' : '') +
                (input_area);

                // logger.log("element.part.input_area", element.part.input_area);
                
                const solution = element.part.explanation ? element.part.explanation.replaceAll('&nbsp;', ' ') : "";

                // logger.log(element, "problem", problem, "solution", solution);

                if(problem === "" && solution === "") {
                    logger.log(element, "problem", problem, "solution", solution);
                    continue;
                }

                const data = {
                    style: 'formal',
                    problem: problem,
                    solution: solution,
                    text_format: "html",
                };

                const defHeaders_quanda = {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, PUT',
                    'Access-Control-Max-Age': '1800',	
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Accept': 'application/json',
                    // 'Origin': document.location.origin,
                    'Content-Type': 'application/json',
                    'X-Qanda-AIDT-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lciI6InZpc2FuZyJ9.Ke3BEy4JlPS87yth1X8nvTyFO0QZLirEEHNSPwQ5gvY',
                };
                
                const HINT_API = {
                    prod: 'https://aidt.qanda.ai',
                    // dvlp: 'https://aidt.qanda.ai',
                    dvlp: 'https://aidt.qanda-ai.dev'
                };
                       
                // let hint : {"article_id": string, "hint": string}[] = [];

                await axios.post(HINT_API.prod+'/metadata/v1/generate-problem-hint', data, { headers: defHeaders_quanda
                }).then(async (response) => {
                    // logger.log(response);
                    if(response.data && response.data.hint && response.data.hint.length > 10) {
                        successCnt++;

                        const hint = {"article_id": String(element.id), "hint": response.data.hint, "commentary": response.data.concept_explanation, "explanation": response.data.concept_explanation};
                        const result = await util.articleExForSave(hint);
                        // logger.log("articleExForSave", hint, result);                        
                    }
                }).catch((err) => {
                    logger.log(err);
                });          
            }            
        }

        if(!isForAll) {
            if(totalCnt === successCnt) {
                props.showModal("", "힌트 생성이 완료되었습니다.");
            } else if(successCnt === 0) {
                props.showModal("", "힌트 생성에 실패하였습니다.");
            } else {
                props.showModal("", totalCnt-successCnt + "개의 문항에 힌트가 생성되지 않았습니다. 다시 확인해 주세요.");
            }    
        
            _onSearch(searchData);
        }

        props.showLoading(false);

        return true;
    }
    
    async function onViewContentsUpdateHistory(item: IArticle) {
        setSelectedItem(item);
        setViewContentsUpdateHistory(true);
    } 

    return (
        <div>

            <div style={{display: viewAnalogousArticles ? "none" : ""}}>            

                <div className="top-title">
                    <h3>{common.leftmenu_2[1][currMenu.menu2]}</h3>
                </div>

                <div className="mb-4 border-b border-gray-200">
                    <ul className="flex flex-wrap -mb-px font-medium text-center" id="default-tab" role="tablist">
                        <li className="w-1/2" role="presentation">
                            <button className={currArticleCategory === "60" ? "inline-block p-4 border-b-2 rounded-t-lg w-full text-sky-500 border-sky-500" : "inline-block p-4 border-b-2 rounded-t-lg w-full"} 
                            id="subject-tab" type="button" role="tab" aria-controls="subject" aria-selected={currArticleCategory === "60"} onClick={() => onChangeCurriContents("60")}>교과서 콘텐츠</button>
                        </li>
                        <li className="w-1/2" role="presentation">
                            <button className={currArticleCategory === "61" ? "inline-block p-4 border-b-2 rounded-t-lg w-full text-sky-500 border-sky-500" : "inline-block p-4 border-b-2 rounded-t-lg w-full"} 
                            id="nosubject-tab" type="button" role="tab" aria-controls="nosubject" aria-selected={currArticleCategory === "60"} onClick={() => onChangeCurriContents("61")}>비교과서 콘텐츠</button>
                        </li>
                    </ul>
                </div>
                    
                <div className=""  id="subject" role="tabpanel" aria-labelledby="subject-tab"> 
                    
                    {/* <!-- 검색영역 --> */}
                    <div className="px-8 py-8 border border-gray-300 rounded-xl">
                        <div>
                            <fieldset>
                                <input type="radio" id="rd-select-cb" name="rd-select" className="items-center mr-2" />
                                <label htmlFor="curriBook">교과</label>
                                <select id="CL_curriBook" name="CL_curriBook" onChange={(e) => onChangeCurriBook(e)}>
                                    <option value="0">교과</option>              
                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.code}>{item.val}</option>              
                                    )})}
                                </select>
                            </fieldset>
                            <fieldset>
                                <label htmlFor="curriUnit1" className="ml-2">교과과정</label>
                                <select className="mr-2" id="CL_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriDepth > 1 ? "" : "none"}}>
                                    <option value="0">2Depth</option>              
                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriDepth > 2 ? "" : "none"}}>
                                    <option value="0">3Depth</option>              
                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriDepth > 3 ? "" : "none"}}>
                                    <option value="0">4Depth</option>              
                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select id="CL_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriDepth > 4 ? "" : "none"}}>
                                    <option value="0">5Depth</option>              
                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                            </fieldset>

                            {/* <fieldset>
                                <input type="radio" id="rd-select-sm" name="rd-select" className="items-center mr-2" />
                                <label htmlFor="curriBook">학습맵</label>
                                <select id="CL_studyMap" name="CL_studyMap" onChange={(e) => onChangeStudyMap(e)}>
                                    <option value="0">학습맵</option>              
                                    {studyMap0List && studyMap0List.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.code}>{item.val}</option>              
                                    )})}
                                </select>
                            </fieldset>
                            <fieldset>
                                <select className="mx-2" id="CL_studyMap1" name="studyMap1" onChange={(e) => onChangeStudyMap1(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {studyMap1List && (studyMap1List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_studyMap2" name="studyMap2" onChange={(e) => onChangeStudyMap2(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">2Depth</option>              
                                    {studyMap2List && (studyMap2List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_studyMap3" name="studyMap3" onChange={(e) => onChangeStudyMap3(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">3Depth</option>              
                                    {studyMap3List && (studyMap3List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_studyMap4" name="studyMap4" onChange={(e) => onChangeStudyMap4(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">4Depth</option>              
                                    {studyMap4List && (studyMap4List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select id="CL_studyMap5" name="studyMap5" style={{display:studyMap5List.length > 0 ? "" : "none"}}>
                                    <option value="0">5Depth</option>              
                                    {studyMap5List && (studyMap5List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                            </fieldset> */}

                        </div>

                        <div className="mt-3">
                            <fieldset>
                                <input type="radio" id="rd-select-sm" name="rd-select" className="items-center mr-2" />
                                <label htmlFor="curriBook">학습맵</label>
                                <select id="CL_studyMap" name="CL_studyMap" onChange={(e) => onChangeStudyMap(e)}>
                                    <option value="0">학습맵</option>              
                                    {studyMap0List && studyMap0List.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.code}>{item.val}</option>              
                                    )})}
                                </select>
                            </fieldset>
                            <fieldset>
                                <select className="mx-2" id="CL_studyMap1" name="studyMap1" onChange={(e) => onChangeStudyMap1(e)} style={{display:studyMap1List.length > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {studyMap1List && (studyMap1List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_studyMap2" name="studyMap2" onChange={(e) => onChangeStudyMap2(e)} style={{display:studyMap1List.length > 0 ? "" : "none"}}>
                                    <option value="0">2Depth</option>              
                                    {studyMap2List && (studyMap2List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_studyMap3" name="studyMap3" onChange={(e) => onChangeStudyMap3(e)} style={{display:studyMap1List.length > 0 ? "" : "none"}}>
                                    <option value="0">3Depth</option>              
                                    {studyMap3List && (studyMap3List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="CL_studyMap4" name="studyMap4" onChange={(e) => onChangeStudyMap4(e)} style={{display:studyMap1List.length > 0 ? "" : "none"}}>
                                    <option value="0">4Depth</option>              
                                    {studyMap4List && (studyMap4List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select id="CL_studyMap5" name="studyMap5" style={{display:studyMap5List.length > 0 ? "" : "none"}}>
                                    <option value="0">5Depth</option>              
                                    {studyMap5List && (studyMap5List as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                            </fieldset>
                        </div>

                        <div className="mt-3">
                            <fieldset>
                                <label htmlFor="search-3">콘텐츠</label>
                                <select id="CL_contentsType" name="search-3">
                                    <option value={""}>전체</option>
                                    <option value={"개념"}>개념</option>
                                    <option value={"활동"}>활동</option>
                                    <option value={"문항"}>문항</option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <label htmlFor="search-4" className="ml-8">AIDT 공개 여부</label>
                                <select id="CL_public" name="search-4">
                                    <option value={""}>전체</option>
                                    <option value={"1"}>공개</option>
                                    <option value={"0"}>비공개</option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <label htmlFor="search-5" className="ml-8">검인정 심사</label>
                                <select id="CL_review" name="search-5">
                                    <option value={""}>전체</option>
                                    <option value={"0"}>통과</option>
                                    <option value={"1"}>완료</option>
                                </select>
                            </fieldset>

                            <fieldset>
                                <label htmlFor="date" className="ml-8">등록기간</label>
                            </fieldset>

                            <fieldset>
                                <DatePicker id='CL_sdate' name='CL_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                            </fieldset>

                            <fieldset>
                                <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickSDateIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>                                            
                                </button> 
                            </fieldset>

                            <fieldset>
                                <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                            </fieldset>

                            <fieldset>
                                <DatePicker id='CL_edate' name='CL_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                            </fieldset>

                            <fieldset>
                                <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>                                            
                                </button> 
                            </fieldset>

                            <fieldset>
                                <label htmlFor="search-5" className="ml-8">난이도</label>
                                <select id="CL_difficulty" name="search-5">
                                    <option value={""}>전체</option>
                                    {curriInfo && (curriInfo.difficulty as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                            </fieldset>

                        </div>
                        <div className="mt-3 text-right">
                            <fieldset>
                                <label htmlFor="search-6">검색</label>
                                <select id="CL_keyword_type" name="search-6">
                                    <option value={"name"}>콘텐츠명</option>
                                    <option value={"id"}>KEY</option>
                                    <option value={"hashTags"}>해시 태그</option>
                                    <option value={"creator"}>등록자</option>
                                </select>
                                <input type="text" name="search" id="CL_keyword" className="w-[300px] ml-3" onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearch();
                                    }
                                }} />
                                <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                    <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                </button>
                            </fieldset>
                        </div>                        
                    </div>
                    {/* <!-- .//검색영역 --> */}

                    <div className="mt-6 mb-3">
                        <form action="" className="flex justify-between">
                            <fieldset>
                                <span>총 <strong className="text-red">{listCount}</strong>건</span>
                                <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                                <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={100}>100</option>
                                </select>
                            </fieldset>
                            <fieldset aria-labelledby="radiogruop" className="mt-2 items-center">

                                <button type="button" className="btn-white-s mr-2" onClick={() => {
                                            
                                    const brand = util.getCurrentBrand();
                                    if(brand) {                                    
                                        const url = "https://t-cmsapi.vsaidt.com"+"/ocr/createForm?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                        +"&token="+util.getUserInfo("token");
                                        window.open(url, "_blank", "noopener, noreferrer");        
                                    }

                                }}>PDF변환</button>

                                {/* <button type="button" className="btn-white-s mr-2" onClick={() => {
                                    setViewAnalogousArticles(true);
                                }}>유사문항 관리</button> */}

                                {/* {isDev && util.getUserInfo("uid") === "chaesy" &&
                                <button type="button" className="btn-sky mr-2" onClick={() => onBulkUpdateHintAll()}>모든 콘텐츠 힌트 생성</button>
                                } */}
                                {/* <button type="button" className="btn-sky mr-2" onClick={() => onBulkUpdateHint()}>힌트 생성</button> */}
                                {/* <button type="button" className="btn-sky mr-2" onClick={}>해설 생성</button> */}

                                {/* <button type="button" className="btn-white mr-5" onClick={onDelete}>삭제</button> */}

                                <input type="radio" id="rd-viewlist1" name="rd-viewlist" className="items-center" onChange={onChangeViewType} />
                                <label htmlFor="rd-viewlist1" className="items-center ml-1">간단히</label>
                                <input type="radio" id="rd-viewlist2" name="rd-viewlist" className="items-center" onChange={onChangeViewType}/>
                                <label htmlFor="rd-viewlist2" className="items-center ml-1">큰 아이콘</label>
                            </fieldset>
                        </form>
                        
                    </div>

                    {/* <!-- table --> */}
                    {viewList && viewType === "s" &&
                    <table>
                        <caption className="sr-only">교과 콘텐츠 테이블</caption>
                        <thead>
                            <tr>
                                <th style={{display:""}} scope="col" className="pl-4 pr-3">
                                    <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                    const list_ck = document.getElementsByName("list_ck");
                                    for (let i = 0; i < list_ck.length; i++) {
                                        const element = list_ck[i] as HTMLInputElement;
                                        if(!element.disabled) {
                                            element.checked = (e.target as HTMLInputElement).checked;
                                        }                                    
                                    }
                                }} /></th>
                                <th scope="col" className="pl-4 pr-3">번호</th>
                                <th scope="col" className="pl-4 pr-3">Key</th>
                                <th scope="col" className="px-3">교과</th>
                                {/* <th scope="col" className="px-3">난이도</th> */}
                                <th scope="col" className="px-3">콘텐츠</th>
                                <th scope="col" className="px-3">콘텐츠 명</th>
                                <th scope="col" className="px-3">난이도</th>
                                <th scope="col" className="px-3">연관 라이브러리 KEY</th>
                                <th scope="col" className="px-3">해시태그</th>
                                <th scope="col" className="px-3">등록일</th>
                                <th scope="col" className="px-3">수정기록</th>
                                <th scope="col" className="px-3">힌트</th>
                                {/* <th scope="col" className="px-3">해설</th> */}
                                <th scope="col" className="px-3">등록자</th>
                                <th scope="col" className="px-3">미리보기</th>
                                <th scope="col" className="px-3">편집</th>
                                <th scope="col" className="px-3">AIDT 공개 설정</th>
                                <th scope="col" className="px-3">배포이력</th>
                                {/* <th scope="col" className="pl-3 pr-4">심사</th> */}
                            </tr>
                        </thead>
                        <tbody>

                        {viewList && list &&
                            <tr style={{display: (list as IArticle[]).length === 0 ? "" : "none"}}>
                                <td colSpan={16}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                            </tr>}

                        {viewList && list && (list as IArticle[]).map((item, idx) => {

                            let _curriBookVal = ""

                            if(curriInfo && curriInfo.curriBook) {
                                const curriBook = curriInfo.curriBook as IMeta[];
                                const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                _curriBookVal = _curriBook ? _curriBook.val : "";
                            }

                            const totalCnt = item.full_count;
                            const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;
                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                            let _articleTypeVal = "";
                            if(curriInfo && curriInfo.articleType) {
                                const articleType = curriInfo.articleType as IMeta[];
                                const _articleType = articleType.find((article, _) => article.id === item.articleType);
                                _articleTypeVal = _articleType ? _articleType.val : "-";
                            }

                            let updateDate = "-";
                            if(item.updatedate) {
                                updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                            }
                            
                            let hashTag = "";
                            const arrHashTag = item.hashTag as string[];
                            if(arrHashTag) {
                                arrHashTag.map((item) => {
                                    if(item.trim() !== "") {
                                        if(hashTag !== "") {
                                            hashTag = hashTag + "<br/>";
                                        }
                                        hashTag = hashTag + (item[0] !== "#" ? "#" : "") + item.trim() + " ";
                                    }
                                });    
                            }

                            let libraryIds = "";
                            const libraryMap = item.libraryMap;
                            if(libraryMap) {
                                libraryMap.map((item) => {
                                    if(libraryIds === "") {
                                        libraryIds = String(item.id);
                                    } else {
                                        libraryIds = libraryIds + "," + item.id;
                                    }
                                });
                            }

                            let name = item.name && item.name.length > 15 ? item.name.substring(0, item.name.length/2) + "<br/>" + item.name.substring(item.name.length/2) : item.name;
                            if(item.sub_id > 0) {
                                name = "ㄴ " + (item.setSummary_name ? item.setSummary_name : name);
                            }
                            
                            let deployStatus = "-";
                            if(item.deployStatus === 1) {
                                deployStatus = "배포대기";
                            } else if(item.deployStatus === 3) {
                                deployStatus = "배포보류";
                            } else if(item.deployStatus === 10) {
                                deployStatus = "배포중";
                            } else if(item.deployStatus === 20) {
                                deployStatus = "배포실패";                            
                            }

                            const hasAuth = util.getHasAuthMenu(item.creator ? item.creator : "");
                
                            return (

                                <tr key={idx}>
                                    <td style={{display:""}}>
                                        <input id={"list_ck_"+idx} name="list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" onClick={(e) => {
                                            const list_ck = document.getElementsByName("list_ck");
                                            let cntChecked = 0;
                                            for (let i = 0; i < list_ck.length; i++) {
                                                const element = list_ck[i] as HTMLInputElement;
                                                if(element.checked) {
                                                    cntChecked = cntChecked + 1;
                                                }
                                            }
                                            const all_check = document.getElementById("all_check") as HTMLInputElement;
                                            if(cntChecked === list_ck.length) {                                                
                                                all_check.checked = true;                                                
                                            } else {
                                                all_check.checked = false;                                                
                                            }
                                        }}/>
                                    </td>
                                    <td>{_num}</td>
                                    <td>{(item.sub_id === 0 || !item.sub_id) ? item.id : "-"}</td>
                                    <td>{_curriBookVal}</td>
                                    {/* <td>{item.difficulty}</td> */}
                                    <td>{_articleTypeVal}</td>
                                    <td style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: name }}></td>
                                    <td>{item.difficultyVal ? item.difficultyVal : ""}</td>
                                    <td>{libraryIds}</td>
                                    {/* <td dangerouslySetInnerHTML={}>{hashTag}</td> */}
                                    <td style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: hashTag }}></td>

                                    <td style={{fontSize: "0.8em"}}>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                    <td style={{fontSize: "0.8em"}}>
                                        {/* {updateDate} */}
                                        <button type="button" className="btn-white-s" onClick={() => onViewContentsUpdateHistory(item)}>확인</button>
                                    </td>
                                    <td>{item.hintYN}</td>
                                    {/* <td>{item.commentaryYN}</td> */}
                                    <td style={{fontSize: "0.8em"}}>{item.creator}</td>
                                    <td><button type="button" className="btn-white-s" onClick={() => onPreView(item)}>미리보기</button></td>
                                    <td>
                                        {/* <button type="button" className="btn-white-s" disabled={item.review === 1} onClick={() => onEditPage(item)}>편집</button> */}
                                        <MyButton type="button" className="btn-white-s" disabled={item.review === 1} creator={item.creator} onClick={() => onEditPage(item)} showModal={props.showModal} children={"편집"} />
                                    </td>
                                    <td>
                                        <label className="inp-toggle">
                                            <input type="checkbox" id="CL_is_publicOpen" value="" className="peer" defaultChecked={item.is_publicOpen} onChange={() => onChangePublicOpen(item)} disabled={!hasAuth}/>
                                            <div className={"peer peer-checked:after:translate-x-full peer-checked:after:border-white " + (hasAuth ? "peer-checked:bg-sky-500" : "peer-checked:bg-sky-50")} style={{cursor: hasAuth ? "pointer" : "auto"}}></div>
                                        </label>
                                    </td>
                                    {/* <td>
                                        <button type="button" className={item.review === 0 ? "btn-white-s" : "btn-white-s !text-sky-500 !bg-sky-50"} onClick={() => onReview(item)}>{item.review === 0 ? "통과" : "완료"}</button>
                                    </td> */}

                                    <td>{deployStatus}</td>

                                </tr>

                            )
                            })}

                        </tbody>
                    </table>}
                    {/* <!-- .//table -->	 */}

                    {viewList && viewType === "b" &&
                    <ul>

                        {viewList && list &&
                            <li style={{display: (list as IArticle[]).length === 0 ? "" : "none"}}>
                                <div role="label" aria-labelledby="edit-contents1" className="w-full flex">
                                    <p>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</p>
                                </div>
                            </li>}

                        {viewList && list && (list as IArticle[]).map((item, idx) => {

                            let _unitVal = "";
                            let _curriBookVal = ""

                            if(curriInfo && curriInfo.curriBook) {
                                const curriBook = curriInfo.curriBook as IMeta[];
                                const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                _curriBookVal = _curriBook ? _curriBook.val : "";
                            }

                            const totalCnt = item.full_count;
                            const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;
                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                            let _articleTypeVal = "";
                            if(curriInfo && curriInfo.articleType) {
                                const articleType = curriInfo.articleType as IMeta[];
                                const _articleType = articleType.find((article, _) => article.id === item.articleType);
                                _articleTypeVal = _articleType ? _articleType.val : "";
                            }

                            let updateDate = "-";
                            if(item.updatedate) {
                                updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                            }

                            let hashTag = "";
                            const arrHashTag = item.hashTag as string[];
                            if(arrHashTag) {
                                arrHashTag.map((item) => {
                                    if(item.trim() !== "") {
                                        hashTag = hashTag + (item[0] !== "#" ? "#" : "") + item + "<br/>";
                                    }
                                });    
                            }

                            let libraryIds = "";
                            const libraryMap = item.libraryMap;
                            if(libraryMap) {
                                libraryMap.map((item) => {
                                    if(libraryIds === "") {
                                        libraryIds = String(item.id);
                                    } else {
                                        libraryIds = libraryIds + "," + item.id;
                                    }
                                });
                            }

                            const thumb = item.thumbnail ? S3Server+item.thumbnail : "http://placehold.it/150x150.png/aaaaaa/ffffff";

                        return (

                            <li className="flex items-start" key={idx}>
                                <div role="label" aria-labelledby="edit-contents1" className="w-full flex">
                                    <span className="inline-block mx-3 text-lg">{_num}</span>
                                    <div className="flex relative p-4 border border-slate-300 flex-grow justify-between items-center">
                                        <div className="flex items-center">
                                            <div className="mr-3">
                                                <span className={_articleTypeVal === "개념" ? "block w-16 border border-slate-300 rounded-md mt-3 text-center bg-sky-500 text-white" : 
                                                "block w-16 border border-slate-300 rounded-md mt-3 text-center"}>개념</span> 
                                                <span className={_articleTypeVal === "활동" ? "block w-16 border border-slate-300 rounded-md mt-3 text-center bg-sky-500 text-white" : 
                                                "block w-16 border border-slate-300 rounded-md mt-3 text-center"}>활동</span> 
                                                <span className={_articleTypeVal === "문항" ? "block w-16 border border-slate-300 rounded-md mt-3 text-center bg-sky-500 text-white" : 
                                                "block w-16 border border-slate-300 rounded-md mt-3 text-center"}>문항</span> 
                                            </div>
                                            <div className="overflow-hidden">
                                                <img src={thumb} style={{width:"150px", cursor:"pointer"}} alt="썸네일" onClick={() => onPreView(item)} />
                                            </div>
                                            <div className="ml-4">
                                                <p><strong className="font-normal" style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: hashTag }}></strong></p>
                                            </div>
                                        </div>
                                        
                                        <ul className=" w-96 list-disc ">
                                            <li><strong className="font-normal" style={{fontSize: "0.8em"}}>{_curriBookVal}</strong></li>

                                            {/* <li><strong className="font-normal" dangerouslySetInnerHTML={{ __html: _unitVal }}></strong> </li> */}

                                            <li>
                                                <strong className="font-normal" style={{fontSize: "0.8em"}}>콘텐츠 명 :</strong>
                                                <span style={{fontSize: "0.8em"}}>{item.name}</span>
                                            </li>
                                            <li>
                                                <strong className="font-normal" style={{fontSize: "0.8em"}}>연관 라이브러리 KEY :</strong>
                                                <span style={{fontSize: "0.8em"}}>{libraryIds}</span>
                                            </li>
                                        </ul>

                                        <div className="absolute top-4 right-28">
                                            <strong className="font-normal" style={{fontSize: "0.8em"}}>등록일 :</strong>
                                            <span style={{fontSize: "0.8em"}}>{item.regdate.substring(0, 10).replaceAll("-", ".")}</span>
                                            {updateDate && updateDate.length > 8 && <>
                                            <em> / </em>
                                            <strong className="font-normal" style={{fontSize: "0.8em"}}>수정일 :</strong>
                                            <span style={{fontSize: "0.8em"}}>{updateDate}</span>
                                            </>}
                                        </div>

                                        {/* <!-- 완료 !text-sky-500 !bg-sky-50 추가--> */}
                                        <button type="button" className={item.review === 0 ? "btn-white-s absolute top-16 right-4" : "btn-white-s absolute top-16 right-4 !text-sky-500 !bg-sky-50"} onClick={() => onReview(item)}>{item.review === 0 ? "통과" : "완료"}</button>

                                        <div className="absolute right-4 top-4">
                                            <button type="button" aria-label="수정" className="disabled:bg-transparent disabled:text-slate-300" disabled={item.review === 1}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                </svg>
                                            </button>
                                            <label className="inp-toggle">
                                                <input type="checkbox" value="" className="peer" defaultChecked={item.is_publicOpen} />
                                                <div className="peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-sky-500"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            )
                        })}

                    </ul>}

                    <div className="relative mt-5">

                        {/* <!-- pagenation --> */}
                        <div className="text-center">
                            <nav className="pagenation" aria-label="Pagination">

                                {listOfPage && (listOfPage as number[]).length > 0 &&
                                <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                    <span className="sr-only">Previous</span>
                                    <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                    </svg>
                                </div>}

                                {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                return (
                                    currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                )})}

                                {listOfPage && (listOfPage as number[]).length > 0 &&
                                <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                    <span className="sr-only">Next</span>
                                    <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                    </svg>
                                </div>}
                                
                            </nav>
                        </div>
                        {/* <!-- .// pagenation --> */}
                    </div>
                </div>

                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : modalShow ? "" : "none"}}>
                    <div className="dim"></div>
                    <div className="popup-contents">
                        <div className="popup-contents-inner">
                            <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                            <div className="popup-box max-w-xl min-w-[576px]">
                                {/* <!-- 팝업 내용 --> */}
                                <div className="inner">
                                    <button type="button" className="btn-close" onClick={onReviewClose}>
                                        <span className="sr-only">Close</span>
                                        <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                    <h2 className="pop-title">심사 확인</h2>
                                    <div className="px-5">
                                        <div className="py-8 text-center">
                                            <p>심사 완료된 콘텐츠로 변경 하시겠습니까?</p>
                                        </div>
                                    </div>
                                    <div className="my-5 text-center">
                                        <button type="button" className="btn-sky" onClick={onReviewOk}>확인</button>

                                    </div>
                                </div>
                                {/* <!-- .//팝업 내용 --> */}
                            </div>
                        </div>
                    </div>
                </div>

                {viewContentsUpdateHistory &&
                <PopupContentsUpdateHistory selectedItem={selectedItem} onClose={() => {
                    setSelectedItem(null);
                    setViewContentsUpdateHistory(false);
                }} showModal={props.showModal} showLoading={props.showLoading} />}

                <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />
            
            </div>

            {/* {viewAnalogousArticles &&
            <AnalogousArticles brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewAnalogousArticles(false);
            }} />} */}

        </div>
    );  
}
export default ContentsList;
