import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { count } from "console";
import MetaAddEdit from "./MetaAddEdit";
import { CurriInfo, IBrand, ICurriInfo, IMenu, IMeta, headers } from "../common";
import '../common.scss';
import { logger } from '../logger';
import MetaStudyMapEdit from "./MetaStudyMapEdit";

interface IMetaStudyMap {
    brand: IBrand;
    menu: IMenu;
    item: IMeta;
    onClose: () => void;    
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaStudyMap(props: IMetaStudyMap) {

    // const [metaList, setMetaList] = useState([] as IMeta[]);
    const [viewList, setViewList] = useState(false);
    const [topMeta, setTopMeta] = useState(null as any);
    const [metaHList, setMetaHList] = useState([] as IMeta[]);

    const [currItem, setCurrItem] = useState(null as any);
    const [parentItem, setParentItem] = useState(null as any);
    const [isEditing, setIsEditing] = useState(false);
    const [editType, setEditType] = useState("");
    const [maxDepth, setMaxDepth] = useState(0);

    const [title, setTitle] = useState("");
    
    let prevView = false;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            logger.log("selected item", props.item);
            if(props.item) {
                setMaxDepth(props.item.max_depth ? props.item.max_depth : 5);
                getDescendantsList(props.item);
                // getMetaOfMetaExById(String(props.item.id));
            }
        }
        bDone = true;
    }, [])

    // useEffect(() => {
    //     if(props.menu) {
    //         logger.log("props.menu");
    //         // changeMenu();
    //     }
    // }, [props.menu])

    // const changeMenu = () => {
    //     logger.log("menu", util.getCurrentMenu());

    //     setViewList(false);

    //     const _name = MetaMenuInfo[props.menu.menu2].name;
    //     const _title = MetaMenuInfo[props.menu.menu2].title;
    //     getMetaList("1", _name);
    //     setTitle(_title);
    // }

    useEffect(() => {
        if(props.brand) {
        }
    }, [props.brand])

    useEffect(() => {
        if(metaHList && metaHList.length > 0) {
            metaHList.map((item) => {
                if(item.depth === 2) {
                    getValueOfContentArea(String(item.id));
                }    
            });

            //정합성 체크로직
            // testParent(metaHList);
        }
    }, [metaHList])

    async function testParent(metalist: IMeta[]) {

        for (let i = 0; i < metalist.length; i++) {
            const meta = metalist[i];
            let _pcode = "";
            const arr = meta.code.split("-");
            for (let i = 0; i < arr.length-1; i++) {
                if(_pcode === "")
                    _pcode = arr[i];
                else 
                    _pcode = _pcode + "-" + arr[i];
            }
            
            const parent = await util.getMetaByCode(_pcode);
            if(parent) {
                if(meta.parent_id !== (parent as IMeta).id) {
                    logger.log("testParent", meta, (parent as IMeta));
                    alert("정합성 오류!!! " + meta.name + "(" + meta.id + ")");
                } else {
                    logger.log("testParent ok", i, metalist.length);
                }
            }
        }

        logger.log("정합성 테스트 완료!!!");
    }

    async function getValueOfContentArea(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);

        const _contentArea = list.find((meta, _) => meta.name === "contentArea");
        if(_contentArea) {
            const meta = metaHList.find((item) => item.id === Number(id));
            if(meta) {
                meta.title = meta.val + "(내용영역:" + _contentArea.val + ")";
                logger.log("getValueOfContentArea", meta.title);
            }
        }
    }

    async function getDescendantsList(meta: IMeta) {

        props.showLoading(true);

        const list = await util.getDescendantsList(meta.code);
        const _list = list.filter((item) => item.code !== meta.code);
        // const __list = _list.filter((item) => item.is_active === true);

        const top = list.find((item) => item.code === meta.code);
        setTopMeta(top);
        logger.log("getDescendantsList", _list);  

        setMetaHList(_list);

        props.showLoading(false);
    }

    // async function getMetaList(depth: string, name: string) {

    //     props.showLoading(true);

    //     const list = await util.getMetaList(depth, name);
    //     const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
    //     setMetaList(_list);
    //     setViewList(true);

    //     props.showLoading(false);
    // }

    function onAddTopMeta(): void {
        setEditType("unit1");
        setParentItem(null);  
        setCurrItem(null);
        setIsEditing(true);
    }
    
    function onAddChildMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta, type: string): void {
        e.stopPropagation();

        logger.log("onAddChildMeta setParentItem", item);
        setEditType(type);
        setParentItem(item);  
        setCurrItem(null);
        setIsEditing(true);
    }

    function onEditMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta): void {
        e.stopPropagation();

        let _pcode = "";
        const arr = item.code.split("-");
        for (let i = 0; i < arr.length-1; i++) {
            if(_pcode === "")
                _pcode = arr[i];
            else 
                _pcode = _pcode + "-" + arr[i];
        }
        // logger.log("onEditMeta setCurrItem", item, _pcode);
        // const _pcode = item.code.substring(0, item.code.length-5);

        let _parentItem = metaHList.find((item) => item.code === _pcode);
        if(!_parentItem) {
            _parentItem = topMeta;
            if(!_parentItem) {
                logger.log("onEditMeta setCurrItem", _parentItem, _pcode);
                alert("parentItem 가져오기 실패!!!");
                return;
            }    
        }
        setParentItem(_parentItem);
        logger.log("onEditMeta setCurrItem", item, _parentItem);

        setEditType(item.name === "studyMap_1" ? "1" : item.name === "studyMap_2" ? "2" : "unit1");
        setCurrItem(item);
        setIsEditing(true);
    }

    function onCloseEdit(isEdited: boolean, from: string): void {

        if(isEdited) {            
            getDescendantsList(props.item);
        }

        setIsEditing(false);
    }

    return (
    <div>

        {!isEditing && viewList &&
        <div>
            <table>
                <caption className="sr-only">{title}</caption>
                <thead>
                    <tr>
                        <th scope="col" className="px-3">메타 값</th>
                        <th scope="col" className="px-3">Key</th>
                        <th scope="col" className="px-3">코드</th>
                        <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                        <th scope="col" className="px-3" style={{width:"80px"}}>추가</th>
                        <th scope="col" className="px-3" style={{width:"100px"}}>등록자​</th>
                        <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                    </tr>
                </thead>
                
                <tbody>
                    {metaHList && (metaHList as IMeta[]).length === 0 &&
                    <tr>
                        <td colSpan={7}>등록된 메타 정보가 없습니다.</td>
                    </tr>}

                    {metaHList && (metaHList as IMeta[]).map((item, idx) => {
                        let _code = item.code;
                        const arrCode = item.code.split("-");
                        // _code = arrCode[arrCode.length-1];
                        _code = _code.replace(arrCode[0]+"-", "");

                        let clsName = "";
                        if(item.depth === 1) {
                            clsName = "";
                        } else if(item.depth === 2) {
                            clsName = "";
                        } else if(item.depth === 3) {
                            clsName = "pl-5";
                        } else if(item.depth === 4) {
                            clsName = "pl-10";
                        } else if(item.depth === 5) {
                            clsName = "pl-15";
                        } else if(item.depth === 6) {
                            clsName = "pl-20";
                        } else if(item.depth === 7) {
                            clsName = "pl-25";
                        }

                        const bAdd1 = item.depth === maxDepth;
                        const bAdd2 = item.depth === maxDepth+1;
                        
                        // logger.log("item", item.depth, bAdd1, bAdd2);
                        
                        let name = item.title ? item.title : item.val;

                    return (
						<tr key={idx} style={{backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}>
							<td className="text-left"><p className={clsName}>{name}</p></td>
                            <td className="text-center">{item.id}</td>
							<td className="text-left">{_code}</td>
                            <td>{item.is_active ? "사용" : "미사용"}</td>
							<td className="w-24">
                                {bAdd1 &&
                                <button type="button" className="btn-white-s" onClick={(e) => onAddChildMeta(e, item, "1")}>추가1</button>}
                                {bAdd2 &&
                                <button type="button" className="btn-white-s" onClick={(e) => onAddChildMeta(e, item, "2")}>추가2</button>}
                            </td>
                            <td className="leading-4">{item.creator}</td>
							<td className="w-24">
                                {(item.name === "studyMap1" || item.name === "studyMap_1" || item.name === "studyMap_2") &&
                                <button type="button" className="btn-white-s" onClick={(e) => onEditMeta(e, item)}>보기</button>}
							</td>
						</tr>
                    )
                    })}
                </tbody>

            </table>

            <div className="relative mt-5">
                <div className="flex">
                    <button type="button" className="btn-white mr-2" onClick={() => {
                        props.onClose();
                    }}>목록</button>
                    {/* <button type="button" className="btn-white" onClick={onAddMetaToCurrent}>추가<br/>(1Depth)</button> */}
                    <div className="text-right" style={{width: "100%"}}>
                        <button type="button" className="btn-sky" onClick={onAddTopMeta}>등록</button>
                    </div>
                </div>
            </div>
            
        </div>}
        
        {isEditing && 
            <MetaStudyMapEdit metalist={metaHList} brand={props.brand} type={editType} item={currItem} parentItem={parentItem} onCloseEdit={onCloseEdit} showModal={props.showModal} showLoading={props.showLoading} />
        }

    </div>
    );
}

export default MetaStudyMap;
