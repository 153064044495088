import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, IUser } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";

interface IPopupContentsList {
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
    onRequestIndex: (item: ITextBookIndex) => void;
}

function PopupContentsList(props: IPopupContentsList) {

    const [list, setList] = useState([] as any);
    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [listCount, setListCount] = useState(0);

    const curriInfo = util.getCurrentCurriInfo();

    const numOfViewPage = 10;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            refreshList();
        }
        bDone = true;
    }, [])

    function onClose() {
        props.onClose();
    }

    const refreshList = () => {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        const data = {
            brand_id: brand.id ? brand.id : null,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        const list = await util.getTextbookIndexList(data);
        setList(list);
        logger.log("onSearch", data, list);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }
        }
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const TB_keyword = document.getElementById("searchKeyword") as HTMLInputElement;        
        
        const TB_curriBook = document.getElementById("curriBook") as HTMLSelectElement;
        const TB_curriBook_val = curriInfo.curriBook[TB_curriBook.selectedIndex-1];

        // const TB_curriUnit = document.getElementById("MM_Unit") as HTMLSelectElement;
        // const TB_curriUnit_val = TB_curriUnit.value;

        // const TB_curriYear = document.getElementById("TB_curriYear") as HTMLSelectElement;
        // const TB_curriYear_val = curriInfo.curriYear[TB_curriYear.selectedIndex-1];
        // const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
        // const TB_curriSchool_val = curriInfo.curriSchool[TB_curriSchool.selectedIndex-1];
        // const TB_usage = document.getElementById("TB_usage") as HTMLSelectElement;
        // const TB_usage_val = TB_usage.value;

        const data = {
            brand_id: brand.id ? brand.id : null,
            keyword: TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined,

            // curriYear: TB_curriYear_val ? TB_curriYear_val.id : undefined,
            // curriSchool: TB_curriSchool_val ? TB_curriSchool_val.id : undefined,
            curriBook: TB_curriBook_val ? TB_curriBook_val.id : undefined,
            // curriUnit: TB_curriUnit_val ? TB_curriUnit_val : undefined,
            // usage: TB_usage ? TB_usage_val : undefined,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{maxWidth:"60rem", maxHeight:"60rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">목차 불러오기</h2>

                            {/* <!-- 검색영역 --> */}
                            <div className="px-8 py-8 border border-gray-300 rounded-xl mt-5 ml-5 mr-5 mb-5">
                                <div className="text-right">
                                    <fieldset style={{display: "inline-flex", alignItems: "center"}}>
                                        <label htmlFor="sel-title">{"교과과정"}</label>
                                        <select id="curriBook">
                                            <option value="">전체</option>
                                            {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>              
                                            )})}
                                        </select>
                                        <label htmlFor="sel-meta" className="ml-5">검색어</label>
                                        {/* <select id="MM_Unit">
                                            <option value="">전체</option>
                                            <option value="curriUnit1">1Depth</option>
                                            <option value="curriUnit2">2Depth</option>
                                            <option value="curriUnit3">3Depth</option>
                                            <option value="curriUnit4">4Depth</option>
                                            <option value="curriUnit5">5Depth</option>
                                        </select> */}
                                        <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" />
                                        <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                                            <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                        </button>
                                    </fieldset>
                                </div>
                            </div>
                            {/* <!-- .//검색영역 --> */}

                            {/* <!-- table --> */}
                            <div className="ml-5 mr-5">
                                <table>
                                    <caption className="sr-only">목차 리스트</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pl-4 pr-3" style={{textAlign: "center"}}>번호</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>제목</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>등록일</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>등록자</th>
                                            {/* <th scope="col" className="pl-3 pr-4" style={{textAlign: "center"}}>불러오기</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && (list as ITextBookIndex[]).length === 0 &&
                                        <tr className="text-center">    
                                            <td colSpan={4}>등록된 목차가 없습니다. 교과서 목차를 등록 해 주세요.</td>
                                        </tr>}
                                        {list && (list as ITextBookIndex[]).map((item, idx) => {

                                            const totalCnt = item.full_count ? item.full_count : 0;
                                            const _numOfPage = 10;
                                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                                            return(
                                                <Tooltip key={idx} title="불러오기" arrow>
                                                    <tr className="rt-tr action" onClick={() => {
                                                        props.onRequestIndex(item);
                                                    }}>

                                                        <td className="rt-td">{_num}</td>
                                                        <td className="rt-td"><a href="#">{item.name}</a></td>
                                                        <td className="rt-td">{item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>
                                                        <td className="rt-td">{item.creator_name}</td>
                                                        {/* <td>
                                                            <button type="button" className="btn-white" style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}} onClick={() => {

                                                            }} >불러오기</button>
                                                        </td> */}
                                                    </tr>    
                                                </Tooltip>

                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- .//table -->	 */}                

                            <div className="relative mt-10 mb-5">

                                {/* <!-- pagenation --> */}
                                <div className="text-center">
                                    <nav className="pagenation" aria-label="Pagination">

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Previous</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}

                                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                        return (
                                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                        )})}

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Next</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}
                                        
                                    </nav>
                                </div>
                                {/* <!-- .// pagenation --> */}
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>
        </div>        
    );
}
  
export default PopupContentsList;
