import { useEffect, useState } from "react";
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, IMeta, latex_delimiters, latex_macros } from "../common";
import { logger } from '../logger';
import PopupContentsList from "./PopupContentsList";
import Latex from 'react-latex-next';

interface IMainArticleInfo {
    id: number;
    curriInfo: string;
    studyMapInfo: string;
    source: string;
    hashTags: string;
}

interface IAnalogousArticlesEdit {
    selectedItem: IArticle | null;
    brand: IBrand;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
    onClose: (isDirty: boolean) => void;    
}
  
function AnalogousArticlesEdit(props: IAnalogousArticlesEdit) {

    const [list, setList] = useState([] as any);
    const [viewList, setViewList] = useState(false);
    const [listCount, setListCount] = useState(0);
    const [mainArticle, setMainArticle] = useState<IArticle>(null as any);
    const [mainArticleInfo, setMainArticleInfo] = useState<IMainArticleInfo>(null as any);

    const [viewMainArticle, setViewMainArticle] = useState(false);
    const [viewAnalogousArticle, setViewAnalogousArticle] = useState(false);
    const [viewTwinArticle, setViewTwinArticle] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const currMenu = util.getCurrentMenu();
    const currBrand = util.getCurrentBrand();

    const numOfViewPage = 10;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            const AA_mainArticle = document.getElementById("AA_mainArticle") as HTMLInputElement;
            if(AA_mainArticle) {
                AA_mainArticle.value = "";
            }

            if(props.selectedItem) {
                onSelectMain(props.selectedItem);
            } else {
                const _mainArticleInfo : IMainArticleInfo = {
                    id: -1,
                    curriInfo: "",
                    studyMapInfo: "",
                    source: "",
                    hashTags: ""
                }
                setMainArticleInfo(_mainArticleInfo);    
            }
        }
        bDone = true;
    }, [])

    async function onSelectMain(item: any) {
        logger.log("onSelectContent", item);
        if(item as IArticle) {
            const AA_mainArticle = document.getElementById("AA_mainArticle") as HTMLInputElement;
            AA_mainArticle.value = item.name + " (" + item.id + ")";
            setMainArticle(item);
        }        
        setViewMainArticle(false);

        const articleInfo = await util.getArtcleInfo({"id": item.id});
        logger.log("articleInfo", articleInfo);    
        if(articleInfo) {

            let curriInfo = "";
            const curriBook = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "curriBook");
            if(curriBook) {
                curriInfo = curriBook.val + "";
            }
            // const curriUnit1 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "curriUnit1");
            // if(curriUnit1) {
            //     curriInfo = curriInfo + " > " + curriUnit1.val;
            //     // curriInfo = curriUnit1.val;
            // }
            // const curriUnit2 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "curriUnit2");
            // if(curriUnit2) {
            //     curriInfo = curriInfo + " > " + curriUnit2.val + "<br/>";
            // }
            // const curriUnit3 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "curriUnit3");
            // if(curriUnit3) {
            //     curriInfo = curriInfo + " > " + curriUnit3.val;
            // }
            // const curriUnit4 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "curriUnit4");
            // if(curriUnit4) {
            //     curriInfo = curriInfo + " > " + curriUnit4.val;
            // }
            // const curriUnit5 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "curriUnit5");
            // if(curriUnit5) {
            //     curriInfo = curriInfo + " > " + curriUnit5.val;
            // }

            let studyMap = "";
            const studyMap1 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "studyMap1");
            if(studyMap1) {
                studyMap = studyMap1.val;
            }
            const studyMap2 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "studyMap2");
            if(studyMap2) {
                studyMap = studyMap + " > " + studyMap2.val;
            }
            const studyMap3 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "studyMap3");
            if(studyMap3) {
                studyMap = studyMap + " > " + studyMap3.val + "";
            }
            const studyMap_1 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "studyMap_1");
            if(studyMap_1) {
                studyMap = studyMap + " > " + studyMap_1.val + "";
            }
            const studyMap_2 = (articleInfo.metaMap as IMeta[]).find((meta) => meta.name === "studyMap_2");
            if(studyMap_2) {
                studyMap = studyMap + " > " + studyMap_2.val;
            }

            let hashTag = "";
            const arrHashTag = articleInfo.hashTag as string[];
            if(arrHashTag) {
                arrHashTag.map((item) => {
                    if(item.trim() !== "") {
                        hashTag = hashTag + (item[0] !== "#" ? "#" : "") + item + "<br/>";
                    }
                });    
            }

            let source = "-";
            if(articleInfo.source) {
                source = articleInfo.source;
            }

            const mainArticleInfo : IMainArticleInfo = {
                id: articleInfo.id,
                curriInfo: curriInfo,
                studyMapInfo: studyMap,
                hashTags: hashTag,
                source: source,
            }
            setMainArticleInfo(mainArticleInfo);

            const data = {
                "article_id_a": articleInfo.id,
                //"article_id_b":"5",
                // "type": 0,  //0: 유사, 1: 쌍둥이
            }

            props.showLoading(true);

            setViewList(false);
    
            const tmpList = await util.getAnalogousArticles(data);
            if(tmpList) {
                setList(tmpList);
                logger.log("getAnalogousArticles", data, tmpList);    
            }
            
            setViewList(true);    
    
            props.showLoading(false);                
        }
    }

    async function onSelectAnalogous(article: IArticle) {
        logger.log("onSelectAnalogous", article);
        if(article) {
            const _list = JSON.parse(JSON.stringify(list));
            const found = (_list as IArticle[]).find((item) => item.id === article.id);
            if(!found) {
                _list.push(article);
            }
            setList(_list);
        }   
        
        setViewAnalogousArticle(false);
    }

    async function onSelectMultiAnalogous(articles: IArticle[]) {
        logger.log("onSelectMultiAnalogous", articles);
        if(articles as IArticle[]) {
            const _list = JSON.parse(JSON.stringify(list));
            for (let i = 0; i < articles.length; i++) {
                const article = articles[i];                    
                const found = (_list as IArticle[]).find((item) => item.id === article.id);
                if(!found) {
                    _list.push(article);
                }
            }
            setList(_list);
        }   
        
        setViewAnalogousArticle(false);
    }

    async function onSelectTwin(article: IArticle) {
        logger.log("onSelectTwin", article);
        if(article) {
            // const _list = JSON.parse(JSON.stringify(list));
            // _list.push(article);
            // setList(_list);
        }   
        
        setViewTwinArticle(false);
    }

    async function onSelectMultiTwin(articles: IArticle[]) {
        logger.log("onSelectMultiTwin", articles);
        if(articles as IArticle[]) {
            // const _list = JSON.parse(JSON.stringify(list));
            // for (let i = 0; i < articles.length; i++) {
            //     const article = articles[i];                
            //     _list.push(article);
            // }
            // setList(_list);
        }   
        
        setViewTwinArticle(false);
    }

    function onDelete() {

        const AA_list_ck = document.getElementsByName("AA_list_ck");

        let _list = JSON.parse(JSON.stringify(list)) as IArticle[];

        for (let i = 0; i < AA_list_ck.length; i++) {
            const element = AA_list_ck[i] as HTMLInputElement;
            if(element.checked) {                
                _list = _list.filter((item) => item.id !== list[i].id);
                element.checked = false;
            }
        }

        logger.log("onDelete", _list);

        setList(_list);
    }

    async function onSave() {

        let article_ids : string[] = [];
        for (let i = 0; i < list.length; i++) {
            article_ids.push(list[i].id);
        }

        props.showLoading(true);

        const data = {
            "article_id_a": mainArticleInfo.id,
            "article_id_b_list": article_ids,
            "type": 0,
            // "type": 0,  //0: 유사, 1: 쌍둥이
        }

        logger.log("onSave", data);

        await util.saveAnalogousArticles(data);

        props.showLoading(false);   
        
        props.onClose(true);
    }

    async function onPreView(item: IArticle) {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        if(item) {

            let url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&article_id="+item.id+"&token="+util.getUserInfo("token");

            // if(searchData.searchField === "creator" && searchData.search === util.getUserInfo("uid")) {
            //     let ids = "";
            //     const _list = list as IArticle[];
            //     for (let i = 0; i < _list.length; i++) {
            //         const a = _list[i];
            //         if(ids === "") {
            //             ids = String(a.id);
            //         } else {
            //             ids = ids + "," + String(a.id);
            //         }
            //     }    
            //     url = url + "&article_ids=" + ids;
            // }
            window.open(url, "_blank", "noopener, noreferrer");        
        }
    }
    
    return (
        <div>
            <div className="top-title">
                <h3>{"유사문항 관리"}</h3>
            </div>

            <h4>대표문항 등록</h4>
            {/* <!-- table --> */}
            <table>
                <tbody>
                    <tr>
                        <th scope="row" className="w-56" style={{backgroundColor: "#bbbbbb", color: "#ffffff"}}>대표문항</th>                    
                        <td className="text-left">
                            <input type="text" id="AA_mainArticle" className="w-avail" placeholder="대표 문항을 먼저 검색하여 불러와 주세요." readOnly />
                        </td>
                        <td className="w-8" style={{borderLeftWidth:0}}>
                            <button type="button" className="btn-sky-s" onClick={() => {        
                                setViewMainArticle(true);                        
                            }}>검색</button>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56" style={{backgroundColor: "#bbbbbb", color: "#ffffff"}}>대표문항 정보</th>                    
                        <td className="text-left" colSpan={2} style={{padding: 0}}>
                            <table className="border-none">
                                <tbody>
                                    <tr>
                                        <th scope="row" className="text-center w-28">교과과정</th>                    
                                        {/* <td className="text-left" dangerouslySetInnerHTML={{__html: mainArticleInfo?.curriInfo}}></td> */}
                                        <td className="text-left">
                                            {mainArticleInfo && 
                                            <Latex delimiters={latex_delimiters} macros={latex_macros}>{mainArticleInfo?.curriInfo}</Latex>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-center w-28">학습맵</th>                    
                                        {/* <td className="text-left" dangerouslySetInnerHTML={{__html: mainArticleInfo?.studyMapInfo}}></td> */}
                                        <td className="text-left">
                                            {mainArticleInfo && 
                                            <Latex delimiters={latex_delimiters} macros={latex_macros}>{mainArticleInfo?.studyMapInfo}</Latex>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-center w-28">출처</th>                    
                                        <td className="text-left">{mainArticleInfo?.source}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-center w-28">해시태그</th>                    
                                        {/* <td className="text-left">{mainArticleInfo?.hashTags}</td> */}
                                        <td className="text-left" dangerouslySetInnerHTML={{__html: mainArticleInfo?.hashTags}}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="flex justify-center items-center mt-5">
                <h4 className="text-left w-56">유사문항 등록</h4>
                <span className="text-right mt-3 w-avail">
                    <button type="button" className="btn-sky-s" disabled={mainArticle ? false : true} onClick={() => {        
                        onDelete();                        
                    }}>삭제</button>
                    <button type="button" className="btn-sky-s ml-1" disabled={mainArticle ? false : true} onClick={() => {                                
                        setViewAnalogousArticle(true);                        
                    }}>검색</button>
                </span>
            </div>

            {/* <!-- table --> */}
            <table className="mt-5">
                <thead>
                    <tr>
                        <th style={{display:""}} scope="col" className="text-center w-16">
                            <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                            const AA_list_ck = document.getElementsByName("AA_list_ck");
                            for (let i = 0; i < AA_list_ck.length; i++) {
                                const element = AA_list_ck[i] as HTMLInputElement;
                                if(!element.disabled) {
                                    element.checked = (e.target as HTMLInputElement).checked;
                                }                                    
                            }
                        }} /></th>
                        <th scope="col" className="px-3 text-center w-60">유사문항</th>
                        <th scope="col" className="px-3 text-center w-avail">유사문항 정보</th>
                    </tr>
                </thead>
                <tbody>

                    {list &&
                        <tr style={{display: (list as IArticle[]).length === 0 ? "" : "none"}}>
                            <td className="h-64" colSpan={3}>
                                {"등록된 유사문항이 없습니다."}<br/>
                                {"대표문항을 등록한 뒤 유사문항 등록이 가능합니다."}
                            </td>
                        </tr>}

                    {list && (list as any[]).map((item, idx) => {
                        
                        let bFound = false;

                        return (
                            <tr key={idx}>                        
                                <td style={{display:""}}>
                                    <input id={"AA_list_ck_"+idx} name="AA_list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" defaultChecked={bFound} onClick={(e) => {
                                        const AA_list_ck = document.getElementsByName("AA_list_ck");
                                        let cntChecked = 0;
                                        for (let i = 0; i < AA_list_ck.length; i++) {
                                            const element = AA_list_ck[i] as HTMLInputElement;
                                            if(element.checked) {
                                                cntChecked = cntChecked + 1;
                                            }
                                        }
                                        const all_check = document.getElementById("all_check") as HTMLInputElement;
                                        if(cntChecked === AA_list_ck.length) {                                                
                                            all_check.checked = true;                                                
                                        } else {
                                            all_check.checked = false;                                                
                                        }
                                    }}/>
                                </td>
                                <td className="">{"유사문항"+(idx+1)} ({item?.id})</td>
                                <td className="flex items-center justify-between">
                                    <span className="text-left">{item?.name}</span>
                                    <span className="text-right">
                                        <button type="button" className="btn-sky-s ml-1" onClick={() => {                                
                                            onPreView(item);
                                        }}>미리보기</button>
                                        {/* <button type="button" className="btn-sky-s ml-1" onClick={() => {   
                                            setViewTwinArticle(true);                                                     
                                        }}>쌍둥이</button> */}
                                        <button type="button" className="btn-sky-s ml-1" onClick={() => {                                
                                            onPreView(item);
                                        }}>상세</button>
                                    </span>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
            {/* <!-- .//table -->	 */}

            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-sky" onClick={() => props.onClose(false)}>{"목록"}</button>
                    <button type="button" className="btn-sky" onClick={onSave}>{"저장"}</button>
                </div>
            </div>

            {viewMainArticle &&
            <PopupContentsList brand={props.brand} textType={"Analogous_Main"} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectMain} onClose={function (): void {
                setViewMainArticle(false);
            } } />}

            {viewAnalogousArticle &&
            <PopupContentsList brand={props.brand} textType={"Analogous_Analogous"} multiSelect={true} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectAnalogous} onSelectMulti={onSelectMultiAnalogous} onClose={function (): void {
                setViewAnalogousArticle(false);
            } } />}

            {viewTwinArticle &&
            <PopupContentsList brand={props.brand} textType={"Analogous_Twin"} multiSelect={true} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectTwin} onSelectMulti={onSelectMultiTwin} onClose={function (): void {
                setViewTwinArticle(false);
            } } />}

            {/* {viewContentsUpdateHistory &&
            <PopupContentsUpdateHistory selectedItem={selectedItem} onClose={() => {
                setSelectedItem(null);
                setViewContentsUpdateHistory(false);
            }} showModal={props.showModal} showLoading={props.showLoading} />}

            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} /> */}

        </div>
    );
}
export default AnalogousArticlesEdit;
