import { ChangeEvent, useEffect, useState } from "react";
import * as util from '../util';
import { IBrand, IEngTemp, IMeta, _type2List, _type3TextList } from "../common";
import { logger } from '../logger';
import MyButton from "../MyButton";

interface ILibraryExceptionRuleRegister {
    brand: IBrand;
    selectedItem: IEngTemp | null;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function LibraryExceptionRuleRegister(props: ILibraryExceptionRuleRegister) {

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    
    const [curriDepth, setCurriDepth] = useState(0);
        
    const curriInfo = util.getCurrentCurriInfo();
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        if(!bDone) {
            
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     props.OnClose();
            //     return;
            // }

            const selectedItem = props.selectedItem;
            if(selectedItem) {
                init(selectedItem.id);
            } else {
                (document.getElementById('LR_rd-on') as HTMLInputElement).checked = true;
                (document.getElementById('LR_rd-off') as HTMLInputElement).checked = false;
                (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = true;
                (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = false;
            }
        }
        bDone = true;

    }, [])

    async function init(id: number) {

        props.showLoading(true);
        const result = await util.getExceptionRuleInfo(id);
        props.showLoading(false);
        
        if(result) {

            const selectedItem  = result as IEngTemp;

            logger.log("init", selectedItem);

            (document.getElementById('LR_title') as HTMLInputElement).value = selectedItem.name;

            (document.getElementById('LR_rd-on') as HTMLInputElement).checked = selectedItem.is_active;
            (document.getElementById('LR_rd-off') as HTMLInputElement).checked = !selectedItem.is_active;    
            (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = selectedItem.is_publicOpen;
            (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = !selectedItem.is_publicOpen;
                
            const metaMap = selectedItem.metaMap;
            if(metaMap && metaMap.length > 0) {
                const curriSchool = metaMap.find((item) => item.name === "curriSchool");
                if(curriSchool) {
                    const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
                    LR_school.value = curriSchool.val;
                }
                const curriSubject = metaMap.find((item) => item.name === "curriSubject");
                if(curriSubject) {
                    const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
                    LR_subject.value = curriSubject.val;
                }
            }
            
            const curriBook = curriInfo?.curriBook.find((meta, _) => meta.id === selectedItem.curriBook);
    
            if(curriInfo && curriBook) {
    
                const _bookDescendantsList = await getDescendantsList(curriBook.code);
    
                const LR_textbook = (document.getElementById("LR_textbook") as HTMLSelectElement);
                if(LR_textbook) {
                    LR_textbook.value = curriBook.val;
                }
    
                if(_bookDescendantsList && _bookDescendantsList.length > 0) {
                    const curriUnit1s = _bookDescendantsList.filter((item) => item.parent_id === curriBook.id);
                    setCurriUnit1(curriUnit1s);
        
                    setTimeout(() => {
                        const curriUnit1 = curriUnit1s.find((meta, _) => meta.id === selectedItem.curriUnit1);
                        if(curriUnit1) {
                            (document.getElementById("LR_curriUnit1") as HTMLSelectElement).value = String(curriUnit1.id);
            
                            const curriUnit2s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit1.id);
                            setCurriUnit2(curriUnit2s);
                            setTimeout(() => {
                                const curriUnit2 = curriUnit2s.find((meta, _) => meta.id === selectedItem.curriUnit2);
                                if(curriUnit2) {
                                    (document.getElementById("LR_curriUnit2") as HTMLSelectElement).value = String(curriUnit2.id);
            
                                    const curriUnit3s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit2.id);
                                    setCurriUnit3(curriUnit3s);
                                    setTimeout(() => {
                                        const curriUnit3 = curriUnit3s.find((meta, _) => meta.id === selectedItem.curriUnit3);
                                        if(curriUnit3) {
                                            (document.getElementById("LR_curriUnit3") as HTMLSelectElement).value = String(curriUnit3.id);
            
                                            const curriUnit4s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit3.id);
                                            setCurriUnit4(curriUnit4s);
                                            setTimeout(() => {
                                                const curriUnit4 = curriUnit4s.find((meta, _) => meta.id === selectedItem.curriUnit4);
                                                if(curriUnit4) {
                                                    (document.getElementById("LR_curriUnit4") as HTMLSelectElement).value = String(curriUnit4.id);
            
                                                    const curriUnit5s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit4.id);
                                                    setCurriUnit5(curriUnit5s);
                                                    setTimeout(() => {
                                                        const curriUnit5 = curriUnit5s.find((meta, _) => meta.id === selectedItem.curriUnit5);
                                                        if(curriUnit5) {
                                                            (document.getElementById("LR_curriUnit5") as HTMLSelectElement).value = String(curriUnit5.id);
                                                        }                            
                                                    }, 10);
                                                }                            
                                            }, 10);
                                        }                            
                                    }, 10);    
                                }                            
                            }, 10);        
                        }                            
                    }, 10);    
                }
            }
        }
    }

    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        
        const curriBook = curriInfo?.curriBook.find((meta, _) => meta.val === e.target.value);
        if(curriBook) {
            getDescendantsList(curriBook.code);

            const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
            const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
            const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
            const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
            const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
        
            if(LR_html_curriUnit1) {
                LR_html_curriUnit1.selectedIndex = 0;
            }
            if(LR_html_curriUnit2) {
                LR_html_curriUnit2.selectedIndex = 0;
            }
            if(LR_html_curriUnit3) {
                LR_html_curriUnit3.selectedIndex = 0;
            }
            if(LR_html_curriUnit4) {
                LR_html_curriUnit4.selectedIndex = 0;
            }
            if(LR_html_curriUnit5) {
                LR_html_curriUnit5.selectedIndex = 0;
            }
    
            setCurriUnit2([]);
            setCurriUnit3([]);
            setCurriUnit4([]);
            setCurriUnit5([]);
        } else {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit2) {
            LR_html_curriUnit2.selectedIndex = 0;
        }
        if(LR_html_curriUnit3) {
            LR_html_curriUnit3.selectedIndex = 0;
        }
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit3) {
            LR_html_curriUnit3.selectedIndex = 0;
        }
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;    
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function OnClose(): void {
        props.OnClose();
    }

    function onSave() {

        if(!curriInfo) {
            return;
        }

        const LR_title = document.getElementById("LR_title") as HTMLInputElement;
        const LR_title_val = LR_title.value;
        if(LR_title_val === "") {
            LR_title.focus();
            props.showModal("", "키워드을 입력해 주세요.");
            return;
        }
        
        const LR_textbook = document.getElementById("LR_textbook") as HTMLSelectElement;
        if(LR_textbook.value === "") {
            props.showModal("", "교과를 선택해 주세요.");
            return;
        }
        const LR_textbook_val = curriInfo.curriBook[LR_textbook.selectedIndex-1];

        const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
        const LR_school_val = curriInfo.curriSchool[LR_school.selectedIndex];
        
        const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
        const LR_subject_val = curriInfo.curriSubject[LR_subject.selectedIndex];

        const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        const LR_html_curriUnit1_val = LR_html_curriUnit1 ? LR_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[LR_html_curriUnit1.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit2_val = LR_html_curriUnit2 ? LR_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[LR_html_curriUnit2.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit3_val = LR_html_curriUnit3 ? LR_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[LR_html_curriUnit3.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit4_val = LR_html_curriUnit4 ? LR_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[LR_html_curriUnit4.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit5_val = LR_html_curriUnit5 ? LR_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[LR_html_curriUnit5.selectedIndex-1] as IMeta : undefined;
    
        const LR_rd_on = document.getElementById('LR_rd-on') as HTMLInputElement;    
        const LR_rd_on_val = LR_rd_on.checked

        const LR_rd_public_on = document.getElementById('LR_rd-public-on') as HTMLInputElement;    
        const LR_rd_public_on_val = LR_rd_public_on.checked

        const brandId = props.brand.id;

        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");
        
        const listMetaMap : IMeta[] = [];

        listMetaMap.push(LR_school_val);
        listMetaMap.push(LR_subject_val);

        const data = {
            "id": props.selectedItem ? props.selectedItem.id : undefined,
            "brand_id": brandId,
            "name": LR_title_val, 
            "is_active": LR_rd_on_val, 
            "is_publicOpen": LR_rd_public_on_val, 

            "creator": props.selectedItem ? undefined : uid,
            "creator_name": props.selectedItem ? undefined : uname,
            "creator_id": props.selectedItem ? undefined : _id,
            
            "updater": props.selectedItem ? uid : undefined,
            "updater_name": props.selectedItem ? uname : undefined,
            "updater_id": props.selectedItem ? _id : undefined,
                        
            "curriBook": LR_textbook_val?.id,
            "curriUnit1": LR_html_curriUnit1_val ? LR_html_curriUnit1_val.id : undefined,
            "curriUnit2": LR_html_curriUnit2_val ? LR_html_curriUnit2_val.id : undefined,
            "curriUnit3": LR_html_curriUnit3_val ? LR_html_curriUnit3_val.id : undefined,
            "curriUnit4": LR_html_curriUnit4_val ? LR_html_curriUnit4_val.id : undefined,
            "curriUnit5": LR_html_curriUnit5_val ? LR_html_curriUnit5_val.id : undefined,
            
            "metaMap": listMetaMap, 
        };

        logger.log("onSave", data);
    
        _onSave(data, props.selectedItem ? "update" : "insert");
    }

    async function _onSave(data: any, type: string) {

        props.showLoading(true);

        const result = await util.exceptionRuleForSave(data, type);
        logger.log("_onSave", result);

        props.showLoading(false);

        props.showModal("", "저장이 완료 되었습니다.");

        props.OnClose();         
    }

    return (
        <div>

            <div>
                <h4>기본 정보 등록</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>
                        <tr>
							<th scope="row" className="w-56">브랜드</th>
							<td className="text-left">{props.brand.name}</td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-class">학교 급<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_school" name="sel-school">
                                    {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-subject">교과목<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_subject" name="sel-subject">
                                    {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-textbook">교과<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_textbook" name="sel-textbook" onChange={(e) => onChangeCurriBook(e)}>
                                    <option value="">교과</option>              
                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-unit">교과과정<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">

                                <select className="mr-2" id="LR_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                    <option value="0">2Depth</option>              
                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                    <option value="0">3Depth</option>              
                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                    <option value="0">4Depth</option>              
                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                    <option value="0">5Depth</option>              
                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">KEY</th>
							<td className="text-left">{props.selectedItem ? props.selectedItem.id : ""}</td>
						</tr>

                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">키워드<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="LR_title" className="w-1/2" />
                            </td>
						</tr>
                        {/* <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">설명</label></th>
							<td className="text-left">
                                <input type="text" id="LR_description" className="w-avail" />
                            </td>
						</tr> */}
                        <tr>
							<th scope="row" className="w-56">작성자</th>
                            {props.selectedItem &&
                            <td className="text-left">{props.selectedItem.creator_name} ({props.selectedItem.creator})</td>}
							{!props.selectedItem &&
                            <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
						</tr>
                        <tr>
							<th scope="row" className="w-56">등록일</th>
                            {props.selectedItem &&
							<td className="text-left">{props.selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
							{!props.selectedItem &&
							<td className="text-left">{util.getDate()}</td>}
                            </tr>
                        <tr>
							<th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-on" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-on">사용</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-off" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-off">미사용</label>
                                    </span>
                                </div>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">공개 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-on" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-on">공개</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-off" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-off">비공개</label>
                                    </span>
                                </div>
                            </td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
                        {!props.selectedItem &&
                        <button type="button" className="btn-sky" onClick={onSave} >저장</button>}
                        {props.selectedItem &&
                        <MyButton type="button" className="btn-sky" onClick={onSave} children={"수정"} creator={props.selectedItem?.creator} showModal={props.showModal} />}
					</div>
				</div>

            </div>
        </div>
    );
}
export default LibraryExceptionRuleRegister;