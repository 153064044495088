import React, { useEffect, useState } from 'react';
import './App.css';

import { IBrand, ICurriInfo, IMenu, IMeta } from './common';
import LibraryManager from './library/LibraryManager';
import MetaManager from './meta/MetaManager';
import * as util from './util';
import SystemManager from './system/SystemManager';
import ContentsManager from './contents/ContentsManager';
import TextBookManager from './textbook/TextBookManager';
import ReportManager from './bbs/ReportManager';
import { logger } from './logger';
import AITutorManager from './aitutor/AITutorManager';
import TeachingManager from './teaching/TeachingManager';
import InstrumentManager from './instrument/InstrumentManager';
import VisangBookManager from './visangbook/VisangBookManager';

interface IContents {
    view: boolean;
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function Contents(props: IContents) {

    const currMenu = util.getCurrentMenu();

    useEffect(() => {
    }, [])

    useEffect(() => {
        if(props.brand) {
        }
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log(props.menu);
        }
    }, [props.menu])

    return (
        
        <div className="contents-wrap" style={{display:props.view ? "" : "none"}}>

            {currMenu && currMenu.menu1 === 0 && <LibraryManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 1 && <ContentsManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 2 && <TextBookManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 3 && <VisangBookManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 4 && <TeachingManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 5 && <InstrumentManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 6 && <ReportManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 7 && <MetaManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 8 && <AITutorManager view={true} menu={props.menu} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}
            {currMenu && currMenu.menu1 === 9 && <SystemManager view={true} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
            
        </div>

    );      
}

export default Contents;
