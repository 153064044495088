import { useEffect, useRef } from "react";
import axios from "axios";
import * as util from './util';
import { HOST, IUser, headers } from "./common";
import { logger } from './logger';

interface ILogin {
    view: boolean;
    isTest: boolean;
    afterLogin?: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function Login(props: ILogin) {
    
    useEffect(() => {
        if(props.view) {

            // logger.log("localStorage", localStorage);

            const rememberme = localStorage.getItem("remember-me");
            if(rememberme === "1") {
                const remember_me = (document.getElementById('remember-me') as HTMLInputElement)
                remember_me.checked = true;
                const savedId = localStorage.getItem("saved-id");
                if(savedId) {
                    (document.getElementById('uid') as HTMLInputElement).value = savedId;
                }
            }
            (document.getElementById('uid') as HTMLInputElement).focus();
        }
    }, [props.view])

    async function _onLogin(data: any) {

        props.showLoading(true);

        const result = await util.login(data);
        if(result) {
            const _userInfo = result as IUser;
            logger.log("onLogin", _userInfo);
    
            if(_userInfo) {
    
                util.setUserInfo(null);
                util.setCurrentBrand(null);
                util.setCurrentMenu(null);
                util.setCurrentAuth(null);
                util.setCurrentCurriInfo(null);
    
                util.setUserInfo(JSON.stringify(_userInfo));
                if(props.afterLogin) {
                    props.afterLogin();
                }
    
                (document.getElementById('password') as HTMLInputElement).value = "";
    
            } else {        
                props.showModal("", "로그인 정보를 다시 확인해 주세요.");
            }
    
        } else {
            props.showModal("", "로그인 정보를 다시 확인해 주세요.");
        }

        props.showLoading(false);

    }

    const onLogin = () => {

        const apiHost = (document.getElementById('apiHost') as HTMLSelectElement);
        if(apiHost) {
            util.setHost(apiHost.value);
        } else {
            util.setHost(HOST);
        }

        const uid = (document.getElementById('uid') as HTMLInputElement).value
        const password = (document.getElementById('password') as HTMLInputElement).value

        logger.log("uid", uid, "password", password);
        if(uid === "" ) {
            props.showModal("", "아이디를 입력해 주세요.");
            return;
        } else if(password === "") {
            props.showModal("", "비밀번호를 입력해 주세요.");
            return;
        }

        const rememberme = (document.getElementById('remember-me') as HTMLInputElement)
        if(rememberme.checked) {
            localStorage.setItem("remember-me", "1");
            localStorage.setItem("saved-id", uid);
        } else {
            localStorage.setItem("remember-me", "0");
            localStorage.setItem("saved-id", "");
        }
        
        const data = { uid: uid, pwd: password };
        // axios.post(HOST+'/api/auth/login', data, { headers: headers
        // }).then((response) => {

        //     const _userInfo = response.data.vo;
        //     logger.log("onLogin", _userInfo);
        //     if(_userInfo && _userInfo.token) {

        //         util.setUserInfo(null);
        //         util.setCurrentBrand(null);
        //         util.setCurrentMenu(null);
        //         util.setCurrentAuth(null);
        //         util.setCurrentCurriInfo(null);
    
        //         util.setUserInfo(JSON.stringify(_userInfo));
        //         if(props.afterLogin) {
        //             props.afterLogin();
        //         }

        //         (document.getElementById('password') as HTMLInputElement).value = "";

        //     } else {        
        //         props.showModal("", "로그인 정보를 다시 확인해 주세요.");
        //     }
        
        // }).catch((err) => {
        //     logger.log(err)
        // });    
        _onLogin(data);    
    } 

    function onKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            onLogin();
        }
    }
    
    return (
        <div className="wrap max-w-screen-2xl mx-auto" style={{display: props.view ? "" : "none"}}>

            {props.isTest &&
            <select id="apiHost" name="apiHost" onChange={(e) => (e)}>
                <option value="https://t-cmsapi.vsaidt.com">https://t-cmsapi.vsaidt.com</option>              
                <option value="https://cmsapi.vsaidt.com">https://cmsapi.vsaidt.com</option>              
                <option value="https://visangcbs.link">https://visangcbs.link</option>              
                <option value="http://localhost:8080">http://localhost:8080</option>              
                <option value="http://172.24.0.132:8080">http://172.24.0.132:8080</option>              
            </select>}

            <div className="flex w-full h-screen">
                <div className="flex justify-center items-center w-[55%] h-full border-slate-200 border-r">
                    <div className="ml-11 mr-5">
                        <p className="text-sky-500 font-display text-3xl leading-normal tracking-tight">비상교육의 AIDT 콘텐츠 은행에<br />오신 것을 환영합니다!</p>
                        <span className="block mt-10 text-lg tracking-tight text-slate-600">
                            비상교육의 콘텐츠 은행은 초중고 맞춤형 수업을 위한 콘텐츠 관리 시스템으로, 학생들의 수준별<br />
                            콘텐츠는 물론 다양한 교과 활동이 가능한 콘텐츠를 저작 및<br />
                            관리할 수 있는 환경을 제공합니다.
                            <br /><br />
                            500만 학생을 위한 500만 개의 교과서!
                        </span>
                    </div>
                </div>
                <div className="w-[45%] flex justify-center items-center flex-col">
                <h1><img src="./images/logo.png" alt="Visang"/></h1>
                <form action="" method="" className="w-96">
                    <div className="flex items-center">
                        <label htmlFor="id" className="sr-only">아이디</label>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(71,85,105)" className="w-8 h-8 mr-3">
                            <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                        </svg>
                        <input id="uid" name="uid" type="text" required className="block w-[340px]"/>
                    </div>
                
                    <div className="flex items-center mt-3">
                        <label htmlFor="password" className="sr-only">비밀번호</label>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(71,85,105)" className="w-8 h-8 mr-3">
                            <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z" clipRule="evenodd" />
                        </svg>
                        <input id="password" name="password" type="password" autoComplete="current-password" required className="block w-[340px]" onKeyUp={(e) => onKeyUp(e)}/>
                    </div>
                    <div className="flex items-center ml-11 mt-3">
                        <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" onChange={() => {
                        }}/>
                        <label htmlFor="remember-me" className="ml-2 block text-sm leading-6 text-gray-900">아이디 저장하기</label>
                    </div>
                    <button onClick={() => onLogin()} type="button" className="block mt-5 ml-11 w-[340px] rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2">Login</button>
                    <span className="block mt-5 text-sm text-slate-500 text-center">아이디/비밀번호를 분실하신 CP님은 관리자에게 연락 바랍니다</span>
                </form>
                </div>
            </div>
            
        </div>    
        
    );
}

export default Login;
