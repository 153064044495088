import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import User from "./Admin";
import Brand from "./Brand";
import AuthGroup from "./AuthGroup";
import { IBrand, IMenu, leftmenu_2, IMeta, ICurriInfo } from "../common";
import Admin from "./Admin";
import MyPage from "./MyPage";
import { logger } from '../logger';

interface ISystemManager {
    view: boolean;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function SystemManager(props: ISystemManager) {

    const [title, setTitle] = useState("");

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu");
            changeTitle();
        }
    }, [props.menu])

    const changeTitle = () => {
        const _title = leftmenu_2[currMenu.menu1][currMenu.menu2];
        setTitle(_title);
    }

    return (
    <div>
        <div className="top-title">
            <h3>{title}</h3>
        </div>

        {currMenu.menu2 === 0 && <Brand menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
        {currMenu.menu2 === 1 && <AuthGroup menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
        {currMenu.menu2 === 2 && <Admin menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} />}
        {currMenu.menu2 === 3 && <MyPage showModal={props.showModal} showLoading={props.showLoading} />}
        
    </div>
    );
}
export default SystemManager;
