import axios from "axios";
import { authGroupInfo_new, headers, IAITutor, IArticle, IAuthGroup, IBrand, ICurriInfo, ILibrary, ILibraryFile, IMenu, IMeta, IMetaMap, isDev, ISet, ITextBook, ITextBookEvaluation, ITextBookIndex, ITextBookTab, IUser, VERSION } from "./common";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { logger } from './logger';
import { ISearchData } from "./meta/MetaManager";

const noBrandName = [   
    "curriBook", 
    "curriUnit1",
    "curriUnit2",
    "curriUnit3",
    "curriUnit4",
    "curriUnit5",
    "curriUnit6",
    "subjectAbility",
    "evaluationArea",
    "difficulty",
    "studyMap",
    "studyMap1",
    "studyMap2",
    "studyMap_1",
    "studyMap_2",
    "contentArea",
];

let g_MenuAuth = [false, false, false, false, false, false];
export function setMenuAuth(auth: boolean[] = [false, false, false, false, false, false]) {
    g_MenuAuth = auth;
}
export function getMenuAuth() {
    return g_MenuAuth;
}
let g_EditAuth = [false, false, false, false, false, false];
export function setEditAuth(auth: boolean[] = [false, false, false, false, false, false]) {
    g_EditAuth = auth;
}
export function getEditAuth() {
    return g_EditAuth;
}

let HOST = localStorage.getItem("HOST");
export function setHost(host: string) {
    localStorage.setItem("HOST", host);
    HOST = host;
}
export function getHost() {
    return HOST;
}

export function setCMSVersion(value: string | null) {
    if(value === null) {
        localStorage.removeItem("cms_version");
    } else {
        localStorage.setItem("cms_version", value);
    }
}

export function getCMSVersion() : string | null {
    const cms_version = localStorage.getItem("cms_version");
    return cms_version;
}

export function setUserInfo(value: string | null) {
    if(value === null) {
        localStorage.removeItem("user");
    } else {
        localStorage.setItem("user", value);
    }
}

export function getUserInfo(key: string = "") : any {
    const user = localStorage.getItem("user");
    if(key === "" && user) {
        const jsonUser = JSON.parse(user);
        return jsonUser;
    } else {
        if(user) {
            const jsonUser = JSON.parse(user);
            if(jsonUser) {
                // if(key === "token") {
                //     logger.log("getUserInfo token", jsonUser[key]);
                // }
                return jsonUser[key];
            }
        }
    }
    return null;
}

export async function onCheckLoginApi() {
    
    let result = false;

    // const token = getUserInfo("token");
    // if(!token) {
    //     return false;
    // }    

    await axios.post(HOST+'/api/auth/checkUserId', {}, { headers: headers()
    }).then((response) => {
        logger.log(response);
        
        if(response.data.errorMessage && response.data.errorMessage === "anonymousUser") {
            // alert("사용자 토큰이 만료되었습니다.\n\n다시 로그인해 주시기 바랍니다." + "(" + getUserInfo("token") + ")");
            alert("사용자 토큰이 만료되었습니다.\n다시 로그인해 주시기 바랍니다.");
        } else {
            const api_uid = response.data.vo as string;
            const uid = getUserInfo("uid") as string;
        
            if(uid === api_uid) {
                result = true;
            }    
        }

    }).catch((err) => {
        logger.log(err);
    });          

    return result;
} 

export async function checkLogin() {

    const sourceVersion = VERSION;
    const localVersion = getCMSVersion();

    if(sourceVersion !== localVersion) {
        logger.log("version different", sourceVersion, localVersion);
        if(isDev) {
            alert("새버전으로 업데이트 되었습니다. \n\n강력 새로고침 후 이용해 주세요!!!");
            setCMSVersion(sourceVersion);
        }
    }

    // const bLogin = await onCheckLoginApi();    
    // return bLogin;

    const user = localStorage.getItem("user");
    // logger.log("checkLogin", user, localStorage);
    if(user !== null && user !== undefined && user !== "null") {
        const jsonUser = JSON.parse(user);
        const access_token = jsonUser["token"];

        if(access_token === null || access_token === "" || access_token === undefined || access_token.length < 10) {
            logger.log("no access_token!!!");
            return false;
        } else {
            const token_expired = jsonUser["tokenExpireDate"];
            if(token_expired === null) {
                logger.log("no tokenExpireDate!!!");
                return false;
            } else {
                const now = new Date();
                // logger.log(token_expired, now.getTime()/1000);
                if(Number(token_expired) < now.getTime()/1000) {
                  logger.log("token expired!!!");
                  return false;
                }  
            }          
        }         
        // logger.log("checkLogin true");
        return true;
    }
    logger.log("no userinfo");
    return false;
}

export async function login(data: any) {

    let result = null;

    await axios.post(HOST+'/api/auth/login', data, { headers: headers()
    }).then((response) => {
        logger.log(response);
        result = response.data.vo;
    }).catch((err) => {
        logger.log(err);
    });          

    return result;
}

export async function getAiTutorArticleList(data: any) {

    let result: IArticle[] = [];

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/AiTutorContents/articleList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IArticle[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getArticlePartList(data: any) {

    let result: IArticle[] = [];

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/AiTutor/articlepartlist', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IArticle[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getArticleList(data: any) {

    let result: IArticle[] = [];

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contents/articlelist', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IArticle[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getArtcleInfo(data: any) {

    let result: any = null;

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contents/articleInfo', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getArticleSearch(data: any) {

    let result: IArticle[] = [];

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contents/articleSearch', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IArticle[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getAnalogousMainArticles(data: any) {

    let result = null;

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contentsEx/getArticleAList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getAnalogousArticles(data: any) {

    let result = null;

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contentsEx/list', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function saveAnalogousArticles(data: any) {

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/contentsEx/save', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
        }).catch((err) => {
            logger.log(err);
        });          
    }
}

export async function updateArticle(data: any) {

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/contents/articleForSave', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
        }).catch((err) => {
            logger.log(err);
        });          
    }
}

export async function articlePartGroup(data: any) {

    let result = null;

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/log/articlePartGroup', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function changeContentsActiveStatus(data: any) {

    let result = null

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contents/changeActiveStatus', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function articleExForSave(data: any) {

    let result = null;

    const saveData = {
        "saveType" : "auto",
        "articleExtension" : data,
    };

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/articleEx/save', saveData, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function articleForSave(data: any) {

    let result = null;

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/contents/articleForSave', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function updateTutorusColumn(data: any) {

    let result = null;

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/AiTutorContents/updateTutorusColumn', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function aiTutorContentsForSave(data: any) {

    let result = null;

    const saveData = {
        "saveType" : "auto",
        "aiTutorContents" : data,
    };

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/AiTutorContents/aiTutorContentsForSave', saveData, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getSetsByArticleId(id: number) {

    let result : ISet[] = [];

    if(await checkLogin()) {
  
        await axios.get(HOST+'/api/contents/getSetsByArticleId?id='+id, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ISet[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getSetList(data: any) {

    let result: ISet[] = [];

    // data.myuid = getUserInfo("uid");

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contents/setlist', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ISet[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getSetSearch(data: any) {

    let result: ISet[] = [];

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contents/setSearch', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ISet[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getSetInfo(data: any) {

    let result: any = null;

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/contents/setInfo', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function setForSave(data: any, type: string) {

    let result: any = null;

    const saveData = {
        saveType: type,
        set: data,
    };

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/contents/setForSave', saveData, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function setForInsert(data: any) {

    let result: any = null;

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/contents/setForSave', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function copySet(data: any) {

    let result: any = null;

    if(await checkLogin()) {
  
        if(data) {
            data.loginUserId = getUserInfo("uid");
        }

        await axios.post(HOST+'/api/contents/copySet', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function deleteSet(data: any) {

    let result: any = null;

    if(await checkLogin()) {

        if(data) {
            data.loginUserId = getUserInfo("uid");
        }
    
        await axios.post(HOST+'/api/contents/deleteSet', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function copyTextbook(id: number) {

    let result: any = null;

    const data = {
                    "id": id, 
                    "loginUserId": getUserInfo("uid")
                };

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/textbook/copyTextbook', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {                
            } else {
                result = response.data.vo;
            }
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function deleteTextbook(id: number) {

    let result: any = null;

    if(await checkLogin()) {
  
        await axios.post(HOST+'/api/textbook/deleteTextbook', {id: id}, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getChildrenList(id: string, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IMeta[] = [];

    let url = "/api/system/meta/children/"+id+"?orderby_col="+orderby_col+"&orderby_dir="+orderby_dir;

    if(await checkLogin()) {    
        await axios.get(HOST+url, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getDescendantsList(code: string, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IMeta[] = [];

    if(await checkLogin()) {    
        await axios.get(HOST+'/api/system/meta/descendants/'+code, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getMetaList(depth: string, name: string, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IMeta[] = [];

    if(await checkLogin()) {
    
        let url = "/api/system/meta/list?depth="+depth+"&name="+name+"&orderby_col="+orderby_col+"&orderby_dir="+orderby_dir;

        if(noBrandName.includes(name)) {
            const brand = getCurrentBrand();
            if(brand) {
                url = url + "&brand_id="+brand.id;
            } else {
                logger.log("no brand");
                return result;
            }    
        }
        // logger.log("getMetaList", url);

        await axios.get(HOST+url, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getMetaNamesList(names: string[], is_active: boolean = true) {

    let result: IMeta[] = [];

    if(await checkLogin()) {
    
        const brand = getCurrentBrand();
        // const data = {names: names, brand_id: brand?.id, orderby_col: "id", orderby_dir: "desc"};
        const data = {names: names, orderby_col: "id", orderby_dir: "desc", is_active: is_active};

        let url = "/api/system/meta/list";

        await axios.post(HOST+url, data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getMetaOfMetaExById(id: string) {

    let result: IMeta[] = [];

    if(await checkLogin()) {
    
        let url = "/api/system/meta/metaEx/"+id;
    
        await axios.get(HOST+url, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getMetaByCode(code: string) {

    let result = null;

    if(await checkLogin()) {
    
        let url = "/api/system/meta/code/"+code;
    
        await axios.get(HOST+url, { headers: headers()
        }).then((response) => {
            // logger.log(response);
            result = response.data.vo as IMeta;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function searchMeta(data: any) {

    let result = null;

    if(await checkLogin()) {

        let url = "/api/system/meta/search";

        await axios.post(HOST+url, data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function changeActiveStatus(code: string, is_active: boolean) {

    let result: any = null;

    if(await checkLogin()) {

        const data = {   
            "code": code,  
            "is_active": is_active,
            "loginUserId": getUserInfo("uid"),
        }

        await axios.post(HOST+'/api/system/meta/changeActiveStatus', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function searchCurriBook(data: ISearchData, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IMeta[] = [];

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/system/meta/search/curriBook', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function searchStudyMap(data: ISearchData, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IMeta[] = [];

    if(await checkLogin()) {    
        await axios.post(HOST+'/api/system/meta/search/studyMap', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IMeta[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function insertMeta(metaList: IMeta[]) {
    if(await checkLogin()) {

        metaList.map((meta) => {
            if(noBrandName.includes(meta.name)) {
            } else {
                meta.brand_id = null;
            }    
        });

        await axios.post(HOST+'/api/system/meta/add', metaList, { headers: headers()
        }).then((response) => {
            logger.log(response);
        }).catch((err) => {
            logger.log(err);
        });          
    }
}

export async function updateMeta(meta: IMeta) {
    if(await checkLogin()) {    

        if(noBrandName.includes(meta.name)) {
        } else {
            meta.brand_id = null;
        }    

        await axios.post(HOST+'/api/system/meta/update', meta, { headers: headers()
        }).then((response) => {
            logger.log(response);
        }).catch((err) => {
            logger.log(err);
        });          
    }
}

export async function insertMetaMetaMap(metaMapList: IMetaMap) {

    if(await checkLogin()) {

        const meta = metaMapList.meta;
        if(noBrandName.includes(meta.name)) {
        } else {
            meta.brand_id = null;
        }

        await axios.post(HOST+'/api/system/meta/addMetaEx', metaMapList, { headers: headers()
        }).then((response) => {
            logger.log(response);
        }).catch((err) => {
            logger.log(err);
        });          
    }
}

export async function updateMetaMetaMap(metaMapList: IMetaMap) {

    if(await checkLogin()) {

        const meta = metaMapList.meta;
        if(noBrandName.includes(meta.name)) {
        } else {
            meta.brand_id = null;
        }

        await axios.post(HOST+'/api/system/meta/updateMetaEx', metaMapList, { headers: headers()
        }).then((response) => {
            logger.log(response);
        }).catch((err) => {
            logger.log(err);
        });          
    }
}

export async function addStudyMap(metaMapList: IMetaMap) {

    if(await checkLogin()) {

        await axios.post(HOST+'/api/system/meta/addStudyMap', metaMapList, { headers: headers()
        }).then((response) => {
            logger.log(response);
        }).catch((err) => {
            logger.log(err);
        });          
    }
}

export async function getLibraryList(type: string = "image", orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: ILibrary[] = [];

    if(await checkLogin()) {

        await axios.get(HOST+'/api/library/list?type_1='+type, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ILibrary[];

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getLibrarySearchList(data: any) {

    let result: ILibrary[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/library/search', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo as ILibrary[];            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getLibraryFileList(id: number, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: ILibraryFile[] = [];

    if(await checkLogin()) {

        await axios.get(HOST+'/api/library/libraryFilelist?library_id='+id , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo as ILibraryFile[];            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}


export async function getLibraryInfo(id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/library/libraryInfo', {"id": id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getLibraryMetaList(id: number, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IMeta[] = [];

    if(await checkLogin()) {

        await axios.get(HOST+'/api/library/libraryMetalist?library_id='+id , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo as IMeta[];            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function mediaForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        media : data,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/library/mediaForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function updateMigrationLibrary(data: any) {

    let result = false;
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/migration/updateMigrationLibrary', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = true;
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function updateMigrationLibraryByArticleId(data: any) {

    let result = false;
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/migration/updateMigrationLibraryByArticleId', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = true;
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextSearchList(data: any) {

    let result: any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/text/search', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        text : data,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/text/textForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function subtitleForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        subtitles : data,
    };
    
    // logger.log("subtitleForSave", saveData);

    if(await checkLogin()) {

        await axios.post(HOST+'/api/text/subtitleForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getMultiLangInfo(entry: string) {

    let result = null;

    const data = {
        "entry": entry,
    };

    logger.log("getMultiLangInfo", data);

    if(await checkLogin()) {

        await axios.post(HOST+'/api/text/libtextSubtitlesInfo', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getEngTempList(data: any) {

    let result : any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/engtemp/list', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getEngTempInfo(id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/engtemp/Info', {"id": id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function engtempForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        engtemp : data,
    };
    
    // logger.log("engtempForSave", saveData);

    if(await checkLogin()) {

        await axios.post(HOST+'/api/engtemp/engtempForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getExceptionRuleList(data: any) {

    let result : any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/exceptionRule/list', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getExceptionRuleSearch(data: any) {

    let result : any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/exceptionRule/search', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}
export async function getExceptionRuleInfo(id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/exceptionRule/Info', {"id": id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function exceptionRuleForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        exceptionRule : data,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/exceptionRule/exceptionRuleForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getReportList(data: any) {

    let result : any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/report/list', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getReportInfo(id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/report/Info', {"id": id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function reportForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        report : data,
    };
    
    // logger.log("engtempForSave", saveData);

    if(await checkLogin()) {

        await axios.post(HOST+'/api/report/reportForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextInfo(id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/text/Info', {"id": id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextBookInfo(id: number, detail: boolean = true) {

    let result = null;

    const data = {
        "id": id, 
        "detail": detail,
    };

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookInfo', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookIndexForSave(textbook_id: number, data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        textbook_id: textbook_id,
        textbookIndex: data,
        textbookCurriculumListJson: data.curriculumList,
    };
    
    // logger.log("saveData", saveData, JSON.stringify(saveData));

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookIndexForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                const arrResult = response.data.vo as any[];
                if(arrResult.length > 0) {
                    if(saveType === "insert") {
                        result = arrResult[0] as ITextBookIndex;
                    } else {
                        result = arrResult[0] as number;
                    }    
                }
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookIndexList(data: any, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: ITextBookIndex[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookIndexList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ITextBookIndex[];

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookCurriculumList(data: any, orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookCurriculumList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookCurriculumForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        textbookIndex_id: data[0].textbookIndex_id,    
        textbookCurriculumList: data,
    };
    
    logger.log(saveData);

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookCurriculumForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookList(data: any) {

    let result: ITextBook[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ITextBook[];

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookSearch(data: any) {

    let result: ITextBook[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookSearch', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ITextBook[];

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        textbook : data,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookSaveVersion(tb_id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookSaveVersion', {"id": tb_id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookDeployList(data: any) {

    let result: any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookDeployList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookDeployList_new(data: any) {

    let result: any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/deploy/deployList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookDeployStatusList(data: any) {

    let result: any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookDeployStatusList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookDeployChangeStatus(data: any) {

    let result = null;
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookDeployChangeStatus', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function deployChangeStatusId(data: any) {

    let result = null;
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/deploy/deployChangeStatusId', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getDeployServer() {

    let result: any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/deploy/deployServer', {}, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookIndexInfo(data: any) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookIndexInfo', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ITextBookIndex;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookIndexTabInfo(data: any) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookTabInfo', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo;

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookTabList(data: any) {

    let result: ITextBookTab[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookTabList', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as ITextBookTab[];

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookTabForSave(data: any, saveType: string, is_default: boolean = false) {
      
    let result = null;

    const saveData = {
        saveType : saveType,
        textbookTab : data,
        is_default: is_default,
    };
    
    logger.log(saveData);

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/textbookTabForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function deleteTextbookTab(id: number) {

    let result = null;

    const data = {
        id : id,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/deleteTextbookTab', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}


export async function deleteTextbookIndexTab(id: number) {

    let result = null;

    const data = {
        id : id,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbook/deleteTextbookIndexTab', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getTextbookEvaluationSearch(data: any) {

    let result: any[] = [];

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbookEvaluation/search', data, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as any[];

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function textbookEvaluationForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        textbookEvaluation : data,
    };
    
    logger.log(saveData);

    if(await checkLogin()) {

        await axios.post(HOST+'/api/textbookEvaluation/save', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function deployChangeStatus(data: any) {

    let result : {"succeed": boolean, "data": any} = {
        succeed: false,
        data: undefined
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/deploy/deployChangeStatus', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
                result = {succeed: false, data: response.data.errorMessage};
            } else {
                result = {succeed: true, data: response.data.vo};
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function contentsHistoryList(data: any) {

    let result: any[] = [];
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/deploy/contentsHistoryList', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function restoreArticle(data: any) {

    let result = null;
    
    if(await checkLogin()) {

        if(data) {
            data.loginUserId = getUserInfo("uid");
        }

        await axios.post(HOST+'/api/contents/restoreArticle', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
                // result = response.data.errorMessage;
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}
 
export async function deployRequestDateList(data: any) {

    let result: any[] = [];
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/deploy/deployRequestDateList', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getBrandList(orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IBrand[] = [];

    if(await checkLogin()) {

        await axios.get(HOST+'/api/system/brandlist', { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IBrand[];

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function addBrand(brand: IBrand) {

    if(!brand) {
        return;
    }

    logger.log("brand", brand);

    if(await checkLogin()) {
    
        await axios.post(HOST+'/api/system/brand/add', brand, { headers: headers()
        }).then((response) => {
            logger.log(response.data.vo)  
        }).catch((err) => {
            logger.log(err)
        });            
    }
}

export async function updateBrand(brand: IBrand) {

    if(!brand) {
        return;
    }

    logger.log("brand", brand);

    if(await checkLogin()) {
    
        await axios.post(HOST+'/api/system/brand/update', brand, { headers: headers()
        }).then((response) => {
            logger.log(response)  
        }).catch((err) => {
            logger.log(err)
        });            
    }
}

export async function getAuthGroupList(orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IAuthGroup[] = [];

    if(await checkLogin()) {
  
        await axios.get(HOST+'/api/system/authlist', { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IAuthGroup[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;

}

export async function addAuthGroup(authgroup: IAuthGroup) {

    if(!authgroup) {
        return;
    }

    logger.log("authgroup", authgroup);

    if(await checkLogin()) {
    
        await axios.post(HOST+'/api/system/authgroup/add', authgroup, { headers: headers()
        }).then((response) => {
            logger.log(response.data.vo)  
        }).catch((err) => {
            logger.log(err)
        });            
    }
}

export async function updateAuthGroup(authgroup: IAuthGroup) {

    if(!authgroup) {
        return;
    }

    logger.log("updateAuthGroup", authgroup);

    if(await checkLogin()) {
    
        await axios.post(HOST+'/api/system/authgroup/update', authgroup, { headers: headers()
        }).then((response) => {
            logger.log(response)  
        }).catch((err) => {
            logger.log(err)
        });            
    }
}

export async function getUserList(orderby_col: string = "id", orderby_dir: string = "desc") {

    let result: IUser[] = [];

    if(await checkLogin()) {
  
        await axios.get(HOST+'/api/system/userlist', { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IUser[];
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function getUserById(uid: string) {

    let result: IUser | undefined = undefined;

    if(await checkLogin()) {
  
        await axios.get(HOST+'/api/system/user?uid='+uid, { headers: headers()
        }).then((response) => {
            logger.log(response);
            result = response.data.vo as IUser;
        }).catch((err) => {
            logger.log(err);
        });          
    }

    return result;
}

export async function addUser(user: any) {

    if(!user) {
        return;
    }

    logger.log("addUser", user);

    if(await checkLogin()) {
    
        await axios.post(HOST+'/api/system/useradd', user, { headers: headers()
        }).then((response) => {
            logger.log(response)  
        }).catch((err) => {
            logger.log(err)
        });            
    }
}

export async function updateUser(user: any) {

    if(!user) {
        return;
    }

    logger.log("updateUser", user);

    let result = null;

    if(await checkLogin()) {
    
        await axios.post(HOST+'/api/system/userupdate', user, { headers: headers()
        }).then((response) => {
            logger.log(response)
            result = response.data.vo;
        }).catch((err) => {
            logger.log(err)
        });            
    }
    return result;
}

export async function getAITutorSearchList(data: any) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/AiTutor/search', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function aiTutorForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        aiTutor : data,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/AiTutor/aiTutorForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getAITutorInfo(id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/AiTutor/Info', {"id": id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getCurriToolSearchList(data: any) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/curriTool/search', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function curriToolForSave(data: any, saveType: string) {

    let result = null;

    const saveData = {
        saveType : saveType,
        curriTool : data,
    };
    
    if(await checkLogin()) {

        await axios.post(HOST+'/api/curriTool/curriToolForSave', saveData , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getCurriToolInfo(id: number) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/curriTool/Info', {"id": id} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getEngPron(url: string, reference: string, user: string) {

    let result = null;

    if(await checkLogin()) {

        await axios.post(HOST+'/api/transfer/engpron', {"url": url, "reference": reference, "user": user} , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export async function getEngPron_new(data: any) {

    let result = null;

    const _headers = {
        "Content-Type": "application/json; charset=UTF-8",
        "x-api-key": "$2b$12$/0nybzumrd5HjvsTJLbkz.RCY2jZSw22QEPrTNNgYJE4AQ2GXSdIe",
    }
    
    if(await checkLogin()) {

        await axios.post('https://t-tutorus.aidtclass.com/api/engpron', data , { headers: _headers
        }).then((response) => {
            logger.log(response);
            if(response.status === 200 && response.data) {
                result = response.data;            
            } else {
            }

        }).catch((err) => {
            logger.log(err);
        });          
    }
    
    return result;
}

export function getDate() {
    const today = new Date();
    const month_raw = today.getMonth() + 1;
    const year = today.getFullYear();
    const date_raw = today.getDate();

    let date = "";
    let month = "";

    if (date_raw<10)  {  date ="0"+date_raw.toString()} else {  date =date_raw.toString()}
    if (month_raw<10)  {  month ="0"+month_raw.toString()} else {  month =month_raw.toString()}

    return `${year}.${month}.${date}`;
}

const REACT_APP_AWS_S3_ACCESS_KEY_ID = "AKIAWXWURISTLFZRYNRJ";
const REACT_APP_AWS_S3_SECRET_ACCESS_KEY = "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";
const REACT_APP_S3_REGION = "ap-northeast-2";
const REACT_APP_S3_BUCKET = "vsaidt-contentsbank-fe-dvlp";

export async function handleS3Upload(file: File, filename: string) {

    const client = new S3Client({
        region: REACT_APP_S3_REGION,
        credentials: {
            accessKeyId: REACT_APP_AWS_S3_ACCESS_KEY_ID as string,
            secretAccessKey: REACT_APP_AWS_S3_SECRET_ACCESS_KEY as string,
        },
    });

    const params = {
        Bucket: REACT_APP_S3_BUCKET,
        Key: filename,
        Body: file /** object body */,
    };

    logger.log("handleS3Upload", client, params);

    const command = new PutObjectCommand(params);
    const data = await client.send(command);
    logger.log(data);

    return data;
};

// const arrExt = ["jpeg","jpg","png","gif","svg","mp4","mp3","html","json","csv","xls","xlsx","ods","hwp","hwpx","pdf","doc","docx","odt","ppt","pptx","odp","webm","wav"];
export async function S3Upload(files: File, targetUrl: string) {

    let result = false;
    let exts : string[] = [];

    await axios.post(HOST+'/s3/uploadExtension', {}, { headers: headers()
    }).then((response) => {
        logger.log(response);
        exts = response.data ? response.data : [];
    }).catch((err) => {
        logger.log(err);
    });          

    const fileExt = files.name.split('.').pop();
    if(fileExt) {
        if(!exts.includes(fileExt.toLowerCase())) {
            alert("허용되지 않은 파일 확장자 입니다.");
            return result;
        }
    }

    // construct form data
    const formData = new FormData();
    formData.append('files', files);
    formData.append('targetUrl', targetUrl);
 
    // make a POST request with Axios
    await axios.post(HOST+'/s3/uploadFiles', formData, {
        headers: {
            "Authorization": getUserInfo("token"),
            'Content-Type': 'multipart/form-data',
        },
    }).then((response) => {
        logger.log(response);
        result = true;
    }).catch((err) => {
        logger.log(err);
    });          

    return result;
}

export async function UploadFile(files: File, targetUrl: string, type: string) {

    // construct form data
    const formData = new FormData();
    formData.append('files', files);
    formData.append('targetUrl', targetUrl);
    formData.append('type', type);
    formData.append('tag', type);

    // make a POST request with Axios
    await axios.post(HOST+'/batch/upload', formData, {
        headers: {
            "Authorization": getUserInfo("token"),
            'Content-Type': 'multipart/form-data',
        },
    }).then((response) => {
        logger.log(response);
    }).catch((err) => {
        logger.log(err);
    });          
}

export function getCurrentBrand() {
    const currBrand = localStorage.getItem("currBrand");
    // logger.log("getCurrentBrand", currBrand);

    if(currBrand && currBrand !== "undefined") {
        const selectedBrand = JSON.parse(currBrand) as IBrand;
        return selectedBrand;
    }
    return null;
}

export function setCurrentBrand(brand: IBrand | null) {
    if(brand === null) {
        localStorage.removeItem("currBrand");
    } else {
        localStorage.setItem("currBrand", JSON.stringify(brand));
    }
    logger.log("setCurrentBrand", brand);
}

export function getCurrentMenu() : IMenu {
    const currMenu = localStorage.getItem("currMenu");
    // logger.log("getCurrentMenu", currMenu);

    if(currMenu) {
        return JSON.parse(currMenu) as IMenu;
    }
    return {menu1: 0, menu2: 0};
}

export function setCurrentMenu(menu: IMenu | null) {
    if(menu === null) {
        localStorage.removeItem("currMenu");
    } else {
        localStorage.setItem("currMenu", JSON.stringify(menu));
    }
}

export function getHasAuthMenu(creator: string) {

    let hasAuth = false;

    const authMenu = getAuthMenu(getCurrentMenu());
    if(authMenu > 100) {
        hasAuth = true;
    } else if(creator === getUserInfo("uid")) {
        hasAuth = true;
    }

    return hasAuth;    
}

export function getAuthMenu(menu: IMenu) {

    let auth = 0;

    const currAuth = getCurrentAuth();
    if(currAuth) {
        if(currAuth.indexOf("["+authGroupInfo_new[menu.menu1][menu.menu2]+"]1,") > -1) {            
            auth = 101;
        } 
        if(currAuth.indexOf("["+authGroupInfo_new[menu.menu1][menu.menu2]+"],") > -1) {
            auth = 1;
        } 
    }

    logger.log("getAuthMenu", auth);

    return auth;
}

export function getCurrentAuth() {
    const currAuth = localStorage.getItem("currAuth");
    // logger.log("getCurrentAuth", currAuth);

    if(currAuth) {
        const selectedAuth = JSON.parse(currAuth) as string;
        return selectedAuth;
    }
    return "";
}

export function setCurrentAuth(auth: string | null) {
    if(auth === null) {
        localStorage.removeItem("currAuth");
    } else {
        // const newAuth = {user: getUserInfo("uid"), auth: auth};
        localStorage.setItem("currAuth", JSON.stringify(auth));
    }
    logger.log("setCurrentAuth", auth);
}

let g_CurrentCurriInfo: string | null = null;
export function getCurrentCurriInfo() {
    const currCurriInfo = g_CurrentCurriInfo;
    if(currCurriInfo) {
        const _currCurriInfo = JSON.parse(currCurriInfo) as ICurriInfo;
        return _currCurriInfo;
    }
    return null;
}

export function setCurrentCurriInfo(currCurriInfo: ICurriInfo | null) {
    if(currCurriInfo === null) {
        g_CurrentCurriInfo = null;
    } else {
        g_CurrentCurriInfo = JSON.stringify(currCurriInfo);
    }
    logger.log("setCurrentCurriInfo", currCurriInfo);
}

export async function existsUrl(url: string) {
    try {
        const result = await fetch(url, { method: 'HEAD' });
        return result.ok;
    } catch (exception) {
        logger.log(exception);
    }
        
    return false;
}

export async function getBase64FromUrl(url: string) {
    
    var res = await fetch(url);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
        var reader  = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);
  
        reader.onerror = () => {
            return reject();
        };
        reader.readAsDataURL(blob);
    })
}

export function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

// export function setCurrentCurriInfo(currCurriInfo: ICurriInfo | null) {
//     if(currCurriInfo === null) {
//         localStorage.removeItem("currCurriInfo");
//     } else {
//         localStorage.setItem("currCurriInfo", JSON.stringify(currCurriInfo));
//     }
//     logger.log("setCurrentCurriInfo", currCurriInfo);
// }

// export async function existsUrl(url: string) {
//     const result = await fetch(url, { method: 'HEAD' });
//     return result.ok;
// }